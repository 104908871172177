import { Pipe, PipeTransform } from '@angular/core';
import { ResourceBookingLabels } from '@dm-workspace/types';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'resourceLabelsTooltip',
})
export class ResourceLabelsTooltipPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(labels: ResourceBookingLabels[]): string {
    return labels
      .map((value) => this.translateService.instant('TOOLTIPS.RESOURCE_BOOKING_LABEL.' + value.toUpperCase()))
      .join('\n');
  }
}
