import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dm-ui-badge-icon',
  templateUrl: './badge-icon.component.html',
  styleUrls: ['./badge-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeIconComponent {
  @HostBinding('class')
  @Input()
  color: 'primary' | 'secondary' | 'white' = 'white';
}
