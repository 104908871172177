import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'singularPlural',
})
export class SingularPluralPipe implements PipeTransform {
  public transform(value: number | null, singularKey: string, pluralKey: string): string {
    if (!value || value === 1) {
      return singularKey;
    }

    return pluralKey;
  }
}
