<div class="header">
  <ng-content select="[header]" />
  <!-- TODO: remove -->
  <h2 class="title" *ngIf="header">
    {{ header | translate }}
  </h2>

  <button type="button" class="close-button" (click)="close()" *ngIf="showCloseModal">
    <i class="icon i-close"></i>
  </button>
</div>

<ng-content select="[body]" />

<div class="footer">
  <ng-content select="[footer]" />
</div>
