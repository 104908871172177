import { ChangeDetectionStrategy, Component, forwardRef, inject, input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutocompleteService } from '@dm-workspace/data-access';
import { IAutocompleteResourceDetails } from '@dm-workspace/types';
import { map, Observable } from 'rxjs';
import { CustomSearchComponent } from '../customeSeach.class';

@Component({
  selector: 'dm-form-search-berth',
  templateUrl: './search-berth.component.html',
  styleUrl: './search-berth.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBerthComponent),
      multi: true,
    },
  ],
})
export class SearchBerthComponent extends CustomSearchComponent<IAutocompleteResourceDetails> {
  override placeholder = input<string>('SEARCH_FOR_A_BERTH');
  fetchItem(berthId: string): Observable<IAutocompleteResourceDetails> {
    return this.#autocompleteApiService.searchResource(berthId).pipe(map((value) => value[0]));
  }
  #autocompleteApiService = inject(AutocompleteService);
  fetchItems(search: string): Observable<IAutocompleteResourceDetails[]> {
    return this.#autocompleteApiService.searchResource(search);
  }
}
