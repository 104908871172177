import { DestroyRef, Directive, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { FiltersHelperService } from '@dm-workspace/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GenericObject } from '@dm-workspace/types';
import { isEmpty, removeEmptyProperties } from '@dm-workspace/utils';
import { map } from 'rxjs';
import { isEqual } from 'lodash';

@Directive()
export abstract class FiltersCollectionDirective<
  F extends FormGroup = FormGroup,
  V extends GenericObject = ReturnType<F['getRawValue']>,
> implements OnInit
{
  protected readonly filterHelper = inject(FiltersHelperService);
  protected readonly fb = inject(FormBuilder);
  readonly #destroyRef = inject(DestroyRef);

  @Input({ required: true }) public storageKey?: string;
  @Input() public defaultFilters: Partial<V>;
  @Output() public filtersChange = new EventEmitter<Partial<V>>();
  protected abstract readonly form: F;
  protected readonly filtersCount = signal(0);

  public ngOnInit() {
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        debounceTime(500),
        filter(() => this.form.valid),
        map((values) => removeEmptyProperties(values)),
        distinctUntilChanged(isEqual),
        tap(() => this.#onFormChange())
      )
      .subscribe();

    this.form.reset(this.#getInitialValue());
  }

  #onFormChange() {
    const formValue = this.form.getRawValue();
    for (const key in formValue) {
      const value = formValue[key];
      if (isEmpty(value)) {
        delete formValue[key];
      }
    }
    console.log(formValue);
    this.filterHelper.saveToStorage(this.storageKey, formValue);
    this.filterHelper.addToSearchParams(formValue);
    this.filtersCount.set(
      (Object.keys(formValue) as [keyof V]).filter((value) => !this.defaultFilters?.[value]).length
    );
    this.filtersChange.emit(formValue);
  }

  #getInitialValue(): Partial<V> {
    const storage = this.filterHelper.loadFromStorage(this.storageKey) as Partial<V>;
    if (storage) {
      return storage;
    }
    const parsedQueryParams = removeEmptyProperties(this.transformQueryParams(this.filterHelper.getParamsFromQuery()));
    return { ...this.defaultFilters, ...parsedQueryParams };
  }

  protected transformQueryParams(initValue: Partial<V>) {
    return initValue;
  }

  public resetForm(): void {
    this.form.reset(this.defaultFilters);
  }
}
