import { Directive, effect, Host, input, Self } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateUtils } from '../../../../utils/src/lib/ngb-date.utils';

export type InputMaxDateDatepicker = string | Date | NgbDateStruct;

@Directive({
  selector: '[dmInputMaxDate][dmForm][ngbDatepicker], [dmInputMinDate][dmForm][ngbDatepicker]',
})
export class InputMinMaxDateDatepickerDirective {
  dmInputMaxDate = input<InputMaxDateDatepicker>();
  dmInputMinDate = input<InputMaxDateDatepicker>();

  constructor(@Host() @Self() private ngbDatepicker: NgbInputDatepicker) {
    effect(() => {
      const maxDateStruct = NgbDateUtils.convertValueToDateStruct(this.dmInputMaxDate());
      const minDateStruct = NgbDateUtils.convertValueToDateStruct(this.dmInputMinDate());
      this.#setMinMaxDate(minDateStruct, maxDateStruct);
    });
  }

  #setMinMaxDate(minDateStruct: NgbDateStruct, maxDateStruct: NgbDateStruct) {
    const correctMaxValue = maxDateStruct ? Object.values(maxDateStruct).every((data) => !isNaN(data)) : false;
    const correctMinValue = minDateStruct ? Object.values(minDateStruct).every((data) => !isNaN(data)) : false;
    const dateStructKeys: (keyof NgbDateStruct)[] = ['year', 'month', 'day'];

    if (maxDateStruct && correctMaxValue) {
      this.ngbDatepicker.maxDate = maxDateStruct;
    }

    if (minDateStruct && correctMinValue && maxDateStruct && correctMaxValue) {
      const maxDate = new NgbDate(maxDateStruct.year, maxDateStruct.month, maxDateStruct.day);
      if (maxDate.after(minDateStruct)) {
        this.ngbDatepicker.minDate = minDateStruct;
      }
      if (dateStructKeys.every((key) => minDateStruct[key] === maxDateStruct[key])) {
        this.ngbDatepicker.minDate = minDateStruct;
        this.ngbDatepicker.maxDate = maxDateStruct;
      }
    } else if (minDateStruct && correctMinValue) {
      this.ngbDatepicker.minDate = minDateStruct;
    }
  }
}
