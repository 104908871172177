import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverflowSpinnerModule } from './overflow-spinner/overflow-spinner.module';
import { CardModule } from './card/card.module';

@NgModule({
  declarations: [],
  exports: [OverflowSpinnerModule, CardModule],
  imports: [CommonModule],
})
export class ModulesModule {}
