import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { NgbDate, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { DateRange } from '@dm-workspace/types';
import { LayoutService } from '@dm-workspace/core';

@Component({
  selector: 'dm-form-calendar-range',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarRangeComponent),
      multi: true,
    },
  ],
})
export class CalendarRangeComponent implements ControlValueAccessor, OnInit {
  //TODO: See if this component can be merged with dm-booking-ui-calendar-range
  @Input() initialDateRange: DateRange | null = null;

  @Input() set minDate(date: string) {
    if (!date) {
      return;
    }
    this.minDate_ = this.adapter.fromModel(date);
  }

  @Input() set maxDate(date: string) {
    if (!date) {
      return;
    }
    this.maxDate_ = this.adapter.fromModel(date);
  }

  @Output() dateRangeSelected = new EventEmitter<DateRange>();

  private destroy$: Subject<boolean> = new Subject<boolean>();

  dateRange: DateRange | null = null;
  value: DateRange | null = null;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDateStruct | null = null;
  toDate: NgbDateStruct | null = null;
  minDate_: NgbDateStruct;
  maxDate_: NgbDateStruct;
  displayMonths = 2;

  constructor(private layout: LayoutService, private adapter: NgbDateAdapter<string>) {}

  ngOnInit(): void {
    this.layout.isMobile$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
      this.displayMonths = isMobile ? 1 : 2;
    });

    if (this.initialDateRange) {
      this.fromDate = this.adapter.fromModel(this.initialDateRange.fromDate);
      this.toDate = this.adapter.fromModel(this.initialDateRange.toDate);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate && this.toDate) {
      this.dateRange = {
        fromDate: this.adapter.toModel(this.fromDate),
        toDate: this.adapter.toModel(this.toDate),
      };
      this.onChange(this.dateRange);
      this.dateRangeSelected.emit(this.dateRange);
    } else {
      this.onChange(null);
      this.dateRangeSelected.emit(null);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void = () => ({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    return;
  }

  writeValue(value: DateRange) {
    if (value !== undefined && value !== null) {
      this.value = value;
    }
  }

  daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isFirst(date: NgbDate) {
    return date.equals(this.fromDate) && !this.toDate && !date.before(this.hoveredDate);
  }

  isLast(date: NgbDate) {
    return date.equals(this.toDate) || date.day === this.daysInMonth(date.month, date.year);
  }
}
