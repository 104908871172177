import { UserType } from '../backend';
import { IMarinaPermissions } from './users.interface';

export interface IAuthUser {
  id: string;
  name: string;
  type: UserType;
  isActive: boolean;
  permissions: IMarinaPermissions;
}
