import { Injectable } from '@angular/core';
import { Point } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { IBerthBase, IMapTransformationResponse } from '@dm-workspace/types';
import { featureCollection, FeatureCollection, point, Polygon } from '@turf/helpers';
import concave from '@turf/concave';

@Injectable()
export class MapTransformationsService {
  getTransformedPts(el: SVGGraphicsElement): Point[] {
    const m = el.getScreenCTM();
    const bb = el.getBBox();
    const tpts = [
      this.matrixXY(m, bb.x, bb.y),
      this.matrixXY(m, bb.x + bb.width, bb.y),
      this.matrixXY(m, bb.x + bb.width, bb.y + bb.height),
      this.matrixXY(m, bb.x, bb.y + bb.height),
    ];

    return tpts;
  }

  public mapGeoToLat(geoJson: FeatureCollection<Polygon>): [number, number][][] {
    return geoJson.features.map((feature) => {
      const points = feature.geometry.coordinates[0];
      return points.map(([lat, lng]: Array<number>) => [lat, lng]);
    });
  }

  matrixXY(m: DOMMatrix, x: number, y: number): Point {
    return { x: x * m.a + y * m.c + m.e, y: x * m.b + y * m.d + m.f };
  }

  getLatLangFromXY(
    { y, x }: Point,
    neBoundInPx: google.maps.Point,
    swBoundInPx: google.maps.Point,
    offsetTop: number,
    map: google.maps.Map
  ): [number, number] {
    const procX = x / window.innerWidth;
    const procY = (y - offsetTop) / (window.innerHeight - offsetTop);
    const newLngInPx = (neBoundInPx.x - swBoundInPx.x) * procX + swBoundInPx.x;
    const newLatInPx = (swBoundInPx.y - neBoundInPx.y) * procY + neBoundInPx.y;
    const newLatLng = map.getProjection().fromPointToLatLng(new google.maps.Point(newLngInPx, newLatInPx));
    return [newLatLng.lng(), newLatLng.lat()];
  }

  transformSVGToGeo(
    rectangles: SVGGraphicsElement[],
    map: google.maps.Map,
    offsetTop: number
  ): Observable<IMapTransformationResponse> {
    return new Observable<IMapTransformationResponse>((subscriber) => {
      const berths: IMapTransformationResponse = {};
      const latLngBounds = map.getBounds();
      const neBound = latLngBounds.getNorthEast();
      const swBound = latLngBounds.getSouthWest();

      const neBoundInPx = map.getProjection().fromLatLngToPoint(neBound);
      const swBoundInPx = map.getProjection().fromLatLngToPoint(swBound);
      rectangles.forEach((rec) => {
        // const bounds = rec.getBoundingClientRect();
        const points = this.getTransformedPts(rec);
        const poinstLat: [number, number][] = [];
        points.forEach((point) => {
          poinstLat.push(this.getLatLangFromXY(point, neBoundInPx, swBoundInPx, offsetTop, map));
        });
        berths[rec.id] = [...poinstLat, poinstLat[0]];
      });
      subscriber.next(berths);
      subscriber.complete();
    });

    // this.berths = berths;
  }

  getBerthsPolygon(berths: Pick<IBerthBase, 'polygon'>[]): [number, number][] {
    const hull = concave(
      featureCollection(
        berths
          .map((value) => value?.polygon || [])
          .reduce((p, c) => [...p, ...c], [])
          .map((value) => point(value))
      )
    );
    return hull?.geometry?.coordinates?.[0] as [number, number][];
  }
}
