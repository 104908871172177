<button type="button" class="input-wrapper" (click)="onClickCopy()">
  <input type="text" [value]="placeholder ?? valueToCopy" readonly />
  <div class="icon-wrapper" #tooltip="ngbTooltip" [ngbTooltip]="tooltipCopiedSuccess" triggers="manual">
    <span class="icon i-copy"></span>
  </div>
</button>

<ng-template #tooltipCopiedSuccess>
  {{ 'COPIED' | translate }}!
</ng-template>
