import { Pipe, PipeTransform } from '@angular/core';
import { parse } from 'yaml';
import { YamlValue } from '../../../../types/src/libs/interfaces/yaml.interface';

@Pipe({
  name: 'yamlParse',
})
export class YamlParsePipe implements PipeTransform {
  transform<V>(value?: YamlValue<V>): V | null {
    if (!value) {
      return;
    }
    return parse(value) as V;
  }
}
