import { Component, inject } from '@angular/core';
import { ENV } from '@dm-workspace/types';
import { GoogleTagManagerService } from '@dm-workspace/core';

@Component({
  selector: 'dm-ncp-root',
  templateUrl: './ncp-app.component.html',
  styleUrl: './ncp-app.component.scss',
})
export class NcpAppComponent {
  #env = inject(ENV);
  #gtag = inject(GoogleTagManagerService);
  ngOnInit(): void {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    head.insertBefore(script, head.firstChild);
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=' + this.#env.recaptchaKey;
    this.#gtag.inject(this.#env.googleTagManager.ncp, false);
  }
}
