import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MARINA_URL_TOKEN, SELECTED_MARINA } from '@dm-workspace/types';
import { BehaviorSubject, Observable, switchMap, take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class MarinaCodeInterceptor implements HttpInterceptor {
  constructor(@Inject(SELECTED_MARINA) private selectedMarinaCodeSubject: BehaviorSubject<string>) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasMarinaToken(params: Record<string, any>): string | undefined {
    if (params) {
      return Object.keys(params).find((key: string) => params[key] === MARINA_URL_TOKEN);
    }
    return undefined;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hasUrl = request.url.includes(MARINA_URL_TOKEN);
    const marinaKey = request.params.keys().find((key) => request.params.get(key) === MARINA_URL_TOKEN);
    const bodyMarinaKey = request.body && this.hasMarinaToken(request.body);
    if (hasUrl || !!marinaKey || bodyMarinaKey) {
      return this.selectedMarinaCodeSubject.pipe(
        filter((marinaCode) => marinaCode !== null),
        take(1),
        switchMap((value) => {
          const cloneObject: {
            url?: string;
            setParams?: {
              [param: string]: string;
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            body?: any;
          } = {};
          if (hasUrl) {
            cloneObject.url = request.url.replace(MARINA_URL_TOKEN, value);
          }
          if (marinaKey) {
            cloneObject.setParams = {
              [marinaKey]: value,
            };
          }
          if (bodyMarinaKey && request.body && typeof request.body === 'object') {
            cloneObject.body = {
              ...request.body,
              [bodyMarinaKey]: value,
            };
          }
          const newRequest = request.clone(cloneObject);
          return next.handle(newRequest);
        })
      );
    }
    return next.handle(request);
  }
}
