import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'dm-ncp-marina-reviews',
  templateUrl: 'ncp-marina-reviews.component.html',
  styleUrl: 'ncp-marina-reviews.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpMarinaReviewsComponent {
  userTotal = input.required<number>();
  rating = input.required<number>();
}
