import { DmSentry } from '@dm-workspace/sentry-core';
import { ApiPrefix } from '@dm-workspace/types';
import { environment } from '@dm-workspace/environments';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NcpAppModule } from './ncp-app/ncp-app.module';
import 'hammerjs';

DmSentry.init({
  apiPrefix: ApiPrefix.newCustomerPanel,
  env: environment,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(NcpAppModule)
  .catch((err) => console.error(err));
