@if(showingNavbar()) {
  <dm-ncp-layout-header class="header" [showingLoginButton]="showingLoginButton()"/>
}

<main class="main">
 <router-outlet/>
</main>

@if (showingFooter()) {
  <dm-ncp-layout-footer class="footer"/>
}
