import { DateUtils } from '@dm-workspace/utils';

type OpeningInterval = '(' | '[';
type ClosingInterval = ')' | ']';
export type TimestampRangeSerialized = [OpeningInterval, string | null, string | null, ClosingInterval];

export class TimestampRangeUtils {
  public static createFromDate(
    _fromDate: Date | string = null,
    _toDate: Date | string = null
  ): TimestampRangeSerialized {
    if (!_fromDate && !_toDate) {
      return;
    }
    const fromDate = TimestampRangeUtils.#dateToStartDateISOString(_fromDate);
    const toDate = TimestampRangeUtils.#dateToStartDateISOString(_toDate);
    return ['[', fromDate, toDate, ']'];
  }

  static #dateToStartDateISOString(_date: Date | string): string {
    if (!_date) {
      return null;
    }
    const date = DateUtils.convertToDate(_date);
    return date.toISOString().split('T')[0];
  }
}
