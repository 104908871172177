<div class="container">
  <i class="i-check status-icon"></i>

  <p class="title">
    {{ file.name }}
  </p>

  <div class="meta">
    <span>{{ file.lastModified | dateFormat }}</span>
    <span>{{ file.size | formatFileSize }}</span>
  </div>

  <button type="button" class="btn-remove" (click)="deleteFile()">
    <i class="icon i-bin"></i>
  </button>
</div>
