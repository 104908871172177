import { Pipe, PipeTransform } from '@angular/core';
import { IBoat, MmsQuoteDescriptionBoat } from '@dm-workspace/types';

@Pipe({
  name: 'quoteDescriptionBoatToBoat',
})
export class QuoteDescriptionBoatToBoat implements PipeTransform {
  public transform(boat?: MmsQuoteDescriptionBoat | undefined): Partial<IBoat> {
    if (!boat) {
      return;
    }

    return {
      ...boat,
      maxBeam: boat.width,
      brand: {
        name: boat.brand,
        id: null,
      },
    };
  }
}
