import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @deprecated use takeUntilDestroyed
 */
@Directive({
  selector: '[dmDestroyed]',
  standalone: true,
})
export class DestroyedDirective implements OnDestroy {
  public destroyed$ = new Subject<void>();

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
