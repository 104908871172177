import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethodType } from '@dm-workspace/types';

@Pipe({
  name: 'paymentTypeIconPipe',
})
export class PaymentTypeIconPipe implements PipeTransform {
  transform(paymentStatus: PaymentMethodType): string {
    if (!paymentStatus) {
      return null;
    }

    switch (paymentStatus) {
      case 'WireTransfer':
        return 'i-transfer';
      default:
        return 'i-card-2';
    }
  }
}
