import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import copy2Clipboard from 'copy-to-clipboard';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-ui-copy-input',
  templateUrl: './copy-input.component.html',
  styleUrls: ['./copy-input.component.scss'],
})
export class CopyInputComponent implements OnDestroy {
  @ViewChild('tooltip') tooltip: NgbTooltip;
  @Input({ required: true }) valueToCopy: string | number | undefined;
  @Input() placeholder?: string | undefined;
  #timer: NodeJS.Timeout;

  protected onClickCopy() {
    if (!this.valueToCopy) {
      return;
    }
    const textToCopy = this.valueToCopy.toString();
    copy2Clipboard(textToCopy);
    this.#handleTooltip();
  }

  #handleTooltip() {
    clearTimeout(this.#timer);
    this.tooltip.open();
    this.#timer = setTimeout(() => {
      this.tooltip.close(true);
    }, 2000);
  }

  ngOnDestroy() {
    clearTimeout(this.#timer);
  }
}
