<button type="button" class="btn btn-primary">BUTTON</button>
<button type="button" class="btn btn-primary"><i class="icon i-boat"></i>BUTTON</button>
<button type="button" class="btn btn-tertiary">BUTTON</button>
<button type="button" class="btn btn-secondary">BUTTON</button>
<button type="button" class="btn btn-danger">BUTTON</button>
<button type="button" class="btn btn-primary-negative">BUTTON</button>
<br />
<button type="button" class="btn btn-sm btn-primary">BUTTON</button>
<button type="button" class="btn btn-sm btn-primary"><i class="icon i-boat"></i>BUTTON</button>
<button type="button" class="btn btn-sm btn-tertiary"></button>
<button type="button" class="btn btn-sm btn-secondary">BUTTON</button>
<button type="button" class="btn btn-sm btn-danger">BUTTON</button>
<button type="button" class="btn btn-full btn-primary-negative">BUTTON</button>
