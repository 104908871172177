import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '@dm-workspace/notification';
import { TFormsFilesFormatsExtension } from '../../forms-files.interface';

const FILE_FORMATS_MIME: { [key in TFormsFilesFormatsExtension]: string } = {
  jpg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
  txt: 'text/plain',
  xml: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

@Component({
  selector: 'dm-form-files',
  templateUrl: './files-drop.component.html',
  styleUrls: ['./files-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesDropComponent {
  public isDraggedFocused = false;
  public allowedFormatsMime: string[] = [];

  public _allowedFormats: string[];
  @Input() public set allowedFormats(value: TFormsFilesFormatsExtension[]) {
    this._allowedFormats = value;
    this.allowedFormatsMime = value.map((v) => FILE_FORMATS_MIME[v]);
  }

  @Input() public sizeLimitBytes = 2097152; // 2MB
  @Input() public hasTip = true;
  @Input() public multiple = true;

  @Output() public add = new EventEmitter<File[]>();

  constructor(private notificationService: NotificationService) {}

  public openFileDialog(input: HTMLInputElement) {
    input.value = null;
    input.click();
  }

  public inputChange(e: Event): void {
    const files = Array.from((e.target as HTMLInputElement).files);

    this.addFiles(files);
  }

  public validateFiles(files: File[]): boolean {
    const length = files.length;

    for (let i = 0; i < length; i++) {
      const file = files[i];

      if (file.size > this.sizeLimitBytes) {
        this.notificationService.add({
          type: 'error',
          content: 'FORM_MODULE.FILES.FILE_TOO_LARGE',
        });

        return false;
      }

      if (!this.allowedFormatsMime.includes(file.type)) {
        this.notificationService.add({
          type: 'error',
          content: 'FORM_MODULE.FILES.NOT_ALLOWED',
        });

        return false;
      }
    }

    return true;
  }

  public addFiles(files: File[]) {
    const isValid = this.validateFiles(files);

    if (!isValid) {
      return;
    }

    this.add.emit(files);
  }
}
