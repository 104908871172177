import { AfterViewInit, Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[remainingHeight]',
})
export class RemainingHeightDirective implements AfterViewInit, OnInit {
  @Input() minHeight: number;
  @Input() topOffset: number;
  #renderer = inject(Renderer2);
  #elementRef = inject(ElementRef);
  #domElement = this.#elementRef.nativeElement as HTMLElement;

  constructor() {
    // register on window resize event
    fromEvent(window, 'resize')
      .pipe(throttleTime(500), debounceTime(500), takeUntilDestroyed())
      .subscribe(() => this.setHeight());
  }

  ngOnInit(): void {
    this.setHeight();
  }

  ngAfterViewInit() {
    this.setHeight();
    const intersectionObserver = new IntersectionObserver(() => {
      this.setHeight();
      setTimeout(() => {
        this.setHeight();
      }, 13500);

      intersectionObserver.disconnect();
    });

    intersectionObserver.observe(this.#elementRef.nativeElement);
  }

  private setHeight() {
    let height: number;
    const aspectRatioHeight = window.innerWidth / (1920 / 1080);
    const windowHeight = window?.innerHeight;
    const topOffset = this.topOffset || this.calcTopOffset();
    height = Math.max(windowHeight - topOffset, aspectRatioHeight);

    if (this.minHeight && height < this.minHeight) {
      height = this.minHeight;
    }
    this.#renderer.setStyle(this.#domElement, 'height', `${height}px`);
  }

  private calcTopOffset(): number {
    const rect = this.#domElement.getBoundingClientRect();
    return rect?.top || undefined;
  }
}
