import { ChangeDetectionStrategy, Component, forwardRef, HostListener, Input } from '@angular/core';
import { CheckboxButtonClass } from '../checkbox-group/checkbox-button-base-class.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dm-form-checkbox-icon',
  templateUrl: './checkbox-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CheckboxButtonClass, useExisting: forwardRef(() => CheckboxIconComponent) },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxIconComponent), multi: true },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'isDisabled()',
    '[class.selected]': 'checked()',
  },
})
export class CheckboxIconComponent extends CheckboxButtonClass {
  @Input({ required: true }) icon: string;
  @HostListener('click')
  override onModelChange() {
    super.onModelChange();
  }
}
