import { Injectable } from '@angular/core';
import { S4bBoatAlertApiService } from '@dm-workspace/data-access';
import { IBoatAlertByBoatParams, IBoatAlertResponseDto, IRequestParamsWithPagination } from '@dm-workspace/types';
import { delay, EMPTY, Observable } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class S4bService {
  constructor(private s4bApiService: S4bBoatAlertApiService) {}

  public getExpandedAlertsForBoat(
    boatId: string,
    params?: IRequestParamsWithPagination<IBoatAlertByBoatParams>
  ): Observable<IBoatAlertResponseDto[]> {
    return this.s4bApiService.getAlertsForBoat(boatId, params).pipe(
      expand(({ metadata }) => {
        const { totalCount, offset, limit = 100 } = metadata || {};
        const nextOffset = offset + limit;
        const newParams = {
          ...params,
          limit,
          offset: nextOffset,
        };

        return totalCount > nextOffset
          ? this.s4bApiService.getAlertsForBoat(boatId, { ...newParams }).pipe(delay(500))
          : EMPTY;
      }),
      reduce((accumulated, current) => [...accumulated, ...current.values], [])
    );
  }
}
