import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BoatMovementOperationForStatus } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-boat-marina-status',
  templateUrl: './boat-marina-status.component.html',
  styleUrls: ['./boat-marina-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatMarinaStatusComponent {
  @Input() status: BoatMovementOperationForStatus;

  get indicatorStatus(): string {
    switch (this.status) {
      case BoatMovementOperationForStatus.CRUISING:
        return 'cruising';
      case BoatMovementOperationForStatus.ARRIVAL:
        return 'arrival';
      case BoatMovementOperationForStatus.DEPARTURE:
        return 'departure';
    }
  }
}
