<button
  *ngIf="rowsToLoadSize"
  type="button"
  (click)="onLoadMore()"
  class="btn btn-primary-negative btn-tiny"
  [disabled]="this.isPending"
>
  <i class="i-add"></i>
  <ng-content /> ({{ rowsToLoadSize }})
</button>
