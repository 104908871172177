import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Directive, EventEmitter, inject, OnDestroy, Output } from '@angular/core';

@Directive()
export class ConfirmResultModal<V> implements OnDestroy {
  // Cold observable
  @Output() public readonly confirmed = new EventEmitter<V | false>();
  protected readonly aModal = inject(NgbActiveModal);

  protected onConfirm(value: V) {
    this.aModal.close(value);
    this.confirmed.emit(value);
    this.confirmed.complete();
  }

  protected onCancel() {
    this.aModal.close(false);
    this.confirmed.emit(false);
    this.confirmed.complete();
  }

  public ngOnDestroy() {
    this.confirmed.emit(false);
    this.confirmed.complete();
  }
}
