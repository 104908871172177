<ng-container *ngIf="items$ | async as items">
  <ng-select
    dmForm
    [loading]="loading()"
    [typeahead]="input$"
    [items]="items"
    [placeholder]="placeholder() | translate"
    [clearable]="clearable()"
    [disabled]="disabled()"
    [ngModel]="value"
    (ngModelChange)="onSelectChange($event)"
  >
    <ng-template ng-label-tmp let-berth="item">
      {{ berth.name }}
    </ng-template>
    <ng-template ng-option-tmp let-berth="item" let-search="searchTerm">
      <div class="d-flex justify-content-between align-items-start">
        <p class="display-name" [innerHTML]="berth.name | highlight: search"></p>
      </div>
    </ng-template>
    <ng-template ng-notfound-tmp let-search="searchTerm">
      <dm-form-select-not-found [search]="search" />
    </ng-template>
  </ng-select>
</ng-container>
<span class="i-search"></span>
