<table>
  <tr>
    <th>{{ "BERTH" | translate }}:</th>
    <td>{{ resource.resource?.name }}</td>
  </tr>
  <tr>
    <th>{{ "TOTAL_PRICE" | translate }}:</th>
    <td>
      {{ resource?.pricingDetails?.sumPriceGross | currency : resource?.pricingDetails?.currency }}
    </td>
  </tr>
</table>
