<div class="tooltip-bubble-wrapper tooltip-bubble-wrapper__mobile">
  <div class="rounded-circle user-avatar__mobile">
    <img src="assets/set-password.png" alt="set-password-img" />
  </div>
  <div class="tooltip-bubble tooltip-bubble__mobile">
    {{ "NCP_AUTH_MODULE.SET_PASSWORD.WELCOME" | translate }}
    <strong>{{ userEmail() }}</strong>
    {{ "NCP_AUTH_MODULE.SET_PASSWORD.AS_LOGIN" | translate }}
  </div>
</div>
