<dm-ui-info-box class="py-xl" icon="info" text="SHOWING_ONLY_ONLINE_PAYMENTS" />

<table class="table">
  <thead>
    <tr>
      <th>{{ "DATE" | translate }}</th>
      <th>{{ "PAYMENT_METHOD" | translate }}</th>
      <th>{{ "PAYMENT_STATUS" | translate }}</th>
      <th class="text-right">{{ "TOTAL_AMOUNT" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="payments$ | async as payments; else emptyBlock">
      <tr *ngFor="let payment of payments">
        <td>{{ payment.createdAt | dateFormat: "date_time" }}</td>
        <td>{{ payment.type }}</td>
        <td>
          <dm-ui-payment-status-badge [status]="payment.status" />
        </td>
        <td class="text-right">{{ payment.amount?.value | currency: payment.amount?.currency }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>

<dm-ui-overflow-spinner [showSpinner]="pending" />

<ng-template #emptyBlock>
  <tr>
    <td colspan="4">
      {{ "NO_ONLINE_PAYMENTS_RECORDED" | translate }}
    </td>
  </tr>
</ng-template>
