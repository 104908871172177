import { Directive, Input } from '@angular/core';
import { TFormsFilesFormatsExtension } from '../forms-files.interface';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export abstract class AbstractFilesComponent<V> implements ControlValueAccessor {
  public disabled: boolean;
  public model: V[] = [];

  @Input() public hasTip = true;
  @Input() public multiple = true;
  @Input() public deleteFromListIfError = false;
  @Input() public allowedFormats: TFormsFilesFormatsExtension[];
  @Input() public sizeLimitBytes = 2097152; // 2MB

  public removeFile(index: number) {
    this.model.splice(index, 1);
    this.onChange(this.model);
  }

  public abstract writeValue(files: V[]): void;

  public onChange: (files: V[]) => void = () => undefined;
  public registerOnChange(fn: (files: V[]) => void): void {
    this.onTouched();
    this.onChange = fn;
  }

  public onTouched: () => void = () => undefined;
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
