import { inject, Injectable } from '@angular/core';
import { GetMarinaTermsDto, ENV, GetGlobalTermsDto, AllTerms } from '@dm-workspace/types';
import { forkJoin, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TermsApiService {
  #env = inject(ENV);
  #httpClient = inject(HttpClient);
  private readonly resourceUrl = this.#env.apiUrl + `/global-info/terms`;

  public fetchTerms(): Observable<GetGlobalTermsDto> {
    return this.#httpClient.get<GetGlobalTermsDto>(this.resourceUrl);
  }
  public fetchMarinaTerms(marinaCode: string): Observable<GetMarinaTermsDto> {
    return this.#httpClient.get<GetMarinaTermsDto>(`${this.resourceUrl}/${marinaCode}`);
  }
  public fetchAllTerms(marinaCode: string): Observable<AllTerms> {
    return forkJoin([this.fetchTerms(), this.fetchMarinaTerms(marinaCode)]).pipe(
      map(([globalTerms, marinaTerms]) => ({ ...globalTerms, ...marinaTerms }))
    );
  }
}
