import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal.component';

@Component({
  selector: 'dm-ui-hand-over-confirm-modal',
  templateUrl: './hand-over-confirm-modal.component.html',
  styleUrls: ['./hand-over-confirm-modal.component.scss'],
})
export class HandOverConfirmModalComponent extends ConfirmModalComponent {
  @Input() customerEmail?: string;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }
}
