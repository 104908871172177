import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { diffDatesInDays } from '@dm-workspace/utils';

@Component({
  selector: 'dm-ui-progress-date',
  templateUrl: './progress-date.component.html',
  styleUrls: ['./progress-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressDateComponent implements OnChanges {
  @Input() public from: string;
  @Input() public to: string;
  @Input() public marinaCode: string;
  @Input() public nowDate = new Date();
  @Input() public hasProgress = true;

  public diffDates: number;
  public diffToday: number;

  ngOnChanges() {
    this.diffDates = diffDatesInDays(this.from, this.to);
    const nowTime = this.nowDate.getTime();
    this.diffToday = this.diffDates - diffDatesInDays(nowTime, this.to);

    if (this.diffToday < 0) {
      this.diffToday = 0;
    }

    if (this.diffToday > this.diffDates) {
      this.diffToday = this.diffDates;
    }
  }
}
