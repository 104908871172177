import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { AbstractFilesComponent } from '../abstract-files.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dm-form-files-select',
  templateUrl: './files-select.component.html',
  styleUrls: ['./files-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FilesSelectComponent),
    },
  ],
})
export class FilesSelectComponent extends AbstractFilesComponent<File> {
  public writeValue(files: File[]): void {
    this.model.push(...files);
  }

  addFiles(files: File[]) {
    this.writeValue(files);
    this.onChange(this.model);
  }
}
