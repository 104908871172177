import { GenericObject } from '@dm-workspace/types';
import { isObject } from './utils';

export const mapObjectPropertyValue = (
  object: GenericObject,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapCallbackFn: (value: any, key: string, index: number) => any
): GenericObject =>
  Object.entries(object).reduce(
    (result: GenericObject, [key, value], index: number) => ({
      ...result,
      [key]: mapCallbackFn(value, key, index),
    }),
    {}
  );

export function removeEmptyProperties<T extends GenericObject>(obj: T): T {
  return isObject(obj)
    ? (Object.entries(obj)
        .filter(matchEmptyNullUndefined)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) as T)
    : obj;
}
export function removeEmptyString<T extends GenericObject>(obj: T): T {
  return isObject(obj)
    ? (Object.entries(obj).reduce((acc, [k, v]) => ({ ...acc, [k]: v === '' ? null : v }), {}) as T)
    : obj;
}
export const matchEmptyNullUndefined: ([_, v]: [string, unknown]) => boolean = ([_, v]) =>
  v !== null && v !== '' && v !== undefined;
