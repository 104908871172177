<ng-container *ngIf="items$ | async as items">
  <ng-select
    dmForm
    [loading]="isLoading"
    [typeahead]="input$"
    [items]="items"
    [minTermLength]="minLengthTerm"
    [notFoundText]="notFoundText || ('NO_ITEMS_FOUND' | translate)"
    [placeholder]="placeholder"
    [clearable]="clearable"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [addTag]="addTag"
    [multiple]="multiple"
    [compareWith]="compareWith"
    [disabled]="disabled"
    [(ngModel)]="value"
    (change)="onSelectChange()"
  >
    <ng-container *ngIf="items.length" [ngSwitch]="typeahead">
      <ng-container *ngSwitchCase="'boats'">
        <ng-template ng-label-tmp ng-option-tmp let-boat="item">
          <strong>{{ boat.name }} </strong> <img class="flag" [src]="boat.flag | flag" /><br />
          {{ boat.registrationNumber }} <small>({{ boat.length }}m)</small>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'customers'">
        <ng-template ng-label-tmp ng-option-tmp let-customer="item">
          {{ customer.displayName }}
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-select>
</ng-container>
