import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IResourceBookingQuotation,
  MARINA_URL_TOKEN,
  MmsDashboardQuotesStatName,
  MmsDashboardStatName,
  MmsDashboardStats,
  MmsQuotesStats,
  PaginatedNormal,
  PaginationQuery,
  Quote,
} from '@dm-workspace/types';
import { ApiHttpClient, ApiService } from '../api/api-http-client';

@Injectable({
  providedIn: 'root',
})
export class MmsDashboardApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/mms/dashboard/${MARINA_URL_TOKEN}`);

  public fetchStats(): Observable<MmsDashboardStats> {
    return this.apiHttpClient.get<MmsDashboardStats>(`${this.resourceUrl}/stats`);
  }

  public fetchEnquiriesStats(): Observable<MmsQuotesStats> {
    return this.apiHttpClient.get<MmsQuotesStats>(`${this.resourceUrl}/stats/enquiries/summary`);
  }

  public fetchResourceBookingsByStats(
    name: MmsDashboardStatName,
    params: PaginationQuery
  ): Observable<PaginatedNormal<IResourceBookingQuotation>> {
    return this.apiHttpClient.get<PaginatedNormal<IResourceBookingQuotation>>(
      `${this.resourceUrl}/stats/bookings/${name}`,
      {
        params,
      }
    );
  }

  public fetchQuotesByStatName(statName: MmsDashboardQuotesStatName, params?: PaginationQuery) {
    return this.apiHttpClient.get<PaginatedNormal<Quote>>(`${this.resourceUrl}/stats/quotes/${statName}`, {
      params,
    });
  }
}
