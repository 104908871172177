import { animate, group, query, style, transition, trigger } from '@angular/animations';

function slideTo(direction: 'left' | 'right') {
  return [
    query(':enter', [
      style({
        position: 'absolute',
        top: 0,
        [direction]: '-100%',
        width: '100%',
        opacity: 0,
      }),
    ]),
    query(':leave', [
      style({
        position: 'relative',
        top: 0,
        [direction]: '0',
        width: '100%',
        opacity: 1,
      }),
    ]),
    group([
      query(':leave', [animate('500ms ease', style({ [direction]: '100%', opacity: 0 }))]),
      query(':enter', [animate('500ms ease', style({ [direction]: '0%', opacity: 1 }))]),
    ]),
  ];
}

export const slideRouteAnimation = trigger('routeAnimations', [
  transition(':increment', slideTo('right')),
  transition(':decrement', slideTo('left')),
]);
