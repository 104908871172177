import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToLatLng',
})
export class GeoToPolyPipe implements PipeTransform {
  transform(value: [number, number][], ...args: unknown[]) {
    return value ? value.map((v: Array<number>) => ({ lat: v[1], lng: v[0] })) : [];
  }
}
