import { Marina } from '@dm-workspace/types';
import { ApiCrudService, ApiHttpClient, ApiService } from '../api/api-http-client';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ObMarinasApiService extends ApiService implements ApiCrudService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl('/online-booking/marinas/');

  get(showAll: false) {
    return this.apiHttpClient.get<Marina[]>(this.resourceUrl, {
      params: {
        showAll,
      },
    });
  }
}
