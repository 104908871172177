<dm-ob-quote-hero [marina]="quoteMarina$ | async">
  <ng-container title>
    @switch (quote()?.type) {
      @case (QuoteType.ANNUAL_SALES) {
        <div [innerHTML]="'ENJOY_YOUR_STAY_AT_MARINA' | translate : (quoteWithMarina$ | async)"></div>
      }
      @case (QuoteType.RENEWAL) {
        <div [innerHTML]="'ENJOY_YOUR_STAY_AT_MARINA_RENEWAL' | translate : (quoteWithMarina$ | async)"></div>
      }
    }
  </ng-container>
</dm-ob-quote-hero>

<router-outlet/>

<dm-ui-overflow-spinner [showSpinner]="fetchingOffer()" />
