import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/**
 * @deprecated use UiInfoComponent instead
 */
@Component({
  selector: 'dm-ui-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBoxComponent {
  @Input() text!: string;
  @Input() icon?: unknown;
  @Input() color?: 'warning' | 'error' | 'success' | 'info';
  @Input() alignIcon?: 'top' | 'center' = 'center';

  @HostBinding('class') get hostClass() {
    return {
      [this.color]: true,
      [this.alignIcon]: true,
    };
  }
}
