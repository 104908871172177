<ng-container *ngIf="items$ | async as items">
  <ng-select
    dmForm
    [loading]="loading()"
    [typeahead]="input$"
    [items]="items"
    [placeholder]="placeholder() | translate"
    [clearable]="clearable()"
    [disabled]="disabled()"
    [ngModel]="value"
    (ngModelChange)="onSelectChange($event)"
  >
    <ng-template ng-label-tmp let-customer="item">
      {{ customer.displayName }}
      <ng-container *ngIf="customer.accountNumber"> • {{ customer.accountNumber }}</ng-container>
    </ng-template>
    <ng-template ng-option-tmp let-customer="item" let-search="searchTerm">
      <div class="d-flex justify-content-between align-items-start">
        <div class="pe-2 w-50">
          <div class="d-flex justify-content-start align-items-start">
            <dm-form-search-has-account-badge [customer]="customer" />
            <p class="display-name" [innerHTML]="customer.displayName | highlight: search"></p>
          </div>
          <p [innerHTML]="customer.accountNumber | highlight: search | empty"></p>
        </div>
        <div class="text-right ps-2 w-50">
          <p [innerHTML]="customer.email | highlight: search | empty"></p>
          <p [innerHTML]="customer.phone | highlight: search | empty"></p>
        </div>
      </div>
    </ng-template>
    <ng-template ng-notfound-tmp let-search="searchTerm">
      @if (noResultDirective?.tpl) {
        <ng-container [ngTemplateOutlet]="noResultDirective.tpl" [ngTemplateOutletContext]="{ search: search }" />
      } @else {
        <dm-form-select-not-found [search]="search" />
      }
    </ng-template>
  </ng-select>
</ng-container>
