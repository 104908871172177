import { Component, forwardRef } from '@angular/core';
import { ApiFile } from '@dm-workspace/types';
import { AbstractFilesComponent } from '../abstract-files.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dm-form-files-uploader',
  templateUrl: './files-uploader.component.html',
  styleUrls: ['./files-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FilesUploaderComponent),
    },
  ],
})
export class FilesUploaderComponent extends AbstractFilesComponent<ApiFile> {
  public files: File[] = [];

  public addFilesToUpload(files: File[]) {
    this.files.push(...files);
  }

  public writeValue(files: ApiFile[]) {
    this.model.push(...files);
  }

  public cancelUploadingFile(index: number) {
    this.files.splice(index, 1);
  }

  public deleteUploadedFile(index: number) {
    this.model.splice(index, 1);
    this.onChange(this.model);
  }

  public addUploadedFile(file: ApiFile, index: number) {
    this.model.push(file);
    this.files.splice(index, 1);
    this.onChange(this.model);
  }
}
