import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getKeyFromEnum',
})
export class GetKeyFromEnumPipe implements PipeTransform {
  public transform<V extends string>(value: V, enumArray?: Record<string, V>): string {
    if (!enumArray) {
      return;
    }
    const key = Object.keys(enumArray).find((key) => enumArray[key] === value);
    return key ?? value;
  }
}
