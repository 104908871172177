import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, race } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationBrowserService {
  constructor(private translateService: TranslateService) {
    this.requestPermission();
  }

  private requestPermission() {
    if ('Notification' in window && Notification.permission === 'default') {
      race(fromEvent(window, 'click'), fromEvent(window, 'scroll'))
        .pipe(take(1))
        .subscribe({
          next: () => Notification.requestPermission(),
        });
    }
  }

  public notify(title: string, options?: NotificationOptions, onClick?: () => void) {
    if ('Notification' in window && Notification.permission === 'granted' && document.hidden) {
      const translatedTitle = this.translateService.instant(title);

      const notification = new Notification(translatedTitle, {
        ...options,
        body: this.translateService.instant(options.body),
      });

      if (onClick) {
        notification.onclick = () => {
          onClick();
          window.focus();
        };
      }

      const focusHandler = () => {
        if (document.visibilityState === 'visible') {
          notification.close();
          document.removeEventListener('visibilitychange', focusHandler);
        }
      };
      document.addEventListener('visibilitychange', focusHandler);
    }
  }
}
