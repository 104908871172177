export enum SbMessageTypes {
  boat = 'boat',
  brand = 'brand',
  contact = 'contact',
  contactBoatRelation = 'contactboat',
  contract = 'contract',
  customer = 'customer',
  model = 'model',
  order = 'order',
  orderProduct = 'orderproduct',
  paymentMethod = 'paymentmethod',
  paymentTerm = 'paymentterm',
  resourceBooking = 'resourcebooking',
  customerInLegalEntity = 'customerinlegalentity',
  invoice = 'invoice',
  salesTaxAuthority = 'salestaxauthority',
  salesTaxGroup = 'salestaxgroup',
  customerGroup = 'customergroup',
  payment = 'payment',
  movement = 'movement',
}
