import { Pipe, PipeTransform } from '@angular/core';
import { PaymentHistory, EntityPaymentStatus, PaymentStatusEnum } from '@dm-workspace/types';

@Pipe({
  name: 'paymentHistoryEntity',
})
export class PaymentHistoryEntityPipe implements PipeTransform {
  static getPaymentStatusFromPaymentHistory(paymentHistory?: PaymentHistory): EntityPaymentStatus {
    if (!paymentHistory) {
      return null;
    }

    if (paymentHistory?.paymentStatus === PaymentStatusEnum.UNPAID && paymentHistory?.payments?.length === 0) {
      return null;
    }

    const payments = paymentHistory.payments?.sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    const status =
      payments[payments.length - 1]?.status || paymentHistory?.paymentStatus || paymentHistory?.crmPaymentStatus;

    switch (status) {
      case 'waiting':
      case 'Pending':
        return 'Pending';
      case 'paid':
      case 'PAID':
      case 'Success':
        return 'Success';
      case 'Failed':
      case 'rejected':
        return 'Failed';
      case 'Expired':
        return 'Expired';
      default:
        return null;
    }
  }

  transform(paymentHistory?: PaymentHistory): EntityPaymentStatus {
    return PaymentHistoryEntityPipe.getPaymentStatusFromPaymentHistory(paymentHistory);
  }
}
