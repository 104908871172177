<ng-select
  [loading]="pending"
  [typeahead]="input$"
  [items]="items$ | async"
  [minTermLength]="2"
  [clearable]="clearable"
  [addTag]="addTag"
  [placeholder]="placeholder"
  [bindValue]="bindValue"
  [(ngModel)]="value"
  [disabled]="disabled"
  [bindLabel]="getLabel()"
  (change)="onSelectChange($event)"
/>
