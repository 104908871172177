import { Component, forwardRef, inject, InjectionToken, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, Breakpoint } from '@dm-workspace/core';
import { IAuthSetUpPassword } from '@dm-workspace/types';
import { Observable, of } from 'rxjs';
import { NcpLayoutMainView } from '../../../../../ncp-layout/src/lib/views/ncp-layout-main-view/ncp-layout-main-view.component';

export const NcpAuthView = new InjectionToken<NcpAuthViewComponent>('NcpAuthView');
@Component({
  selector: 'dm-ncp-auth-route-view',
  templateUrl: './ncp-auth-view.component.html',
  styleUrl: './ncp-auth-view.component.scss',
  providers: [{ provide: NcpAuthView, useExisting: forwardRef(() => NcpAuthViewComponent) }],
})
export class NcpAuthViewComponent implements OnInit, OnDestroy {
  protected readonly Breakpoint = Breakpoint;
  setPasswordParams = signal<IAuthSetUpPassword>(null);
  public userEmail$: Observable<string>;
  #aRoute = inject(ActivatedRoute);
  #auth = inject(AuthService);
  #layoutMainView = inject(NcpLayoutMainView);

  ngOnInit(): void {
    this.#layoutMainView.changeFooterVisibility(false);
    this.#layoutMainView.changeHeaderLoginButtonVisibility(false);
    this.setPasswordParams.update(() =>
      this.#aRoute.snapshot.queryParams['id'] && this.#aRoute.snapshot.queryParams['confirmCode']
        ? (this.#aRoute.snapshot.queryParams as IAuthSetUpPassword)
        : null
    );
    this.setPasswordParams()?.cryptedEmail
      ? (this.userEmail$ = this.#auth.decryptEmail(this.setPasswordParams().cryptedEmail))
      : (this.userEmail$ = of(null));
  }

  ngOnDestroy() {
    this.#layoutMainView.changeFooterVisibility(true);
    this.#layoutMainView.changeHeaderLoginButtonVisibility(true);
  }

  hideBubbleTooltip(): void {
    this.setPasswordParams.set(null);
  }
}
