import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from '@dm-workspace/types';

@Pipe({
  name: 'hasCustomerOnlineAccount',
})
export class HasCustomerOnlineAccountPipe implements PipeTransform {
  public transform(customer: Customer): boolean {
    return customer.customerPortalAccounts.findIndex((v) => v.hasAccount === true) > -1;
  }
}
