import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import {
  ENV,
  PaymentAdyenV5ResultCode,
  PaymentAdyenInitResponse,
  TPaymentAdyenV5PayResponse,
  TPaymentApplePayValidateMerchantSession,
} from '@dm-workspace/types';
import { ExternalScriptService } from '@dm-workspace/core';
import { PaymentsApiService } from '@dm-workspace/data-access';
import { catchError, switchMap, throwError } from 'rxjs';
import * as AdyenWeb from '@adyen/adyen-web/dist/types/core/types';
import { merge } from 'lodash';
import { PaymentMethodOptions } from '@adyen/adyen-web/dist/types/types';
import { PAYMENT_METHOD_ADYEN_OPTIONS } from './payment-method-adyen';
import { PaymentMethodComponent } from '../payment-method.class';

@Component({
  selector: 'dm-payment-method-adyen',
  templateUrl: './payment-method-adyen.component.html',
  styleUrls: ['./payment-method-adyen.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodAdyenComponent extends PaymentMethodComponent implements OnInit, OnDestroy {
  readonly #styleConfig = inject(PAYMENT_METHOD_ADYEN_OPTIONS);
  readonly #env = inject(ENV);
  readonly #el = inject(ElementRef);
  readonly #paymentApiService = inject(PaymentsApiService);
  readonly #externalScriptService = inject(ExternalScriptService);

  #dropinElement?: DropinElement;

  readonly #adyenDefaultConfig: PaymentMethodOptions<'dropin'> = {
    showPayButton: true,
    showPaymentMethods: true,
    openFirstStoredPaymentMethod: true,
    openFirstPaymentMethod: true,
    paymentMethodsConfiguration: {
      ideal: {
        showImage: true,
      },
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        showBrandIcon: false,
      },
      applepay: {
        onValidateMerchant: (
          resolve: (merchantSession: TPaymentApplePayValidateMerchantSession) => void,
          reject: () => void,
          validationURL: string
        ) => {
          this.#paymentApiService.applePayValidateMerchantSession(validationURL).subscribe({
            next: (response) => {
              resolve(response.merchantSession);
            },
            error: () => reject(),
          });
        },
      },
    },
  };

  ngOnInit() {
    this.#paymentApiService
      .initAdyenPayment({ referenceId: this.paymentReferenceId })
      .pipe(
        catchError((error) => {
          this.paymentMountError.emit(error);
          return throwError(() => error);
        }),
        switchMap((val) => {
          return this.#externalScriptService
            .loadScript(this.#env.googlePay.scriptUrL)
            .pipe(switchMap(() => this.#mountPayment(val)));
        })
      )
      .subscribe();
  }

  async #mountPayment(value: PaymentAdyenInitResponse) {
    const configuration: AdyenWeb.CoreOptions = {
      ...merge(this.#styleConfig, this.#adyenDefaultConfig),
      ...value,
      ...this.#env.adyen,
      onPaymentCompleted: (res: TPaymentAdyenV5PayResponse) => this.#onPaymentCompleted(res),
      onError: () => this.paymentError.emit(),
    };

    const checkout = await AdyenCheckout(configuration);
    this.#dropinElement = checkout.create('dropin').mount(this.#el.nativeElement);

    this.paymentMounted.emit({
      sessionExpiresAt: new Date(value.session.expiresAt),
      paymentMethodType: 'Adyen',
    });
  }

  #onPaymentCompleted(value: TPaymentAdyenV5PayResponse) {
    switch (value.resultCode) {
      case PaymentAdyenV5ResultCode.AUTHORISED:
        return this.paymentSuccess.emit();
      default:
        return this.paymentError.emit();
    }
  }

  ngOnDestroy(): void {
    this.#dropinElement?.remove();
  }
}
