import { ChangeDetectorRef, Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { StickyElementService } from './sticky-element.service';

@Directive({
  selector: '[dmUiStickyElement]',
})
export class StickyElementDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Input() name: string;
  @Input() secondElementOffset: number = 0;

  @HostBinding('style.position') public position = 'sticky';
  @HostBinding('style.top') public top = '0';
  @HostBinding('class.sticky-element') public isSticky = true;

  constructor(
    private stickyService: StickyElementService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription = this.stickyService.stickyOffsets$.pipe(filter((offset) => !!offset && !!this.name)).subscribe({
      next: (offset) => {
        if (this.secondElementOffset !== 0) {
          this.top = offset.value + this.secondElementOffset + 'px';
          this.cd.markForCheck();
          return;
        }
        this.top = offset.value + 'px';
        this.cd.markForCheck();
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
