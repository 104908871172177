import { FormGroup } from '@angular/forms';
import { ProfileDetailsForm, ProfileDetailsFormService } from './profile-details-form.service';
import { ProfileDocumentsForm, ProfileDocumentsFormService } from './profile-documents-form.service';
import { Injectable } from '@angular/core';
import { CountryCode, Contact, UpdatePersonalInfoDto, CustomerType, CorporateType } from '@dm-workspace/types';
import { FormUtils } from '@dm-workspace/forms';

export type ProfileForm = FormGroup<ProfileDetailsForm['controls'] & ProfileDocumentsForm['controls']>;
export type ProfileFormValue = ReturnType<ProfileForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class ProfileFormService {
  constructor(
    private profileDetailsFormService: ProfileDetailsFormService,
    private profileDocumentsFormService: ProfileDocumentsFormService
  ) {}

  public createForm(
    initValue: Partial<ProfileFormValue>,
    options?: {
      countryCode?: CountryCode;
      marinaCode?: CountryCode;
      customerType?: CustomerType;
      corporateType?: CorporateType;
    }
  ): ProfileForm {
    const documentsForm = this.profileDocumentsFormService.createForm(initValue, options);
    const detailsForm = this.profileDetailsFormService.createForm(initValue, options);
    return FormUtils.concatForms<ProfileForm>(documentsForm, detailsForm);
  }

  public transformFormValueToPayload(formValue: Partial<ProfileFormValue>): Partial<UpdatePersonalInfoDto> {
    return {
      ...this.profileDetailsFormService.transformFormValueToPayload(formValue as ProfileFormValue),
      ...this.profileDocumentsFormService.transformFormValueToPayload(formValue),
    };
  }

  public transformCustomerContactToFormValue(contact: Contact): Partial<ProfileFormValue> {
    return {
      ...this.profileDetailsFormService.transformCustomerContactToFormValue(contact),
      ...this.profileDocumentsFormService.transformCustomerContactToFormValue(contact),
    };
  }
}
