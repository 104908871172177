import { ChangeDetectionStrategy, Component, forwardRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { IBoatDto } from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { CustomSearchComponent } from '../customeSeach.class';
import { removeEmptyProperties } from '@dm-workspace/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dm-form-search-boat',
  templateUrl: './search-boat.component.html',
  styleUrl: './search-boat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBoatComponent),
      multi: true,
    },
  ],
})
export class SearchBoatComponent extends CustomSearchComponent<IBoatDto> implements OnChanges {
  @Input() public preferredCustomerId?: string;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['preferredCustomerId']) {
      this.additionalItems$.next([]);
    }
  }

  fetchItem(id: string): Observable<IBoatDto> {
    return this.#boatApiService.fetchById(id);
  }
  #boatApiService = inject(MmsBoatsApiService);
  fetchItems(search: string): Observable<IBoatDto[]> {
    const params = removeEmptyProperties({
      search,
      limit: 200,
    });

    return this.#boatApiService.searchBoats(params);
  }

  onSearchFocus() {
    if (this.preferredCustomerId && this.additionalItems$.value.length === 0 && !this.loading()) {
      this.loading.set(true);
      this.#boatApiService
        .searchBoats({
          limit: 200,
          preferredCustomerId: this.preferredCustomerId,
        })
        .pipe(finalize(() => this.loading.set(false)))
        .subscribe((value) => this.additionalItems$.next(value.map((value1) => ({ ...value1, isHighlighted: true }))));
    }
  }
}
