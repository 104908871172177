import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IAuthBackendResetPasswordParam } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class AuthResetPasswordGuard {
  constructor(private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    const { passwordResetCode, id } = route.queryParams as IAuthBackendResetPasswordParam;

    if (!passwordResetCode || !id) {
      return this.router.parseUrl('/auth/login');
    }

    return true;
  }
}
