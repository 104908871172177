import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService, FormView } from '@dm-workspace/core';
import { ApiValidatorService, emailLoginValidator } from '@dm-workspace/forms';
import { NotificationService } from '@dm-workspace/notification';
import { IAuthLoginUserResponse } from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { ApiValidator } from '@dm-workspace/shared';
import { Router } from '@angular/router';

type FormValue = {
  email: string;
};

@Component({
  selector: 'dm-ncp-auth-forgot-password',
  templateUrl: './ncp-auth-forgot-password.component.html',
  styleUrls: ['./ncp-auth-forgot-password.component.scss', '../ncp-auth-shared.component.scss'],
})
export class NcpAuthForgotPasswordComponent extends FormView<FormValue> implements OnInit {
  #fb = inject(FormBuilder);
  #notification = inject(NotificationService);
  #auth = inject(AuthService);
  #router = inject(Router);
  constructor(override readonly apiValidator: ApiValidatorService) {
    super(apiValidator);
  }

  ngOnInit(): void {
    this.form = this.#fb.group({
      email: new FormControl<string>('', [Validators.required, emailLoginValidator()]),
    });
  }

  protected override apiCall(params: FormValue): Observable<IAuthLoginUserResponse> {
    return this.#auth.forgotPassword(params.email);
  }

  protected override onSuccess(): void {
    this.#notification.add({
      type: 'ncp_success',
      text: 'NCP_AUTH_MODULE.FORGOT_PASSWORD.SEND_LINK_SUCCESS',
      content: 'NCP_AUTH_MODULE.FORGOT_PASSWORD.SEND_LINK_SUCCESS_PARAGRAPH',
    });

    setTimeout(() => {
      this.#router.navigate(['/login']);
    }, 1000);
  }

  protected override onError(res: HttpErrorResponse) {
    super.onError(res);
    this.#notification.add({
      type: 'ncp_error',
      content: ApiValidator.getApiNotificationError(res),
    });
  }
}
