<div class="header">
  <ng-content select="[header]"/>
  {{ label | translate }}
</div>

<div class="body">
  <ng-container *ngIf="value !== undefined">{{ value | empty }}</ng-container>
  <ng-content />
  <ng-content select="[body]"/>
</div>

<div class="footer mt-3">
  <ng-content select="[footer]"/>
</div>

