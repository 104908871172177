import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.loggedIn$.pipe(
    map((isLoggedIn) => {
      if (isLoggedIn) {
        return true;
      }
      authService.redirectUrl = state.url;
      authService.logout();
      return router.parseUrl('auth/login');
    })
  );
};
