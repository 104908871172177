import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { API_PREFIX, ApiPrefix, APP_TOKEN, AppToken } from '@dm-workspace/types';
import { CoreModule } from '@dm-workspace/core';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { SentryCoreModule } from '@dm-workspace/sentry-core';
import { ServiceWorkerCoreModule } from '@dm-workspace/service-worker-core';
import { NcpAppComponent } from './ncp-app.component';
import { ncpAppRoutes } from './ncp-app.routes';
import { NcpLayoutModule } from '@dm-workspace/new-customer-portal/ncp-layout';
import { MarinasApiServiceToken } from '@dm-workspace/data-access';
import { ObMarinasApiService } from '@dm-workspace/data-access';
import {
  PAYMENT_METHOD_ADYEN_LIGHT_THEME_OPTIONS_NEW_CUSTOMER,
  PAYMENT_METHOD_ADYEN_OPTIONS,
} from '@dm-workspace/payment';

export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}
@NgModule({
  declarations: [NcpAppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    HammerModule,
    NgbModule,
    NcpLayoutModule,
    SentryCoreModule,
    RouterModule.forRoot(ncpAppRoutes, {
      enableTracing: false,
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
    NgxMaskModule.forRoot(),
    ServiceWorkerCoreModule,
  ],
  providers: [
    {
      provide: APP_TOKEN,
      useValue: AppToken.newCustomerPanel,
    },
    {
      provide: PAYMENT_METHOD_ADYEN_OPTIONS,
      useValue: PAYMENT_METHOD_ADYEN_LIGHT_THEME_OPTIONS_NEW_CUSTOMER,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: MarinasApiServiceToken,
      useClass: ObMarinasApiService,
    },
    {
      provide: API_PREFIX,
      useValue: ApiPrefix.newCustomerPanel,
    },
  ],
  bootstrap: [NcpAppComponent],
})
export class NcpAppModule {}
