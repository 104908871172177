import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable, race } from 'rxjs';
import { ConfirmModalComponent } from './confirm-modal.component';
import { PylonStateConfirmModalComponent } from './pylon-state-confirm-modal/pylon-state-confirm-modal.component';
import { TPylonAction, TPylonState } from '@dm-workspace/types';
import { HandOverConfirmModalComponent } from './handover-confirm-modal/hand-over-confirm-modal.component';
import { ConfirmModal } from './confirm-modal';

export interface IConfirmModalOptions {
  title?: string;
  body: string;
  name?: string;
  cancelBtn?: string;
  confirmBtn?: string;
  actionButtonClass?: string;
}

export interface IConfirmPylonModalOptions extends IConfirmModalOptions {
  pylonName?: string;
  pylonOutput?: string;
  expectedResult?: TPylonState;
  actionType?: TPylonAction;
}

export interface IConfirmHandOverModalOptions extends IConfirmModalOptions {
  customerEmail?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {
  constructor(private modalService: NgbModal) {}

  public openComponent(component: ConfirmModal) {
    const modal = this.modalService.open(component, {
      size: 'md',
      backdrop: 'static',
    });
    return race(modal.closed, modal.dismissed.pipe(map(() => false)));
  }

  public open(modalOptions: IConfirmModalOptions): Observable<boolean> {
    const modal = this.modalService.open(ConfirmModalComponent, {
      size: 'md',
      backdrop: 'static',
    });
    Object.assign(modal.componentInstance, modalOptions);
    return race(modal.closed, modal.dismissed.pipe(map(() => false)));
  }

  openPylonConfirmModal(modalOptions: IConfirmPylonModalOptions): Observable<string> {
    const modal = this.modalService.open(PylonStateConfirmModalComponent, {
      size: 'md',
      backdrop: 'static',
    });
    Object.assign(modal.componentInstance, modalOptions);
    return race(modal.closed, modal.dismissed);
  }

  openHandOverConfirmModal(modalOptions: IConfirmHandOverModalOptions): Observable<string> {
    const modal = this.modalService.open(HandOverConfirmModalComponent, {
      size: 'md',
      backdrop: 'static',
    });
    Object.assign(modal.componentInstance, modalOptions);
    return race(modal.closed, modal.dismissed);
  }
}
