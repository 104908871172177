<div class="px-xxl add-wrapper">
  <button
    *ngIf="!disableNewComment"
    class="btn btn-primary-negative btn-primary-negative--transparent btn-tiny"
    (click)="addNewClick.emit()"
  >
    <i class="i-add"></i>
    {{ "NEW COMMENT" | translate }}
  </button>
</div>

<div class="card d-flex px-xxl py-xl" *ngFor="let note of comments">
  <div class="note-info d-flex">
    <i class="note-icon i-comment i-oval i-oval--light"></i>
    <div class="title-wrapper">
      <span class="note-meta">{{ note.timestamp | dateFormat : "date_time" }}</span>
      <span class="note-meta">{{ note.author.name }}</span>

      <h3 class="note-title">{{ note.title }}</h3>
    </div>
  </div>

  <div class="comment">{{ note.note }}</div>
</div>
