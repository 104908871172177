import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'dm-ui-stepper',
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
  providers: [{ provide: CdkStepper, useExisting: DmUiStepperComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DmUiStepperComponent extends CdkStepper {
  @HostBinding('class') get orientationClass() {
    return this.orientation;
  }
  @HostBinding('class.disabled')
  @Input()
  disabled: boolean;
  selectStepByIndex(index: number): void {
    if (this.disabled) {
      return;
    }
    this.selectedIndex = index;
  }
}
