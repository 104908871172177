import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { map, Observable } from 'rxjs';
import { CustomerType, IUserSingle } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class UserCustomerService {
  public isCorporate$: Observable<boolean> = this.userService.loggedUser$.pipe(map(this.checkIfUserIsCorporate));

  public get isCorporateSnapshot(): boolean {
    const user = this.userService.userSnapshot;
    return this.checkIfUserIsCorporate(user);
  }

  private checkIfUserIsCorporate(user: IUserSingle): boolean {
    if (!user) {
      return false;
    }
    return user.contact?.customer?.type === CustomerType.CORPORATE;
  }

  constructor(private userService: UserService) {}
}
