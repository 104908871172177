// SHARED_WITH_FRONTEND

export interface AddressEntity {
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  countryCode: string | null;
  phone?: string | null;
}
