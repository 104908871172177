export enum Ability {
  READ_DASHBOARD = 'read:dashboard',

  CREATE_BOAT = 'create:boat',
  READ_BOAT = 'read:boat',
  UPDATE_BOAT = 'update:boat',
  DELETE_BOAT = 'delete:boat',

  CREATE_CUSTOMER = 'create:customer',
  READ_CUSTOMER = 'read:customer',
  UPDATE_CUSTOMER = 'update:customer',
  DELETE_CUSTOMER = 'delete:customer',

  READ_CONTRACT = 'read:contract',

  CREATE_TRANSIENT_BOOKING = 'create:transient_booking',

  CREATE_RESOURCE_BOOKING = 'create:resource_booking',
  CREATE_RESOURCE_BOOKING_UNRESTRICTED = 'create:resource_booking_unrestricted',
  READ_RESOURCE_BOOKING = 'read:resource_booking',
  UPDATE_RESOURCE_BOOKING = 'update:resource_booking',
  DELETE_RESOURCE_BOOKING = 'delete:resource_booking',

  CREATE_RESOURCE_ASSIGNMENT = 'create:resource_assignment',
  READ_RESOURCE_ASSIGNMENT = 'read:resource_assignment',
  UPDATE_RESOURCE_ASSIGNMENT = 'update:resource_assignment',
  DELETE_RESOURCE_ASSIGNMENT = 'delete:resource_assignment',

  CREATE_PRICELIST = 'create:pricelist',
  READ_PRICELIST = 'read:pricelist',
  UPDATE_PRICELIST = 'update:pricelist',
  DELETE_PRICELIST = 'delete:pricelist',
  IMPORT_PRICELIST = 'import:pricelist',

  READ_PRODUCT = 'read:product',

  CREATE_CHECK_IN = 'create:check_in',
  READ_CHECK_IN = 'read:check_in',
  UPDATE_CHECK_IN = 'update:check_in',
  DELETE_CHECK_IN = 'delete:check_in',

  CREATE_ROLE = 'create:role',
  READ_ROLE = 'read:role',
  UPDATE_ROLE = 'update:role',
  DELETE_ROLE = 'delete:role',

  CREATE_USER = 'create:user',
  READ_USER = 'read:user',
  UPDATE_USER = 'update:user',
  DELETE_USER = 'delete:user',

  IMPERSONATE_USER = 'impersonate:user',

  UPDATE_MARINA = 'update:marina',

  CREATE_LOCALIZATION = 'create:localization',

  LIST_FILES = 'list:file',
  DELETE_FILE = 'delete:file',

  READ_PAYMENT = 'read:payment',
  CREATE_PAYMENT = 'create:payment',

  ADMIN = 'all:admin',

  CREATE_ORDER = 'create:order',
  UPDATE_ORDER = 'update:order',
  DELETE_ORDER = 'delete:order',
  READ_ORDER = 'read:order',
  UPDATE_BERTH = 'update:berth',

  MANAGE_TASK_MANAGEMENT = 'manage:task-management',
  OPERATE_TASK_MANAGEMENT = 'operate:task-management',

  READ_OFFERS = 'read:offer',
}
