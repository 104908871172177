import { Injector, Type, InjectionToken, InjectFlags } from '@angular/core';

export class NotificationInjector implements Injector {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private _parentInjector: Injector, private _additionalTokens: WeakMap<any, any>) {}

  public get<T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T, flags?: InjectFlags): T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get(token: any, notFoundValue?: any): unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get(token: any, notFoundValue?: any, flags?: any) {
    const value = this._additionalTokens.get(token);

    if (value) {
      return value;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._parentInjector.get<any>(token, notFoundValue);
  }
}
