import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { CardHeaderComponent } from './card-header.component';
import { CardBodyDirective } from './card-body.component';
import { CardFooterDirective } from './card-footer.component';

const components = [CardComponent, CardHeaderComponent, CardBodyDirective, CardFooterDirective];
@NgModule({
  imports: [CommonModule],
  declarations: components,
  exports: components,
})
export class CardModule {}
