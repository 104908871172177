import { trigger, transition, style, animate } from '@angular/animations';
import { CUBIC } from '../constants';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('.2s', style({ opacity: 1 }))]),
  transition(':leave', [animate('.2s', style({ opacity: 0 }))]),
]);
export const fadeWithScaleAnimation = trigger('fadeWithScaleAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.8)' }),
    animate(`.25s ${CUBIC}`, style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [animate(`.25s ${CUBIC}`, style({ opacity: 0, transform: 'scale(.8)' }))]),
]);
