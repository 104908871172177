import { Injectable } from '@angular/core';
import {
  BookingResource,
  BookingResourceProduct,
  CustomerCruiseDetailsDto,
  IApproveDocumentRq,
  IDocument,
  IOnlineBookingResourceResponseWithBoatFitment,
  IRequestParamsWithPagination,
  IResourceBooking,
  IResourceBookingBerthAvailabilityBody,
  IResourceBookingBerthAvailabilityResponse,
  IResourceBookingCalculatePricingParams,
  IResourceBookingCancellationPreview,
  IResourceBookingCancelPayload,
  IResourceBookingCancelPreviewPayload,
  IResourceBookingForBoat,
  IResourceBookingQuotation,
  IResourceBookingQuotationsSearchPayload,
  MARINA_URL_TOKEN,
  MmsResourceBookingMediaAccess,
  PaginatedNormal,
  QuotationStatus,
  ResourceBookingBody,
  ResourceBookingResponse,
} from '@dm-workspace/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiClientService } from '../api/api-client.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ResourceBookingApiService {
  private resourceUrl = `/mms/marinas/${MARINA_URL_TOKEN}/resource-booking`;
  private resourceUrlWithoutMarina = `/mms/marinas`;
  // TODO: remove
  private refreshViewSubject$ = new BehaviorSubject(null);

  constructor(private apiClientService: ApiClientService) {}

  public createBooking(body: ResourceBookingBody, marinaCode?: string): Observable<ResourceBookingResponse> {
    const url = marinaCode ? this.resourceUrl.replace(MARINA_URL_TOKEN, marinaCode) : this.resourceUrl;
    return this.apiClientService.post<ResourceBookingResponse>(url, body);
  }

  public delete(id: string): Observable<boolean> {
    return this.apiClientService.delete(`${this.resourceUrl}/${id}`);
  }

  public updateBooking(id: string, body: Partial<ResourceBookingBody>): Observable<ResourceBookingResponse> {
    return this.apiClientService.patch<ResourceBookingResponse>(`${this.resourceUrl}/${id}`, body);
  }

  public getBookingAvailableMediaAccess(id: string): Observable<MmsResourceBookingMediaAccess> {
    return this.apiClientService.get<MmsResourceBookingMediaAccess>(`${this.resourceUrl}/${id}/enable-media-access`);
  }

  public setBookingMediaAccess(id: string): Observable<void> {
    return this.apiClientService.post(`${this.resourceUrl}/${id}/enable-media-access`, null);
  }

  public verifyCruise(bookingId: string, allowBerthReuse: boolean): Observable<void> {
    return this.apiClientService.post(`${this.resourceUrl}/${bookingId}/verify-cruise`, { allowBerthReuse });
  }

  public cruiseDetails(bookingId: string): Observable<CustomerCruiseDetailsDto> {
    return this.apiClientService.get<CustomerCruiseDetailsDto>(
      `${this.resourceUrl}/${bookingId}/customer-cruise-details`
    );
  }

  public checkIfPaymentSessionIsActive(bookingId: string): Observable<{
    updateAvailable: boolean;
  }> {
    return this.apiClientService.get(`${this.resourceUrl}/${bookingId}/update-availability`);
  }

  public getResourceById(id: string): Observable<ResourceBookingResponse> {
    return this.apiClientService.get(`${this.resourceUrl}/${id}`);
  }

  public checkAvailability(
    resourceId: string,
    body: IResourceBookingBerthAvailabilityBody,
    boatId?: string,
    marinaCode?: string
  ): Observable<IResourceBookingBerthAvailabilityResponse> {
    let params = new HttpParams({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fromObject: body as any,
    });
    let url = this.resourceUrl;
    if (boatId) {
      params = params.append('boatId', boatId);
    }
    if (!body.forBooking) {
      params = params.delete('forBooking');
    }
    if (marinaCode) {
      url = `${this.resourceUrlWithoutMarina}/${marinaCode}/resource-booking`;
    }
    return this.apiClientService.get(`${url}/resources/${resourceId}/availability`, { params });
  }

  public fetchQuotations(
    params: IRequestParamsWithPagination<IResourceBookingQuotationsSearchPayload>
  ): Observable<PaginatedNormal<IResourceBookingQuotation>> {
    return this.apiClientService.get<PaginatedNormal<IResourceBookingQuotation>>(`${this.resourceUrl}/quotations`, {
      params: { ...params },
    });
  }

  public changeStatus(quotationId: string, newStatus: QuotationStatus, marinaCode?: string): Observable<unknown> {
    const url = marinaCode ? this.resourceUrl.replace(MARINA_URL_TOKEN, marinaCode) : this.resourceUrl;
    return this.apiClientService.post(`${url}/${quotationId}/quotations/change-status`, {
      newStatus,
    });
  }

  public cancel(
    quotationId: string,
    marinaCode: string,
    payload: IResourceBookingCancelPayload
  ): Observable<IResourceBooking> {
    const url = marinaCode ? this.resourceUrl.replace(MARINA_URL_TOKEN, marinaCode) : this.resourceUrl;

    return this.apiClientService.post(`${url}/${quotationId}/quotations/change-status`, {
      ...payload,
      newStatus: QuotationStatus.CANCELLED,
    });
  }

  public cancelEmailPreview(
    id: string,
    marinaCode: string,
    payload: IResourceBookingCancelPreviewPayload
  ): Observable<IResourceBookingCancellationPreview> {
    const url = marinaCode ? this.resourceUrl.replace(MARINA_URL_TOKEN, marinaCode) : this.resourceUrl;

    return this.apiClientService.post(`${url}/${id}/quotations/change-status/preview`, {
      ...payload,
      newStatus: QuotationStatus.CANCELLED,
    });
  }

  public approve(bookingId: string): Observable<IResourceBooking> {
    return this.apiClientService.post<IResourceBooking>(`${this.resourceUrl}/${bookingId}/approve`, {});
  }

  public reject(bookingId: string, comment: string): Observable<IResourceBooking> {
    return this.apiClientService.post<IResourceBooking>(`${this.resourceUrl}/${bookingId}/reject`, { comment });
  }

  public approveDocuments(bookingId: string, documents: IApproveDocumentRq[]): Observable<IDocument> {
    return this.apiClientService.post<IDocument>(`${this.resourceUrl}/${bookingId}/approve/documents`, { documents });
  }

  public documents(bookingId: string, fileIds: string[]): Observable<IDocument[]> {
    return this.apiClientService.post<IDocument[]>(`${this.resourceUrl}/${bookingId}/documents`, { fileIds });
  }

  public createUnrestrictedBooking(params: ResourceBookingBody): Observable<ResourceBookingResponse> {
    return this.apiClientService.post<ResourceBookingResponse>(`${this.resourceUrl}/unrestricted`, params);
  }

  fetchBerthsForBoatAndMarina(reserveParams: IResourceBookingForBoat): Observable<BookingResource[]> {
    const { marinaCode } = reserveParams;
    return this.apiClientService.get<BookingResource[]>(
      `${this.resourceUrlWithoutMarina}/${marinaCode || MARINA_URL_TOKEN}/resource-booking/berths/for-boat`,
      {
        params: reserveParams,
      }
    );
  }

  public fetchProductsForBoatAndMarina(params: IResourceBookingForBoat): Observable<BookingResourceProduct[]> {
    const { marinaCode = MARINA_URL_TOKEN } = params;
    const url = `/mms/marinas/${marinaCode}/resource-booking/berths/for-boat/additional-products`;
    return this.apiClientService.get<BookingResourceProduct[]>(url, { params });
  }

  fetchPricing(
    pricingParams: IResourceBookingCalculatePricingParams
  ): Observable<IOnlineBookingResourceResponseWithBoatFitment> {
    const { marinaCode } = pricingParams;
    return this.apiClientService.post<IOnlineBookingResourceResponseWithBoatFitment>(
      `${this.resourceUrlWithoutMarina}/${marinaCode || MARINA_URL_TOKEN}/resource-booking/calculate-price`,
      pricingParams
    );
  }

  // TODO: remove
  get refreshView$(): Observable<null> {
    return this.refreshViewSubject$.asObservable();
  }

  // TODO: remove
  public triggerViewRefresh(): void {
    this.refreshViewSubject$.next(null);
  }
}
