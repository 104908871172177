import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { map, takeUntil, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dm-payment-countdown',
  templateUrl: './payment-countdown.component.html',
  styleUrls: ['./payment-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCountdownComponent {
  #destroyRef = inject(DestroyRef);
  @Input({ required: true }) toDate: Date;
  @Output() countingDone = new EventEmitter<void>();

  protected readonly countdown$ = timer(0, 1000).pipe(
    takeUntil(this.countingDone),
    takeUntilDestroyed(this.#destroyRef),
    map(() => this.#getCounterTime())
  );

  #getCounterTime(): Date {
    const now = Date.now();
    const diff = this.toDate.getTime() - now;
    const diffDate = new Date(diff);

    if (diff <= 0) {
      this.countingDone.emit();
    }
    return diffDate;
  }
}
