import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { ObLayoutService } from '../../services/ob-layout.service';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-ob-header',
  templateUrl: './ob-header.component.html',
  styleUrls: ['./ob-header.component.scss'],
})
export class ObHeaderComponent {
  #router = inject(Router);
  #marinasService = inject(MarinasService);
  #obLayoutService = inject(ObLayoutService);

  protected headerBackLink = this.#obLayoutService.headerBackLink;

  title$ = this.#router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => {
      let route = this.#router.routerState.root;
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route.snapshot.data?.['title'];
    })
  );

  readonly backToMarinaLink$ = this.#marinasService.selectedMarina$.pipe(
    map((marina) => {
      const slug = marina.pageSlug;
      return this.#marinasService.getMarinaWebsiteLink({ slug });
    })
  );
}
