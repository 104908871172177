import { Directive, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { ApiValidatorService } from '@dm-workspace/forms';
import { ApiErrorObject, GenericObject } from '@dm-workspace/types';

/**
 * @deprecated
 */
@Directive()
export abstract class FormView<
  RequestParams extends GenericObject,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Response = any,
  RequestParseParams = RequestParams,
> {
  public pending = false;
  public form!: UntypedFormGroup;
  public apiErrors: ApiErrorObject<RequestParams>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public additionalOutputParams: any;

  @Output() formSubmitted: EventEmitter<RequestParams> = new EventEmitter();

  protected constructor(protected apiValidator: ApiValidatorService) {}

  protected apiCall?(params: RequestParseParams): Observable<Response>;
  protected parseForm(formData: RequestParams): RequestParseParams {
    return formData as unknown as RequestParseParams;
  }

  getRequestParams(): RequestParseParams {
    return this.parseForm(this.form.value);
  }

  protected onError(res: HttpErrorResponse) {
    if (res.error) {
      this.apiErrors = this.apiValidator.parseApiValidationErrorsToObject(res.error);
    }
  }

  protected onSuccess(res: Response): void {
    return;
  }

  formIsNotValid(): boolean {
    return !this.apiValidator.formIsValid(this.form);
  }

  protected emitValue(params: RequestParseParams): void {
    this.formSubmitted.emit({
      ...params,
      ...this.additionalOutputParams,
    });
  }

  public onSubmit() {
    if (this.pending || this.formIsNotValid()) {
      return;
    }

    const params = this.getRequestParams();

    if (!this.apiCall) {
      return this.emitValue(params);
    }

    this.pending = true;

    this.apiCall(params)
      .pipe(finalize(() => (this.pending = false)))
      .subscribe({
        next: (value) => this.onSuccess(value),
        error: (err) => this.onError(err),
      });
  }
}
