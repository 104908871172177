import { Directive, Input, Query, TemplateRef } from '@angular/core';

export interface ITabItem {
  id: string;
  label: string;
}

@Directive({
  selector: '[dmUiTabItem]',
})
export class TabItemDirective {
  public id: string;
  public label: string;

  constructor(public template: TemplateRef<Query>) {}

  @Input('dmUiTabItem') set data(params: ITabItem) {
    const { id, label } = params;

    this.id = id;
    this.label = label;
  }
}
