<div *ngIf="!loadingAvailability">
  <dm-ui-availability-info
    class="mb-3 d-block"
    [available]="berthAvailable"
    availableDescription="IN_SELECTED_RANGE_BERTH_AVAILABLE"
    availableHeader="BERTH_AVAILABLE"
    unavailableDescription="IN_SELECTED_RANGE_BERTH_UNAVAILABLE"
    unavailableHeader="BERTH_UNAVAILABLE"
  />
  <dm-ui-availability-info
    [available]="boatFit"
    availableDescription="BOAT_FIT_DESCRIPTION"
    availableHeader="BOAT_FIT_HEADER"
    unavailableDescription="BOAT_DOES_NOT_FIT_DESCRIPTION"
    unavailableHeader="BOAT_DOES_NOT_FIT_HEADER"
    [reasons]="reasons"
  />
</div>
<div class="d-flex row justify-content-start align-items-center" *ngIf="loadingAvailability">
  <div class="col-sm-6 col-md-4 col-lg-2 status">
    <dm-ui-circle-spinner [showSpinner]="true" />
  </div>
</div>
