import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinBerths',
})
export class JoinBerthsPipe implements PipeTransform {
  transform(berths: { name: string }[]): string {
    if (!berths) {
      return '';
    }

    return berths.map((value) => value.name).join(' - ');
  }
}
