<ul ngbNav #nav="ngbNav" class="nav-tabs px-xxl" [(activeId)]="activeContent" (activeIdChange)="activeIdChange($event)">
  <li *ngFor="let component of components" [ngbNavItem]="component[0]">
    <a ngbNavLink>{{ component[0] | uppercase | translate }}</a>
    <ng-template ngbNavContent>
      <ng-container *ngIf="injector">
        <ng-container *ngComponentOutlet="component[1]; injector: injector" />
      </ng-container>
      <ng-container *ngIf="!injector">
        <ng-container *ngComponentOutlet="component[1]" />
      </ng-container>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
