<ng-content/>

<div class="input-wrapper" *ngIf="iconName || clear; else icons">
  <ng-content select="[formInput]"/>
  <ng-container *ngTemplateOutlet="icons"/>
</div>

<ng-content select="dm-form-error-message"/>

<ng-template #icons>
  <div class="icons">
    <span class="clear-btn" *ngIf="clear && hasValue" (click)="onClear($event)">&times;</span>
    <i class="form-group__icon i-{{ iconName }}" *ngIf="iconName"></i>
  </div>
</ng-template>
