import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim',
})
export class TrimPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (value === null || value === '' || value === undefined || typeof value !== 'string') {
      return value;
    }

    return value.trim();
  }
}
