import { NcpLayoutHeaderComponent } from './components/ncp-layout-header/ncp-layout-header.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { NcpLayoutMainViewComponent } from './views/ncp-layout-main-view/ncp-layout-main-view.component';
import { NcpLayoutFooterComponent } from './components/ncp-layout-footer/ncp-layout-footer.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NcpSharedModule } from '@dm-workspace/new-customer-portal/ncp-shared';

const declarations = [NcpLayoutHeaderComponent, NcpLayoutMainViewComponent, NcpLayoutFooterComponent];

@NgModule({
  declarations: [...declarations],
  imports: [CommonModule, TranslateModule, RouterOutlet, RouterLink, NcpSharedModule],
})
export class NcpLayoutModule {}
