import { ApiPrefix } from 'libs/types/src';
import { environment, IEnvironment } from '@dm-workspace/environments';
import { Integrations } from '@sentry/tracing';
import packageInfo from '../../../../package.json';
import * as Sentry from '@sentry/angular';

export type DmSentryInitConfig = {
  env: IEnvironment;
  apiPrefix: ApiPrefix;
};

export class DmSentry {
  public static init({ apiPrefix, env }: DmSentryInitConfig) {
    const environment = DmSentry.#getEnvName(apiPrefix);
    const release = `${packageInfo.version}${env.stage ? '_stage' : env.production ? '' : '_dev'}`;

    Sentry.init({
      dsn: env.sentry,
      enabled: env.production || env.stage,
      autoSessionTracking: true,
      environment,
      release,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [env.apiUrl],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      tracesSampleRate: env.production ? 0.5 : 1.0,
      denyUrls: [/maps\.googleapis\.com/],
    });
  }

  static #getEnvName(apiPrefix: ApiPrefix) {
    const envString = environment.production ? 'production' : environment.stage ? 'stage' : 'dev';
    return `${apiPrefix}::${envString}`;
  }
}
