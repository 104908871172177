import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FileService } from '@dm-workspace/core';
import { ApiFile } from '@dm-workspace/types';
import { catchError, Subscription, throwError } from 'rxjs';
import { ApiValidatorService } from '@dm-workspace/forms';

@Component({
  selector: 'dm-form-files-item-upload',
  templateUrl: './files-item-upload.component.html',
  styleUrls: ['./files-item-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesItemUploadComponent implements OnInit, OnDestroy {
  public progress = 0;
  @Input() public file: File;
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public uploaded: EventEmitter<ApiFile> = new EventEmitter();
  private uploadSubscription: Subscription;

  constructor(
    private fileService: FileService,
    private cd: ChangeDetectorRef,
    private notification: NotificationService,
    private apiValidator: ApiValidatorService
  ) {}

  public ngOnInit(): void {
    this.upload();
  }

  public ngOnDestroy(): void {
    this.unsubscribeUpload();
  }

  public unsubscribeUpload() {
    this.uploadSubscription?.unsubscribe();
  }

  public upload(): void {
    const formData = new FormData();
    formData.append('file', this.file);

    this.uploadSubscription = this.fileService
      .upload(formData)
      .pipe(
        catchError((res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);

          this.unsubscribeUpload();
          this.cancel.emit();

          return throwError(() => res);
        })
      )
      .subscribe((e) => this.setUploadStatus(e));
  }

  public cancelUpload(): void {
    this.uploadSubscription.unsubscribe();
    this.cancel.emit();
  }

  private setUploadStatus($event: HttpEvent<ApiFile>) {
    switch ($event.type) {
      case HttpEventType.UploadProgress:
        this.progress = Math.round(($event.loaded / $event.total) * 100);
        break;

      case HttpEventType.Response:
        this.uploaded.emit($event.body);
        this.unsubscribeUpload();
        break;
    }

    this.cd.detectChanges();
  }
}
