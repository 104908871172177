import { CountryCode, Customer } from '@dm-workspace/types';

export class CustomerUtils {
  static isFiscalCodeRequired(customerCountryCode: string, marinaCountryCode: string): boolean {
    return customerCountryCode === CountryCode.Italy && marinaCountryCode === CountryCode.Italy;
  }
  static isPassportIdRequired(customerCountryCode: string, marinaCountryCode: string): boolean {
    return marinaCountryCode === CountryCode.Turkey && customerCountryCode !== CountryCode.Turkey;
  }
  static isTaxNumberIdRequired(customerCountryCode: string, marinaCountryCode: string): boolean {
    const turkishInTurkey = marinaCountryCode === CountryCode.Turkey && customerCountryCode === CountryCode.Turkey;
    const spanishInSpain = marinaCountryCode === CountryCode.Spain && customerCountryCode === CountryCode.Spain;
    return turkishInTurkey || spanishInSpain;
  }
  static hasOnlineAccount(customer: Customer) {
    return customer?.customerPortalAccounts?.some((account) => account.hasAccount && account.isPrimaryContact);
  }
}
