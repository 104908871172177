import { Pipe, PipeTransform } from '@angular/core';
import { Features } from '@dm-workspace/core';

/**
 * @deprecated
 * https://github.com/angular/angular/issues/25963#fromHistory
 */
@Pipe({
  name: 'features',
})
export class FeaturesPipe implements PipeTransform {
  /**
   * @deprecated
   * Pipes shouldn't be used for this purpose.
   */
  transform(value: unknown): typeof Features {
    return Features;
  }
}
