import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-ui-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
  @Input() title?: string;
  @Input() body: string;
  @Input() name?: string;
  @Input() cancelBtn?: string = 'CANCEL';
  @Input() confirmBtn?: string = 'CONFIRM';
  @Input() actionButtonClass = 'btn-primary';

  constructor(protected activeModal: NgbActiveModal) {}
}
