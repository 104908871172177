import { Component, Input } from '@angular/core';
import { COUNTRIES_NEW } from '@dm-workspace/types';

@Component({
  selector: 'dm-form-select-option-country',
  templateUrl: './form-select-option-country.component.html',
  styleUrls: ['./form-select-option-country.component.scss'],
})
export class FormSelectOptionCountryComponent {
  @Input({ required: true }) country: (typeof COUNTRIES_NEW)[number];
}
