import { Component, Input } from '@angular/core';
import { QuoteDetailsCmsDataBenefitImageData } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-cms-image',
  templateUrl: './ob-cms-image.component.html',
  styleUrls: ['./ob-cms-image.component.scss'],
})
export class ObCmsImageComponent {
  @Input() public data: QuoteDetailsCmsDataBenefitImageData;
}
