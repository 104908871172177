import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutocompleteService } from '@dm-workspace/data-access';
import { AutocompleteTypes, GenericObject, IAutocompleteResponse, IAutocompleteStateParams } from '@dm-workspace/types';
import { concat, debounceTime, distinctUntilChanged, filter, Observable, of, Subject, switchMap, tap } from 'rxjs';

@Component({
  selector: 'dm-form-select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  styleUrls: ['./select-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true,
    },
  ],
})
export class SelectAutocompleteComponent<I = IAutocompleteResponse, V = string>
  implements ControlValueAccessor, OnInit
{
  public pending = false;
  public disabled = false;
  public value: V;
  public items$: Observable<IAutocompleteResponse[] | GenericObject[]>;
  public input$ = new Subject<string>();

  @Input() public autocomplete: AutocompleteTypes;
  @Input() public clearable = true;
  @Input() public placeholder?: string;
  @Input() public addTag = false;
  @Input() public bindLabel = '';
  @Input() public bindValue?: string;
  @Input() public countryCode?: string;
  @Input() public stateId?: string;

  private _initItem: GenericObject;
  @Input() public set initItem(value: GenericObject) {
    this._initItem = value;
    this.setInitItem(value);
  }

  constructor(private autocompleteService: AutocompleteService, private cd: ChangeDetectorRef) {}

  public ngOnInit() {
    if (!this._initItem) {
      this.setInitItem();
    }
  }

  private setInitItem(item?: GenericObject) {
    this.items$ = concat(
      of(item ? [item] : []),
      this.input$.pipe(
        distinctUntilChanged(),
        filter((v) => !!v),
        tap(() => (this.pending = true)),
        debounceTime(300),
        switchMap((term) => {
          const searchParams: IAutocompleteStateParams = {};
          if (this.countryCode) {
            searchParams.countryCode = this.countryCode;
          }
          if (this.stateId) {
            searchParams.stateId = this.stateId;
          }
          return this.autocompleteService
            .search(term, this.autocomplete, searchParams)
            .pipe(tap(() => (this.pending = false)));
        })
      )
    );
  }

  public getLabel(): string {
    return this.bindLabel ?? '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onSelectChange($event?: any): void {
    this.onTouched();

    if (this.bindValue && $event) {
      this.onChange($event[this.bindValue]);
    } else {
      this.onChange($event);
    }
  }

  public writeValue(value: V): void {
    this.value = value;
    this.cd.detectChanges();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChange: (selected: V) => void = () => undefined;
  public registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  private onTouched: () => void = () => undefined;
  public registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }
}
