import { inject, Injectable } from '@angular/core';
import { ApiPrefix, Ticket, MARINA_URL_TOKEN } from '@dm-workspace/types';
import { ApiHttpClient, ApiService } from '../api/api-http-client';

@Injectable({
  providedIn: 'root',
})
export class TicketsApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = (() => {
    const url = ApiPrefix.mmsPanel
      ? `/${ApiPrefix.mmsPanel}/marinas/${MARINA_URL_TOKEN}/ticket`
      : `/${ApiPrefix.customerPanel}/ticket`;
    return this.apiHttpClient.createResourceUrl(url);
  })();

  public getById(ticketId: string) {
    return this.apiHttpClient.get<Ticket>(`${this.resourceUrl}/${ticketId}`);
  }
}
