import { inject, Injectable } from '@angular/core';
import { ApiHttpClient, ApiService } from '../api/api-http-client';
import {
  CustomerOrder,
  EntityPaymentMethods,
  TPaymentChannel,
  IResourceBookingOrderPaymentPayload,
  TResourceBookingPaymentResponse,
} from '@dm-workspace/types';
import { of } from 'rxjs';

export type CustomerGEtOrderPaymentMethods = {
  orderId: string;
  shopperLocale: string;
  channel: TPaymentChannel;
};

@Injectable({
  providedIn: 'root',
})
export class CustomerOrderApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/customer/order`);

  getById(orderId: string) {
    return this.apiHttpClient.get<CustomerOrder>(`${this.resourceUrl}/${orderId}`);
  }

  getPaymentHistory(orderId: string) {
    return of(null);
    // return this.apiHttpClient.get<PaymentHistory>(`${this.resourceUrl}/${orderId}`);
  }

  getPaymentMethods(params: CustomerGEtOrderPaymentMethods) {
    return this.apiHttpClient.post<EntityPaymentMethods>(`${this.resourceUrl}/enabled-payment-methods`, params);
  }

  paymentOrder(payload: IResourceBookingOrderPaymentPayload) {
    return this.apiHttpClient.post<TResourceBookingPaymentResponse>(`${this.resourceUrl}/payment`, payload);
  }
}
