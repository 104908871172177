import { Directive, ElementRef, EventEmitter, HostBinding, inject, Output } from '@angular/core';

@Directive()
export abstract class StateSwitchItem<V = void> {
  #el = inject(ElementRef<HTMLElement>);

  @HostBinding('class') hostClass = 'state-switch-item';
  @HostBinding('attr.aria-current') public abstract active: boolean;

  @Output() abstract stateChange: EventEmitter<V>;

  getWidth() {
    return Math.max(this.#el.nativeElement.scrollWidth, this.#el.nativeElement.clientWidth);
  }

  getLeftPosition() {
    return this.#el.nativeElement.offsetLeft;
  }
}
