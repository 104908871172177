<google-map
  *ngIf="isLoaded$ | async as options"
  #myMap="googleMap"
  width="100%"
  class="restore-info-window-tip-pointer-events"
  [options]="options"
  [height]="height"
  [center]="center"
>
  <map-polygon *ngFor="let pier of piers" [options]="piersOptions" [paths]="pier | arrayToLatLng" />
  <map-polygon
    *ngFor="let multihull of multihullsPolygon"
    [paths]="multihull.poly | arrayToLatLng"
    [options]="multihull.color | multihullPolygonStyleByStatus"
  />

  <map-polygon
    *ngFor="let berth of berths"
    #berthM="mapPolygon"
    [paths]="berth.polygon | arrayToLatLng"
    [options]="berth | berthPolygonStyleByBookingPipe: bookingBerthsByKey[berth.id] : selectedResource"
    (polygonClick)="$event.domEvent.preventDefault(); onBerthClick(berthM, berth)"
    (polygonMouseover)="$event.domEvent.preventDefault(); onBerthHover(berthM, berth)"
    (polygonMouseout)="$event.domEvent.preventDefault(); onBerthBlur(berthM, berth)"
  />

  <map-info-window *ngIf="selectedPolygon" #hoverInfoWindow="mapInfoWindow" [position]="selectedPolygon?.lat">
    <dm-ob-maps-price-popup [resource]="selectedPolygon?.resource" [marinaCode]="marina.code" />
  </map-info-window>
  <ng-container *ngIf="!selectedMarina && marinasArray">
    <ng-container *ngFor="let marina of marinasArray">
      <map-marker
        *ngIf="marina.layout.center"
        #marker="mapMarker"
        [position]="marina.layout.center | centerArrayToMarker"
        [title]="marina.name"
        [icon]="markerIcon"
        (mapClick)="onMarinaMarkerClick(marina)"
      />
    </ng-container>
  </ng-container>
</google-map>

<dm-ui-overflow-spinner type="fixed" [showSpinner]="pending" />
