import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// This service is dedicated only to formatting dates displayed inside inputs.
// Should not be used to manipulate dates in the model.
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '.';

  /* Used when input value typed manually */
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  /* Used to display the input value */
  format(date: NgbDateStruct | null): string {
    return date
      ? ('00' + date.day).slice(-2) + this.DELIMITER + ('00' + date.month).slice(-2) + this.DELIMITER + date.year
      : '';
  }
}

// This service is dedicated to manipulating dates for model purposes, ex. calculations, proper formatting for API calls.
// Should not be used to format dates visible to the user.
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);

      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? date.year + this.DELIMITER + ('00' + date.month).slice(-2) + this.DELIMITER + ('00' + date.day).slice(-2)
      : null;
  }
}
