import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { PaymentWireTransferInitResponse } from '@dm-workspace/types';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { PaymentsApiService } from '@dm-workspace/data-access';
import { PaymentMethodComponent } from '../payment-method.class';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';

@Component({
  selector: 'dm-payment-method-wire-transfer',
  templateUrl: './payment-method-wire-transfer.component.html',
  styleUrls: ['./payment-method-wire-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodWireTransferComponent extends PaymentMethodComponent implements OnInit {
  #paymentApiService = inject(PaymentsApiService);
  #notificationService = inject(NotificationService);
  protected readonly data = signal<PaymentWireTransferInitResponse>(null);
  protected readonly pending = signal<boolean>(true);
  protected readonly apiCallConfirming = signal<boolean>(false);

  ngOnInit() {
    this.#paymentApiService
      .wireTransferInit({ referenceId: this.paymentReferenceId })
      .pipe(
        finalize(() => this.pending.set(false)),
        catchError((error) => {
          this.paymentMountError.emit(error);
          return throwError(() => error);
        }),
        tap((res) => {
          this.data.set(res);
          this.paymentMounted.emit();
        })
      )
      .subscribe();
  }

  confirmPay() {
    this.apiCallConfirming.set(true);
    this.#paymentApiService
      .wireTransferConfirm({ referenceId: this.paymentReferenceId })
      .pipe(
        finalize(() => this.apiCallConfirming.set(false)),
        catchError((res: HttpErrorResponse) => {
          const notificationContent = ApiValidator.getApiNotificationError(res);
          this.#notificationService.openError(notificationContent);
          return throwError(() => res);
        }),
        tap(() => {
          this.paymentSuccess.emit();
        })
      )
      .subscribe();
  }
}
