import { Pipe, PipeTransform } from '@angular/core';
import { IBoatAlertResponseDtoSeverityEnum, IBoatAlertResponseDtoStatusEnum } from '@dm-workspace/types';

@Pipe({
  name: 'alertStatusTranslateKey',
})
export class AlertStatusTranslateKeyPipe implements PipeTransform {
  public transform(alert: {
    status: IBoatAlertResponseDtoStatusEnum;
    severity: IBoatAlertResponseDtoSeverityEnum;
  }): string {
    const { status, severity } = alert;

    return `ALERT_.STATUSES_.${severity === IBoatAlertResponseDtoSeverityEnum.Warning ? 'INFO' : status}`;
  }
}
