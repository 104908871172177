import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'centerArrayToMarker',
})
export class CenterArrayToMarkerPipe implements PipeTransform {
  transform([lng, lat]: [number, number]): google.maps.LatLngLiteral {
    return { lng, lat };
  }
}
