import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @deprecated
 * use normal input with type="checkbox"
 */
@Component({
  selector: 'dm-form-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  public model: boolean;
  public disabled = false;

  @Input() public label: string;
  @Input() public id: string;
  @Input() public isContentInside = true;

  @Output() public write: EventEmitter<boolean> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) {}

  private onChange: (value: boolean) => void = () => undefined;
  private onTouched: () => void = () => undefined;

  public writeValue(value: boolean) {
    this.model = value;
    this.cd.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onModelChange($event?: boolean): void {
    this.onTouched();
    this.onChange($event);

    this.write.emit($event);
  }
}
