import { Directive, Host, HostBinding, Input } from '@angular/core';
import { FormGroupComponent } from '../components/form-group/form-group.component';

@Directive({
  selector: `[dmFormGroupCheckbox]`,
})
export class FormGroupCheckboxDirective {
  @HostBinding('class.ng-input-checked')
  public checked = this.formGroup.hasValue;

  @HostBinding('class.ng-input-indeterminate')
  @Input()
  public indeterminate?: boolean;

  constructor(@Host() private formGroup: FormGroupComponent) {}
}
