import { PierStructure, PierType, Polygon } from '../backend';
import { IBerth } from './berth.interface';
import { Marina } from './marina.interface';

export interface IPier {
  id: string;
  name: string;
  latitude: string | null;
  longitude: string | null;
  structure: PierStructure;
  type: PierType;
  polygon: Polygon | null;
  marker: number[];
  marina: Marina;
  berths?: IBerth[];
}

export interface IPierShort {
  name: string;
  id: string;
  marinaCode: string;
}
