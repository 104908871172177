<table class="table table-desktop">
  <thead *ngIf="(isMobile$ | async) === false">
    <tr>
      <th scope="col"></th>
      <th scope="col">{{ "BERTH" | translate }}</th>
      <th scope="col">{{ "LENGTH" | translate }}</th>
      <th scope="col">{{ "WIDTH" | translate }}</th>
      <th scope="col">{{ "DURATION" | translate }}</th>
      <th scope="col" class="col-price">
        {{ "NET_PRICE" | translate }}
        <dm-ui-icon-tooltip [tooltipText]="'VAT_INCLUDED_FOR_INDIVIDUAL_CUSTOMER' | translate" />
      </th>
    </tr>
  </thead>
  <tbody *ngIf="berthList?.length">
    <tr
      *ngFor="let berth of berthList | slice: 0 : 5"
      [ngClass]="{ checked: berth.resource.name === selectedBerth }"
      (click)="onBerthClick(berth)"
    >
      <td>
        <dm-form-group class="me-3">
          <input dmForm type="radio" [ngModel]="selectedBerth" [value]="berth.resource.name" />
          <label class="berth-select-type-label"></label>
        </dm-form-group>
      </td>
      <td>
        <p class="mobile-header">{{ "BERTH" | translate }}</p>
        {{ berth.resource.name }}
      </td>
      <ng-container [ngSwitch]="isMobile$ | async">
        <td *ngSwitchCase="true" class="details-row">
          <p>
            LEN. <strong>{{ findBerthFormValues?.boatLength }}m</strong>
          </p>
          <p>
            WID. <strong>{{ findBerthFormValues?.boatWidth }}m</strong>
          </p>
        </td>
        <ng-container *ngSwitchCase="false">
          <td>{{ findBerthFormValues?.boatLength }}</td>
          <td>{{ findBerthFormValues?.boatWidth }}</td>
        </ng-container>
      </ng-container>
      <td *ngIf="(isMobile$ | async) === false">
        {{ berth.pricingDetails?.duration }}
        {{ (berth.pricingDetails?.duration === 1 ? "NIGHT" : "NIGHTS") | translate }}
      </td>

      <td class="price-row">
        <p class="mobile-header">{{ "TOTAL_PRICE" | translate }}</p>
        {{ berth?.pricingDetails.sumPriceGross | currency: berth?.pricingDetails.currency }}
      </td>
    </tr>
  </tbody>
</table>
