import { AlertsByType, IBoatAlertResponseDto, IBoatSensorShort, PylonMediaType } from '@dm-workspace/types';
import * as Sentry from '@sentry/angular';

export function iconFromPylonMediaType(mediaType: PylonMediaType): string {
  switch (mediaType) {
    case PylonMediaType.WATER:
      return 'i-drop-outline';
    case PylonMediaType.ELECTRICITY:
      return 'i-lightning';
  }
}

export function mapAlertsWithSensorType(alerts: IBoatAlertResponseDto[], sensors: IBoatSensorShort[]): AlertsByType {
  return alerts.reduce((acc: AlertsByType, alert) => {
    const alertSensorType = sensors?.find((s) => s.id === alert.sensorId)?.type;

    if (!alertSensorType) {
      Sentry.captureException('Alert sensor type not found');
      return acc;
    }

    const { count = 0, alerts = [] } = acc[alertSensorType] || {};

    return {
      ...acc,
      [alertSensorType]: { count: count + 1, alerts: [...alerts, { ...alert, sensorType: alertSensorType }] },
    };
  }, {});
}
