import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import { NotificationService } from './services/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationBrowserService } from './services/notification-browser.service';

const declarations = [NotificationContainerComponent];

@NgModule({
  declarations: [...declarations],
  providers: [NotificationService, NotificationBrowserService],
  imports: [CommonModule, TranslateModule],
})
export class NotificationModule {}
