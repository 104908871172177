import { inject, Pipe, PipeTransform } from '@angular/core';
import { ENV } from '@dm-workspace/types';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'quoteCrmLink',
})
export class QuoteCrmLinkPipe implements PipeTransform {
  #env = inject(ENV);
  #domSanitizer = inject(DomSanitizer);

  public transform(quoteId: string) {
    return this.#domSanitizer.bypassSecurityTrustUrl(
      `${this.#env.crmUrl}?pagetype=entityrecord&etn=quote&id=${quoteId}`
    );
  }
}
