import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { StepState } from '../steplabel.pipe';

@Component({
  selector: 'dm-ui-step-label',
  templateUrl: 'step-label.component.html',
  styleUrl: 'step-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DmUiStepLabelComponent {
  @HostBinding('class')
  @Input()
  state: StepState;
  @Input() index: number;
  @Input() label: string;
}
