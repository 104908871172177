import { AuthService } from '../auth/auth.service';
import { Ability, Permissions } from '@dm-workspace/types';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public userPermission: Ability[] | null = [];
  public permissions = Ability;
  public isLoggedIn = false;

  constructor(private authService: AuthService) {
    this.authService.loggedIn$.subscribe((isLogged) => {
      if (isLogged) {
        const tokenInfo = this.authService.tokenInfo;

        if (tokenInfo) {
          this.userPermission = tokenInfo.scope;
          this.isLoggedIn = true;
        }
      } else {
        this.userPermission = null;
      }
    });
  }

  /**
   * @deprecated use checkUserPermission
   */
  public hasPermission(permission: Permissions): boolean {
    if (!permission || !this.userPermission) {
      return false;
    }

    if (typeof permission === 'string') {
      return !!this.userPermission.includes(permission);
    }

    if (Array.isArray(permission)) {
      return permission.some((value) => this.userPermission.includes(value));
    }

    return false;
  }

  public checkUserPermission(permission: Permissions): Observable<boolean> {
    return this.authService.loggedIn$.pipe(filter(Boolean)).pipe(
      map(() => {
        const { scope } = this.authService.tokenInfo;

        if (typeof permission === 'string') {
          return scope.includes(permission);
        }
        if (Array.isArray(permission)) {
          return permission.some((value) => this.userPermission.includes(value));
        }
      })
    );
  }
}
