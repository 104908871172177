import { inject, Injectable } from '@angular/core';
import { MmsMarinasApiService } from '@dm-workspace/data-access';
import { MarinasService } from './marinas.service';
import { distinctUntilChanged, first, map, Observable, switchMap } from 'rxjs';
import { Marina } from '@dm-workspace/types';
import { SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MmsMarinasService {
  #marinasApiService = inject(MmsMarinasApiService);
  #marinasService = inject(MarinasService);

  get selectedMarinaSVG$(): Observable<{ marina: Marina; svg: SafeHtml }> {
    return this.#marinasService.selectedMarina$.pipe(
      first(),
      distinctUntilChanged(),
      switchMap((marina) => this.#marinasApiService.svgFile(marina.code).pipe(map((value) => ({ svg: value, marina }))))
    );
  }

  uploadSVG(svg: File) {
    return this.#marinasService.selectedMarina$.pipe(
      first(),
      switchMap((value) => this.#marinasApiService.uploadSVG(value.code, svg))
    );
  }
}
