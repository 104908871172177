import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FileService } from '@dm-workspace/core';
import { NotificationService } from '@dm-workspace/notification';
import { ApiFile } from '@dm-workspace/types';
import { catchError, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService } from '@dm-workspace/forms';

@Component({
  selector: 'dm-form-files-item',
  templateUrl: './files-item.component.html',
  styleUrls: ['./files-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesItemComponent {
  public isRemoving: boolean;
  public isError: boolean;

  @Input() public file: ApiFile;
  @Input() forceDelete = false;
  @Output() public deleted: EventEmitter<void> = new EventEmitter();

  constructor(
    private fileService: FileService,
    private cd: ChangeDetectorRef,
    private notification: NotificationService,
    private apiValidator: ApiValidatorService
  ) {}

  public deleteFile(): void {
    if (this.isRemoving) {
      return;
    }

    this.isError = false;
    this.isRemoving = true;

    this.cd.detectChanges();

    this.fileService
      .delete(this.file.id)
      .pipe(
        catchError((res: HttpErrorResponse) => {
          if (this.forceDelete) {
            return of(null);
          }
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);

          this.isRemoving = false;
          this.isError = true;

          this.cd.detectChanges();

          return throwError(() => res);
        })
      )
      .subscribe(() => {
        this.deleted.emit();
      });
  }
}
