import { inject, Injectable } from '@angular/core';
import { ApiCrudService, ApiHttpClient, ApiService } from '../api/api-http-client';
import {
  ENV,
  Marina,
  MARINA_URL_TOKEN,
  MarinasNearbyFacilities,
  MarnaAdditionalPropertiesDto,
  MmiMarinaDto,
} from '@dm-workspace/types';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MmsMarinasApiService extends ApiService implements ApiCrudService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl('/mms/marinas');
  #env = inject(ENV);
  #domSanitizer = inject(DomSanitizer);

  get() {
    return this.apiHttpClient.get<Marina[]>(this.resourceUrl);
  }

  getByCode(marinaCode: string) {
    return this.apiHttpClient.get<MmiMarinaDto>(`${this.resourceUrl}/${marinaCode}`);
  }

  update(marinaCode: string, body: Partial<MmiMarinaDto>) {
    return this.apiHttpClient.patch<MmiMarinaDto>(`${this.resourceUrl}/${marinaCode}`, body);
  }

  additionalProperties(properties: MarnaAdditionalPropertiesDto) {
    return this.apiHttpClient.patch(`${this.resourceUrl}/${MARINA_URL_TOKEN}/additionalProperties`, properties);
  }
  uploadSVG(marinaCode: string, svg: File): Observable<{ path: string }> {
    // const svgMatch = svg.match(/data-viewbox="(.*?)"/);
    // const newSvg = svgMatch && svgMatch[1] ? svg.replace(/viewBox=\"(.*?)\"/, `viewBox="${svgMatch[1]}"`) : svg;
    // const svgBlob = new Blob([newSvg], { type: 'image/svg+xml;charset=utf-8' });
    const formData = new FormData();
    formData.append('file', svg);
    return this.apiHttpClient.post<{ path: string }>(`${this.resourceUrl}/${marinaCode}/upload-svg`, formData);
  }

  svgFile(marina: string): Observable<SafeHtml> {
    return this.apiHttpClient
      .get(`${this.#env.staticUrl}${marina}.svg?t=${new Date().getTime()}`, {
        responseType: 'text',
      })
      .pipe(
        catchError(() => of('')),
        map((res) => this.#domSanitizer.bypassSecurityTrustHtml(res))
      );
  }

  getMarinasNearbyFacilities(marinaCode: string) {
    return this.apiHttpClient.get<MarinasNearbyFacilities>(`${this.resourceUrl}/${marinaCode}/nearby-facilities`);
  }
}
