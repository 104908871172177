import { IEnvironment } from './interfaces/environment.interface';

export const environmentDefault: IEnvironment = {
  production: false,
  stage: false,
  // apiUrl: 'http://172.20.1.147:3000',
  apiUrl: 'https://dmarin-api-dev.futuremind.dev',
  // apiUrl: 'https://dmarin-api-stage.futuremind.dev',
  // apiUrl: 'https://api.d-marinmarinas.com',
  embeddedMap: 'https://d-marin-portal-frontend.stage.futuremind.dev/',
  // embeddedMap: 'https://d-marin-portal-frontend.dev.futuremind.dev/embeded-map',
  cmsApiUrl: 'https://d-marin-portal-backend.stage.futuremind.dev/api/',
  //  cmsApiUrl: 'https://d-marin-portal-backend.dev.futuremind.dev/api/',
  crmUrl: 'https://dmaringroup-uat.crm4.dynamics.com/main.aspx',
  staticUrl: 'https://dmarin-mms-dev.futuremind.dev/static/',
  cmsKey:
    'c6c82cf5d56600e658bd3f4306f1d0ae8b639502d8f75b0341b92ad69a4116a68db784f50c245dc3872f8873d7f12f020701d4644e116be867e549f8a2ce9673de7d98bcf25c0ae6882725987fb671d50100724c30dfe7d8d330588b4426589f79f60abdfdd5c6bffa0716c6d69a4099e235a50fa727fa2da38b8c8180789332',
  sentry: 'https://cd3202bf1284f361fae23a530de28d3e@ingest.sentry.futuremind.network/24',
  tokenName: 'DM_T',
  mapApiKey: 'AIzaSyBSmH8lcImUyNvdI3QmYUWFefK0LVHxIEw',
  mapId: '3121dcaa9b8d04e0',
  openMobileAppUrl: 'https://dmarin.page.link/app',
  googleTagManager: {
    booking: 'GTM-5CXHR5P5',
    ncp: 'GTM-K8QF7Q9M',
  },

  recaptchaKey: '6Lco_EYqAAAAACkhMmJGR87la3pD1oYQuHMzsKMX',
  googlePay: {
    scriptUrL: 'https://pay.google.com/gp/p/js/pay.js',
  },
  appStores: {
    Google: {
      url: 'https://play.google.com/store/apps/details?id=',
      id: 'com.dmarin',
    },
    Apple: {
      url: 'https://apps.apple.com/us/app/d-marin-premium-marinas/id',
      id: '6446983110',
    },
  },
  adyen: {
    environment: 'test',
  },
  sense4boatUrl: 'https://sense4boat.com/tools/sensor-check.html?sensor_id=',
  resetPasswordUrl: 'https://dmarin-customer-dev.futuremind.dev/auth/password-lost',
  enableServiceWorker: true,
};
