import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dm-form-radio-button-visual',
  templateUrl: './radio-button-visual.component.html',
  styleUrls: ['./radio-button-visual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonVisualComponent {
  @Input() isActive = false;

  get classNames(): { [key: string]: boolean } {
    return {
      radio: true,
      active: this.isActive,
    };
  }
}
