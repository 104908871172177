import { animate, style, transition, trigger } from '@angular/animations';
import { CUBIC } from '../constants';

export const enterLeaveOpacityAnimation = trigger('enterLeaveOpacity', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(`.6s ${CUBIC}`),
  ]),
  transition(':leave', [
    animate(
      `.6s ${CUBIC}`,
      style({
        opacity: 0,
      })
    ),
  ]),
]);
