<google-map
  *ngIf="isLoadedMapScript$ | async"
  [center]="center"
  [height]="mapHeight"
  [options]="mapOptions"
  width="100%"
>
  <map-polygon *ngFor="let pier of piers$ | async" [options]="piersPolygonOptions" [paths]="pier | arrayToLatLng" />
  <map-polygon
    *ngFor="let berth of berths$ | async; trackBy: 'id' | trackByProperty"
    [options]="defaultBerthPolygonOptions"
    [paths]="berth.polygon | arrayToLatLng"
  />
  <map-polygon
    *ngFor="let berth of selectedBerths$ | async; trackBy: 'id' | trackByProperty"
    [options]="selectedBerthPolygonOptions"
    [paths]="berth.polygon | arrayToLatLng"
  />
  <map-info-window #infoWindow="mapInfoWindow" (positionChanged)="infoWindow.open()"
                   [position]="infoWindowPosition$ | async">
    {{ "BERTH" | translate }}: <strong>{{ selectedBerths$ | async | joinBerths}}</strong>
  </map-info-window>
</google-map>
