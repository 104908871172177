import { Inject, Injectable, Optional } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap, take } from 'rxjs';
import { HTTP_REQUESTS_URL, MARINA_URL_TOKEN, SELECTED_MARINA } from '@dm-workspace/types';
import { filter, tap } from 'rxjs/operators';
import { environment } from '@dm-workspace/environments';

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  private tmpCache: Record<string, HttpEvent<unknown>> = {};

  constructor(
    @Inject(HTTP_REQUESTS_URL) private httpRequestsUrl: string[] = [],
    @Optional() @Inject(SELECTED_MARINA) private selectedMarinaCodeSubject: BehaviorSubject<string>
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url.replace(environment.apiUrl, '');
    const hasPatters = this.httpRequestsUrl.some((patter) => url === patter || !!url.match(patter));
    if (!hasPatters) {
      return next.handle(request);
    }
    return this.urlObservableWithMarinaCode(url).pipe(
      switchMap((url) =>
        this.tmpCache[url]
          ? of(this.tmpCache[url])
          : next.handle(request).pipe(tap((value) => (this.tmpCache[url] = value)))
      )
    );
  }

  urlObservableWithMarinaCode(url: string): Observable<string> {
    if (url.includes(MARINA_URL_TOKEN) && this.selectedMarinaCodeSubject) {
      return this.selectedMarinaCodeSubject.pipe(
        filter((marinaCode) => marinaCode !== null),
        take(1),
        map((marinaCode) => url.replace(MARINA_URL_TOKEN, marinaCode))
      );
    }
    return of(url);
  }
}
