import { Customer } from './customer.interface';
import { IBoat } from './boats.interface';
import { IOrder } from './order.interface';
import { Contact } from './contact.interface';
import {
  BoatType,
  ContractSort,
  ContractStatus,
  ContractType,
  Currency,
  CustomerType,
  PaymentStatusEnum,
  ResourceType,
} from '../backend';

export interface IContractSingleSearchPayload {
  boatId?: string;
  customerId?: string;
  orderId?: string;
  berthId?: string;
  dateFrom?: string;
  dateTo?: string;
  contractSort?: ContractSort;
  sensorSetupStatus?: SensorSetupStatus[];
  postPaidSetupStatus?: PostPaidSetupStatus[];
}

export interface IContractSingle {
  id: string;
  startDate: string | null;
  endDate: string | null;
  marinaCode: string;
  name: string | null;
  boat: IContractBoat | null;
  contactFromCustomer: IContractContact | null;
  customer: IContractCustomer | null;
  contact: IContractContact | null;
  captain: IContractContact | null;
  boatOwner: IContractContact | null;
  orderProductId: string;
  order: IContractOrder;
  price: {
    currency: Currency;
    amount: number;
  };
  status: ContractStatus;
  isCancelled: boolean;
  type: ContractType;
  resource: IContractResource | null;
  availableActions: ContractAvailableActions[];
  postPaidSetupStatus: PostPaidSetupStatus;
  sensorSetupStatus: SensorSetupStatus;
}

export type ContractAvailableActions =
  | 'SETUP_SENSORS_ACCESS'
  | 'MAKE_TRANSIENT_CONTRACT_POSTPAID'
  | 'MARK_CONTRACT_AS_ELIGIBLE';

export interface IContract {
  endDate: string;
  id: string;
  startDate: string;
  status: ContractStatus;
  customer?: Customer;
  boat?: IBoat;
  orders: IOrder[];
  type: ContractType;
  owner: Contact;
  captain: Contact;
  contact: Contact;
  name?: string;
}

export interface IFetchContractsSearchParams {
  pier?: string;
  berth?: string;
  status?: ContractStatus;
  paymentStatus?: PaymentStatusEnum;
  customerName?: string;
  activeOnDate?: string;
}

export interface IContractBoat {
  name: string;
  id: string;
  type: BoatType;
  flag: string;
  registrationNumber: string;
  length: number;
  maxBeam: string;
}

export interface IContractCustomer {
  id: string;
  name: string;
  displayName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  countryCode: string | null;
  type: CustomerType;
  phone: string | null;
}

export interface IContractContact {
  id: string;
  type: CustomerType;
  fistName: string;
  email?: string;
  lastName: string;
  countryCode: string | null;
}

export interface IContractOrder {
  id: string;
  startDate: string;
  endDate: string;
  isDraft: boolean;
  humanReadableId: string | null;
  name: string;
  customer: IContractCustomer | null;
}

export interface IContractResource {
  id: string;
  berthIds: string[];
  name: string;
  disabled: boolean;
  type: ResourceType;
}

export interface IMakePostpaidContractPayload {
  pylonStartDate?: string;
  pylonOutputUsages?: IMakePostpaidPylonOutputUsage[];
}

export interface IMakePostpaidPylonOutputUsage {
  pedestalId: string;
  socketNumber: string;
}

export type TContractsViewType = 'postpaid' | 'sensors';

export interface IContractConnectedPylonResponse {
  connectedPylon: IContractConnectedPylonData[];
}

export interface IContractConnectedPylonData {
  pylonCode: string;
  socketNumber: string;
}

export enum PostPaidSetupStatus {
  SETUP_COMPLETED = 'SETUP_COMPLETED',
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
}

export enum SensorSetupStatus {
  CONNECTED = 'CONNECTED',
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
}
