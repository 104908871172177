import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api-client.service';
import {
  CountryCode,
  MARINA_URL_TOKEN,
  MmsPlannerTemplate,
  PaginatedNormal,
  PaginationQuery,
  PlannerTasksReportDto,
  PlannerTemplatesEditor,
  TEditPlannerTeamBody,
  TEditPlannerTemplateBody,
  TGetPlannerTasksParams,
  TGetPlannerTemplatesParams,
  TPlannerMarinaLocation,
  TPlannerTask,
  TPlannerTaskBoatCountingSingleProcessResult,
  TPlannerTaskBoatCountingSingleReport,
  TPlannerTeam,
  TPlannerTeamSingle,
  TPlannerTemplateSet,
  TPostPlannerMarinaLocation,
} from '@dm-workspace/types';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { FileUtil, FileUtilResponse, PartialBy } from '@dm-workspace/utils';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MmiPlannerApiService {
  private readonly resourceUrl = `/task-management`;

  private readonly templateSetsSubject = new BehaviorSubject<TPlannerTemplateSet[]>(null);
  public get templateSets$(): Observable<TPlannerTemplateSet[]> {
    if (this.templateSetsSubject.value) {
      return this.templateSetsSubject.asObservable();
    }

    return this.api.get<TPlannerTemplateSet[]>(`${this.resourceUrl}/task-template/template-set`).pipe(
      switchMap((res) => {
        this.templateSetsSubject.next(res);
        return this.templateSets$;
      })
    );
  }

  constructor(
    private api: ApiClientService,
    private httpClient: HttpClient
  ) {}

  public getTemplates(
    params: PaginationQuery & PartialBy<TGetPlannerTemplatesParams, 'marinaCode'>
  ): Observable<PaginatedNormal<MmsPlannerTemplate>> {
    params.marinaCode ??= MARINA_URL_TOKEN;
    return this.api.get(`${this.resourceUrl}/task-template`, { params });
  }

  public getTemplatesEditors(marinaCode: string = MARINA_URL_TOKEN): Observable<PlannerTemplatesEditor[]> {
    return this.api.get(`${this.resourceUrl}/task-template/${marinaCode}/editors`);
  }

  public getTemplateById(id: string): Observable<MmsPlannerTemplate> {
    return this.api.get(`${this.resourceUrl}/task-template/${id}`);
  }

  public createTemplate(body: TEditPlannerTemplateBody): Observable<MmsPlannerTemplate> {
    body.marinaCode ??= MARINA_URL_TOKEN;
    return this.api.post(`${this.resourceUrl}/task-template`, body);
  }

  public editTemplate(id: string, body: TEditPlannerTemplateBody): Observable<MmsPlannerTemplate> {
    body.marinaCode ??= MARINA_URL_TOKEN;
    return this.api.patch(`${this.resourceUrl}/task-template/${id}`, body);
  }

  public deleteTemplate(id: string): Observable<void> {
    return this.api.delete(`${this.resourceUrl}/task-template/${id}`);
  }

  public exportTemplates(marinaCode: string = MARINA_URL_TOKEN): Observable<FileUtilResponse> {
    const url = this.api.apiUrl(`${this.resourceUrl}/task-template/${marinaCode}/export-templates`);
    return FileUtil.fetchFile(this.httpClient, url);
  }

  public generateTasksFromTemplateId(templateId: string): Observable<MmsPlannerTemplate> {
    return this.api.post(`${this.resourceUrl}/task/from-template`, { templateId });
  }

  public toggleTaskStatus(id: string): Observable<MmsPlannerTemplate> {
    return this.api.patch(`${this.resourceUrl}/task-template/${id}/toggle-status`, null);
  }

  public getMarinaLocations(marinaCode: string = MARINA_URL_TOKEN): Observable<TPlannerMarinaLocation[]> {
    return this.api.get(`${this.resourceUrl}/marina/${marinaCode}/location`);
  }

  public createMarinaLocations(
    body: PartialBy<TPostPlannerMarinaLocation, 'marinaCode'>
  ): Observable<TPlannerMarinaLocation> {
    body.marinaCode ??= MARINA_URL_TOKEN;
    return this.api.post(`${this.resourceUrl}/marina/location`, body);
  }

  public getTasks(
    params: PaginationQuery & PartialBy<TGetPlannerTasksParams, 'marinaCode'>
  ): Observable<PaginatedNormal<TPlannerTask>> {
    params.marinaCode ??= MARINA_URL_TOKEN;
    return this.api.get(`${this.resourceUrl}/task`, { params });
  }

  public getTaskById(id: string): Observable<TPlannerTask> {
    return this.api.get(`${this.resourceUrl}/task/${id}`);
  }

  public exportTasks(dto: PlannerTasksReportDto): Observable<FileUtilResponse> {
    const { marinaCode = MARINA_URL_TOKEN as CountryCode, ...params } = dto;
    const url = this.api.apiUrl(`${this.resourceUrl}/task/${marinaCode}/export-tasks`);
    return FileUtil.fetchFile(this.httpClient, url, params);
  }

  public getBoatCountingReportByTaskId(id: string): Observable<TPlannerTaskBoatCountingSingleReport[]> {
    return this.api.get(`${this.resourceUrl}/task/${id}/boat-counting-report`);
  }

  public processBoatCountingByTaskId(id: string): Observable<TPlannerTask> {
    return this.api.get(`${this.resourceUrl}/boat-counting/${id}/process-boat-counting`);
  }

  public getBoatCountingProcessResultsByTaskId(id: string): Observable<TPlannerTaskBoatCountingSingleProcessResult[]> {
    return this.api.get(`${this.resourceUrl}/task/${id}/process-results`);
  }

  public setTaskAsAssign(id: string) {
    return this.api.patch(`${this.resourceUrl}/task/${id}/unassign`, null);
  }

  public setTaskAsDone(id: string) {
    return this.api.patch(`${this.resourceUrl}/task/${id}/mark-done`, null);
  }

  public resetTask(id: string) {
    return this.api.patch(`${this.resourceUrl}/task/${id}/restart`, null);
  }

  public deleteTask(id: string) {
    return this.api.delete(`${this.resourceUrl}/task/${id}`);
  }

  public getTaskGroups(marinaCode: string = MARINA_URL_TOKEN): Observable<TPlannerTeam[]> {
    return this.api.get(`${this.resourceUrl}/marina/${marinaCode}/task-groups`);
  }

  public getTaskGroupById(id: string): Observable<TPlannerTeamSingle> {
    return this.api.get(`${this.resourceUrl}/marina/task-group/${id}`);
  }

  public createTaskGroup(body: PartialBy<TEditPlannerTeamBody, 'marinaCode'>): Observable<TPlannerTeam> {
    body.marinaCode ??= MARINA_URL_TOKEN;
    return this.api.post(`${this.resourceUrl}/marina/task-group`, body);
  }

  public editTeam(id: string, body: Omit<TEditPlannerTeamBody, 'marinaCode'>): Observable<TPlannerTeam> {
    return this.api.patch(`${this.resourceUrl}/marina/task-group/${id}`, body);
  }

  public deleteTaskGroup(id: string): Observable<void> {
    return this.api.delete(`${this.resourceUrl}/marina/task-group/${id}`);
  }
}
