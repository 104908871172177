import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, first, map, Observable, tap } from 'rxjs';
import { ENV } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  private readonly env = inject(ENV);
  private readonly httpClient = inject(HttpClient);
  private readonly isLoadedSubject = new BehaviorSubject<boolean>(false);
  public readonly isLoaded$ = this.isLoadedSubject.asObservable();

  constructor() {
    this.loadScript().subscribe();
  }

  public loadScript(): Observable<boolean> {
    if (this.isLoadedSubject.value) {
      return this.isLoaded$.pipe(first());
    }

    return this.httpClient
      .jsonp<void>(`https://maps.googleapis.com/maps/api/js?key=${this.env.mapApiKey}`, 'callback')
      .pipe(
        tap(() => this.isLoadedSubject.next(true)),
        map(() => true)
      );
  }

  public createMapOptions(options: google.maps.MapOptions): google.maps.MapOptions {
    return {
      zoom: 4,
      streetViewControl: false,
      mapTypeControl: false,
      mapId: this.env.mapId,
      ...options,
    };
  }
}
