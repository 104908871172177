import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEnvironment } from '@dm-workspace/environments';
import { ENV } from '@dm-workspace/types';

/**
 * @deprecated Use ApiService instead
 */
@Injectable({
  providedIn: 'root',
})
export class ApiClientService {
  apiUrl(url: string): string {
    return `${this.env.apiUrl}${url}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEndpointToUrl<T extends [string, ...any[]]>(args: T): T {
    args[0] = this.apiUrl(args[0]);
    return args;
  }

  constructor(@Inject(ENV) private env: IEnvironment, public httpClient: HttpClient) {}

  public get<T>(...arg: Parameters<typeof HttpClient.prototype.get>): Observable<T> {
    return this.httpClient.get<T>(...this.addEndpointToUrl(arg));
  }

  public delete<T>(...arg: Parameters<typeof HttpClient.prototype.delete>): Observable<T> {
    return this.httpClient.delete<T>(...this.addEndpointToUrl(arg));
  }

  public post<T>(...arg: Parameters<typeof HttpClient.prototype.post>): Observable<T> {
    return this.httpClient.post<T>(...this.addEndpointToUrl(arg));
  }

  public patch<T>(...arg: Parameters<typeof HttpClient.prototype.patch>): Observable<T> {
    return this.httpClient.patch<T>(...this.addEndpointToUrl(arg));
  }

  public put<T>(...arg: Parameters<typeof HttpClient.prototype.put>): Observable<T> {
    return this.httpClient.put<T>(...this.addEndpointToUrl(arg));
  }
}
