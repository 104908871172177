import { IShortUser } from './users.interface';
import { ICustomerShort } from './customer.interface';
import { UserType } from '../backend';

export interface Ticket {
  messages: ITicketMessage[];
  ticket: ITicketDetails;
  seenBy: ITicketSeenBy[];
}

export interface ITicketMessage {
  id: string;
  data: {
    description: string;
  };
  sentAt: string;
  author: IShortUser;
  source: TicketSource;
}

export interface ITicketDetails {
  id: string;
  marinaCode: string;
  customer: ICustomerShort;
  humanReadableId: string;
  status: TicketStatus;
  type: TicketType;
  lastMessageId?: number | null;
  quoteId?: string | null;
}

export interface ITicketSeenBy {
  id: string;
  name: string;
  seenId: string;
  type: UserType;
}

export interface ICreateTicket {
  customerId: string;
  type: TicketType;
}

export interface ICreateTicketMessageParams {
  marinaCode: string;
  ticketId: string;
  messageId: string;
}

export interface ICreateTicketMessageBody {
  content: string;
}

export enum TicketStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum TicketSource {
  EMAIL = 'EMAIL',
  WEB = 'WEB',
  SYSTEM = 'SYSTEM',
}

export enum TicketType {
  OFFER = 'OFFER',
  BOOKING = 'BOOKING',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
}
