import { AbstractControl, ValidatorFn } from '@angular/forms';

export interface IMinSpacialCharactersValidator {
  actualCount: number;
  min: number;
}

export function minSpecialCharactersValidator(min = 1): ValidatorFn {
  return (
    control: AbstractControl
  ): {
    minSpacialCharacters: IMinSpacialCharactersValidator;
  } => {
    const value = control.value as string;

    const passwordPattern = /^(.?)*([\[!@#$%^&*()\/\\\-_=+{};:,<.>?\]])+(.?)*$/;
    const actualCount = (value ? value : '').match(passwordPattern)?.length | 0;

    return actualCount >= min
      ? null
      : {
          minSpacialCharacters: {
            actualCount,
            min,
          },
        };
  };
}
