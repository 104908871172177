<div class="modal-header" *ngIf="title">
  <h2 class="title">{{ title | translate }}</h2>
</div>

<div class="modal-body">
  <p [innerHTML]="body | translate"></p>

  <dm-ui-info-text [label]="'PYLON' | translate" [text]="pylonName" class="col-6 mt-l mb-s" />
  <dm-ui-info-text [label]="'SOCKET_ID' | translate" [text]="pylonOutput" class="col-6 mt-l mb-s" />

  <ng-container *ngIf="shouldUseBookingId">
    <hr class="visual-line" />

    <dm-form-group class="col-12 mt-m" [clear]="true">
      <label class="required">{{ "BOOKING_ID" | translate }}</label>
      <input
        type="text"
        [(ngModel)]="referenceId"
        dmForm
        [placeholder]="'BOOKING_ID' | translate"
        [required]="true"
        #refId="ngModel"
      />
      <dm-form-error-message [control]="refId.control" />
    </dm-form-group>
  </ng-container>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-tertiary btn-narrow" (click)="activeModal.close(null)">{{ cancelBtn | translate }}</button>
  <button type="button" class="btn btn-primary" (click)="onSubmit()">
    {{ confirmBtn | translate }}
  </button>
</div>
