import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MarinaMapColorStatus } from '@dm-workspace/types';
import { berthsColors } from '@dm-workspace/map-utils';

/**
 * @deprecated use <dm-ui-tag>
 */
@Component({
  selector: 'dm-ui-status-dot',
  templateUrl: './status-dot.component.html',
  styleUrls: ['./status-dot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusDotComponent {
  @HostBinding('style.backgroundColor') get color() {
    return berthsColors[this.status] ? berthsColors[this.status].fillColor : '';
  }

  @Input() status!: MarinaMapColorStatus;
  @Input() size: 'm' | 'l' = 'm';
  @Input() statusLabel: string;

  @HostBinding('class') get classNames(): string[] {
    return [`size-${this.size}`, `status-${this.status}`];
  }
  @HostBinding('class.display-label') get labelClass(): string {
    return this.statusLabel;
  }
}
