import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

import { BookingResource, IFindBertForm } from '@dm-workspace/types';
import { FORMS_KEYS, ObFormStoreService } from '../../../../../booking-steps/src/lib/services/ob-form-store.service';

@Component({
  selector: 'dm-ob-berths-list',
  templateUrl: './ob-berths-list.component.html',
  styleUrls: ['./ob-berths-list.component.scss'],
})
export class ObBerthsListComponent {
  @Input() public berthList: BookingResource[] | null = null;
  @Input() public selectedBerth?: string;
  @Input() public marinaCode: string;
  @Input() formCtrl!: UntypedFormControl | null;

  @Output() berthSelect = new EventEmitter();

  private readonly MOBILE_SIZE = 750;

  public isMobile$: Observable<boolean> = fromEvent(window, 'resize').pipe(
    startWith(null),
    debounceTime(100),
    distinctUntilChanged(),
    map(() => window.innerWidth < this.MOBILE_SIZE),
    shareReplay()
  );
  public findBerthFormValues: IFindBertForm = this.formStore.getFormValues(FORMS_KEYS.findBerthForm);

  constructor(private formStore: ObFormStoreService) {}

  onBerthClick(berth: BookingResource) {
    this.berthSelect.emit(berth);
  }
}
