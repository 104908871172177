<h3 class="title mb-4 text-center">{{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.HEADER" | translate | uppercase }}</h3>
<div class="text-center mb-4">
  <span class="text-body-m text-body-md-l fw-400">{{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.SUBTITLE" | translate }}</span>
</div>

<form *ngIf="form" class="text-left" [formGroup]="form" (ngSubmit)="onSubmit()">
  <dm-form-group class="mb-4">
    <label>{{ "E_MAIL" | translate }}</label>
    <input dmForm type="text" formControlName="email" [placeholder]="'ENTER_E_MAIL' | translate" />
    <dm-form-error-message [control]="form.get('email')" />
  </dm-form-group>

  <button class="btn btn-fluid btn-primary btn-full" type="submit" [disabled]="pending">
    <dm-ui-spinner class="me-2" [showSpinner]="pending" />
    <span class="text-body-s text-body-md-m fw-700">
      {{ "NCP_AUTH_MODULE.FORGOT_PASSWORD.SEND_LINK" | translate }}</span
    >
  </button>
</form>
