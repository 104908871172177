import { inject, Injectable } from '@angular/core';
import { ApiHttpClient, ApiService } from '../api/api-http-client';
import {
  MARINA_URL_TOKEN,
  GetWalletBalancesDto,
  WalletTopUpHistoryResponseDto,
  CustomerWalletTopUpDto,
  WalletChangeThresholdsRequestDto,
} from '@dm-workspace/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MmsWalletApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/mms/customers`);

  public getClientWallet(customerId: string): Observable<GetWalletBalancesDto> {
    return this.apiHttpClient.get<GetWalletBalancesDto>(`${this.resourceUrl}/${customerId}/wallet/${MARINA_URL_TOKEN}`);
  }

  public getClientWalletHistory(customerId: string): Observable<WalletTopUpHistoryResponseDto> {
    return this.apiHttpClient.get<WalletTopUpHistoryResponseDto>(
      `${this.resourceUrl}/${customerId}/wallet/${MARINA_URL_TOKEN}/top-up-history`
    );
  }
  public topUpWallet(customerId: string, requestBody: CustomerWalletTopUpDto): Observable<void> {
    return this.apiHttpClient.post<void>(
      `${this.resourceUrl}/${customerId}/wallet/${MARINA_URL_TOKEN}/top-up-history`,
      requestBody
    );
  }
  public walletThresholds(
    customerId: string,
    balanceAlertThreshold: WalletChangeThresholdsRequestDto
  ): Observable<void> {
    return this.apiHttpClient.post<void>(
      `${this.resourceUrl}/${customerId}/wallet/${MARINA_URL_TOKEN}/thresholds`,
      balanceAlertThreshold
    );
  }
}
