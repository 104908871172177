import { animate, animateChild, group, keyframes, query, style, transition, trigger } from '@angular/animations';
import { CUBIC } from '../constants';

export const customerPortalRouteAnimation = trigger('customerPortalRouteAnimation', [
  transition('* => onboarding', []),
  transition('* => auth', []),
  transition('* <=> *', [
    group([
      query(
        '.router-wrapper > :enter, .router-wrapper > :leave',
        [
          style({
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
          }),
        ],
        { optional: true }
      ),
    ]),
    group([
      query('@blockAnimation, @selfBlockAnimation', [animateChild()], { optional: true }),
      query(
        '.router-wrapper > :enter',
        [
          animate(
            `.45s ${CUBIC}`,
            keyframes([
              style({ transform: 'translateX(-15%) scale(.7)', opacity: 0, offset: 0 }),
              style({ transform: 'translateX(0px) scale(1)', opacity: 1, offset: 1 }),
            ])
          ),
        ],
        { optional: true, limit: 1 }
      ),
      query(
        '.router-wrapper > :leave',
        [
          animate(
            `.45s ${CUBIC}`,
            keyframes([
              style({ opacity: 1, transform: 'translateX(0px) scale(1)', offset: 0 }),
              style({ opacity: 0, transform: 'translateX(15%) scale(.7)', offset: 1 }),
            ])
          ),
        ],
        { optional: true, limit: 1 }
      ),
    ]),
  ]),
]);
