import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'dm-ui-boat-flag',
  templateUrl: './boat-flag.component.html',
  styleUrl: './boat-flag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatFlagComponent {
  rectangle = input<boolean>(false);
  flag = input.required<string>();
}
