import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { IRoleShort } from '@dm-workspace/types';
import { RolesApiService } from '@dm-workspace/data-access';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private rolesSubject = new BehaviorSubject<IRoleShort[] | null>([]);

  constructor(private rolesApiService: RolesApiService) {
    this.initService();
  }

  initService(): void {
    this.rolesApiService.getRoles$
      .pipe(
        take(1),
        tap((roles) => {
          this.rolesSubject.next(roles);
        })
      )
      .subscribe();
  }

  get roles$(): Observable<IRoleShort[]> {
    return this.rolesSubject.asObservable().pipe();
  }
}
