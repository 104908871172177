import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type FileUtilResponse = {
  fileName: string;
  blob: Blob;
};

export class FileUtil {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fetchFile(httpClient: HttpClient, url: string, params?: any): Observable<FileUtilResponse> {
    return httpClient.get(url, { observe: 'response', responseType: 'blob', params }).pipe(
      map((response: HttpResponse<Blob>) => {
        const type = response.headers.get('content-type');
        const [, fileName] = response.headers.getAll('content-disposition') || [];
        const blob = new Blob([response.body], { type });
        return { fileName, blob };
      })
    );
  }

  static saveBlob(blob: Blob, name: string): void {
    const localUrl = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = localUrl;
    downloadLink.setAttribute('download', name);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    window.URL.revokeObjectURL(localUrl);
    downloadLink.remove();
  }
}
