import { inject, Injectable } from '@angular/core';
import {
  IBoatAlertByBoatParams,
  IBoatAlertResponseDto,
  IBoatAlertSensorParams,
  IRequestParamsWithPagination,
  MarkAlertPayload,
  PaginatedNormal,
} from '@dm-workspace/types';
import { ApiHttpClient, ApiService } from '../api/api-http-client';

@Injectable({
  providedIn: 'root',
})
export class S4bBoatAlertApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl('/s4b/boat-alert');

  public getSensorAlert(sensorId: string, params?: IRequestParamsWithPagination<IBoatAlertSensorParams>) {
    return this.apiHttpClient.get<PaginatedNormal<IBoatAlertResponseDto>>(`${this.resourceUrl}/${sensorId}`, {
      params,
    });
  }

  public getActiveAlertsForBoat(boatId: string) {
    return this.apiHttpClient.get<IBoatAlertResponseDto[]>(`${this.resourceUrl}/active-alerts-for-boat/${boatId}`);
  }

  public markAlert(s4bAlertId: number, options: MarkAlertPayload) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/${s4bAlertId}/process`, options);
  }

  public getAlertsForBoat(boatId: string, params?: IRequestParamsWithPagination<IBoatAlertByBoatParams>) {
    return this.apiHttpClient.get<PaginatedNormal<IBoatAlertResponseDto>>(`${this.resourceUrl}/by-boat/${boatId}`, {
      params,
    });
  }
}
