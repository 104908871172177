import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  SimpleChange,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl, NgModel, Validators } from '@angular/forms';
import { Subject, Subscription, take } from 'rxjs';
import { NgxMaskDirective } from 'ngx-mask';
import { FormErrorMessageComponent } from '../form-error-messages/form-error-message.component';
import { changeFormUpdateStrategy } from '@dm-workspace/utils';
import { FormDateTimePickerComponent } from '../form-date-time-picker/form-date-time-picker.component';

@Directive({
  // TODO: change to dmFormInput or dmInput
  selector: `[dmForm]`,
})
export class FormControlDirective {
  public destroy$ = new Subject();

  @HostBinding()
  @Input()
  placeholder = '';
  public type = 'text';
  private disabledT = false;
  private requiredT = false;
  private neverEmptyArray = ['date', 'datetime', 'datetime-local', 'month', 'time', 'week'];
  focused = false;
  @HostBinding('attr.aria-describedby') 'ariaDescribedby': string;
  @HostBinding('attr.inputmode') inputmode: string = null;

  @HostBinding()
  @Input()
  get disabled(): boolean | null {
    return this.ngControl ? this.ngControl.disabled : this.disabledT;
  }

  set disabled(value: boolean | null) {
    this.disabledT = !!value;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('type')
  public set setType(inputTypeValue: string) {
    const nodeName = this.getElementNodeName();
    if (nodeName !== 'input') {
      return;
    }

    switch (inputTypeValue) {
      case 'phone': {
        this.setAsPhone();
        break;
      }
      default: {
        this.type = inputTypeValue || 'text';
        break;
      }
    }

    this.renderer.setAttribute(this.elementRef.nativeElement, 'type', this.type);
  }

  private getElementNodeName(): string {
    return this.elementRef?.nativeElement?.nodeName?.toLowerCase();
  }

  @HostBinding()
  @Input()
  get required(): boolean {
    return this.requiredT || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  set required(value: unknown) {
    this.requiredT = !!value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this.elementRef.nativeElement.value;
  }

  @HostBinding()
  get checked(): boolean {
    return this.elementRef.nativeElement.checked;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(value: any) {
    this.elementRef.nativeElement.value = value;
  }

  get empty(): boolean {
    return (
      !(this.neverEmptyArray.indexOf(this.type) !== -1) &&
      (this.value == null || this.value === '') &&
      !(this.elementRef.nativeElement as HTMLInputElement).validity.badInput
    );
  }

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
    @Optional() public ngControl: NgControl,
    @Optional() public ngModel: NgModel,
    @Host() @Optional() public maskDir: NgxMaskDirective
  ) {}

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  blur(): void {
    this.elementRef.nativeElement.blur();
  }

  @HostListener('focus')
  onFocus(): void {
    this.focused = true;
  }

  @HostListener('blur')
  public onBlur(): void {
    if (this.type === 'text') {
      this.trimStringValue();
    }

    this.focused = false;
  }

  private setAsPhone() {
    this.type = 'text';

    this.placeholder = '+00 000000000';
    this.inputmode = 'tel';

    this.maskDir?.ngOnChanges({
      maskExpression: new SimpleChange('', '+0000000000000000', true),
      validation: new SimpleChange(null, false, true),
      dropSpecialCharacters: new SimpleChange(null, false, true),
    });
  }

  private trimStringValue() {
    if (!this.value) {
      return;
    }

    const trimmedValue = this.value.trim();

    if (this.value === trimmedValue) {
      return;
    }

    this.ngControl.control.setValue(this.value.trim());
  }
}

@Component({
  selector: 'dm-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormGroupComponent implements AfterContentInit, OnDestroy {
  private subscription = new Subscription();

  @HostBinding('class.ng-clearable')
  @Input()
  clear = false;

  /**
   * @deprecated use FormInputIconComponent
   */
  @Input() iconName: string;
  @ContentChild(FormControlDirective, { static: false }) inputChild?: FormControlDirective;
  @ContentChild(FormDateTimePickerComponent, { static: false }) dateTimePickerChild?: FormDateTimePickerComponent;
  @ContentChild(FormErrorMessageComponent, { static: false }) error?: FormErrorMessageComponent;

  @HostBinding('class') typeClass?: string;
  @HostBinding('class.form-group') className = true;
  @HostBinding('class.ng-focused') 'inputChild.focused': string;
  @HostBinding('class.ng-disabled') 'inputChild.disabled': string;
  @HostBinding('class.ng-untouched') 'checkClass("untouched")': string;
  @HostBinding('class.ng-hasValue') 'checkClass("value")': string;
  @HostBinding('class.ng-overflowSpinnerState') 'checkClass("pending")': string;
  @HostBinding('class.ng-hasPlaceholder')
  'checkFormDirective("placeholder")': string;
  @HostBinding('class.ng-required')
  get required() {
    return this.checkFormDirective('required');
  }
  @HostBinding('class.ng-disabled')
  get disabled() {
    return this.checkFormDirective('disabled');
  }
  @HostBinding('class.has-date-time-picker')
  get hasDateTimePicker() {
    return !!this.dateTimePickerChild;
  }
  @HostBinding('class.ng-invalid') 'checkClass("invalid")': string;
  @HostBinding('class.ng-touched') 'checkClass("touched")': string;
  @HostBinding('class.ng-pristine') 'checkClass("pristine")': string;
  @HostBinding('class.ng-dirty') 'checkClass("dirty")': string;
  @HostBinding('class.ng-valid') 'checkClass("valid")': string;

  @HostListener('click')
  focusInput(): void {
    if (this.inputChild) {
      this.inputChild.focus();
    }
  }

  constructor(private cd: ChangeDetectorRef) {}

  onClear($event: MouseEvent): void {
    const control = this.inputChild ? this.inputChild.ngControl : null;
    if (control && this.clear) {
      $event.stopPropagation();
      $event.preventDefault();
      control.valueAccessor?.writeValue(null);
      control.reset(null);
      this.inputChild?.blur();
      this.cd.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkFormDirective(prop: string): any {
    const control = this.inputChild ? this.inputChild : null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return control && (control as any)[prop];
  }

  public get hasValue(): boolean {
    return ![undefined, null, ''].includes(this.inputChild?.ngModel?.value);
  }

  public ngAfterContentInit(): void {
    let className = '';

    this.subscription.add(
      this.error?.errorAppear.pipe(take(1)).subscribe(() => {
        this.onFirstErrorAppear();
      })
    );

    if (this.inputChild) {
      className += `ng-type-${this.inputChild.type}`;
      if (this.inputChild && this.inputChild.ngControl && this.inputChild.ngControl.valueChanges) {
        this.subscription.add(
          this.inputChild.ngControl.valueChanges.subscribe(() => {
            this.cd.detectChanges();
          })
        );
      }
    }
    this.typeClass = className;
  }

  public onFirstErrorAppear() {
    if (!this.inputChild?.ngControl?.control) {
      return;
    }

    changeFormUpdateStrategy(this.inputChild.ngControl.control, 'change');
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
