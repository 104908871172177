import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map, merge, Subscription, throttleTime } from 'rxjs';
import { StickyElementService } from './sticky-element.service';

@Directive({
  selector: '[dmUiStickyElementOffset]',
})
export class StickyElementTargetDirective implements OnInit, OnDestroy {
  @Input() dmUiStickyElementOffset: string;
  @Input() stickyTop?: number;

  private subscription: Subscription;

  constructor(
    private el: ElementRef,
    private stickyService: StickyElementService
  ) {}

  ngOnInit() {
    this.watchWindowEvents();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get top(): number {
    if (this.stickyTop != null) {
      return this.stickyTop;
    }

    return this.el?.nativeElement.getBoundingClientRect().top + window.scrollY;
  }

  setOffset(): void {
    this.stickyService.setTargets(this.dmUiStickyElementOffset, this.top);
  }

  private watchWindowEvents() {
    this.subscription = merge(
      fromEvent(window, 'resize').pipe(debounceTime(250), distinctUntilChanged()),
      fromEvent(window, 'scroll').pipe(throttleTime(250)),
      this.stickyService.refreshOffsets$.pipe(debounceTime(350))
    )
      .pipe(map(() => this.top))
      .subscribe({
        next: () => this.setOffset(),
      });
  }
}
