export enum CrmReservationStatus {
  ARRIVED = 'Arrived',
  BLOCKED = 'Blocked',
  CANCELED = 'Canceled',
  COMMITTED = 'Committed',
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
  NO_SHOW = 'No Show',
  ON_CRUISE = 'On cruise',
  RESERVED = 'Reserved',
  TEMPORARY_ASSIGMENT = 'Temporary assignment',
}
