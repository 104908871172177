import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Host,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { filter, tap } from 'rxjs';
import { StateSwitchItem } from './state-switch-item.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[routerLink][dmUiStateSwitchItemLink]',
  template: '<ng-content/>',
  hostDirectives: [RouterLinkActive],
  providers: [{ provide: StateSwitchItem, useExisting: StateSwitchItemLinkComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateSwitchItemLinkComponent extends StateSwitchItem<string> implements OnInit {
  #routerLinkActive = inject(RouterLinkActive);
  #destroyRef = inject(DestroyRef);

  public active = false;
  @Output() stateChange = new EventEmitter<string>();

  constructor(@Host() public routerLink: RouterLink) {
    super();
  }

  public ngOnInit() {
    this.#routerLinkActive.isActiveChange
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap((v) => (this.active = v)),
        filter(Boolean),
        tap(() => this.stateChange.emit(this.routerLink.href))
      )
      .subscribe();
  }
}
