import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class HttpUnauthorizedInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const authService = this.injector.get(AuthService);
        if (err.status === 401 && (!err.url?.includes('refresh-token') || !err.url?.includes('login'))) {
          return authService.refreshToken().pipe(
            switchMap((res) =>
              next.handle(
                request.clone({
                  setHeaders: {
                    authorization: `Bearer ${res.access_token}`,
                  },
                })
              )
            )
          );
        }
        if (err.status === HttpStatusCode.Unauthorized) {
          authService.logout();
        }
        return throwError(() => err);
      })
    );
  }
}
