import { Injectable } from '@angular/core';
import {
  BerthAvailabilityDto,
  BerthAvailabilityType,
  BerthMapResponseDto,
  IBerthBase,
  MARINA_URL_TOKEN,
} from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { ApiClientService } from '@dm-workspace/data-access';

@Injectable()
export class BerthsMapApiService {
  constructor(private apiClientService: ApiClientService) {}

  public fetchMmsBerths(hide: BerthAvailabilityType[] = []): Observable<BerthMapResponseDto[]> {
    return this.apiClientService.get<BerthMapResponseDto[]>(`/mms/marinas/${MARINA_URL_TOKEN}/berths/statuses/new`, {
      params: {
        'hide[]': hide,
      },
    });
  }
  public fetchMmsBerths2(hide: BerthAvailabilityType[] = []): Observable<BerthAvailabilityDto[]> {
    return this.apiClientService.get<BerthAvailabilityDto[]>(`/mms/marinas/${MARINA_URL_TOKEN}/berths/statuses`, {
      params: {
        'hide[]': hide,
      },
    });
  }
  public fetchBookingBerths(marinaCode: string): Observable<IBerthBase[]> {
    return this.apiClientService.get<IBerthBase[]>(`/online-booking/${marinaCode}/berths/all`, {
      params: {
        includeUnavailableOnline: true,
      },
    });
  }
}
