import { Pipe, PipeTransform } from '@angular/core';
import { PylonMediaType } from '@dm-workspace/types';
import { iconFromPylonMediaType } from '@dm-workspace/utils';

@Pipe({
  name: 'iconFromPylonMediaType',
})
export class IconFromPylonMediaTypePipe implements PipeTransform {
  transform(mediaType: PylonMediaType): string {
    return iconFromPylonMediaType(mediaType);
  }
}
