import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const minutes = 1000 * 60;
const hours = minutes * 60;
const days = hours * 24;

@Component({
  selector: 'dm-ui-countdown-date',
  templateUrl: './countdown-date.component.html',
  styleUrls: ['./countdown-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownDateComponent {
  @Input() public timeToCount: number;
  @Input() public nowDate = new Date();

  public get timeLeft(): number {
    const nowTime = this.nowDate.getTime();
    const remaining = this.timeToCount - nowTime;
    return remaining >= 0 ? remaining : 0;
  }

  public get days(): number {
    return Math.floor(this.timeLeft / days);
  }

  public get hours(): number {
    return Math.ceil((this.timeLeft % days) / hours);
  }
}
