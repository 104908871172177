<label dmForm class="container" [class.is-disabled]="disabled">
  <input
    class="input"
    [disabled]="disabled"
    type="checkbox"
    [ngModel]="model"
    (ngModelChange)="onModelChange($event)"
  />

  <span class="checkbox">
    <i class="checkbox__icon icon i-check"></i>
  </span>

  <p class="label">
    {{ label | translate }}
    <ng-content select="[label]" />
  </p>

  <div class="body" *ngIf="isContentInside">
    <ng-content />
  </div>
</label>

<div class="body" *ngIf="!isContentInside">
  <div class="body-outside"><ng-content /></div>
</div>
