import {
  BerthAvailabilityType,
  BerthOccupancyType,
  BerthStatus,
  BoatMaterial,
  BoatMovementOperation,
  BoatMovementOperationForStatus,
  BoatType,
  ContractType,
  CrmReservationStatus,
  PaginatedNormal,
  PowerSupplyVoltage,
  QuotationStatus,
} from '../backend';
import {
  IBoat,
  IBoatAlertResponseDto,
  IBoatAlertResponseDtoSeverityEnum,
  IBoatAlertResponseDtoStatusEnum,
  IBoatAlertResponseDtoTypeEnum,
} from './boats.interface';
import { IContract } from './contract.interface';
import { Note } from './note.interface';
import { IPier, IPierShort } from './pier.interface';
import { MmsDashboardStatName } from './mms-dashboard.interface';

export interface IBerthBase {
  id: string;
  name: string;
  polygon: [number, number][];
}

export interface MultihullPolygon {
  poly: [number, number][];
  color: MarinaMapColorStatus;
  resourceId?: string;
}

export interface IBerth {
  id: string;
  name: string;
  length: number;
  width: number;
  depth: number | null;
  latitude: string | null;
  longitude: string | null;
  polygon: [number, number][];
  boatMaterial: BoatMaterial[] | null;
  boatType: BoatType[] | null;
  wifi: boolean;
  potableWater: boolean;
  waterSupply: boolean;
  powerSupply: boolean;
  powerSupplyAmperage: number | null;
  powerSupplyFrequency: number | null;
  powerSupplyVoltage: PowerSupplyVoltage[] | null;
  leftBlock: number | null;
  rightBlock: number | null;
  leftChain: number | null;
  rightChain: number | null;
  leftRope: number | null;
  rightRope: number | null;
  leftString: number | null;
  rightString: number | null;
  nextId: string;
  pier: IPier;
  status: BerthStatus;
  minAllowedBoatLength?: number;
  maxAllowedBoatLength?: number;
  minAllowedBoatWidth?: number;
  maxAllowedBoatWidth?: number;
}

export interface IBerthShort extends Pick<IBerth, 'name' | 'length' | 'width' | 'depth' | 'id' | 'polygon' | 'status'> {
  pier: IPierShort;
  note: string;
}

export interface IBerthDto extends IBerthShort {
  type: 'berth' | 'land';
  nextId: string;
  minAllowedBoatLength: number;
  maxAllowedBoatLength: number;
  minAllowedBoatWidth: number;
  maxAllowedBoatWidth: number;
}

export interface IFetchResourceOccupancySearchParams {
  startDate: string;
  endDate: string;
  activeOnDate?: string;
  minimalBerthLength?: number;
  minimalBerthWidth?: number;
  minimalBerthDepth?: number;
  pier?: string;
  contractType?: ContractType;
  hasReservations?: boolean;
  orderBy?: 'END_DATE' | 'BERTH_NAME';
  berthNameFragment?: string;
  boatNameFragment?: string;
  boatRegistrationNumberFragment?: string;
}

export interface BerthAvailabilityReservationDto {
  reservationId: string;
  humanReadableId: string;
  boatId: string;
  toDate: string;
  fromDate: string;
  resourceId: string;
  contractType: ContractType | null;
  boat: IBerthAvailabilityBoat;
}

export interface BerthAvailabilityOccupationDto {
  boatId: string;
  resourceId: string;
  boat?: IBerthAvailabilityBoat;
}

export interface IBerthAvailabilityBoat {
  name: string;
  id: string;
}

export interface IBerthBoatAlert extends Omit<IBoatAlertResponseDto, 'actionLogs'> {
  marinaCode: string;
  actionLogs: IBerthBoatAlertActionLog[];
}

export interface IBerthBoatAlertExtended extends IBerthBoatAlert {
  boatId: string;
  boatName: string;
  berthName: string;
  berthId: string;
  berthPolygon: [number, number][];
}

export interface IBerthBoatAlertActionLog {
  boatAlertId: string;
  boatAlert: unknown;
  userId: string;
  note: string;
  boatAlertStatus: IBoatAlertResponseDtoStatusEnum;
  actorContext: 0 | 1;
}
export enum BerthReservationsStatusInMarina {
  unallowedOverbooking = 'UNALLOWED_OVERBOOKING',
  reservedLongTerm = 'RESERVED_LONG_TERM',
  reservedMidTerm = 'RESERVED_MID_TERM',
  allowedOverbooking = 'ALLOWED_OVERBOOKING',
  reservedTransient = 'RESERVED_TRANSIENT',
  temporaryAvailableCruise = 'TEMPORARY_AVAILABLE_CRUISE',
  temporaryAvailableDryDock = 'TEMPORARY_AVAILABLE_DRY_DOCK',
  available = 'AVAILABLE',
}
export enum BerthPylonsStatusInMarina {
  connectedPrepaid = 'CONNECTED_PREPAID',
  connectedPostpaid = 'CONNECTED_POSTPAID',
  notConnected = 'NOT_CONNECTED',
}
export enum BerthOccupancyStatusInMarina {
  overOccupied = 'OVER_OCCUPIED',
  occupiedWithoutBooking = 'OCCUPIED_WITHOUT_BOOKING',
  occupiedWithBooking = 'OCCUPIED_WITH_BOOKING',
  expectedArrivals = 'EXPECTED_ARRIVALS',
  expectedDepartures = 'EXPECTED_DEPARTURES',
  temporaryAbsenceCruise = 'TEMPORARY_ABSENCE_CRUISE',
  temporaryAbsenceDryDock = 'TEMPORARY_ABSENCE_DRY_DOCK',
  empty = 'EMPTY',
}
export enum BerthBoatAlertStatus {
  noSubscription = 'NO_SUBSCRIPTION',
  eligible = 'ELIGIBLE',
  noAlert = 'NO_ALERT',
  activeAlert = 'ACTIVE_ALERT',
  empty = 'EMPTY',
}

export type TooltipBoatInfo = {
  name?: string;
  id?: string;
  type?: BerthBoatAlertStatus | BerthPylonsStatusInMarina;
};

export enum BerthMaintenanceStatusInMarina {
  disabled = 'DISABLED',
}
export type BerthStatusInMarina =
  | BerthReservationsStatusInMarina
  | BerthOccupancyStatusInMarina
  | BerthMaintenanceStatusInMarina
  | BerthBoatAlertStatus
  | BerthPylonsStatusInMarina;

export enum MarinaMapColorStatus {
  yellow = 'yellow',
  red = 'red',
  green = 'green',
  darkGreen = 'darkGreen',
  purple = 'purple',
  lightBlue = 'lightBlue',
  lightGreen = 'lightGreen',
  gray500 = 'gray500',
  orange = 'orange',
  darkOrange = 'darkOrange',
  blue = 'blue',
  gray = 'gray',
  lightGray = 'lightGray',
  darkGray = 'darkGray',
  black = 'black',
  darkBlue = 'darkBlue',
  white = 'white',
}

export enum BerthListStatus {
  'free' = 'free',
  'occupied' = 'occupied',
  'disabled' = 'disabled',
}

export enum BookingBerthStatus {
  'free' = 'free',
  'selected' = 'selected',
  'disabled' = 'disabled',
}

export interface BerthAvailabilityDto extends Omit<IBerthBase, 'name' | 'id'> {
  status: BerthStatus;
  pierName: string;
  berthId: string;
  berthName: string;
  isInMaintenance: boolean;
  reservation: BerthAvailabilityReservationDto[] | null;
  occupation: BerthAvailabilityOccupationDto[] | null;
  berthDimensions?: BerthDimensions;
  alertStatus?: IBoatAlertResponseDtoStatusEnum;
  dashboardStat?: MmsDashboardStatName;
}
export interface BoatMapResponseDto {
  id: string;
  name: string;
}
export interface AlertMapResponseDto {
  boatId: string;
  alertId: string;
  severity: IBoatAlertResponseDtoSeverityEnum;
  status: IBoatAlertResponseDtoStatusEnum;
  type: IBoatAlertResponseDtoTypeEnum;
  boat: BoatMapResponseDto;
  createdDate: string;
  devEui: string;
}

export interface BoatAbsenceMapResponseDto {
  operation: BoatMovementOperation;
  boatId: string;
  resourceId: string;
  expectedReturnDate: string;
  allowBerthReuse: boolean;
  movementId: string;
  gapStart: string;
  gapEnd: string;
  boat?: BoatMapResponseDto;
  movementOperationDate: string;
}
export interface BerthMapResponseDto {
  polygon: [number, number][];
  berthName: string;
  pierName: string;
  berthId: string;
  pierId: string;
  isInMaintenance: boolean;
  status: BerthStatus;
  note: string;
  bookings: BerthAvailabilityReservationDto[];
  occupancy: BerthAvailabilityOccupationDto[];
  alerts: AlertMapResponseDto[];
  expectedArrivals: BerthAvailabilityReservationDto[];
  expectedDepartures: BerthAvailabilityReservationDto[];
  temporaryAbsence: BoatAbsenceMapResponseDto[];
  sensorSubscriptions: S4bSubscriptionMapResponseDtoS4bSubscriptionMapResponseDto[];
  eligibleContracts: EligibleContracts[];
}
export interface EligibleContracts {
  id: string;
  name: string;
}
export interface S4bSubscriptionMapResponseDtoS4bSubscriptionMapResponseDto {
  subscriptionId: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  boat: {
    id: string;
    name: string;
  };
}
export interface BerthOccupancyDetails extends BerthAvailabilityOccupationDto {
  temporaryAbsence?: BoatAbsenceMapResponseDto;
  booking?: BerthAvailabilityReservationDto;
  expectedArrivals: boolean;
  expectedDepartures: boolean;
  boatIsInBerth: boolean;
}
export interface BerthDimensions {
  length: number;
  width: number;
  depth: number;
  minAllowedBoatLength: number;
  maxAllowedBoatLength: number;
  minAllowedBoatWidth: number;
  maxAllowedBoatWidth: number;
}

export interface IBerthOccupancy {
  berth: IBerthShort;
  bookings: IBookingCalendar[];
}

export interface IBerthOccupancyWithIndex extends Omit<IBerthOccupancy, 'bookings'> {
  bookings: IBookingCalendarWithIndex[];
  rowCount?: number;
}

export type GetBerthSearchCalendarDto = {
  marinaCode: string;
  pier?: string;
  berth?: string;
  fromDate: string;
  toDate: string;
  boatType?: BoatType;
  berthStatus?: BerthStatus;
  humanReadableId?: string;
};

export interface IBookingCalendar {
  boatId: string;
  boatStatusInMarina: BoatMovementOperationForStatus;
  boatName: string;
  dateFrom: string;
  dateTo: string;
  humanReadableId: string;
  id: string;
  movements: IMovement[];
  contractType: ContractType;
}

export interface IBookingCalendarWithIndex extends IBookingCalendar {
  calendarTopIndex?: number;
  overlapping?: boolean;
}

export interface IMovement {
  status: BoatMovementOperationForStatus | null;
  dateFrom: string;
  dateTo: string | null;
  declaredReturnDate: string | null;
}

export interface ICalendarBookingMovement {
  dateFrom: string;
  dateTo: string;
  status: BoatMovementOperationForStatus;
}

export interface ISearchBerthForBoatBody {
  fromDate: string;
  toDate: string;
}

export interface DatesOfInterval {
  daysOfInterval: DaysOfInterval[];
  datesOfInterval?: number[];
}

export interface DaysOfInterval {
  date: Date;
  isFirstDayOfMonth: boolean;
}

export interface ISearchBerthForBoatResponse {
  berths: IBerthIndividualStatus[];
  propositions: IBerthProposition[];
}

export interface IBerthIndividualStatus {
  berth: IBerth;
  occupations: IBerthOccupationDto[];
}

export interface IBerthOccupationDto {
  type: BerthAvailabilityType;
  fromDate: string;
  toDate: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reservationDetails: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  occupationDetails: any;
}

export interface IBerthProposition {
  berthIds: string[];
  totalWidth: number;
  length: number;
  depth: number;
}

export interface IParsedBerthProposition {
  berthStatuses: IBerthIndividualStatus[];
  length: number;
  width: number;
}

export type IBerthsSearchResponse = PaginatedNormal<IBerthsSearchResponseItem>;

export interface IBerthsSearchResponseItem {
  berth: IBerth;
  boatsInBerth: IBoat[];
  activeContracts: IContract[];
}

export interface IFetchResourceOccupancySearchResponse {
  berth: IShortBerthResourceOccupancy;
  reservation: IShortBerthResourceOccupancyReservation;
}

export interface IShortBerthResource {
  id: string;
  pier: IShortPierResourceOccupancy;
  name: string;
  length: number;
  width: number;
  depth: number;
}

export interface IShortBerthResourceOccupancy extends IShortBerthResource {
  status: BerthOccupancyType;
}

export interface IShortPierResourceOccupancy {
  id: string;
  name: string;
  marinaCode: string;
}

export interface IShortBerthResourceOccupancyReservation {
  id: string;
  boat: IBerthResourceOccupancyBoat;
  contractType: ContractType;
  contractId: string;
  humanReadableId: string;
  fromDate: string;
  toDate: string;
  isApproved: boolean;
  source: string | null;
  status: CrmReservationStatus;
  comments: string;
  quotationStatus: QuotationStatus;
}

export interface IBerthResourceOccupancyBoat {
  id: string;
  name: string;
  registrationNumber: string;
  notes: Note;
  type: BoatType;
}

export interface IFetchBerthsSearchParams {
  pier?: string;
  berth?: string;
  isOccupiedByBoatTypes?: BoatType;
}

export interface IBerthAutocompleteItem {
  value: string;
  details: IBerthAutocompleteDetailsItem;
}

export interface IBerthAutocompleteDetailsItem {
  berthName: string;
  berthId: string;
  pierName: string;
}

export type IBerthAutocompleteRequestParams = {
  marinaCode: string;
  search?: string;
  resourceId?: string;
  berthId?: string;
  multipleBerthsIds?: string[];
  pier?: string; //Pier ID or name
  limit?: number;
};

export type IBerthAutocompleteParams = Partial<IBerthAutocompleteRequestParams>;
