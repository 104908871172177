import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ICreatePriceListParams,
  ICreatePriceListPayload,
  ICreatePriceListResponse,
  IGetPriceList,
  IGetPriceListResponse,
  IPriceListGetOne,
  MARINA_URL_TOKEN,
} from '@dm-workspace/types';
import { ApiFilesService } from '@dm-workspace/core';

import { ApiClientService } from '../api/api-client.service';
import { map } from 'rxjs/operators';
import { mapPriceListsWithValidToDates } from '@dm-workspace/utils';

@Injectable({
  providedIn: 'root',
})
export class PriceListApiService {
  resourceUrl = `/marinas/${MARINA_URL_TOKEN}/pricelists`;

  constructor(private apiClientService: ApiClientService) {}

  public fetchPriceListsForProduct(productId: string): Observable<IGetPriceList[]> {
    return this.apiClientService
      .get<IGetPriceListResponse[]>(`${this.resourceUrl}/${productId}`)
      .pipe(map(mapPriceListsWithValidToDates));
  }

  public fetchPriceListById(id: string, productNumber: string): Observable<IPriceListGetOne> {
    return this.apiClientService.get<IPriceListGetOne>(`${this.resourceUrl}/${productNumber}/${id}`);
  }

  public createPriceList(
    body: ICreatePriceListPayload,
    productNumber: string,
    params?: ICreatePriceListParams
  ): Observable<ICreatePriceListResponse> {
    return this.apiClientService.post<ICreatePriceListResponse>(`${this.resourceUrl}/${productNumber}`, body, {
      params,
    });
  }

  uploadPriceList(
    file: File,
    productName: string,
    params?: ICreatePriceListParams
  ): Observable<ICreatePriceListResponse> {
    return this.apiClientService.post<ICreatePriceListResponse>(
      `${this.resourceUrl}/${productName}/upload/xlsx`,
      ApiFilesService.saveFileToFormData(file),
      {
        params: {
          ...params,
          tests: 'SKIP',
        },
      }
    );
  }

  exportPriceList(productNumber: string, productId: string): Observable<Blob> {
    return this.apiClientService.get<Blob>(`${this.resourceUrl}/${productNumber}/${productId}/export/xlsx`, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseType: 'blob' as any,
    });
  }

  delete(productNumber: string, priceListId: string): Observable<boolean> {
    return this.apiClientService.delete(`${this.resourceUrl}/${productNumber}/${priceListId}`);
  }
}
