import { Pipe, PipeTransform } from '@angular/core';
import { BerthStatusInMarina } from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';
import { berthStatusToTagColor } from '@dm-workspace/utils';

@Pipe({
  name: 'tagColorByBerthStat',
  pure: false,
})
export class TagColorByBerthStatPipe implements PipeTransform {
  transform(stat: BerthStatusInMarina, defaultColor: TagColor = 'blue'): TagColor {
    return berthStatusToTagColor(stat) || defaultColor;
  }
}
