import { inject, Injectable } from '@angular/core';
import { QuoteAcceptResponse, QuoteDetails } from '@dm-workspace/types';
import { ApiHttpClient, ApiService } from '../api/api-http-client';
import { getBrowseLocale } from '@dm-workspace/utils';

export type ObGetQuoteByIdParams = {
  checkId: string;
};

@Injectable({
  providedIn: 'root',
})
export class ObQuotesApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl('/online-booking/quote/');

  public getById(quoteId: string, params: ObGetQuoteByIdParams) {
    const acceptLanguage = getBrowseLocale().split('-')[0];

    return this.apiHttpClient.get<QuoteDetails>(`${this.resourceUrl}${quoteId}`, {
      params,
      headers: {
        'accept-language': acceptLanguage,
      },
    });
  }

  public acceptQuote(quoteId: string, body: ObGetQuoteByIdParams) {
    return this.apiHttpClient.post<QuoteAcceptResponse>(`${this.resourceUrl}${quoteId}/accept`, body);
  }
}
