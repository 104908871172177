import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api-client.service';
import {
  ApiPrefix,
  IRequestParamsWithPagination,
  IResourceBookingPaymentResendDTO,
  IResourceBookingPaymentStatusResponse,
  MARINA_URL_TOKEN,
  PaginatedNormal,
  PaymentStatusEnum,
  PaymentProvider,
} from '@dm-workspace/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MmsPaymentsApiService {
  private readonly resourceUrl = `/${ApiPrefix.mmsPanel}/marinas/${MARINA_URL_TOKEN}/payments`;

  constructor(private api: ApiClientService) {}

  public payment(id: string): Observable<IResourceBookingPaymentStatusResponse> {
    return this.api.post(`${this.resourceUrl}/${id}`, {});
  }

  public payments(
    params?: IRequestParamsWithPagination<{
      referenceId: string;
      type: PaymentProvider;
      orderId: string;
      status: PaymentStatusEnum;
    }>
  ): Observable<PaginatedNormal<IResourceBookingPaymentStatusResponse>> {
    return this.api.get(`${this.resourceUrl}`, { params });
  }

  public createPayment(dto: IResourceBookingPaymentResendDTO): Observable<void> {
    return this.api.post(`${this.resourceUrl}/email-link`, dto);
  }
}
