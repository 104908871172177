import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPrefix, ENV, IBoat, IBoatCreatePayload } from '@dm-workspace/types';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BoatCustomerApiService {
  protected readonly env = inject(ENV);
  protected resourceUrl = `${this.env.apiUrl}/${ApiPrefix.customerPanel}/boat`;
  #httpClient = inject(HttpClient);

  fetchBoats(): Observable<IBoat[]> {
    return this.#httpClient.get<IBoat[]>(this.resourceUrl);
  }
  update(boatId: string, boat: Partial<IBoat>): Observable<IBoat> {
    return this.#httpClient.patch<IBoat>(`${this.resourceUrl}/${boatId}`, boat);
  }
  add(boat: IBoatCreatePayload): Observable<IBoat> {
    return this.#httpClient.post<IBoat>(`${this.resourceUrl}`, boat);
  }
}
