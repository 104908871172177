import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DateRange } from '@dm-workspace/types';
import { BookingHelperService } from '@dm-workspace/shared';

@Directive({ selector: '[dmIsLongTerm]' })
export class IsLongTermDirective {
  #bookingHelperService = inject(BookingHelperService);
  #templateRef = inject(TemplateRef);
  #viewContainer = inject(ViewContainerRef);
  #hasView = false;

  @Input() set dmIsLongTerm(dateRange: DateRange) {
    const isLongRange = this.#bookingHelperService.isLongTerm(dateRange);

    if (isLongRange && !this.#hasView) {
      this.#viewContainer.createEmbeddedView(this.#templateRef);
      this.#hasView = true;
    } else if (!isLongRange && this.#hasView) {
      this.#viewContainer.clear();
      this.#hasView = false;
    }
  }
}
