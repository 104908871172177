import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { toggleHeightAnimation } from '@dm-workspace/shared';

@Component({
  selector: 'dm-ui-hidden-content',
  templateUrl: './hidden-content.component.html',
  styleUrls: ['./hidden-content.component.scss'],
  animations: [toggleHeightAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenContentComponent {
  @Input() title = 'FILTERS';
  @Input() isOpen = true;
  @Output() animationStart = new EventEmitter();
  @Output() animationEnd = new EventEmitter();
  @ViewChild('animationContent') el?: ElementRef<HTMLDivElement>;
  private isClickedByUser = false;

  constructor(private cd: ChangeDetectorRef) {}

  toggleOpenState(): void {
    this.isClickedByUser = true;
    this.isOpen = !this.isOpen;
    this.cd.detectChanges();
  }

  onDoneEvent(): void {
    this.animationEnd.emit(this.el && this.el.nativeElement.offsetHeight);
  }

  onStartEvent(): void {
    if (this.isClickedByUser) {
      const height = (this.el?.nativeElement.firstChild as HTMLDivElement)?.offsetHeight;
      this.animationStart.emit(this.el && this.isOpen ? height : height * -1);
    }
  }
}
