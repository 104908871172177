import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StickyOffsetData } from './sticky-element.interface';

@Injectable({
  providedIn: 'root',
})
export class StickyElementService {
  public stickyOffsets$ = new BehaviorSubject<StickyOffsetData>(null);
  public refreshOffsets$: BehaviorSubject<null> = new BehaviorSubject(null);

  public setTargets(name: string, value: number): void {
    this.stickyOffsets$.next({ name, value });
  }

  public refreshOffsets(): void {
    this.refreshOffsets$.next(null);
  }
}
