import { Pipe, PipeTransform } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

export type StepState = 'in_progress' | 'completed' | 'error' | 'pending';
@Pipe({
  name: 'stepStatePipe',
  pure: false,
})
export class StepStatePipePipe implements PipeTransform {
  transform(step: CdkStep, isActive = false): StepState {
    if (isActive) {
      return 'in_progress';
    }
    if (step.hasError) {
      return 'error';
    }
    if (step.completed) {
      return 'completed';
    }
    return 'pending';
  }
}
