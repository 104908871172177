import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { BoatType, BoatTypeCustomer } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-boat-image',
  templateUrl: './boat-image.component.html',
  styleUrls: ['./boat-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatImageComponent {
  boatType = input.required<BoatType | BoatTypeCustomer>();
  imageUrl = computed(() => {
    return `assets/boat-icons/${this.boatType()
      .toLowerCase()
      .replace('multi hull', 'multihull')
      .replace(' ', '-')}.svg`;
  });
}
