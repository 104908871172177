import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Note } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
})
export class CommentsListComponent {
  @Input() comments: Note[];
  @Input() disableNewComment: boolean;

  @Output() addNewClick: EventEmitter<null> = new EventEmitter();
}
