import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { delay, first, Subject, takeUntil } from 'rxjs';
import { FormUtils } from '@dm-workspace/forms';

@Directive({
  selector: `[dmFormScrollToError], form[formGroup]`,
})
export class ScrollToErrorDirective implements OnDestroy {
  #stopSubject = new Subject<void>();
  @Input() labelOffset = 50;
  constructor(
    private el: ElementRef,
    private formGroupDir: FormGroupDirective
  ) {}

  ngOnDestroy(): void {
    this.#stopSubject.next();
  }

  @HostListener('ngSubmit')
  protected onSubmit(): void {
    if (this.formGroupDir.control.invalid) {
      this.formGroupDir.control.markAllAsTouched();
      FormUtils.scrollToFirstInvalidControl(this.el.nativeElement);
    } else {
      this.#stopSubject.next();
      this.formGroupDir.statusChanges.pipe(first(), takeUntil(this.#stopSubject), delay(10)).subscribe((status) => {
        if (status === 'INVALID') {
          FormUtils.scrollToFirstInvalidControl(this.el.nativeElement);
        }
      });
    }
  }
}
