import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayIncludes',
})
export class ArrayIncludesPipe implements PipeTransform {
  transform<T>(array: Array<T> | null | undefined, searchElement: T): boolean {
    if (!array || !Array.isArray(array)) {
      return;
    }
    return array.includes(searchElement);
  }
}
