import { ChangeDetectionStrategy, Component, ElementRef, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { IIyziPayPaymentData, PaymentIyzipayInitResponse } from '@dm-workspace/types';
import { PaymentsApiService } from '@dm-workspace/data-access';
import { catchError, tap, throwError } from 'rxjs';
import { PaymentMethodComponent } from '../payment-method.class';

declare const window: { iyziInit: unknown };

@Component({
  selector: 'dm-payment-method-iyzipay',
  templateUrl: './payment-method-iyzipay.component.html',
  styleUrls: ['./payment-method-iyzipay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodIyzipayComponent extends PaymentMethodComponent implements OnInit, OnDestroy {
  #script: HTMLScriptElement;
  readonly #el = inject(ElementRef);
  readonly #renderer2 = inject(Renderer2);
  readonly #paymentApiService = inject(PaymentsApiService);

  ngOnInit(): void {
    this.#paymentApiService
      .initIyzipayPayment({ referenceId: this.paymentReferenceId })
      .pipe(
        catchError((error) => {
          this.paymentMountError.emit(error);
          return throwError(() => error);
        }),
        tap((res) => this.#handleIyzipayInitResponse(res))
      )
      .subscribe();
  }

  #handleIyzipayInitResponse(val: PaymentIyzipayInitResponse) {
    this.#injectScriptTag(val);
    this.#mountPayment(val);
  }

  #mountPayment(val: PaymentIyzipayInitResponse): void {
    const date = new Date();
    date.setSeconds(date.getSeconds() + val.tokenExpireTime);

    this.paymentMounted.emit({
      sessionExpiresAt: new Date(date),
      paymentMethodType: 'Iyzipay',
    });
  }

  #injectScriptTag(value: IIyziPayPaymentData) {
    this.#script = this.#renderer2.createElement('script');
    this.#script.type = `text/javascript`;
    this.#script.text = value.checkoutFormContent
      .replace('<script type="text/javascript">', '')
      .replace('</script>', '');
    this.#renderer2.appendChild(this.#el.nativeElement, this.#script);
  }

  ngOnDestroy(): void {
    if (this.#script) {
      this.#renderer2.removeChild(this.#el.nativeElement, this.#script);
    }

    if (window.iyziInit) {
      window.iyziInit = undefined;
    }
  }
}
