<div class="header">
  <ng-container>
    <button
      *ngFor="let item of items"
      (click)="changeTab(item.id)"
      class="header__button"
      [class.is-active]="activeId === item.id"
      type="button"
    >
      {{ item.label | translate }}
    </button>
  </ng-container>
</div>

<div class="body">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="activeId === item.id">
      <ng-template [ngTemplateOutlet]="item.template" />
    </ng-container>
  </ng-container>
</div>
