import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(value: unknown): string {
    if (value === null || value === '' || value === undefined || Number.isNaN(value)) {
      return '-';
    }

    return value as string;
  }
}
