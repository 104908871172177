import { inject, Injectable } from '@angular/core';

import { filter, first, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@dm-workspace/core';
import { IBoat } from '@dm-workspace/types';
import { BoatCustomerApiService } from '@dm-workspace/data-access';

@Injectable({
  providedIn: 'root',
})
export class NcpUserBoatsService {
  #boatApi = inject(BoatCustomerApiService);
  #authService = inject(AuthService);
  #fetchingBoats = false;
  protected userBoatsSubject = new ReplaySubject<IBoat[]>(1);
  get boats$() {
    return this.userBoatsSubject.asObservable().pipe(filter(() => !this.#fetchingBoats));
  }
  constructor() {
    this.#authService.loggedIn$
      .pipe(
        switchMap((isLogged) => (isLogged ? this.fetchBoats() : of([]))),
        tap((boats) => this.userBoatsSubject.next(boats)),
        takeUntilDestroyed()
      )
      .subscribe();
  }
  fetchBoats() {
    this.#fetchingBoats = true;
    return this.#boatApi.fetchBoats().pipe(tap(() => (this.#fetchingBoats = false)));
  }

  addBoat(boat: IBoat) {
    this.userBoatsSubject.pipe(first()).subscribe((boats) => {
      this.userBoatsSubject.next([boat, ...boats]);
    });
  }
}
