export enum BoatMovementOperation {
  RETURN = 'return',
  DEPARTURE = 'departure',
  DRY_DOCK_LIFT = 'dry dock lift',
  DRY_DOCK_LAUNCH = 'dry dock launch',
  CRUISING = 'cruising',
  ARRIVAL = 'arrival',
  INTERNAL_MOVEMENT = 'internal relocation movement',
}

export enum BoatMovementOperationForStatus {
  RETURN = 'return',
  DEPARTURE = 'departure',
  CRUISING = 'cruising',
  ARRIVAL = 'arrival',
  DRY_DOCK_LIFT = 'dry dock lift',
  DRY_DOCK_LAUNCH = 'dry dock launch',
  VERIFY_CRUISE = 'verify cruise',
}
