import { Directive, HostBinding, HostListener, Inject, InjectionToken, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FileUtil } from '@dm-workspace/utils';

export const SAMEORIGIN = new InjectionToken<RegExp>('sameorigin.regex', {
  factory: () => {
    return new RegExp(`^data:|^blob:|^http(?:s)?:\/\/${window.location.host}`);
  },
});

@Directive({
  selector: 'a[dmDownload]',
})
export class DownloadDirective implements OnDestroy {
  public error = false;
  public busy = false;

  private sub?: Subscription;

  constructor(@Inject(SAMEORIGIN) private sameOrigin: RegExp, private http: HttpClient) {}

  @HostBinding('attr.download')
  @Input()
  download?: string;

  @Input()
  href!: string;

  @HostListener('click', ['$event']) onClick($event: MouseEvent) {
    $event.stopPropagation();
    if (!this.href || this.busy) {
      return false;
    }
    if (this.error || this.sameOrigin.test(this.href)) {
      return true;
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.busy = true;

    this.sub = this.http.get(this.href, { responseType: 'blob' }).subscribe(
      (blob) => {
        FileUtil.saveBlob(blob, this.download || '');
        this.busy = false;
      },
      (error1) => {
        this.busy = false;
      }
    );

    // Prevents default
    return false;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
