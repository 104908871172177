import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiErrorResponse, DmarinError } from '@dm-workspace/types';

export class FormUtils {
  public static isValid(form: FormGroup): boolean {
    if (form.valid) {
      return form.valid;
    }
    this.validate(form);
    form.markAsDirty();
    return false;
  }

  static scrollToFirstInvalidControl(el: HTMLElement): void {
    const firstInvalidControl: HTMLElement = el.querySelector('.ng-invalid.ng-dirty');
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  public static emptyStringToNull<T>(object: T): T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObject: Record<string, any> = {};
    for (const k in object) {
      newObject[k] =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (object as any)[k] === ''
          ? null
          : typeof object[k] === 'object' && object[k] !== null
            ? this.emptyStringToNull(object[k])
            : object[k];
    }
    return newObject as T;
  }

  public getControlsPath(form: FormGroup, predicate: (value: FormControl | FormGroup) => boolean) {
    if (!form) {
      console.warn('Missing form property');
      return [];
    }
    const output: Array<string> = [];

    const loop = (form: FormGroup, prefix = '') => {
      for (const key of Object.keys(form.controls)) {
        const control = form.get(key);
        if (control instanceof FormControl || control instanceof UntypedFormControl) {
          if (predicate(control)) {
            output.push(`${prefix}${key}`);
          }
        } else if (control instanceof FormGroup || control instanceof UntypedFormGroup) {
          loop(control, `${prefix}${key}.`);
        }
      }
    };
    return output;
  }

  private static validate(form: FormGroup | FormArray) {
    for (const control of Object.values(form.controls)) {
      if (!control && control.valid) {
        return;
      }
      control.markAsDirty();

      if (control instanceof FormControl) {
        this.changeControlUpdateOnStrategy(control, 'change');
      }
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.validate(control);
      }
    }
  }

  public static changeControlUpdateOnStrategy(formControl: AbstractControl, strategy: 'blur' | 'change') {
    Object.defineProperty(formControl, 'updateOn', {
      get: () => strategy,
      configurable: true,
    });
  }

  public static concatForms<O extends FormGroup, A extends Partial<FormGroup> = Partial<FormGroup>>(...args: A[]): O {
    const output = new FormGroup({});
    for (const formName in args) {
      const form = args[formName];
      for (const controlName in form.controls) {
        output.addControl(controlName, form.controls[controlName]);
      }
    }
    return output as unknown as O;
  }

  public static getApiNotificationError(res: HttpErrorResponse): string {
    if (FormUtils.isApiErrorResponse(res)) {
      const { errorCode, detailedErrorCode } = res.error;
      return 'ERRORS.' + (detailedErrorCode || errorCode);
    }
    return res.message ?? 'ERRORS.UNKNOWN_ERROR';
  }

  private static isApiErrorResponse(res: HttpErrorResponse): res is ApiErrorResponse {
    return !!(res.error as DmarinError | undefined)?.errorCode;
  }
}
