export enum DmarinErrorCodes {
  EMAIL_ALREADY_TAKEN = 'EMAIL_ALREADY_TAKEN',
  ALREADY_PAID = 'ALREADY_PAID',
  PAYMENT_LINK_EXPIRED = 'PAYMENT_LINK_EXPIRED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  ENTITY_DOES_NOT_EXIST = 'ENTITY_DOES_NOT_EXIST',
  BAD_REQUEST = 'BAD_REQUEST',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  UNIQUE_CONSTRAINT_VIOLATED = 'UNIQUE_CONSTRAINT_VIOLATED',
  OVERLAPPING_PERIODS = 'OVERLAPPING_PERIODS',
  EXTERNAL_PROVIDER_ERROR = 'EXTERNAL_PROVIDER_ERROR',
  FATAL_DATA_INTEGRITY_ERROR = 'FATAL_DATA_INTEGRITY_ERROR',
  PAYMENT_NOT_VALID_REQUIRED_DATA = 'PAYMENT_NOT_VALID_REQUIRED_DATA',
  PRICING_NOT_FOUND = 'PRICING_NOT_FOUND',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
}
