import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { take } from 'rxjs';

export function requiredWhen(field: () => AbstractControl, hasValue: (string | number | boolean)[]): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.parent) return null;
    if (field()) {
      const f = field();
      f.valueChanges.pipe(take(1)).subscribe(() => control.updateValueAndValidity());
      return hasValue.includes(f.value) ? Validators.required(control) : null;
    }
    return null;
  };
}
