import { Component, inject, input } from '@angular/core';
import { NcpBookingModalServices } from '@dm-workspace/new-customer-portal/ncp-auth';
import { AuthService, Breakpoint, TranslateCoreService } from '@dm-workspace/core';
import { LANGUAGES_SUPPORTED_BY_D365, LOCALIZATION_LANGUAGES } from '@dm-workspace/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'dm-ncp-layout-header',
  templateUrl: './ncp-layout-header.component.html',
  styleUrls: ['./ncp-layout-header.component.scss'],
})
export class NcpLayoutHeaderComponent {
  protected languages = LANGUAGES_SUPPORTED_BY_D365.filter(
    (item) => item.code !== LOCALIZATION_LANGUAGES.GREEK && item.code !== LOCALIZATION_LANGUAGES.ENGLISH_US
  ).map((item) => {
    if (item.code === LOCALIZATION_LANGUAGES.GREEK_ISO) {
      item.name = 'LANGUAGES.GREEK';
      return { ...item };
    }
    return item;
  });
  public selectedLang: { name: string; code: LOCALIZATION_LANGUAGES };
  public showingLoginButton = input<boolean>(true);
  protected readonly isLoggedIn$: Observable<boolean>;
  protected readonly Breakpoint = Breakpoint;

  #translationService = inject(TranslateCoreService);
  #modalService = inject(NcpBookingModalServices);
  #authService = inject(AuthService);

  constructor() {
    this.isLoggedIn$ = this.#authService.loggedIn$;
    const locale = LANGUAGES_SUPPORTED_BY_D365.find((item) => item.code === this.#translationService.locale);
    this.selectedLang = {
      name: locale.name,
      code: locale.code,
    };
    this.changeLng(this.selectedLang);
  }

  changeLng(lng: { name: string; code: LOCALIZATION_LANGUAGES }): void {
    if (lng.code === LOCALIZATION_LANGUAGES.ENGLISH_US) {
      this.#translationService.changeLng(LOCALIZATION_LANGUAGES.ENGLISH);
    } else if (lng.code === LOCALIZATION_LANGUAGES.GREEK_ISO) {
      this.#translationService.changeLng(LOCALIZATION_LANGUAGES.GREEK);
    } else {
      this.#translationService.changeLng(lng.code);
    }
    this.selectedLang = { name: lng.name, code: lng.code };
  }

  onLoginClick(): void {
    this.#modalService.openLoginModal();
  }

  onLogOutClick(): void {
    this.#authService.logout();
  }
}
