import { trigger, transition, style, animate } from '@angular/animations';
import { CUBIC } from '../constants';

export const fromTopAnimation = trigger('fromTopAnimation', [
  transition(':enter', [
    style({ transform: 'translateY(-20%)', opacity: '0' }),
    animate(`.25s ${CUBIC}`, style({ transform: 'translateY(0)', opacity: '1' })),
  ]),
  transition(':leave', [animate('.25s', style({ transform: 'translateY(-20%)', opacity: '0' }))]),
]);
