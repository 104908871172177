import { Location } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { Breakpoint } from '@dm-workspace/core';

@Component({
  selector: 'dm-ncp-auth-route-navigation-header',
  templateUrl: './ncp-auth-navigation-header.component.html',
  styleUrl: './ncp-auth-navigation-header.component.scss',
})
export class NcpAuthNavigationHeaderComponent implements OnInit {
  showBackButton = signal(false);
  #location = inject(Location);
  protected readonly Breakpoint = Breakpoint;

  ngOnInit(): void {
    this.#location.path() !== '/auth/login' ? this.showBackButton.set(true) : this.showBackButton.set(false);
    this.#location.onUrlChange((change) => {
      if (change === '/auth/login') {
        this.showBackButton.set(false);
        return;
      }
      this.showBackButton.set(true);
    });
  }
  goBack(): void {
    this.#location.back();
  }
}
