import { BoatType, Currency, BoatTypeCustomer, ResourceType } from './../backend';
import { BoatFitmentWithReasons, BookingResource, ShortResourceBookingBody } from './resource-booking.interface';
import { Customer } from './customer.interface';
import { ISimpleBrandModel } from './boats.interface';
import { ProfileDetailsDto, ProfileDocumentsDto } from './users.interface';
import { MarinaProductDto, MarinaProductType } from './marina.interface';
import { FormControl, FormGroup, FormRecord } from '@angular/forms';

export interface IDateRange {
  fromDate: string;
  toDate: string;
}
export type ObConfirmedViewChannel = 'wire-transfer';
export type ObThankYouViewType = 'booking' | 'order' | 'annual' | 'enquiry';

export interface IOnlineBookingResource {
  name: string;
  pier: string;
}

export type MarinaProductBillingType = 'PREPAID' | 'POSTPAID';

export interface IOnlineBookingBerthPriceDetails {
  unitPrice: number;
  unitPriceGross: number;
  sumPrice: number;
  sumPriceGross: number;
  duration: number;
  durationUnit: string;
  currency: Currency;
  calculationBase: string;
  vatRate: string;
  vatAmount: number;
  sumPriceVat?: number;
  exchangeRate: ExchangeRateResponse;
}

export interface IOnlineBookingResourceDetails extends IOnlineBookingResource {
  resourceId: string;
  name: string;
  pier: string;
  pierId: string;
  length: number;
  width: number;
  draft?: number;
  berthIds?: string[];
  type: ResourceType;
}

export type ObGetBerthsForBoatDto = {
  fromDate: string;
  toDate: string;
  boatLength: number;
  boatWidth: number;
  boatDraft: number;
  boatType: BoatType;
  marinaCode?: string;
  showAll?: boolean;
  includeUnavailableOnline?: boolean;
};

export type ObGetReservationDto = {
  marinaCode?: string;
  referenceId: string;
  checkId: string;
};

export type ObBookingReservation = {
  customer?: Partial<Customer>;
  reservation: ShortResourceBookingBody;
  quotationPrefilledData: Partial<Customer>;
};

export type ObFetchAcailableProductsDto = {
  boat: {
    width: number;
    length: number;
    draft: number;
    type: BoatType | BoatTypeCustomer;
  };
  fromDate: string;
  toDate: string;
  bookingHumanReadableId?: string;
};

export interface SetBookingProductsDto {
  checkId: string;
  bookingHumanReadableId: string;
  products: MarinaProductDto[];
}

export type ObGetBookingAvailableProductsDto = {
  bookingHumanReadableId: string;
  checkId: string;
};

export interface OnlineBookingAvailableProductsParams {
  bookingHumanReadableId: string;
  checkId: string;
}

export type ObBookingProduct = {
  isEnabled: boolean;
  pricing: IOnlineBookingBerthPriceDetails;
  product: MarinaProductType;
  isMandatory: boolean;
  productBillingType: MarinaProductBillingType;
  additionalData: unknown;
  productUnit: string;
};

export interface IFindBertForm {
  boatLength: number;
  boatWidth: number;
  boatDraft: number;
  dateRange: IDateRange;
  boatType: BoatType;
}

export interface IPersonFormBoat {
  brand: ISimpleBrandModel;
  name: string;
  type: BoatType;
  registrationNumber: string;
  flag: string;
}

export interface ObBookingStepTwoDto {
  person: ProfileDetailsDto & ProfileDocumentsDto;
  boat: IPersonFormBoat;
  products: MarinaProductDto[];
  agreements: {
    agreementOne: boolean;
  };
}

export interface IOnlineBookingSocketForm {
  referenceId: string;
  email: string;
}

export interface IChooseResource {
  booking: BookingResource;
  comments?: string;
  channel?: ObReservationChannel;
}

export interface IOnlineBookingResourceResponseWithBoatFitment extends BookingResource {
  willBoatFit: BoatFitmentWithReasons;
}

export interface IOnlineBookingBoat {
  length: number;
  width: number;
  draft?: number;
  modelId?: string;
  brandId: string;
  flag: string;
  name: string;
  registrationNumber?: string;
  portOfRegistration: string;
  type: BoatType;
}

export enum ObReservationChannel {
  GOOGLE_ADVERTISEMENT = 'Google Advertisement',
  GOOGLE_SEARCH = 'Google Search',
  SOCIAL_MEDIA = 'Social Media',
  MAGAZINE = 'Magazine',
  DMARIN_WEBSITE = 'D-Marin Website',
  SEAHELP_WEBSITE = 'SeaHelp Website',
  OTHER_WEBSITE = 'Other Website',
  FRIEND = 'Friend',
  BOAT_SHOW_CANNE = 'Boat Shows – Cannes',
  BOAT_SHOW_MONAC = 'Boat Shows – Monaco',
  BOAT_SHOW_GENOA = 'Boat Shows – Genoa',
  BOAT_SHOW_BOOT = 'Boat Shows – BOOT',
  BOAT_SHOW_BOSPHORUS = 'Boat Shows – Bosphorus',
  OLD_CUSTOMER = 'Old Customer',
  AGENT_AND_Broker = 'Agent & Broker',
  OTHER = 'Other',
}

export interface ObCreateReservationDto {
  person: ProfileDetailsDto & ProfileDocumentsDto;
  boat: IOnlineBookingBoat;
  comments: string;
  resourceId?: string;
  fromDate: string;
  consents: ConsentDto[];
  toDate: string;
  createAccount?: boolean;
  channel?: ObReservationChannel;
  products: MarinaProductDto[];
}
export interface OnlineBookingSendToExternalProcessingDto {
  fromDate: string;
  consents: ConsentDto[];
  toDate: string;
  customer: OnlineBookingSendToExternalProcessingCustomerDto;
  boat: IOnlineBookingBoat;
}
export interface OnlineBookingSendToExternalProcessingCustomerDto {
  email?: string;
  phone: string;
  companyName?: string;
  lastName: string;
  firstName: string;
  isIndividual: boolean;
  countryCode: string;
  address: string;
  city: string;
  zip?: string;
}
export type ObBookingReservationResponse = {
  checkId: string;
  orderId: string;
  reservationNumber: string;
};

export type ObBookingReservationCustomerSupportResponse = {
  reservationNumber: string;
};

export interface ObUpdateReservationDto {
  person: ProfileDetailsDto & ProfileDocumentsDto;
  boat?: Partial<IOnlineBookingBoat>;
  checkId: string;
  products?: MarinaProductDto[];
  referenceId: string;
  createAccount: boolean;
}

export interface ObContinueBookingQueryParams {
  referenceId: string;
  checkId: string;
}

export interface ExchangeRateResponse {
  rateValue: number;
  rateDate: string;
  downloadTime: number;
}
export interface TermsDto {
  documentId: number;
  publishedAt: string;
  versionNumber: number;
  url: string;
}
export interface ConsentDto {
  term: MarinaTermsTypes | GlobalTermsTypes;
  document: TermsDto;
}
export enum MarinaTermsTypes {
  services = 'services',
  privacyPolicy = 'privacyPolicy',
  vexationClauses = 'vexationClauses',
  servicesRenewals = 'servicesRenewals',
  vexationClausesRenewals = 'vexationClausesRenewals',
  withdrawalTermRenewals = 'withdrawalTermRenewals',
}
export enum GlobalTermsTypes {
  platform = 'platform',
  genericPrivacyPolicy = 'genericPrivacyPolicy',
  sensors = 'sensors',
}
export type GetMarinaTermsDto = Partial<Record<MarinaTermsTypes, TermsDto>>;
export type GetGlobalTermsDto = Partial<Record<GlobalTermsTypes, TermsDto>>;
export type AllTerms = GetMarinaTermsDto & GetGlobalTermsDto;
export type TermsForm = FormRecord<
  FormGroup<{
    check: FormControl<boolean>;
    terms: FormControl<ConsentDto>;
  }>
>;
