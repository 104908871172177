import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BookingPaymentStatus,
  IResourceBookingPaymentStatus,
  PostPaidResourceBookingPaymentStatus,
  Size,
} from '@dm-workspace/types';

/**
 * @deprecated use dm-ui-tag instead
 */
@Component({
  selector: 'dm-ui-payment-status-badge',
  templateUrl: './payment-status-badge.component.html',
  styleUrls: ['./payment-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentStatusBadgeComponent {
  @Input() status: BookingPaymentStatus | IResourceBookingPaymentStatus | PostPaidResourceBookingPaymentStatus;
  @Input() size: Size = 'sm';
  readonly SIZES: Partial<Record<Size, string>> = {
    sm: 'small',
    lg: 'large',
  };

  get badgeClass(): string {
    return [this.colorClass, this.colorClass].join(' ');
  }

  get colorClass(): string {
    switch (this.status) {
      case BookingPaymentStatus.PAID:
      case IResourceBookingPaymentStatus.Success:
        return 'badge--color-success';
      default:
        return 'badge--color-error';
    }
  }

  get sizeClass(): string {
    const sizeName = this.SIZES[this.size];
    return sizeName ? `badge--size-${sizeName}` : null;
  }
}
