import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, finalize, fromEvent, Observable, Subject, takeUntil, tap } from 'rxjs';
import { BookingResource, IBerthBase, Marina, MarinaMapColorStatus, MultihullPolygon } from '@dm-workspace/types';
import { GoogleMap, MapMarker, MapPolygon } from '@angular/google-maps';
import { MapComponentBase, MapService, MapTransformationsService, markerSVG } from '@dm-workspace/map-utils';
import { BerthsMapApiService } from '../../../../../../map-utils/src/lib/services/berths-map-api.service';

@Component({
  selector: 'dm-ob-maps-resources',
  templateUrl: './ob-maps-resources.component.html',
  styleUrls: ['./ob-maps-resources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObMapsResourcesComponent
  extends MapComponentBase<IBerthBase>
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  private destroy$ = new Subject();
  bookingBerthsByKey: Record<string, BookingResource> = {};
  berthsMap: Record<string, IBerthBase> = {};
  @Input() public pending = true;
  @Input() public fitToBookingBerths = false;
  @Input() public selectedResource?: BookingResource;

  @Input() resources?: BookingResource[];
  tmpInfoWindow: google.maps.InfoWindow;

  @Input() marina: Marina;

  @Input() set marinas(marinas: Marina[] | undefined) {
    this.marinasArray = marinas;
    if (marinas) {
      this.setMarinasZoomCenter();
      this.updatePending(false);
    }
  }

  @Output() viewChange = new EventEmitter<GoogleMap>();
  @Output() marinaMarkerClick = new EventEmitter<Marina>();
  @Output() berthClick = new EventEmitter<{
    polygon: MapPolygon;
    resource: BookingResource;
  }>();
  @Output() berthHover = new EventEmitter<{
    polygon: MapPolygon;
    resource: BookingResource;
  }>();
  @Output() berthBlur = new EventEmitter<{
    polygon: MapPolygon;
    resource: BookingResource;
  }>();
  public override selectedMarina: Marina;
  public marinasArray: Marina[] | undefined;
  public multihullsPolygon: MultihullPolygon[] = [];
  public selectedPolygon?: {
    polygon: MapPolygon;
    resource: BookingResource;
    lat?: google.maps.LatLng;
  };

  markerIcon: google.maps.Icon = {
    url: markerSVG,
  };

  constructor(
    mapService: MapService,
    private berthsApi: BerthsMapApiService,
    cd: ChangeDetectorRef,
    el: ElementRef,
    private mapTransformation: MapTransformationsService
  ) {
    super(mapService, cd, el);
  }

  public ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.cd.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedResource && this.berths?.length) {
      this.zoomToSelectedBerth();
      this.setReservationMultihullsPolygons();
    }
    if (changes.resources) {
      this.setBookingBerthsByKey();
    }
    if (changes.marina && this.marina) {
      this.selectedMarina = this.marina;
      this.selectedPolygon = undefined;
      if (this.marina) {
        this.updateMarina();
        this.fetchBerth();
      } else if (this.marinasArray) {
        this.setMarinasZoomCenter();
      }
    }
  }

  setBookingBerthsByKey() {
    if (this.resources && this.resources.length) {
      // this.resourcesTmp = resources;
      this.resources.forEach((resource) => {
        resource.resource.berthIds.forEach((value) => {
          this.bookingBerthsByKey[value] = this.bookingBerthsByKey[value] || resource;
        });
      });
      this.setMarinaToResourcesBerth();
    } else {
      this.bookingBerthsByKey = {};
    }
  }

  ngAfterViewInit(): void {
    this.cd.markForCheck();
  }

  updatePending(pending: boolean) {
    this.pending = pending;
    this.cd.markForCheck();
  }

  fetchBerth(): void {
    this.updatePending(true);
    if (this.selectedMarina) {
      this.fetchBookingBerths()
        .pipe(finalize(() => this.updatePending(false)))
        .subscribe((berths) => {
          this.berths = berths;
          this.setMarinaToResourcesBerth();
          this.setReservationMultihullsPolygons();
        });
    } else {
      this.berths = [];
    }
  }

  fetchBookingBerths(): Observable<IBerthBase[]> {
    return this.berthsApi.fetchBookingBerths(this.selectedMarina.code).pipe(
      tap(
        (value) =>
          (this.berthsMap = value.reduce(
            (p, c) => ({
              ...p,
              [c.id]: c,
            }),
            {}
          ))
      )
    );
  }

  onMarinaMarkerClick($event: Marina) {
    this.marinaMarkerClick.emit($event);
  }

  private setMarinasZoomCenter() {
    if (!this.marinasArray) {
      return;
    }
    const latLngList = this.marinasArray.filter((value) => value.layout?.center).map((value) => value.layout?.center);
    if (latLngList) {
      this.setMapBoundsAndCenter(latLngList);
    }
  }

  private setMarinaToResourcesBerth() {
    if (!this.berths || !this.selectedMarina) {
      return;
    }
    const hasResrouces = !!this.resources?.length;
    const latLngList = this.berths
      .filter((value) => (!hasResrouces && !!value.polygon) || (this.bookingBerthsByKey[value.id] && !!value.polygon))
      .reduce((p, c) => [...p, ...c.polygon], []);
    if (latLngList.length > 0) {
      this.setMapBoundsAndCenter(latLngList);
    } else if (this.map) {
      this.map.googleMap.setZoom(17);
      this.viewChange.emit(this.map);
    }
  }

  protected setMapBoundsAndCenter(latLngArray: [number, number][], padding: number | google.maps.Padding = 0) {
    super.setMapBoundsAndCenter(latLngArray, padding);
    this.cd.detectChanges();
    this.viewChange.emit(this.map);
  }

  protected onMapLoad() {
    setTimeout(() => {
      this.setMarinaToResourcesBerth();
      this.setMarinasZoomCenter();
    }, 10);
    this.setMarkerIcon();
  }

  public onBerthClick(polygon: MapPolygon, berth: IBerthBase) {
    const resource = this.bookingBerthsByKey[berth.id];
    this.berthClick.emit({
      polygon,
      resource,
    });
    this.closeInfoWindowWithBerthName();
  }

  public onBerthHover(berthM?: MapPolygon, berth?: IBerthBase) {
    if (!berth || !this.bookingBerthsByKey[berth.id]) {
      return;
    }
    const lat = this.getPolygonCenter(berthM);
    this.selectedPolygon = {
      polygon: berthM,
      lat,
      resource: this.bookingBerthsByKey[berth.id],
    };
    this.openHoverInfoWindow(true);
    this.setReservationMultihullsPolygons(berth.id);
    this.berthHover.emit({ polygon: berthM, resource: this.bookingBerthsByKey[berth.id] });
  }

  public onBerthBlur(berthM?: MapPolygon, berth?: IBerthBase) {
    this.selectedPolygon = null;
    this.closeHoverInfoWindow();
    this.setReservationMultihullsPolygons();
    this.berthBlur.emit({ polygon: berthM, resource: this.bookingBerthsByKey[berth.id] });
  }

  private zoomToSelectedBerth() {
    if (
      this.selectedResource &&
      this.selectedPolygon?.resource.resource?.resourceId !== this.selectedResource.resource.resourceId
    ) {
      const selectedBerth = this.berthsMap[this.selectedResource.resource.berthIds[0]];
      if (selectedBerth && selectedBerth.polygon) {
        this.setMapBoundsAndCenter(selectedBerth.polygon, 100);
      }
    }
  }

  private setMarkerIcon() {
    this.markerIcon.anchor = new google.maps.Point(10, 31);
  }

  showMarkerInfo($event: MapMarker, marina: Marina) {
    this.marinaMarkerMouseOut();
    this.tmpInfoWindow = new google.maps.InfoWindow({
      content: marina.name,
    });
    this.tmpInfoWindow.open(this.map.googleMap, $event.marker);
  }

  marinaMarkerMouseOut() {
    if (this.tmpInfoWindow) {
      this.tmpInfoWindow.close();
    }
  }

  private setReservationMultihullsPolygons(hoverBerthId?: string): void {
    const multihoolPolygons: MultihullPolygon[] = [];
    if (this.selectedResource && this.resources) {
      const reservation = this.selectedResource;
      multihoolPolygons.push({
        color: MarinaMapColorStatus.green,
        poly: this.mapTransformation.getBerthsPolygon(
          reservation.resource.berthIds.map((value) => this.berthsMap[value])
        ),
      });
    }
    if (hoverBerthId && this.bookingBerthsByKey[hoverBerthId]) {
      const reservation = this.bookingBerthsByKey[hoverBerthId];
      multihoolPolygons.push({
        color: MarinaMapColorStatus.yellow,
        poly: this.mapTransformation.getBerthsPolygon(
          reservation.resource.berthIds.map((value) => this.berthsMap[value])
        ),
      });
    }
    this.multihullsPolygon = multihoolPolygons;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }
}
