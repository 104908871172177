import { Pipe, PipeTransform } from '@angular/core';
import { IOrder } from '@dm-workspace/types';

@Pipe({
  name: 'totalOrderPrice',
})
export class TotalOrderPricePipe implements PipeTransform {
  transform(order: IOrder, ...args: unknown[]): number {
    return order.associatedProducts.reduce(
      (previousValue, currentValue) => previousValue + currentValue.totalPriceGross,
      0
    );
  }
}
