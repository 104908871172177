import { Directive, HostBinding, inject, Input, signal } from '@angular/core';
import { FormRadioGroupComponent, RADIO_GROUP } from './form-radio-group.component';
let idCounter = 0;
@Directive()
export class RadioButtonClass<T = string | null | boolean> {
  @HostBinding('class.radio-button')
  @Input()
  id: string = 'RadioButtonClass' + ++idCounter;
  @Input({ required: true }) name: string;
  @Input({ required: true }) value: T;
  protected radioGroup: FormRadioGroupComponent<T> = inject(RADIO_GROUP) as FormRadioGroupComponent<T>;
  public checked = signal<boolean>(false);
  public isDisabled = signal<boolean>(false);
  onModelChange() {
    if (this.isDisabled()) {
      return;
    }
    if (this.radioGroup) {
      this.radioGroup.setValue(this.value);
    }
  }
}
