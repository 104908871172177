import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dm-ui-load-more-button',
  templateUrl: './load-more-button.component.html',
  styleUrls: ['./load-more-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadMoreButtonComponent {
  @Input() collectionSize = 5;
  @Input() offset = 5;
  @Input() rowsPerLoad = 5;
  @Input() isPending = false;

  @Output() public pageChange = new EventEmitter<number>();

  private page = 1;

  public get rowsToLoadSize(): number {
    return Math.max(0, this.collectionSize - this.rowsPerLoad - this.offset);
  }

  public onLoadMore(): void {
    this.page += 1;
    this.pageChange.emit(this.page);
  }
}
