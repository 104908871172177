import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { ServiceWorkerUpdateModalComponent } from '../modals/service-worker-update-modal/service-worker-update-modal.component';

@Injectable()
export class ServiceWorkerUpdateService {
  constructor(
    swUpdate: SwUpdate,
    private modals: NgbModal
  ) {
    if (!swUpdate.isEnabled) {
      return;
    }

    swUpdate.unrecoverable.subscribe(() => {
      window.location.reload();
    });

    swUpdate.versionUpdates.pipe(filter((v) => v.type === 'VERSION_READY')).subscribe(() => {
      this.openVersionUpdateModal();
    });
  }

  private openVersionUpdateModal() {
    this.modals.open(ServiceWorkerUpdateModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      size: 'md',
    });
  }
}
