import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface ITabsNavigationItem {
  label: string;
  link: string;
}

@Component({
  selector: 'dm-ui-tabs-navigation',
  templateUrl: './tabs-navigation.component.html',
  styleUrls: ['./tabs-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsNavigationComponent {
  @Input() public navigation: ITabsNavigationItem[];
  public showActive = false;
  public showActiveIndex?: number;
  public prevActiveActiveIndex?: number;

  get getLeftOffset() {
    if (this.showActiveIndex >= 0) {
      const width = 100 / this.navigation.length;
      return width * this.showActiveIndex;
    }
    return 0;
  }

  onActiveChange($event: boolean, i: number) {
    if ($event && i !== this.showActiveIndex) {
      this.prevActiveActiveIndex = this.showActiveIndex;
      this.showActiveIndex = i;
      this.showActive = true;
    } else if (!$event && i !== this.prevActiveActiveIndex) {
      this.showActiveIndex = undefined;
      this.showActive = $event;
    }
  }
}
