import { inject, Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AutocompleteService } from '@dm-workspace/data-access';
import { IAutocompleteCountyResponse } from '@dm-workspace/types';

@Pipe({
  name: 'getCountyById',
})
export class GetCountyByIdPipe implements PipeTransform {
  #autocompleteService = inject(AutocompleteService);

  transform(countyId: string | null | undefined): Observable<IAutocompleteCountyResponse> {
    if (!countyId) {
      return;
    }

    return this.#autocompleteService.getCountyById(countyId).pipe(map((v) => v[0]));
  }
}
