import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileApiService } from '@dm-workspace/data-access';
import { ApiFile } from '@dm-workspace/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private fileApiService: FileApiService) {}

  public fetchAll(): Observable<ApiFile[]> {
    return this.fileApiService.fetchAll();
  }

  public fetchMine(): Observable<ApiFile[]> {
    return this.fileApiService.fetchMine();
  }

  public upload(file: FormData): Observable<HttpEvent<ApiFile>> {
    return this.fileApiService.upload(file);
  }

  public delete(id: string): Observable<void> {
    return this.fileApiService.delete(id);
  }
}
