import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dm-ui-card',
  template: `<ng-content />`,
  styles: `:host{
    display: block;
  }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @HostBinding('class') get className() {
    return 'card ' + this.color;
  }

  @HostBinding('class.card-shadow') get shadowClass() {
    return this.shadow;
  }

  @Input()
  color?: 'gray' | 'blue';

  @Input()
  shadow?: boolean;
}
