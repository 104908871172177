<ul>
  <li [class.active]="resource?.resource.resourceId">
    <div
      class="berth-item justify-content-between align-items-stretch"
      [class.active]="resource?.resource.resourceId"
      [id]="resource.resource.resourceId"
    >
      <div class="d-flex justify-content-between flex-column content-wrapper">
        <div>
          <h4 class="text-subheader-s berth-name">{{ "BERTH" | translate  }} {{ resource.resource.name }}</h4>
          <p class="text-secondary text-body-s mt-1 mt-md-3 fw-500">
            <dm-ncp-dimensions
              class="titlecase fw-600"
              [draft]="resource.resource.draft"
              [width]="resource.resource.width"
              [length]="resource.resource.length"
            />
          </p>
          <dm-ui-horizontal-line class="my-2 my-md-3" />
          <div class="pricing fw-500 text-body-s">
            <p class="text-secondary">
              {{ resource.pricingDetails.duration }}
              {{ (resource.pricingDetails.duration === 1 ? "NIGHT_BERTH_BOOKING" : "NIGHTS_BERTH_BOOKING") | translate | titlecase }}
            </p>
            <p class="text-secondary">
              {{ resource.pricingDetails.sumPrice | currency: resource.pricingDetails.currency }}
            </p>
            <p class="fw-600">{{ "TOTAL" | translate | titlecase }}</p>
            <p>{{ resource.pricingDetails.sumPriceGross | currency: resource.pricingDetails.currency }}</p>
          </div>
        </div>
        <button type="button" class="btn btn-primary mt-3" (click)="chooseBerth.emit(resource)">
          {{ "SELECT_THIS_BERTH" | translate | uppercase }}
        </button>
      </div>
    </div>
  </li>
</ul>
