<ng-container *ngIf="boat; else noBoatId">
  <dm-ui-boat-icon [boatType]="boat.type" class="boat-icon" />
  <a class="link boat-link" [routerLink]="['/boats', boat.id]" returnUrlCurrentPath [state]="{ backOnClose: true }" *ngIf="boat.id">{{
    boat.name
  }}</a>
  <div class="boat-details" *ngIf="!hideDetails">
    <ng-container [ngSwitch]="additionalRow">
      <ng-container *ngSwitchCase="'statusInMarina'"
        ><span *ngIf="boat.statusInMarina as status; else empty">{{
          "BOAT_MARINA_STATUS." + (status | statusInMarina | uppercase) | translate
        }}</span>
      </ng-container>
      <span *ngSwitchDefault class="boat-dimensions">{{ boat.length }} m / {{ boat.maxBeam }} m</span>
    </ng-container>
  </div>
</ng-container>

<ng-template #noBoatId>
  <ng-container *ngIf="boatDimensions as dimensions; else empty">
    <span class="no-boat-id">{{ dimensions.length }} m / {{ dimensions.width }} m</span>
  </ng-container>
</ng-template>
<ng-template #empty>
  <span>-</span>
</ng-template>
