export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type RequiredPartial<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};
