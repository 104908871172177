import { Injectable } from '@angular/core';
import {
  BookingResource,
  Marina,
  MARINA_URL_TOKEN,
  ObBookingProduct,
  ObBookingReservationCustomerSupportResponse,
  ObBookingReservationResponse,
  ObCreateReservationDto,
  ObFetchAcailableProductsDto,
  ObGetBerthsForBoatDto,
  ObGetBookingAvailableProductsDto,
  ObGetReservationDto,
  ObBookingReservation,
  ObUpdateReservationDto,
  SetBookingProductsDto,
  OnlineBookingSendToExternalProcessingDto,
} from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { ApiClientService } from './api/api-client.service';

/**
 * @api https://dmarin-api-stage.futuremind.dev/documentation/#/online%20booking
 */
@Injectable({
  providedIn: 'root',
})
export class ObApiService {
  private readonly resourceUrl = `/online-booking`;

  constructor(private api: ApiClientService) {}

  public getMarinas(): Observable<Marina[]> {
    return this.api.get(`${this.resourceUrl}/marinas`);
  }

  public getReservation(dto: ObGetReservationDto): Observable<ObBookingReservation> {
    const { marinaCode = MARINA_URL_TOKEN, referenceId, ...params } = dto;
    return this.api.get(`${this.resourceUrl}/${marinaCode}/reserve/${referenceId}`, {
      params,
    });
  }

  public createReservation(
    dto: ObCreateReservationDto,
    marinaCode = MARINA_URL_TOKEN
  ): Observable<ObBookingReservationResponse> {
    const { createAccount, ...body } = dto;
    const params = { createAccount };
    return this.api.post(`${this.resourceUrl}/${marinaCode}/reserve`, body, { params });
  }

  public updateReservation(dto: ObUpdateReservationDto): Observable<ObBookingReservationResponse> {
    const { createAccount, referenceId, ...body } = dto;
    const params = { createAccount };
    return this.api.patch(`${this.resourceUrl}/${MARINA_URL_TOKEN}/reserve/${referenceId}`, body, { params });
  }

  public sendReservationToCustomerSupport(
    dto: ObCreateReservationDto,
    marinaToken = MARINA_URL_TOKEN
  ): Observable<ObBookingReservationCustomerSupportResponse> {
    const body = dto;
    return this.api.post(`${this.resourceUrl}/${marinaToken}/customer-support`, body);
  }
  public sendEnquiry(
    dto: ObCreateReservationDto & { marinaCode: string }
  ): Observable<ObBookingReservationCustomerSupportResponse> {
    return this.api.post(`${this.resourceUrl}/quote/inquiry`, dto);
  }
  public sendToExternalProcessing(
    dto: OnlineBookingSendToExternalProcessingDto,
    marinaToken = MARINA_URL_TOKEN
  ): Observable<void> {
    const body = dto;
    return this.api.post(`${this.resourceUrl}/${marinaToken}/send-to-external-processing`, body);
  }
  public getBerthsForBoat(dto: ObGetBerthsForBoatDto, marinaCode?: string): Observable<BookingResource[]> {
    const params = dto;
    return this.api.get(`${this.resourceUrl}/${marinaCode || MARINA_URL_TOKEN}/berths/for-boat`, { params });
  }

  public getAvailableProducts(
    dto: ObFetchAcailableProductsDto,
    marinaCode = MARINA_URL_TOKEN
  ): Observable<ObBookingProduct[]> {
    const body = dto;
    return this.api.post(`${this.resourceUrl}/${marinaCode}/available-products/search`, body);
  }

  public getBookingAvailableProducts(dto: ObGetBookingAvailableProductsDto): Observable<ObBookingProduct[]> {
    const { bookingHumanReadableId, ...params } = dto;
    return this.api.get(`${this.resourceUrl}/${MARINA_URL_TOKEN}/${bookingHumanReadableId}/available-products`, {
      params,
    });
  }

  public setBookingProducts(dto: SetBookingProductsDto): Observable<ObBookingProduct[]> {
    const { bookingHumanReadableId, ...body } = dto;
    return this.api.post(`${this.resourceUrl}/${MARINA_URL_TOKEN}/reserve/${bookingHumanReadableId}/products`, body);
  }
}
