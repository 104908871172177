import {
  BookingPaymentStatus,
  ContractType,
  CrmOrderStatus,
  CrmProcessingStatus,
  CrmState,
  Currency,
  OrderPaymentStatus,
  OrderStatus,
  PaymentStatus,
  PaymentStatusEnum,
  ResourceType,
} from '../backend';
import { ShortResourceBookingBody } from './resource-booking.interface';

export interface IOrder {
  associatedProducts: OrderProduct[];
  boat: OrderBoat;
  booking: ShortResourceBookingBody;
  bookings: ShortResourceBookingBody[];
  contractType: ContractType;
  createdAt: string;
  currency: Currency;
  orderId: string;
  quoteId: string;
  customer: OrderCustomer;
  date: string;
  endDate: string;
  humanReadableId: string;
  id: string;
  invoiceCustomer: OrderCustomer;
  invoiceUrl: string;
  isDraft: boolean;
  isMultihull: boolean;
  length: number;
  maxBeam: number;
  name: string;
  paymentMethod: OrderPaymentMethod;
  paymentStatus: OrderPaymentStatus;
  paymentTerm: OrderPaymentMethod;
  processingStatus: CrmProcessingStatus;
  resources: OrderResource[];
  startDate: string;
  state: CrmState;
  status: CrmOrderStatus;
  vatIncluded: boolean;
  billingMethod: BillingMethod;
}

export enum BillingMethod {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID',
}

export interface OrderCustomer {
  id: string;
  name?: string;
}

export interface OrderPaymentMethod {
  id: string;
  name: string;
  description: string;
  isDefaultMethodForMmsBooking: boolean;
}

export interface OrderBoat {
  id: string;
  name: string;
}

export interface OrderContract {
  id: string;
  contactId: string;
  captainId: string;
  boatOwnerId: string;
  orderProductId: string;
  name: string;
  amount: number;
  currency: Currency;
  isCanceled: boolean;
}

export interface OrderProduct {
  id: string;
  productId: string;
  name: string;
  description: string;
  quantity: number;
  contract: OrderContract;
  dateFrom: string;
  dateTo: string;
  discountAmount: number;
  discountName: string;
  pricePerUnit: number;
  unit: string;
  product: OrderMarinaProduct;
  totalPriceGross: number;
}

export interface OrderProductWitBookings extends OrderProduct {
  bookings: ShortResourceBookingBody[];
}

export interface OrderMarinaProduct {
  id: string;
  marinaCode: string;
  productNumber: string;
  name: string;
  description?: string;
  price?: number;
  productUnit: MarinaProductUnit;
  billingType: BillingMethod;
  currencyCode: Currency;
  type: OrderProductType;
}

export enum MarinaProductUnit {
  'KWH' = 'kWh',
  'L' = 'L',
}

export interface OrderResource {
  type: ResourceType;
  resourceId: string;
  name: string;
  berthIds: string[];
}

export interface ICreateOrderBody {
  contractId?: string;
  customerId?: string;
  boatId?: string;
  status: OrderStatus;
  payment?: {
    status: PaymentStatusEnum;
    method: string;
    term: string;
  };
  invoiceUrl?: string;
  products: OrderProduct[];
  resources: {
    id: string;
    type: ResourceType;
  }[];
  startDate: string;
  endDate: string;
  name: string;
  currency: string;
}

export interface OrderSearchParams {
  orderId?: string;
  boatId?: string;
  customerId?: string;
  humanReadableId?: string;
  invoiceCustomerId?: string;
  paymentMethodId?: string;
  paymentTermId?: string;
  name?: string;
  state?: CrmState;
  status?: CrmOrderStatus;
  processingStatus?: CrmProcessingStatus;
  isMultihull?: boolean;
  hasBookings?: boolean;
  bookingPaymentStatus?: BookingPaymentStatus;
  bookingId?: string;
}

export enum OrderProductType {
  MOORING = 'MOORING',
  SERVICE_FEES = 'SERVICE_FEES',
  ELECTRICITY = 'ELECTRICITY',
  ELECTRICITY_USAGE = 'ELECTRICITY_USAGE',
  ELECTRICITY_AND_WATER = 'ELECTRICITY_AND_WATER',
  WATER = 'WATER',
  T0 = 'T0',
  T5 = 'T5',
  ENVIRONMENTAL_FEES = 'ENVIRONMENTAL_FEES',
  WATER_USAGE = 'WATER_USAGE',
  WALLET_CREDITS = 'WALLET_CREDITS',
}

export type TOrderListColumns = 'customer' | 'boat';

export type CustomerOrder = {
  humanReadableId: string;
  id: string;
  name: string;
  currency: Currency;
  contractType: ContractType;
  amount: {
    currency: Currency;
    value: number;
  };
  booking?: ShortResourceBookingBody;
  associatedProducts: OrderProduct[];
  boat: OrderBoat;
  customer: OrderCustomer;
  paymentStatus: PaymentStatus;
};
