import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minArrayLengthValidator(min = 1): ValidatorFn {
  return (control: AbstractControl<boolean[]>) => {
    if (control.value.filter(Boolean).length < min) {
      return {
        minArrayLength: {
          requiredLength: min,
          valid: false,
        },
      };
    }

    return null;
  };
}
