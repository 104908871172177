import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Size } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() size: Size = 'md';
  @HostBinding('class')
  @Input()
  color?: 'white';
}
