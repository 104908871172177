import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  InjectionToken,
  Input,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxButtonClass } from './checkbox-button-base-class.directive';

export type CheckboxGroupValue = string[] | undefined | Record<string, boolean>;

export const CHECKBOX_GROUP = new InjectionToken<FormCheckboxGroupComponent>('FormCheckboxGroupComponent');

@Component({
  selector: 'dm-form-checkbox-group',
  template: `<ng-content />`,
  providers: [
    { provide: CHECKBOX_GROUP, useExisting: forwardRef(() => FormCheckboxGroupComponent) },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxGroupComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCheckboxGroupComponent implements AfterViewInit, ControlValueAccessor {
  @HostBinding('class.inline')
  @Input()
  inline: boolean;
  @ContentChildren(CheckboxButtonClass, {
    descendants: true,
  })
  checkbox!: QueryList<CheckboxButtonClass>;
  @Input() returnType: 'stringArray' | 'object' = 'stringArray';
  onChange: (selected: CheckboxGroupValue) => void;

  onTouched: () => void = () => undefined;
  value: CheckboxGroupValue;

  isDisabled = false;
  checkedValues: Set<string> = new Set();

  registerOnChange(fn: (_: CheckboxGroupValue) => void): void {
    this.onChange = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.checkbox) {
      this.checkbox.forEach((item) => item.isDisabled.set(this.isDisabled));
    }
  }
  writeValue(obj: CheckboxGroupValue): void {
    this.value = obj;
    if (Array.isArray(obj)) {
      this.checkedValues = new Set(obj);
    } else if (obj) {
      this.checkedValues = new Set(Object.keys(obj).filter((key) => obj[key]));
    } else {
      this.checkedValues = new Set();
    }
    this.updateCheckboxCheckedState();
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  updateCheckboxCheckedState() {
    if (this.checkbox) {
      this.checkbox.forEach((item) => item.checked.set(this.checkedValues.has(item.name)));
    }
  }
  setValue(value: string) {
    if (this.isDisabled) {
      return;
    }
    if (this.checkedValues.has(value)) {
      this.checkedValues.delete(value);
    } else {
      this.checkedValues.add(value);
    }
    this.updateCheckboxCheckedState();
    this.updateAndEmitValue();
  }
  updateAndEmitValue() {
    if (this.returnType === 'stringArray') {
      const checked = Array.from(this.checkedValues);
      this.value = checked.length ? checked : null;
    } else {
      this.value = this.checkbox.reduce((p, c) => ({ ...p, [c.name]: c.checked }), {});
    }
    this.onChange(this.value);
    this.onTouched();
  }
  ngAfterViewInit(): void {
    this.setDisabledState(this.isDisabled);
    if (this.value) {
      this.updateCheckboxCheckedState();
    }
  }
}
