import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[aspectRatioHeight]',
})
export class AspectRatioHeightDirective implements OnInit {
  @Input() ratio: number = 1920 / 1080;
  @Input() minHeight: number;
  #renderer = inject(Renderer2);
  #elementRef = inject(ElementRef);
  #domElement = this.#elementRef.nativeElement as HTMLElement;

  constructor() {
    // register on window resize event
    fromEvent(window, 'resize')
      .pipe(throttleTime(500), debounceTime(500), takeUntilDestroyed())
      .subscribe(() => this.setHeight());
  }

  ngOnInit() {
    this.setHeight();
  }

  private setHeight() {
    const windowWidth = window?.innerWidth;
    let height = windowWidth / this.ratio;
    if (this.minHeight && height < this.minHeight) {
      height = this.minHeight;
    }
    this.#renderer.setStyle(this.#domElement, 'height', `${height}px`);
  }
}
