import { Pipe, PipeTransform } from '@angular/core';
import { MarinasService } from '@dm-workspace/core';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'marinaName',
})
export class MarinaNamePipe implements PipeTransform {
  constructor(private marinaService: MarinasService) {}
  transform(marinaCode: string): Observable<string> {
    return this.marinaService.marinas$.pipe(
      map((marinas) => {
        if (marinaCode === '*') {
          return 'ALL';
        } else {
          const matchingMarina = marinas.find((marina) => marina.code === marinaCode);
          return matchingMarina?.name || marinaCode;
        }
      })
    );
  }
}
