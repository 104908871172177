export enum PriceListProductNumbers {
  PRESSURE_WASHING_10 = '000010',
  LAUNCH_15 = '000015',
  MOORING_21 = '000021',
  PROPPING_CHARGE_26 = '000026',
  P33_000033 = '000033',
  CRADLE_RENTALS_47 = '000047',
  LIFT_52 = '000052',
  HOLDING_ON_THE_SLINGS_128 = '000128',
  DRY_DOCK_ANNUAL_MOORING_153 = '000153',
  DRY_DOCK_MIDTERM_MOORING_154 = '000154',
  DRY_DOCK_NO_MOORING_155 = '000155',
  DRY_DOCK_EXTENSION_156 = '000156',
  DRY_DOCK_EXTENSION_W_ANNUAL_CONTRACT_157 = '000157',
  HAULING_WITH_ANNUAL_CONTRACT_166 = '000166',
  HAULING_NOT_ANNUAL_CONTRACT_167 = '000167',
  LAUNCHING_WITH_ANNUAL_CONTRACT_168 = '000168',
  LAUNCHING_NOT_ANNUAL_CONTRACT_169 = '000169',
  MOORING_INSTALLMENTS_170 = '000170',
  HIGH_PRESSURE_CLEANING_WITH_ANNUAL_171 = '000171',
  JET_SKI_RENT_396 = '000396',
  GARAGE_WITH_ANNUAL_CONTRACT_414 = '000414',
  CHARTER_MOORING_FEES_PREPAYMENT_416 = '000416',
  CHARTER_MOORING_FEES_INSTALLMENTS_417 = '000417',
  CRANE_PASS_418 = '000418',
  JET_SKI_TENDER_WITH_ANNUAL_MOORING_419 = '000419',
  MOORING_WITH_ANNUAL_LAND_CONTRACT_420 = '000420',
  TRAVEL_LIFT_2_OPERATIONS_421 = '000421',
  SEASONAL_PACKAGE_422 = '000422',
  EXTENDING_SEASONAL_PACKAGE_423 = '000423',
  TRAVEL_LIFT_RELOCATION_424 = '000424',
  TENANTS_IN_RENTAL_GARAGE_WITHOUT_ANNUAL_CONTRACT_425 = '000425',
  MOORING_FEE_BERTH_WITH_FINGER_426 = '000426',
  MOORING_WITH_ADDITIONAL_FEES = '000440',
  P9_000009 = '000009',
  P35_000035 = '000035',
}
