<ngb-datepicker
  #dp
  (dateSelect)="onDateSelection($event)"
  [displayMonths]="displayMonths"
  [minDate]="minDate_"
  [maxDate]="maxDate_"
  [dayTemplate]="t"
  outsideDays="hidden"
  class="ngb-datepicker-custom"
/>

<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.is-focused]="focused"
    [class.first]="isFirst(date)"
    [class.last]="isLast(date)"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
