import { Component, Input } from '@angular/core';
import { QuoteDetailsCmsItem } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-quote-section-hero',
  templateUrl: './ob-quote-section-hero.component.html',
  styleUrls: ['./ob-quote-section-hero.component.scss'],
})
export class ObQuoteSectionHeroComponent {
  @Input() data: QuoteDetailsCmsItem;
}
