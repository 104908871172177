import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthSetPasswordViewType, IAuthSetUpPassword } from '@dm-workspace/types';
import { AuthService, FormView } from '@dm-workspace/core';
import { ApiValidatorService, inputRepeatValidator, minSpecialCharactersValidator } from '@dm-workspace/forms';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs';
import { ApiValidator } from '@dm-workspace/shared';
import { Breakpoint } from '@dm-workspace/core';
import { NcpAuthView } from 'libs/new-customer-portal/ncp-auth-route/src/lib/views/ncp-auth-view/ncp-auth-view.component';

type FormValue = {
  password: string;
  repeatPassword?: string;
};

@Component({
  selector: 'dm-ncp-auth-ncp-auth-reset-password',
  templateUrl: './ncp-auth-set-reset-password.component.html',
  styleUrls: ['ncp-auth-set-reset-password.component.scss', '../ncp-auth-shared.component.scss'],
})
export class NcpAuthSetResetPasswordComponent extends FormView<FormValue> implements OnInit {
  #fb = inject(FormBuilder);
  #notification = inject(NotificationService);
  #auth = inject(AuthService);
  #router = inject(Router);
  #aRoute = inject(ActivatedRoute);
  #cd = inject(ChangeDetectorRef);
  #authView = inject(NcpAuthView);

  passwordParams: IAuthSetUpPassword;
  AuthViewType = AuthSetPasswordViewType;
  public viewMode: AuthSetPasswordViewType;
  protected readonly Breakpoint = Breakpoint;

  constructor(override readonly apiValidator: ApiValidatorService) {
    super(apiValidator);
  }

  ngOnInit(): void {
    this.viewMode = this.#aRoute.snapshot.data['viewMode'] as AuthSetPasswordViewType;
    this.passwordParams = this.#aRoute.snapshot.queryParams as IAuthSetUpPassword;
    this.form = this.#fb.group({
      password: new FormControl<string>('', [Validators.required, minSpecialCharactersValidator(1), Validators.min(8)]),
      repeatPassword: new FormControl<string>(''),
    });

    if (this.viewMode === AuthSetPasswordViewType.setUpPassword) {
      this.form
        .get('repeatPassword')
        .addValidators([
          Validators.required,
          Validators.minLength(8),
          minSpecialCharactersValidator(1),
          inputRepeatValidator('password'),
        ]);
    }
  }

  override onSubmit() {
    if (this.pending || !this.apiValidator.formIsValid(this.form)) {
      return;
    }
    this.pending = true;

    this.onSubmitAction()
      .pipe(
        finalize(() => {
          this.pending = false;
          this.#cd.markForCheck();
        })
      )
      .subscribe({
        next: () => {
          this.#authView.hideBubbleTooltip();
          this.#notification.add({
            type: 'ncp_success',
            text:
              this.viewMode === 'resetPassword'
                ? 'NCP_AUTH_MODULE.RESET_PASSWORD.SUCCESS_HEADER'
                : 'NCP_AUTH_MODULE.SET_PASSWORD.SUCCESS_HEADER',
            content:
              this.viewMode === 'resetPassword'
                ? 'NCP_AUTH_MODULE.RESET_PASSWORD.SUCCESS_PARAGRAPH'
                : 'NCP_AUTH_MODULE.SET_PASSWORD.SUCCESS_PARAGRAPH',
          });

          setTimeout(() => {
            this.#router.navigate(['/login']);
          }, 1000);
        },
        error: (res: HttpErrorResponse) => {
          this.#notification.add({
            type: 'ncp_error',
            content: ApiValidator.getApiNotificationError(res),
          });
        },
      });
  }

  public onSubmitAction() {
    const { password } = this.form.getRawValue();

    switch (this.viewMode) {
      case AuthSetPasswordViewType.resetPassword:
        return this.#auth.resetPassword({
          ...this.passwordParams,
          password,
        });
      case AuthSetPasswordViewType.setUpPassword:
        return this.#auth.setUpPassword({
          ...this.passwordParams,
          password,
        });
    }
  }
}
