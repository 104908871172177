<div class="d-flex align-items-center justify-content-between">
  <h4>{{ title | translate }}</h4>
  <span>
    <ng-content select="[filters-additions]" />
    <button type="button" (click)="toggleOpenState()" class="text-nowrap last-child">
      {{ (isOpen ? "HIDE" : "SHOW") | translate }}
      <dm-ui-carrot [rotate]="isOpen" />
    </button>
  </span>
</div>
<div
  [@toggleHeight]="isOpen"
  (@toggleHeight.done)="onDoneEvent()"
  (@toggleHeight.start)="onStartEvent()"
  #animationContent
  class="content-wrapper"
>
  <div>
    <ng-content />
  </div>
</div>
