import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuoteDetails, QuoteStatus } from 'libs/types/src';

@Component({
  selector: 'dm-ob-quote-summary',
  templateUrl: './ob-quote-summary.component.html',
  styleUrls: ['./ob-quote-summary.component.scss'],
})
export class ObQuoteSummaryComponent {
  @Output() public readonly priceDetailsClick = new EventEmitter<void>();

  @Input({ required: true }) public quote?: QuoteDetails;
  protected readonly QuoteStatus = QuoteStatus;
}
