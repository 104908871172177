import { Pipe, PipeTransform } from '@angular/core';
import { QuoteProduct } from '@dm-workspace/types';

@Pipe({
  name: 'sumQuoteProductsDiscounts',
})
export class ObSumQuoteProductsDiscountsPipe implements PipeTransform {
  public transform(products: QuoteProduct[]): number {
    if (!products?.length) {
      return null;
    }

    return products.reduce((acc, n) => acc + n?.discountAmount ?? 0, 0);
  }
}
