import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CircleSpinnerComponent } from './components/circle-spinner/circle-spinner.component';
import { OverflowSpinnerComponent } from './components/overflow-spinner/overflow-spinner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OverflowSpinnerComponent, SpinnerComponent, CircleSpinnerComponent],
  exports: [OverflowSpinnerComponent, SpinnerComponent, CircleSpinnerComponent],
})
export class OverflowSpinnerModule {}
