import { BoatType, Currency, UOM } from '../backend';

export enum BillingType {
  POSTPAID = 'POSTPAID',
  PREPAID = 'PREPAID',
  WALLET = 'WALLET',
}
export enum ProductType {
  ELECTRICITY = 'ELECTRICITY',
  ELECTRICITY_AND_WATER = 'ELECTRICITY_AND_WATER',
  WATER = 'WATER',
}
export interface IMarinaProduct {
  id: string;
  marinaCode: string;
  productNumber: string;
  name: string;
  description: string | null;
  price: number | null;
  uom: UOM;
}

export type IMarinaProductsApiParams = {
  search?: string;
  productNumber?: string;
};
export type IMarinaProductsUtilitiesApiParams = {
  fromDate?: string;
  toDate?: string;
  boatType: BoatType;
  boatLength: number;
  boatWidth: number;
  draft?: number;
};
export interface MarinaAvailableUtilitiesResponseDto {
  billingType: BillingType;
  product: ProductType;
  sumPriceGross: number;
  currency: Currency;
}
