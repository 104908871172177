<dm-form-input-value class="cell">
  <ng-container header>{{"PERIOD" | translate}}</ng-container>
  <ng-container body>{{quote?.startDate | dateFormat }} - {{quote?.endDate | dateFormat}}</ng-container>
</dm-form-input-value>

<dm-form-input-value *ngIf="(quote?.data | sumQuoteProductsDiscounts) as productsDiscountSum" class="cell">
  <ng-container header>{{"DISCOUNT" | translate}}</ng-container>
  <ng-container body>{{productsDiscountSum | currency : quote?.currency}}</ng-container>
</dm-form-input-value>

<dm-form-input-value class="cell">
  <ng-container header>{{"TOTAL_AMOUNT" | translate}}</ng-container>
  <div class="d-flex justify-content-between align-items-end">
    <span class="font-header-l">{{quote?.totalPriceGross | currency : quote?.currency}}</span>
    <button (click)="priceDetailsClick.emit()" class="font-link-m"
            type="button">{{"PRICE_DETAILS" | translate}}</button>
  </div>
</dm-form-input-value>

@if (quote?.quoteStatus === QuoteStatus.ACCEPTED) {
  <div class="mt-3 mb-2 d-flex align-items-center justify-content-center accepted">
    <i class="icon i-check-circle me-2"></i>
    <span>{{"OFFER_ACCEPTED" | translate | uppercase }}</span>
  </div>
}
@else {
  <dm-ob-quote-expired-timer [quote]="quote" class="expired-timer" />
  <div class="footer cell">
    <ng-content select="[footer]"/>
  </div>
}

