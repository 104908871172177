import {
  BerthBoatAlertStatus,
  BerthAvailabilityReservationDto,
  BerthMaintenanceStatusInMarina,
  BerthMapResponseDto,
  BerthOccupancyStatusInMarina,
  BerthPylonsStatusInMarina,
  BerthReservationsStatusInMarina,
  BerthStatusInMarina,
  BoatAbsenceMapResponseDto,
  BoatMovementOperation,
  BoatMovementOperationForStatus,
  ContractType,
  IBoatAlertResponseDtoStatusEnum,
  IBookingCalendarWithIndex,
  IMovement,
  ListMarinaPylonsResponseDto,
  MarinaMapColorStatus,
  BillingType,
} from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';
import { isWithinInterval } from 'date-fns';

export enum MapViewTypes {
  reservations,
  occupancy,
  sensors,
  pylons,
  statuses,
}
const VAREZZE_TEMP_BOAT = 'SHIPYARD TEMP BOAT';
export const berthStatusToTagColor = (status: BerthStatusInMarina): TagColor => {
  switch (status) {
    case BerthReservationsStatusInMarina.reservedMidTerm:
    case BerthOccupancyStatusInMarina.expectedArrivals:
      return 'orange-light';
    case BerthOccupancyStatusInMarina.expectedDepartures:
      return 'magenta-light';
    case BerthBoatAlertStatus.noAlert:
    case BerthOccupancyStatusInMarina.occupiedWithBooking:
    case BerthReservationsStatusInMarina.allowedOverbooking:
      return 'green-light';
    case BerthOccupancyStatusInMarina.occupiedWithoutBooking:
      return 'yellow-light';
    case BerthBoatAlertStatus.activeAlert:
    case BerthOccupancyStatusInMarina.overOccupied:
    case BerthReservationsStatusInMarina.unallowedOverbooking:
      return 'red-light';
    case BerthBoatAlertStatus.noSubscription:
    case BerthOccupancyStatusInMarina.empty:
    case BerthPylonsStatusInMarina.notConnected:
    case BerthReservationsStatusInMarina.available:
      return 'black-light';
    case BerthReservationsStatusInMarina.temporaryAvailableCruise:
    case BerthOccupancyStatusInMarina.temporaryAbsenceCruise:
      return 'light';
    case BerthBoatAlertStatus.eligible:
    case BerthPylonsStatusInMarina.connectedPrepaid:
    case BerthReservationsStatusInMarina.reservedTransient:
      return 'blue-light';
    case BerthReservationsStatusInMarina.reservedLongTerm:
    case BerthPylonsStatusInMarina.connectedPostpaid:
      return 'dark-blue-light';
    case BerthReservationsStatusInMarina.temporaryAvailableDryDock:
    case BerthOccupancyStatusInMarina.temporaryAbsenceDryDock:
      return 'grey-light';
    case BerthMaintenanceStatusInMarina.disabled:
      return 'grey';
  }
};
function splitDates(dateString: string): Date {
  return new Date(dateString.split('T')[0]);
}
function isInGap(gap: BoatAbsenceMapResponseDto, booking: BerthAvailabilityReservationDto) {
  const gapDates = {
    start: splitDates(gap.gapStart || gap.movementOperationDate),
    end: splitDates(gap.gapEnd || gap.expectedReturnDate),
  };
  return (
    isWithinInterval(splitDates(booking.fromDate), gapDates) && isWithinInterval(splitDates(booking.toDate), gapDates)
  );
}
// function datesDoNotOverlaps(toDateString: string, fromDateString: string) {
//   const toDate = new Date(toDateString);
//   const fromDate = new Date(fromDateString);
//   return !isSameDay(toDate, fromDate) || (getHours(fromDate) > 13 && getHours(toDate) < 13);
// }
export function getPylonsMapStatus(pylon?: ListMarinaPylonsResponseDto): BerthPylonsStatusInMarina[] {
  if (pylon?.pylonBillingType === BillingType.POSTPAID) {
    return [BerthPylonsStatusInMarina.connectedPostpaid];
  }
  if (pylon?.pylonBillingType === BillingType.PREPAID) {
    return [BerthPylonsStatusInMarina.connectedPrepaid];
  }
  return [BerthPylonsStatusInMarina.notConnected];
}
export function getReservationMapStatus(
  berth: BerthMapResponseDto,
  marinaCode: string
): BerthReservationsStatusInMarina[] {
  const alerts = [];
  if (
    marinaCode === 'IV04' &&
    berth.bookings.length === 2 &&
    berth.bookings.some((value) => value.boat.name === VAREZZE_TEMP_BOAT)
  ) {
    alerts.push(BerthReservationsStatusInMarina.allowedOverbooking);
  } else if (berth.bookings?.length > 1 && (!berth.temporaryAbsence || berth.temporaryAbsence.length === 0)) {
    alerts.push(BerthReservationsStatusInMarina.unallowedOverbooking);
  } else if (berth.bookings?.length > 1) {
    const findWithoutGap = berth.bookings.filter((value) =>
      berth.temporaryAbsence.every((absence) => absence.boatId !== value.boatId)
    );
    alerts.push(
      findWithoutGap.length === 0 ||
        (findWithoutGap.length === 1 && berth.temporaryAbsence.every((value) => isInGap(value, findWithoutGap[0])))
        ? BerthReservationsStatusInMarina.allowedOverbooking
        : BerthReservationsStatusInMarina.unallowedOverbooking
    );
  }
  if (berth.bookings.length === 1) {
    if (berth.bookings[0].contractType === ContractType.TRANSIENT) {
      alerts.push(BerthReservationsStatusInMarina.reservedTransient);
    }
    if (berth.bookings[0].contractType === ContractType.MID_TERM) {
      alerts.push(BerthReservationsStatusInMarina.reservedMidTerm);
    }
    if (berth.bookings[0].contractType === ContractType.LONG_TERM) {
      alerts.push(BerthReservationsStatusInMarina.reservedLongTerm);
    }
    if (
      berth.temporaryAbsence.some(
        (value) => value.operation === BoatMovementOperation.CRUISING && value.allowBerthReuse
      )
    ) {
      alerts.push(BerthReservationsStatusInMarina.temporaryAvailableCruise);
    }
    if (
      berth.temporaryAbsence.some(
        (value) => value.operation === BoatMovementOperation.DRY_DOCK_LIFT && value.allowBerthReuse
      )
    ) {
      alerts.push(BerthReservationsStatusInMarina.temporaryAvailableDryDock);
    }
  }

  if (alerts.length) {
    return alerts;
  }
  return [BerthReservationsStatusInMarina.available];
}
export function getOccupancyMapStatus(berth: BerthMapResponseDto): BerthOccupancyStatusInMarina[] {
  const alerts = [];
  if (berth.occupancy?.length > 1) {
    alerts.push(BerthOccupancyStatusInMarina.overOccupied);
  }
  if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.CRUISING)) {
    alerts.push(BerthOccupancyStatusInMarina.temporaryAbsenceCruise);
  }
  if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.DRY_DOCK_LIFT)) {
    alerts.push(BerthOccupancyStatusInMarina.temporaryAbsenceDryDock);
  }
  if (berth.expectedDepartures.length) {
    alerts.push(BerthOccupancyStatusInMarina.expectedDepartures);
  }
  if (berth.expectedArrivals.length) {
    alerts.push(BerthOccupancyStatusInMarina.expectedArrivals);
  }
  if (berth.occupancy?.length === 1) {
    alerts.push(
      berth.bookings.some((value) => value.boat?.id === berth.occupancy[0].boatId)
        ? BerthOccupancyStatusInMarina.occupiedWithBooking
        : BerthOccupancyStatusInMarina.occupiedWithoutBooking
    );
  }
  if (alerts.length) {
    return alerts;
  }
  return [BerthOccupancyStatusInMarina.empty];
}
export function getAlertMapStatus(berth: BerthMapResponseDto): BerthBoatAlertStatus[] {
  if (berth.occupancy.length === 0) {
    return [BerthBoatAlertStatus.empty];
  }
  if (berth.eligibleContracts.length > 0) {
    return [BerthBoatAlertStatus.eligible];
  }
  if (berth.sensorSubscriptions.length === 0) {
    return [BerthBoatAlertStatus.noSubscription];
  }
  if (
    berth.alerts.length > 0 &&
    berth.alerts.some((value) => value.status === IBoatAlertResponseDtoStatusEnum.Active)
  ) {
    return [BerthBoatAlertStatus.activeAlert];
  }
  return [BerthBoatAlertStatus.noAlert];
}
export function getBerthStatusColor(booking: IBookingCalendarWithIndex): MarinaMapColorStatus {
  if (booking.overlapping) {
    return MarinaMapColorStatus.red;
  } else if (isTransient(booking.contractType)) {
    return MarinaMapColorStatus.blue;
  } else if (isNotOnMarina(booking.boatStatusInMarina)) {
    return MarinaMapColorStatus.yellow;
  } else if (isOnMarina(booking.boatStatusInMarina)) {
    return MarinaMapColorStatus.green;
  }
  return MarinaMapColorStatus.gray;
}

export function hasMovements(movements: IMovement[]): boolean {
  return movements && movements.length > 0;
}

export function isOnMarina(boatStatusInMarina: BoatMovementOperationForStatus): boolean {
  return [
    BoatMovementOperationForStatus.RETURN,
    BoatMovementOperationForStatus.ARRIVAL,
    BoatMovementOperationForStatus.DRY_DOCK_LAUNCH,
  ].includes(boatStatusInMarina);
}

export function isNotOnMarina(boatStatusInMarina: BoatMovementOperationForStatus, ignoreNull = false): boolean {
  let notOnMarinaStatuses: BoatMovementOperationForStatus[] = [
    BoatMovementOperationForStatus.CRUISING,
    BoatMovementOperationForStatus.DRY_DOCK_LIFT,
    BoatMovementOperationForStatus.DEPARTURE,
  ];

  if (!ignoreNull) {
    notOnMarinaStatuses = [...notOnMarinaStatuses, null];
  }
  return notOnMarinaStatuses.includes(boatStatusInMarina);
}

export function isTransient(contractType: ContractType): boolean {
  return contractType === ContractType.TRANSIENT;
}
