<dm-ui-progress-date
  *ngIf="showingProgressDate$ | async"
  [from]="booking.fromDate"
  [to]="booking.toDate"
  [nowDate]="marinaDate$ | async"
/>

<dm-ui-countdown-date
  *ngIf="showingCountdown$ | async"
  [timeToCount]="fromDate"
  [nowDate]="marinaDate$ | async"
/>
