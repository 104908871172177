import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@dm-workspace/utils';

@Pipe({
  name: 'changeTimezone',
})
export class ChangeTimezonePipe implements PipeTransform {
  public transform(date: string | number | Date, timezone: string): Date {
    return DateUtils.changeTimezone(date, timezone);
  }
}
