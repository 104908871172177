import { inject, Injectable, signal } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter } from 'rxjs/operators';
import { toObservable } from '@angular/core/rxjs-interop';

export enum Breakpoint {
  MOBILE,
  TABLET,
  DESKTOP,
}

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  #breakpointMap = new Map([
    ['(max-width: 767px)', Breakpoint.MOBILE],
    ['(min-width: 768px) and (max-width: 991px)', Breakpoint.TABLET],
    ['(min-width: 992px)', Breakpoint.DESKTOP],
  ]);
  #breakpoint = signal<Breakpoint>(null);
  #breakpointObserver = inject(BreakpointObserver);
  readonly breakpoint = this.#breakpoint.asReadonly();
  readonly breakpoint$ = toObservable(this.#breakpoint);

  constructor() {
    this.#breakpointMap.forEach(this.#watchBreakpointMap.bind(this));
  }

  #watchBreakpointMap(breakpointKey: Breakpoint, mediaQuery: string) {
    this.#breakpointObserver
      .observe([mediaQuery])
      .pipe(filter((state) => state.matches))
      .subscribe(() => {
        this.#breakpoint.set(breakpointKey);
      });
  }
}
