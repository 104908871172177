import { Pipe, PipeTransform } from '@angular/core';
import { ContactType } from '@dm-workspace/types';

@Pipe({
  name: 'contactTypeTranslateKey',
})
export class ContactTypeTranslateKeyPipe implements PipeTransform {
  public transform(contactType: ContactType | undefined): string {
    if (!contactType) {
      return '';
    }

    const [contactTypeKey] = Object.entries(ContactType).find(([key, value]) => value === contactType);
    return `CONTACT_TYPE_.${contactTypeKey}`;
  }
}
