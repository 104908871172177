import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  enterLeaveFromBottomAnimation,
  enterLeaveFromTopAnimation,
  enterLeaveOpacityAnimation,
} from '@dm-workspace/shared';
import { DOCUMENT, Location } from '@angular/common';
import { NavigationExtrasState } from '../../../../../../types/angular';

const fullPageBodyClass = 'full-page';

/**
 * @deprecated use MmsUiFullPageComponent
 */
@Component({
  selector: 'dm-ui-full-page',
  templateUrl: './full-page.component.html',
  styleUrls: ['./full-page.component.scss'],
  animations: [enterLeaveFromTopAnimation, enterLeaveFromBottomAnimation, enterLeaveOpacityAnimation],
})
export class FullPageComponent implements OnInit, OnDestroy {
  @Input() hideFooter = false;
  @Input() hideClose = false;
  @Input() heightStatic = false;
  @Input() returnUrl: string;

  @HostBinding('class.height-static')
  stateReturnUrl: string;
  backOnClose: boolean;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) {
    const currentNav = this.router.getCurrentNavigation();
    const { returnUrl, backOnClose } =
      currentNav?.extras?.state || (this.location.getState() as NavigationExtrasState) || {};
    this.stateReturnUrl = returnUrl || '';
    this.backOnClose = backOnClose;
  }

  ngOnInit() {
    this.renderer.addClass(this.document.body, fullPageBodyClass);
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, fullPageBodyClass);
  }

  onCloseClick(): void {
    if (this.backOnClose) {
      this.location.back();
    } else {
      this.router.navigate([this.stateReturnUrl || this.returnUrl || '../'], { relativeTo: this.activatedRoute });
    }
  }
}
