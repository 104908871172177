import { trigger, transition, style, animate } from '@angular/animations';
import { CUBIC } from '../constants/animation-timings';

export const enterLeaveFromTopAnimation = trigger('enterLeaveFromTop', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-100%)',
    }),
    animate(`.6s ${CUBIC}`),
  ]),
  transition(':leave', [
    animate(
      `.6s ${CUBIC}`,
      style({
        opacity: 0,
        transform: 'translateY(-100%)',
      })
    ),
  ]),
]);
