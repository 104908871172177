import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { StickyElementService } from '../sticky-element/sticky-element.service';

@Component({
  selector: 'dm-ui-animated-fixed-header',
  templateUrl: './animated-fixed-header.component.html',
  styleUrls: ['./animated-fixed-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimatedFixedHeaderComponent implements AfterViewInit, OnChanges {
  @Input() showHiddenContent = true;
  @Input() offsetTop = 85;

  constructor(private stickyService: StickyElementService) {}

  animationStart($event: number): void {
    this.updateHeaderHeight();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 100);
  }

  ngOnChanges(): void {
    this.ngAfterViewInit();
  }

  updateHeaderHeight(): void {
    this.stickyService.refreshOffsets();
  }
}
