import { Component, computed, input, Input } from '@angular/core';
import { AllTerms, TermsForm } from '@dm-workspace/types';

@Component({
  selector: 'dm-ncp-agreements-form',
  templateUrl: './ncp-agreements-form.component.html',
  styleUrls: ['./ncp-agreements-form.component.scss'],
})
export class NcpAgreementsFormComponent {
  @Input() terms: AllTerms;
  form = input.required<TermsForm>();
  @Input() isDirty: boolean;
  showForm = computed(() => this.form() && Object.keys(this.form().controls).length);
}
