import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { IResourceBookingPaymentStatusResponse } from '@dm-workspace/types';
import { forkJoin, mergeMap, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MmsPaymentsApiService } from '@dm-workspace/data-access';

@Component({
  selector: 'dm-ui-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentListComponent {
  @Input() set bookings(bookings: string[]) {
    this.pending = true;
    this.payments$ = forkJoin(
      bookings.map((booking) => this.paymentApi.payments({ referenceId: booking }).pipe(mergeMap((res) => res.values)))
    ).pipe(
      finalize(() => {
        this.pending = false;
        this.cd.markForCheck();
      })
    );
  }

  public pending = false;
  public payments$: Observable<IResourceBookingPaymentStatusResponse[]>;

  constructor(private paymentApi: MmsPaymentsApiService, private cd: ChangeDetectorRef) {}
}
