import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  finalize,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import * as Sentry from '@sentry/angular';
import {
  APP_TOKEN,
  AppToken,
  IAuthChangePassword,
  IPreferences,
  IUserSingle,
  LOCALIZATION_LANGUAGES,
  UpdatePersonalInfoDto,
  UserMeSse,
  UserRoleName,
} from '@dm-workspace/types';
import { UsersApiService } from '@dm-workspace/data-access';
import { AuthService } from '../auth/auth.service';
import { TranslateCoreService } from '../translate-core/translate-core.service';
import { filter } from 'rxjs/operators';
import { DeepPartial } from '@dm-workspace/utils';
import { SseService } from './sse.service';

export const USER_SSE_URL = '/users/me/sse';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private loginSubscription!: Subscription;
  private userSubject: BehaviorSubject<IUserSingle | null> = new BehaviorSubject<IUserSingle | null>(null);
  private loadingUserSubject = new BehaviorSubject<boolean>(false);

  get user$(): Observable<IUserSingle | null> {
    return this.userSubject.asObservable().pipe(distinctUntilChanged());
  }

  get userSnapshot(): IUserSingle | null {
    return this.userSubject.getValue();
  }

  get loggedUser$(): Observable<IUserSingle | null> {
    return this.userSubject.asObservable().pipe(distinctUntilChanged(), filter(Boolean));
  }

  get loadingUser$(): Observable<boolean> {
    return this.loadingUserSubject.asObservable();
  }

  constructor(
    private authService: AuthService,
    private usersApiService: UsersApiService,
    private translateCoreService: TranslateCoreService,
    @Inject(APP_TOKEN) private appToken: AppToken,
    private sseService: SseService
  ) {
    this.addLoginSubscription();
  }

  public ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
    this.setUser(null);
    this.setSentryUser(null);
  }

  private addLoginSubscription() {
    this.loginSubscription = this.authService.loggedIn$
      .pipe(
        switchMap((isLogged) => {
          this.loadingUserSubject.next(true);
          return isLogged
            ? this.usersApiService.me().pipe(
                finalize(() => this.loadingUserSubject.next(false)),
                catchError((res) => {
                  this.authService.logout(true);
                  return throwError(res);
                })
              )
            : of(null);
        })
      )
      .subscribe((user) => {
        this.setUser(user);
        this.setSentryUser(user);
      });
  }

  private setUser(user: IUserSingle | null) {
    switch (this.appToken) {
      case AppToken.customerPanel:
        this.changeAppLanguage(this.translateCoreService.startLocale());
        break;
      case AppToken.mmsPanel:
        this.changeAppLanguage(user?.preferredLanguage);
    }
    this.userSubject.next(user);
  }

  private changeAppLanguage(language: LOCALIZATION_LANGUAGES = LOCALIZATION_LANGUAGES.ENGLISH) {
    this.translateCoreService.changeLng(language);
  }

  private setSentryUser(user: IUserSingle | null): void {
    Sentry.setUser({
      email: user?.email,
      id: user?.id,
    });
  }

  public changePassword(dto: IAuthChangePassword) {
    return this.usersApiService.changePassword(dto);
  }

  public updatePersonalInfoOnboarding(dto: UpdatePersonalInfoDto) {
    return this.usersApiService.uploadOnboardingPersonalInfo(dto).pipe(tap((user) => this.setUser(user)));
  }

  public updatePersonalInfo(dto: UpdatePersonalInfoDto) {
    return this.usersApiService.updatePersonalInfo(dto).pipe(tap((user) => this.setUser(user)));
  }

  public updatePreferences(dto: DeepPartial<IPreferences>) {
    return this.usersApiService.updatePreferences(dto).pipe(tap((user) => this.setUser(user)));
  }

  public getUserSse(openWhenHidden?: boolean): Observable<UserMeSse> {
    return this.sseService.connect<UserMeSse>(USER_SSE_URL, openWhenHidden);
  }

  public closeUserSse(): void {
    return this.sseService.close(USER_SSE_URL);
  }

  hasRole(role: UserRoleName) {
    return this.userSubject.getValue()?.role.name === role;
  }
}
