<menu>
  <dm-form-radio-group class="row mx-0">
    @for (provider of providers; track provider) {
      <dm-form-radio-card
        [name]="provider"
        [value]="provider"
        (click)="selectProvider.emit(provider)"
        class="col-12 col-md-3"
      >
        <div class="d-flex justify-content-start align-items-center flex-md-column">
          <span [class]="provider | paymentTypeIconPipe" class="payment__icon"></span>
          <p>{{ "PAYMENT_METHOD." + provider.toUpperCase() | translate }}</p>
        </div>
      </dm-form-radio-card>
    }
  </dm-form-radio-group>
</menu>
