import { inject, Injectable } from '@angular/core';
import { PylonSocket, ObPylonParams, PylonSocketSwitchStateBody } from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { ApiHttpClient, ApiService } from '../api/api-http-client';

@Injectable({
  providedIn: 'root',
})
export class ObPylonsApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl('/online-booking/pylons');

  getPylon(pylonId: string, params: ObPylonParams): Observable<PylonSocket[]> {
    return this.apiHttpClient.get<PylonSocket[]>(`${this.resourceUrl}/${pylonId}`, { params });
  }

  switchSocketState(pylonId: string, channel: string, body: PylonSocketSwitchStateBody): Observable<void> {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/${pylonId}/outputs/${channel}/switch-state`, body);
  }
}
