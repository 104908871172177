<h5>{{ label }}</h5>
<p class="content"><ng-content /></p>
<p>
  <ng-container *ngIf="link && text; else textTemplate">
    <a class="link link--color-primary" [routerLink]="link" [state]="{ backOnClose: true }">{{ text }}</a>
  </ng-container>
</p>

<ng-template #textTemplate>
  {{ text | empty }}
</ng-template>
