import { Component, Input } from '@angular/core';
import { CmsContent } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-cms-child',
  templateUrl: './ob-cms-child.component.html',
  styleUrls: ['./ob-cms-child.component.scss'],
})
export class ObCmsChildComponent {
  @Input() public data: CmsContent[];
}
