import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api-client.service';
import { Observable } from 'rxjs';
import { IResource, IResourceForBoatQueryParams, IResourcesForBoatRs, MARINA_URL_TOKEN } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class ResourcesApiService {
  resourceUrl = `/mms/marinas/${MARINA_URL_TOKEN}/resource`;

  constructor(private apiClientService: ApiClientService) {}

  fetchById(id: string): Observable<IResource> {
    return this.apiClientService.get<IResource>(`${this.resourceUrl}/${id}`);
  }

  forBerth(berthId: string): Observable<IResource[]> {
    return this.apiClientService.get<IResource[]>(`${this.resourceUrl}/for-berth/${berthId}`);
  }

  forBoat(boatId: string, params: IResourceForBoatQueryParams): Observable<IResourcesForBoatRs> {
    return this.apiClientService.get<IResourcesForBoatRs>(`${this.resourceUrl}/for-boat/${boatId}`, {
      params,
    });
  }
}
