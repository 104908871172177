import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  ApiErrorObject,
  ApiErrorResponse,
  ApiErrorValidationResponse,
  DmarinError,
  DmarinErrorCodes,
  DmarinFailedValidationInformation,
  DmarinFailedValidationSpecificError,
  DmarinValidationError,
} from '@dm-workspace/types';
import { HttpErrorResponse } from '@angular/common/http';
import { changeFormUpdateStrategy, mergeDeep } from '@dm-workspace/utils';

/**
 * @deprecated use ApiValidator instead
 */
@Injectable({
  providedIn: 'root',
})
export class ApiValidatorService {
  /**
   * @deprecated use FormService instead
   */
  public formIsValid(form: AbstractControl, mark = true): boolean {
    if (form.valid) {
      return form.valid;
    }

    if (form instanceof UntypedFormGroup || form instanceof UntypedFormArray) {
      this.checkValid(form, mark);
    }

    mark && form.markAsDirty();
  }

  /**
   * @deprecated use FormService instead
   */
  public parseApiValidationErrorsToObject<T extends object>(validationErrors: DmarinError): ApiErrorObject<T> {
    if (!validationErrors?.details || validationErrors?.errorCode !== DmarinErrorCodes.VALIDATION_FAILED) {
      return;
    }

    return this.transformErrorResponseToValidationError<T>(validationErrors as DmarinValidationError);
  }

  /**
   * @deprecated use FormService instead
   */
  parseServerSideErrors(
    form: UntypedFormGroup | UntypedFormArray | FormGroup,
    errors: DmarinFailedValidationInformation[]
  ) {
    if (!errors) {
      return;
    }

    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        const element = errors[key];
        const { propertyName } = element;
        if (!element?.errors) {
          return;
        }
        const errorMessage = element.errors.reduce((acc, v) => acc + v.message + '\n', '');
        const control = form.get(propertyName);
        if (control) {
          this.setError(form, propertyName, errorMessage);
          control.markAsDirty();
        }
      }
    }
  }

  /**
   * @deprecated use FormService instead
   */
  emptyStringToNull<T>(object: T): T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObject: Record<string, any> = {};
    for (const k in object) {
      newObject[k] =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (object as any)[k] === ''
          ? null
          : typeof object[k] === 'object' && object[k] !== null
            ? this.emptyStringToNull(object[k])
            : object[k];
    }
    return newObject as T;
  }

  /**
   * @deprecated use FormService instead
   */
  public getControlsPath(form: FormGroup, predicate: (value: FormControl | UntypedFormControl) => boolean) {
    const output: Array<string> = [];

    function loop(form: FormGroup, prefix = '') {
      for (const key of Object.keys(form.controls)) {
        const control = form.get(key);
        if (control instanceof FormControl || control instanceof UntypedFormControl) {
          if (predicate(control)) {
            output.push(`${prefix}${key}`);
          }
        } else if (control instanceof FormGroup || control instanceof UntypedFormGroup) {
          loop(control, `${prefix}${key}.`);
        }
      }
    }
    loop(form);

    return output;
  }

  public setApiValidationErrors(form: FormGroup, res: HttpErrorResponse) {
    if (!this.isApiValidationErrorResponse(res)) {
      return;
    }

    for (const controlErrorValidation of res.error.details) {
      const { propertyName } = controlErrorValidation;
      const control = form.get(propertyName);
      const errorMessage = this.getErrorValidationMessage(controlErrorValidation.errors);
      if (!control || !errorMessage) {
        return;
      }

      control.setErrors({
        server: errorMessage,
      });
      control.markAsDirty();
    }
  }

  public getApiNotificationError(res: HttpErrorResponse): string {
    if (this.isApiErrorResponse(res)) {
      const { errorCode, detailedErrorCode } = res.error;
      return 'ERRORS.' + (detailedErrorCode || errorCode);
    }
    return res.message ?? 'ERRORS.UNKNOWN_ERROR';
  }

  public isApiValidationErrorResponse(res: HttpErrorResponse): res is ApiErrorValidationResponse {
    return this.isApiErrorResponse(res) && res.error.errorCode === DmarinErrorCodes.VALIDATION_FAILED;
  }

  /**
   * @deprecated use FormService instead
   */
  private checkValid(form: UntypedFormGroup | UntypedFormArray, mark = true): void {
    for (const key of Object.keys(form.controls)) {
      const control = form.get(key);
      if (!control && control.valid) {
        return;
      }

      if (mark) {
        control.markAsDirty();
      }

      if (control instanceof UntypedFormControl) {
        changeFormUpdateStrategy(control, 'change');
      }

      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.checkValid(control as UntypedFormGroup);
      }
    }
  }

  /**
   * @deprecated use FormService instead
   */
  private transformErrorResponseToValidationError<T extends object>(
    errorRes: DmarinValidationError
  ): ApiErrorObject<T> {
    let errorsObj = {};
    const errors = errorRes.details;
    errors.forEach((error) => {
      const obj = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error.propertyName?.split('.').reduce((p: any, c, index, array) => {
        p[c] = array.length - 1 === index ? error.errors : {};
        return p[c];
      }, obj);
      errorsObj = mergeDeep(errorsObj, obj);
    });
    return errorsObj as ApiErrorObject<T>;
  }

  /**
   * @deprecated use FormService instead
   */
  private setError(parent: AbstractControl, key: string, errorMessage: string): void {
    const field = parent.get(key);

    if (!field) {
      return;
    }

    if (errorMessage) {
      const { errors = {} } = field;
      field.setErrors({
        ...errors,
        server: errorMessage,
      });
    }
  }

  private getErrorValidationMessage(value: DmarinFailedValidationSpecificError[]): string {
    return value.reduce((acc, v) => acc + v.message + '\n', '');
  }

  /**
   * @deprecated use FormUtils.isApiErrorResponse instead
   */
  private isApiErrorResponse(res: HttpErrorResponse): res is ApiErrorResponse {
    return !!(res.error as DmarinError | undefined)?.errorCode;
  }
}
