import { trigger, animate, style, transition } from '@angular/animations';

export const circleSpinnerShowTrigger = trigger('circleSpinnerShow', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'scale(.5)',
    }),
    animate('0.35s cubic-bezier(.25, .8, .25, 1)'),
  ]),
  transition(
    ':leave',
    animate(
      '0.35s cubic-bezier(.25, .8, .25, 1)',
      style({
        opacity: 0,
        transform: 'scale(.5)',
      })
    )
  ),
]);
