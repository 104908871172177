import { Pipe, PipeTransform } from '@angular/core';

import { GlobalTermsTypes, MarinaTermsTypes } from '@dm-workspace/types';

@Pipe({
  name: 'ncpAgreementsRequiredText',
})
export class NcpAgreementsLinkRequiredPipe implements PipeTransform {
  transform(type: MarinaTermsTypes | GlobalTermsTypes | string): string {
    switch (type) {
      case MarinaTermsTypes.vexationClauses:
      case MarinaTermsTypes.vexationClausesRenewals:
        return 'ACCEPTANCE_OF_THE_VEXATION_CLAUSES';
      case MarinaTermsTypes.servicesRenewals:
      case MarinaTermsTypes.services:
        return 'ACCEPTANCE_OF_THE_SERVICE_TERMS';
      case GlobalTermsTypes.genericPrivacyPolicy:
      case MarinaTermsTypes.privacyPolicy:
        return 'ACCEPTANCE_OF_THE_PRIVACY_POLICY';
      default:
        return `ACCEPTANCE_OF_THE_${type.toUpperCase()}_TERMS`;
    }
  }
}
