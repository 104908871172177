import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericObject } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class FiltersHelperService {
  private readonly storage: { [key: string]: string } = {};

  constructor(
    private readonly aRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  public addToSearchParams(queryParams: Record<string, string>) {
    const { origin, pathname } = location;
    const url = new URL(`${origin}${pathname}`);
    const params: Record<string, string> = {};
    Object.keys(queryParams).forEach((key) => {
      const paramValue = JSON.stringify(queryParams[key]);
      url.searchParams.set(key, paramValue);
      params[key] = paramValue;
    });
    history.replaceState(null, '', url.toString());
    params['page'] = this.urlParams()['page'] as string;
    this.router.navigate([], {
      queryParams: params,
    });
  }

  public getParamsFromQuery<V extends Record<string, unknown>>(): V {
    const params: Record<string, unknown> = {};
    const { searchParams } = new URL(location.href);
    for (const [key, value] of searchParams.entries()) {
      params[key] = JSON.parse(value);
    }
    return params as V;
  }

  public urlParams(): Record<string, unknown> {
    const params: Record<string, string> = {};
    Object.keys(this.aRoute.snapshot.queryParams).forEach((key) => {
      const queryParamValue = this.aRoute.snapshot.queryParams[key];
      try {
        params[key] = JSON.parse(queryParamValue);
      } catch {
        params[key] = queryParamValue;
      }
    });
    return params;
  }

  public loadFromStorage(key: string): GenericObject | false {
    const value = this.storage[key];

    if (!value) {
      return;
    }

    return JSON.parse(value);
  }

  public saveToStorage(key: string, value: unknown) {
    this.storage[key] = JSON.stringify(value);
  }
}
