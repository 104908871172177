import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'dm-ui-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * @deprecated
 * TODO: remove because need to pass ngbTooltip properties from it
 * use BadgeIconComponent
 */
export class IconTooltipComponent {
  @Input() icon: string;
  @Input() triggers = 'hover focus';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() tooltipText: string | TemplateRef<any> | null | undefined;
  @Input() color: 'primary' | 'secondary';
  @Input() container?: 'body';
  @Input() autoClose: boolean | 'inside' | 'outside' = true;
}
