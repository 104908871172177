import { Component, Input } from '@angular/core';
import { GetMarinaTermsDto } from '@dm-workspace/types';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'dm-ob-quote-marina-agreements-form',
  templateUrl: './ob-quote-marina-agreements-form.component.html',
  styleUrls: ['./ob-quote-marina-agreements-form.component.scss'],
})
export class ObQuoteMarinaAgreementsFormComponent {
  @Input({ required: true }) form: FormGroup;
  @Input({ required: true }) terms: GetMarinaTermsDto;
}
