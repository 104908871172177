import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactType, CountryCode } from '@dm-workspace/types';
import { ProfileDetailsForm, ProfileDetailsFormService } from '@dm-workspace/shared';
import { concatForms } from '@dm-workspace/utils';

export type ContactForm = FormGroup<
  ProfileDetailsForm['controls'] & {
    type: FormControl<ContactType>;
    email: FormControl<string>;
  }
>;

export type ContactFormValue = ReturnType<ContactForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  constructor(private profileDetailsFormService: ProfileDetailsFormService) {}

  public createForm(
    initValue?: Partial<ContactFormValue>,
    options?: {
      countryCode?: CountryCode;
      marinaCode?: CountryCode;
    }
  ): ContactForm {
    const profileDetailsForm = this.profileDetailsFormService.createForm(initValue, options);
    const emailControl = this.profileDetailsFormService.createEmailControl(initValue?.email);
    const form = new FormGroup({
      type: new FormControl(initValue?.type, { validators: [Validators.required], updateOn: 'blur' }),
      email: emailControl,
    });

    const combinedForms = concatForms<ContactForm>(form, profileDetailsForm);
    return combinedForms;
  }
}
