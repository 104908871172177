<ng-container [formGroup]="form">
  <dm-form-group class="theme-light mb-3" *ngIf="form.controls.servicesRenewals">
    <input type="checkbox" dmForm formControlName="servicesRenewals" id="servicesRenewals" />
    <label for="servicesRenewals">
      <span class="required">*</span> {{ "I_AGREE_WITH" | translate }}
      <a class="link" [href]="terms?.servicesRenewals?.url" target="_blank">{{
        "TERMS_CONDITIONS_OF_SERVICES" | translate
      }}</a>
    </label>
    <dm-form-error-message [control]="form.controls.servicesRenewals" required="THE_AGREEMENT_TWO_IS_REQUIRED" />
  </dm-form-group>

  <dm-form-group class="theme-light mb-3" *ngIf="form.controls.vexationClausesRenewals">
    <input type="checkbox" dmForm formControlName="vexationClausesRenewals" id="vexationClausesRenewals" />
    <label for="vexationClausesRenewals">
      <span class="required">*</span> {{ "I_AGREE_WITH" | translate }}
      <a class="link" [href]="terms?.vexationClausesRenewals?.url" target="_blank">{{
        "VEXATION_CLAUSES" | translate
      }}</a>
    </label>
    <dm-form-error-message [control]="form.controls.vexationClausesRenewals" />
  </dm-form-group>

  <dm-form-group class="theme-light mb-3" *ngIf="form.controls.withdrawalTermRenewals">
    <input type="checkbox" dmForm formControlName="withdrawalTermRenewals" id="agreementThree" />
    <label for="agreementThree">
      <span class="required">*</span> {{ "I_AGREE_WITH" | translate }}
      <a class="link" [href]="terms?.withdrawalTermRenewals?.url" target="_blank">{{
        "WITHDRAWAL_TERM" | translate
      }}</a>
    </label>
    <dm-form-error-message [control]="form.controls.withdrawalTermRenewals" />
  </dm-form-group>
</ng-container>
