import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { diffDatesInDays } from '@dm-workspace/utils';
import { isSameYear } from 'date-fns';

@Component({
  selector: 'dm-ncp-period',
  template: `@if (withText()) {
      {{ 'PERIOD' | translate }}:
    }
    <strong [style.fontWeight]="fontWeight()"
      >{{ fromDate() | dateFormat: (isSameYear() ? 'd.MM' : 'date') }} - {{ toDate() | dateFormat }}
      @if (withText()) {
        ({{ daysDiff() }} {{ (daysDiff() > 1 ? 'NIGHTS' : 'NIGHT') | translate }})
      }
    </strong>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpPeriodComponent {
  withText = input(true);
  fontWeight = input(600);
  fromDate = input.required<string>();
  toDate = input.required<string>();
  daysDiff = computed(() => diffDatesInDays(this.fromDate(), this.toDate()));
  isSameYear = computed(() => isSameYear(new Date(this.fromDate()), new Date(this.toDate())));
}
