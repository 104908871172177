import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, HostListener, input } from '@angular/core';
import { CheckboxButtonClass } from '../checkbox-group/checkbox-button-base-class.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dm-form-checkbox-new',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CheckboxButtonClass, useExisting: forwardRef(() => CheckboxNewComponent) },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxNewComponent), multi: true },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'isDisabled()',
    '[class.selected]': 'checked()',
  },
})
export class CheckboxNewComponent extends CheckboxButtonClass {
  @HostListener('click')
  override onModelChange() {
    super.onModelChange();
  }
  @HostBinding('class.checkbox-button')
  addClass = false;
  label = input.required<string>();
}
