import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IResourceBooking, QuotationStatus } from '@dm-workspace/types';
import { defer, map, shareReplay } from 'rxjs';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-ui-booking-progress',
  templateUrl: './booking-progress.component.html',
  styleUrls: ['./booking-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingProgressComponent implements OnInit {
  @Input() public booking: IResourceBooking;

  public readonly marinaDate$ = defer(() => {
    return this.marinasService.getMarinaDateByCode(this.booking.marinaCode);
  }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  public fromDate: number;
  public toDate: number;

  public showingProgressDate$ = this.marinaDate$.pipe(
    map((marinaDate) => {
      const marinaTime = marinaDate.getTime();
      const isInProgress = this.booking.quotationStatus === QuotationStatus.IN_PROGRESS;
      return isInProgress || (marinaTime >= this.fromDate && marinaTime <= this.toDate);
    })
  );

  public showingCountdown$ = this.marinaDate$.pipe(
    map((marinaDate) => {
      const marinaTime = marinaDate.getTime();
      const allowedStatuses = [
        QuotationStatus.CONFIRMED,
        QuotationStatus.CHECK_IN,
        QuotationStatus.CHECKED_IN,
        QuotationStatus.CHECK_IN_REJECTED,
      ];
      const isAllowed = allowedStatuses.includes(this.booking.quotationStatus);
      return marinaTime < this.fromDate && isAllowed;
    })
  );

  constructor(private marinasService: MarinasService) {}

  public ngOnInit() {
    const fromDate = new Date(this.booking.fromDate);
    const toDate = new Date(this.booking.toDate);
    fromDate.setHours(14, 0, 0, 0);
    toDate.setHours(12, 0, 0, 0);
    this.fromDate = fromDate.getTime();
    this.toDate = toDate.getTime();
  }
}
