import { Directive, OnInit, inject, DestroyRef, Host } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Location } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MarinasService } from '@dm-workspace/core';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink][returnUrlCurrentPath], [selectedMarinaRouterLink][returnUrlCurrentPath]',
})
export class RouterLinkReturnUrlCurrentPathDirective implements OnInit {
  #destroyRef = inject(DestroyRef);
  #location = inject(Location);
  #activatedRoute = inject(ActivatedRoute);
  #marinaService = inject(MarinasService);
  constructor(@Host() private routerLink: RouterLink) {}

  ngOnInit(): void {
    if (!this.routerLink) {
      return;
    }
    if (!this.routerLink.state) {
      this.routerLink.state = {};
    }
    merge(this.#activatedRoute.queryParams, this.#marinaService.selectedMarinaChange$)
      .pipe(takeUntilDestroyed(this.#destroyRef), debounceTime(100))
      .subscribe((res) => {
        this.routerLink.state['returnUrl'] = this.#location.path();
      });
  }
}
