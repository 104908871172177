import { inject, Injectable } from '@angular/core';
import { IOrder, MARINA_URL_TOKEN, OrderPaymentStatus } from '@dm-workspace/types';
import { ApiCrudService, ApiHttpClient, ResourceUrlOptions, ApiService } from '../api/api-http-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MmsOrdersApiService extends ApiService implements ApiCrudService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/mms/marinas/${MARINA_URL_TOKEN}/order`);

  public getById(orderId: string, options?: ResourceUrlOptions) {
    const resourceUrl = this.modifyResourceUrl(options);
    return this.apiHttpClient.get<IOrder>(`${resourceUrl}/${orderId}`);
  }

  public sendPaymentReminder(orderId: string, options?: ResourceUrlOptions) {
    const resourceUrl = this.modifyResourceUrl(options);
    return this.apiHttpClient.post<void>(`${resourceUrl}/${orderId}/send-payment-reminder`, null);
  }

  public setPaymentStatus(
    orderId: string,
    paymentStatus: OrderPaymentStatus,
    options?: ResourceUrlOptions
  ): Observable<void> {
    const resourceUrl = this.modifyResourceUrl(options);
    return this.apiHttpClient.post<void>(`${resourceUrl}/${orderId}/set-payment-status`, {
      paymentStatus,
    });
  }
}
