import { Component } from '@angular/core';

@Component({
  selector: 'dm-ui-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss'],
})
export class PrintButtonComponent {
  public print(): void {
    window.print();
  }
}
