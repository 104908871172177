import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  /**
   * @deprecated Use `[header]` content projector instead
   */
  @Input() public header: string;
  @HostBinding('class.show-close-modal')
  @Input()
  public showCloseModal = this.modalConfig.backdrop === true;

  constructor(private activeModal: NgbActiveModal, private modalConfig: NgbModalConfig) {}

  public close() {
    this.activeModal.dismiss();
  }
}
