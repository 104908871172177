import { AbstractControl, ValidatorFn } from '@angular/forms';

export interface IMinSpacialCharactersOrNumbersValidator {
  actualCount: number;
  requiredCount: number;
}

export function minSpecialCharactersOrNumbersValidator(requiredCount: 1): ValidatorFn {
  return (
    control: AbstractControl
  ): {
    minSpacialCharactersOrNumbers: IMinSpacialCharactersOrNumbersValidator;
  } => {
    const value = control.value as string;

    const actualCount = value.match(/\W|_|\d/g)?.length | 0;

    return actualCount >= requiredCount
      ? null
      : {
          minSpacialCharactersOrNumbers: {
            actualCount,
            requiredCount,
          },
        };
  };
}
