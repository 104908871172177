import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

export type ProfilePreferencesForm = FormGroup<{
  privacyPolicy: FormControl<boolean>;
  marketingContent: FormControl<boolean>;
  specialNeeds: FormGroup<{
    wheelchair: FormControl<boolean>;
    disabilityGolfCart: FormControl<boolean>;
  }>;
  mooringPreferences: FormGroup<{
    mooringAssistant: FormControl<boolean>;
    bertCloseTo: FormGroup<{
      sanitary: FormControl<boolean>;
      parking: FormControl<boolean>;
      office: FormControl<boolean>;
      restaurants: FormControl<boolean>;
      gasStation: FormControl<boolean>;
      exitOfMarina: FormControl<boolean>;
      other: FormControl<boolean>;
      otherMsg?: FormControl<string>;
    }>;
  }>;
}>;

export type ProfilePreferencesFormValue = ReturnType<ProfilePreferencesForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class ProfilePreferencesFormService {
  constructor(private fb: FormBuilder) {}

  public createForm(initValue?: Partial<ProfilePreferencesFormValue>): ProfilePreferencesForm {
    const form = this.fb.group({
      privacyPolicy: this.fb.control(initValue?.privacyPolicy || false),
      marketingContent: this.fb.control(initValue?.marketingContent || false),
      specialNeeds: this.createSpecialNeedsForm(initValue?.specialNeeds),
      mooringPreferences: this.creatMooringPreferencesForm(initValue?.mooringPreferences),
    });

    return form as ProfilePreferencesForm;
  }

  private createSpecialNeedsForm(initValue?: Partial<ProfilePreferencesFormValue['specialNeeds']>) {
    return this.fb.group({
      wheelchair: this.fb.control(initValue?.wheelchair || false),
      disabilityGolfCart: this.fb.control(initValue?.disabilityGolfCart || false),
    });
  }

  private creatMooringPreferencesForm(initValue?: Partial<ProfilePreferencesFormValue['mooringPreferences']>) {
    return this.fb.group({
      mooringAssistant: [initValue?.mooringAssistant || false],
      bertCloseTo: this.fb.group({
        sanitary: [initValue?.bertCloseTo?.sanitary || false],
        parking: [initValue?.bertCloseTo?.parking || false],
        office: [initValue?.bertCloseTo?.office || false],
        restaurants: [initValue?.bertCloseTo?.restaurants || false],
        gasStation: [initValue?.bertCloseTo?.gasStation || false],
        exitOfMarina: [initValue?.bertCloseTo?.exitOfMarina || false],
        other: [initValue?.bertCloseTo?.other || false],
        otherMsg: this.createOtherMsgControl(initValue?.bertCloseTo?.otherMsg),
      }),
    });
  }

  public createOtherMsgControl(initValue: string) {
    return this.fb.control(initValue);
  }
}
