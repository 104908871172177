import { ValidatorFn, AbstractControl } from '@angular/forms';

export function greaterThan(number: number): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value as number;
    return !isNaN(value) && value > number
      ? null
      : {
          greaterThan: {
            valid: false,
            min: number,
          },
        };
  };
}
