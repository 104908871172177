<div class="wrap">
  <div class="container">
    @if (showBackButton()) {
      @if (Breakpoint.TABLET | minBreakpoint | async) {
        <button type="button" class="back-link" (click)="goBack()">
          <p class="m-0 fw-700">{{ "BACK" | translate | uppercase }}</p>
        </button>
      }
    }
    <div class="logo-link">
      <a href="https://www.d-marin.com/"><span class="logo i-logo"></span></a>
    </div>
  </div>
</div>
