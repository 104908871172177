import { computed, effect, EventEmitter, inject, Injectable, signal } from '@angular/core';
import {
  BoatDimensionsWithType,
  BoatType,
  BoatTypeCustomer,
  cmsMapperBoatType,
  CMSSelectedMarina,
  ConsentDto,
  CountryCode,
  DateRange,
  LOCALIZATION_LANGUAGES,
  ObBookingProduct,
  ObCreateReservationDto,
  OnlineBookingSendToExternalProcessingCustomerDto,
  OnlineBookingSendToExternalProcessingDto,
} from '@dm-workspace/types';
import { EMPTY, finalize, merge, startWith, tap } from 'rxjs';
import { BookingHelperService } from '@dm-workspace/shared';
import { NcpBookingFormService } from './ncp-booking-form.service';
import { NcpBookingStepsRoute } from '../const/stpes-routes';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { checkDateRangeIsValidDate, DateUtils } from '@dm-workspace/utils';
import { differenceInDays, isAfter } from 'date-fns';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { NcpMarinasService } from '../../../../ncp-layout/src/lib/service/ncp-marinas.service';
import { NcpThankYouViewType } from '../../../../ncp-confirmation/src/lib/views/ncp-thank-you-view/ncp-thank-you-view.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NcpBookingFacade {
  #router = inject(Router);
  #selectedMarinaService = inject(NcpMarinasService);
  #bookingHelperService = inject(BookingHelperService);
  #translateService = inject(TranslateService);
  #bookingsForm = inject(NcpBookingFormService);
  stepsForms = this.#bookingsForm.bookingForm();
  selectedMarina = computed(() => this.#selectedMarinaService.selectedMarina() as CMSSelectedMarina);
  summaryForm = this.#bookingsForm.summaryForm();
  requestPending = signal(false);
  selectedUtilities = signal<ObBookingProduct[] | undefined>(undefined);
  utilities = signal<ObBookingProduct[]>([]);
  isAnnual = signal(false);
  showStepValidation = signal<boolean>(null);
  isCorporateUser = signal(false);
  noFreeBerths = signal(false);
  canPay = computed(
    () =>
      !this.isCorporateUser() &&
      !this.marinaIsExternal() &&
      !this.noFreeBerths() &&
      !this.isAnnual() &&
      !this.isMarinaWithShortBooking() &&
      !this.isAtTheTurnOfTheYear()
  );
  get totalPrice() {
    return (
      (this.resource?.pricingDetails?.sumPriceGross || 0) +
      (this.utilities() || []).reduce((p, c) => p + c.pricing.sumPriceGross, 0)
    );
  }
  showCorporate = computed(
    () => this.marinaIsExternal() || !!this.selectedMarina()?.marinaOB.additionalProperties.isNewEnquiryProcessEnabled
  );
  marinaHasUtilities = computed(
    () => this.selectedMarina()?.marinaOB.additionalServices?.some((value) => !value.isMandatory)
  );
  marinaIsExternal = computed(
    () => this.selectedMarina() && this.selectedMarina()?.marinaOB.isExternalProcessingEnabled
  );
  isAtTheTurnOfTheYear = signal(false);
  isMarinaWithShortBooking = signal(false);
  reservationDetails = signal<null | {
    orderId: string;
    checkId?: string;
  }>(null);
  goToNextStep = new EventEmitter();
  initUrl: string;
  marinaCoverUrl = this.#selectedMarinaService.marinaCoverUrl;
  isCompletedProcess: boolean = false;
  constructor() {
    this.setSubscriptionForBoatAndDate();
    this.setSubscriptionForCorporateUser();
    effect(() => this.setResourceRequiredValidator());
    effect(() => this.addCustomerFormValidators());
  }
  get dates() {
    return this.stepsForms.controls.dates.value;
  }
  get datesForm() {
    return this.stepsForms.controls.dates;
  }
  get boatDimension() {
    return this.stepsForms.controls.boat.getRawValue();
  }
  get boatDimensionForm() {
    return this.stepsForms.controls.boat;
  }
  get resource() {
    return this.stepsForms.controls.resource.getRawValue();
  }
  get resourceForm() {
    return this.stepsForms.controls.resource;
  }
  removeSelectedMarina() {
    this.#selectedMarinaService.selectedMarina.set(undefined);
  }
  changeStepValidation(show: boolean): void {
    this.showStepValidation.set(show);
  }

  fetchAndSelectMarinaById(id: string) {
    if (this.requestPending()) {
      return EMPTY;
    }
    this.requestPending.set(true);
    return this.#selectedMarinaService
      .fetchAndSelectMarina(id, this.#translateService.currentLang as LOCALIZATION_LANGUAGES)
      .pipe(
        finalize(() => this.requestPending.set(false)),
        tap(() => this.summaryForm.controls.customer.updateValueAndValidity()),
        tap(() => this.updateAllMarinaInfo())
      );
  }
  updateAllMarinaInfo() {
    this.updateAnnual();
    this.updateIsAtTheTurnOfTheYear();
    this.updateMarinaWithShortBooking();
  }
  updateAnnual() {
    if (this.dates) {
      this.isAnnual.set(this.#bookingHelperService.isLongTermNcP(this.dates));
    } else {
      this.isAnnual.set(false);
    }
  }
  parseUrl(steps: NcpBookingStepsRoute, queryParams?: unknown): UrlTree {
    return this.#router.createUrlTree(['/new', steps], { queryParams });
  }
  goToStep(steps: NcpBookingStepsRoute) {
    this.#router.navigate(['/new', steps]);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }
  nextStep() {
    this.goToNextStep.emit();
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }
  patchFormWithQueryParams(queryParams: Record<keyof Partial<DateRange & BoatDimensionsWithType>, string>) {
    const { toDate, fromDate, length, width, draft, type } = queryParams;
    const dates = { toDate, fromDate };
    if (
      checkDateRangeIsValidDate(dates) &&
      differenceInDays(new Date(dates.fromDate), new Date()) >= 0 &&
      DateUtils.getDateRangeDuration(dates) >= 1
    ) {
      this.datesForm.patchValue({
        toDate,
        fromDate,
      });
    }
    this.boatDimensionForm.patchValue({
      length: +length?.replace(/,/g, '.') || null,
      width: +width?.replace(/,/g, '.') || null,
      draft: +draft?.replace(/,/g, '.') || null,
      type: this.getBoatTypeFromUrl(type),
    });
  }
  getBoatTypeFromUrl(type: string): BoatTypeCustomer | undefined {
    if (!type) {
      return undefined;
    }
    const typeWithoutSlash = type.replace('/', '');
    if (cmsMapperBoatType[typeWithoutSlash]) {
      return cmsMapperBoatType[typeWithoutSlash];
    }
    if (Object.values(BoatTypeCustomer).includes(type as BoatTypeCustomer)) {
      return type as BoatTypeCustomer;
    }
    return undefined;
  }
  getUrlTreeForValidStep(queryParams: Params) {
    const marinaId = queryParams['marinaId'] || queryParams['marinaCode'];
    if (this.stepsForms.valid && marinaId) {
      return this.parseUrl(
        this.isAnnual() ||
          !this.marinaHasUtilities() ||
          !this.isAtTheTurnOfTheYear() ||
          !this.isMarinaWithShortBooking()
          ? NcpBookingStepsRoute.summary
          : NcpBookingStepsRoute.utilities,
        queryParams
      );
    } else if (this.boatDimensionForm.valid && this.datesForm.valid && marinaId) {
      this.boatDimensionForm.markAsTouched();
      this.boatDimensionForm.markAsDirty();
      return this.parseUrl(NcpBookingStepsRoute.berth, queryParams);
    } else if (this.datesForm.valid && marinaId) {
      return this.parseUrl(NcpBookingStepsRoute.boat, queryParams);
    }
    return this.parseUrl(NcpBookingStepsRoute.marina, queryParams);
  }

  patchFormAndGetUrlTreeForRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.initUrl === undefined) {
      this.initUrl = state.url;
    }
    this.patchFormWithQueryParams(
      route.queryParams as Record<keyof Partial<DateRange & BoatDimensionsWithType>, string>
    );
    this.setResourceRequiredValidator();
    return this.getUrlTreeForValidStep(route.queryParams);
  }

  setSubscriptionForBoatAndDate() {
    merge(this.getChangeBoatSubscription(), this.getChangeDateSubscription())
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.noFreeBerths.set(false);
        this.utilities.set([]);
        this.selectedUtilities.set(undefined);
        this.reservationDetails.set(null);
        this.resourceForm.reset();
      });
  }
  setSubscriptionForCorporateUser() {
    this.summaryForm.controls.customer.controls.isIndividual.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.isCorporateUser.set(!value);
      });
  }

  getChangeDateSubscription() {
    return this.datesForm.valueChanges.pipe(
      startWith(this.dates),
      tap((res) => {
        this.changeStepValidation(!res);
        this.updateAnnual();
        this.updateIsAtTheTurnOfTheYear();
        this.updateMarinaWithShortBooking();
      })
    );
  }
  updateIsAtTheTurnOfTheYear() {
    console.log(this.selectedMarina()?.marinaOB);
    this.isAtTheTurnOfTheYear.set(
      !this.isAnnual() &&
        this.selectedMarina()?.marinaOB &&
        this.dates &&
        isAfter(
          new Date(this.dates.toDate),
          new Date(this.selectedMarina().marinaOB.additionalProperties.bookingMaxDate)
        )
    );
  }
  updateMarinaWithShortBooking() {
    this.isMarinaWithShortBooking.set(
      !this.isAnnual() &&
        this.selectedMarina()?.marinaOB &&
        this.dates &&
        this.#bookingHelperService.isMarinaWithShortBooking(this.dates, this.selectedMarina()?.marinaOB.code)
    );
  }
  getChangeBoatSubscription() {
    return this.boatDimensionForm.valueChanges.pipe(
      startWith(this.boatDimension),
      tap((res) => {
        if (Object.values(res).filter((item) => item).length > 1) {
          this.changeStepValidation(!this.boatDimensionForm.valid);
        }
      })
    );
  }

  setResourceRequiredValidator() {
    if (
      this.isAnnual() ||
      this.noFreeBerths() ||
      this.marinaIsExternal() ||
      this.isAtTheTurnOfTheYear() ||
      this.isMarinaWithShortBooking()
    ) {
      this.resourceForm.setValidators([]);
    } else {
      this.resourceForm.setValidators(Validators.required);
    }
    this.resourceForm.updateValueAndValidity();
  }

  getReservationDto(consents: ConsentDto[]): ObCreateReservationDto {
    const { boat, dates, resource } = this.stepsForms.getRawValue();
    const { boat: boatDetails, channel, customer } = this.summaryForm.getRawValue();
    return {
      ...dates,
      createAccount: false,
      channel,
      consents,
      products: this.getProductsDto(),
      boat: {
        ...boat,
        ...boatDetails,
        type: boat.type as unknown as BoatType,
        brandId: boatDetails.brand.id,
      },
      comments: '',
      person: this.setCustomerCompanyParams({
        ...this.#bookingsForm.profileDetailsFormService.transformFormValueToPayload(customer),
        ...this.#bookingsForm.profileDocumentsFormService.transformFormValueToPayload(customer),
      }),
      resourceId: resource?.resource?.resourceId,
    };
  }
  getProductsDto() {
    return this.selectedUtilities()
      ? this.selectedUtilities()
          ?.filter((value) => !value.isMandatory)
          .map((value) => ({
            type: value.product,
            quantity: 1,
          }))
      : [];
  }
  setCustomerCompanyParams<T>(params: OnlineBookingSendToExternalProcessingCustomerDto): T {
    if (params.isIndividual) {
      delete params.companyName;
    } else {
      delete params.lastName;
      delete params.firstName;
    }
    return params as T;
  }
  getExternalDto(consents: ConsentDto[]): OnlineBookingSendToExternalProcessingDto {
    const { boat, dates } = this.stepsForms.getRawValue();
    const { boat: boatDetails, customer, channel } = this.summaryForm.getRawValue();
    return {
      ...dates,
      customer: this.setCustomerCompanyParams({
        ...this.#bookingsForm.profileDetailsFormService.transformFormValueToPayload(customer),
        ...this.#bookingsForm.profileDocumentsFormService.transformFormValueToPayload(customer),
      }),
      consents,
      channel,
      boat: {
        ...boat,
        ...boatDetails,
        brandId: boatDetails.brand.id,
        type: boat.type as unknown as BoatType,
      },
    };
  }
  addCustomerFormValidators() {
    if (this.summaryForm.controls.customer && this.selectedMarina()) {
      this.#bookingsForm.updateCustomerFormWhenMarinaChange(
        this.summaryForm.controls.customer,
        this.selectedMarina().marinaOB.countryCode as CountryCode
      );
    }
  }

  redirectToSuccessPage(type: NcpThankYouViewType) {
    this.#router.navigate(['/confirmation/thank-you', type]);
  }

  clearAll() {
    this.isCompletedProcess = false;
    this.resourceForm.reset();
    this.stepsForms.reset();
    this.reservationDetails.set(null);
    this.selectedUtilities.set(undefined);
    this.utilities.set([]);
    this.noFreeBerths.set(false);
  }
}
