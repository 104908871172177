import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { QuotePromotionalOffers } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-promotional-offer-tag',
  templateUrl: './promotional-offer-tag.component.html',
  styleUrls: ['./promotional-offer-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromotionalOfferTagComponent {
  offer = input.required<QuotePromotionalOffers>();
}
