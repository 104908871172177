import { animate, style, transition, trigger } from '@angular/animations';
import { CUBIC } from '../constants';

export const leaveHeightCollapseAnimation = trigger('leaveHeightCollapse', [
  transition(':leave', [
    animate(
      `.6s ${CUBIC}`,
      style({
        height: 0,
        opacity: 0,
      })
    ),
  ]),
]);
