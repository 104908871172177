import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { Ability } from '../backend';
import { Features } from '@dm-workspace/core';

export type FormControlConstructor<T> = [T, validatorOrOpts?, asyncValidator?];
export type validatorOrOpts = ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;
export type asyncValidator = AsyncValidatorFn | AsyncValidatorFn[] | null;

export type GroupConstructor<T = object> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in keyof Partial<T>]: FormControlConstructor<any>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface OptionLabelAndValue<T = any> {
  label: string;
  value: T;
  path?: string;
  isActive?: boolean;
  count?: number;
  permissions?: Ability[];
  hasWarning?: boolean;
  feature?: Features;
}

export type FormViewType = 'edit' | 'create' | 'duplicate';
