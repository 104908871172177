import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dm-ui-carrot',
  templateUrl: './carrot.component.html',
  styleUrls: ['./carrot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrotComponent {
  @HostBinding('class.is-rotated')
  @Input()
  rotate = false;
}
