import { trigger, transition, style, animate, group, query, keyframes } from '@angular/animations';
import { CUBIC } from '../constants';

export const fadeRouterAnimation = trigger('fadeRouterAnimation', [
  transition('* => void', []),
  transition('* => *', [
    group([
      query(
        '.router-wrapper > :enter, .router-wrapper > :leave',
        [
          style({
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
          }),
        ],
        { optional: true }
      ),
    ]),
    group([
      query(
        '.router-wrapper > :enter',
        [animate(`.45s ${CUBIC}`, keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1 })]))],
        { optional: true, limit: 1 }
      ),
      query(
        '.router-wrapper > :leave',
        [animate(`.45s ${CUBIC}`, keyframes([style({ opacity: 1 }), style({ opacity: 0 })]))],
        { optional: true, limit: 1 }
      ),
    ]),
  ]),
]);
