import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value as string;

    const regexp = new RegExp(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    return regexp.test(value)
      ? null
      : {
          email: {
            valid: false,
          },
        };
  };
}

export function emailLoginValidator(): ValidatorFn {
  return ({ value }: AbstractControl<string>) => {
    const regexp = new RegExp(
      /^(([^#<>()[\]\.,;:\s@"]+(\.[^#<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^#<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;#:\s@\"]{2,})(#([^#<>()[\]\.,;:\s@"]+(\.[^#<>()[\]\.,;:\s@\"]+)*)@(([^#<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;#:\s@\"]{2,}))?$/i
    );
    if (regexp.test(value)) {
      return null;
    }

    return {
      email: {
        valid: false,
      },
    };
  };
}
