<div class="calendar-range-datepicker-group">
  <ngb-datepicker
    #dp
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="2"
    [minDate]="minDate"
    [maxDate]="maxDateRange"
    [dayTemplate]="t"
    outsideDays="hidden"
    class="ngb-datepicker-custom"
  />
  <ng-template #t let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      [class.first]="isFirst(date)"
      [class.last]="isLast(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>
</div>
<div class="calendar-datepicker-group">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <div class="form-group mb-4">
        <label class="required">{{ "FROM" | translate }}</label>

        <div class="form-group-date">
          <input
            ngbDatepicker
            class="form-control form-group-date__control"
            type="text"
            #fromInput
            #fromDateInput="ngbDatepicker"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placeholder]="datePlaceholderFormat"
            [ngModel]="adapter.toModel(fromDate)"
            (dateSelect)="onMobileDateSelect($event, true)"
            (click)="fromDateInput.toggle()"
            (focus)="fromInput.blur()"
          />
          <i class="i i-calendar" (click)="fromDateInput.toggle()"></i>
        </div>
        <dm-form-error-message controlName="fromDate" required="UNTIL_DATE_IS_REQUIRED" />
      </div>
    </div>

    <div class="col-md-6 offset-md-3">
      <div class="form-group mb-4">
        <label class="required">{{ "UNTIL" | translate }}</label>

        <div class="form-group-date">
          <input
            ngbDatepicker
            type="text"
            autoClose="inside"
            class="form-control form-group-date__control"
            #toDateInput="ngbDatepicker"
            #toInput
            [minDate]="fromDate || undefined"
            [maxDate]="maxDateRange"
            [ngModel]="adapter.toModel(toDate)"
            [placeholder]="datePlaceholderFormat"
            [disabled]="!fromDate"
            (dateSelect)="onMobileDateSelect($event, false)"
            (click)="toDateInput.toggle()"
            (focus)="toInput.blur()"
          />
          <i class="i i-calendar" (click)="toDateInput.toggle()"></i>
        </div>
        <dm-form-error-message controlName="toDate" required="UNTIL_DATE_IS_REQUIRED" />
      </div>
    </div>
  </div>
</div>
