export const ReservationsSelect = [
  {
    label: 'Reservations',
    value: true,
  },
  {
    label: 'Availability',
    value: false,
  },
];

export const YesNoValues = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
