import { Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'dm-ui-card-header',
  styles: `
    @use 'variables' as *;
  :host(.has-icon) {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
  }
  i {
    font-size: 24px;
    margin-right: 16px;
    color: color('primary');
  }
  `,
  template: `<i [class]="icon" *ngIf="icon"></i><ng-content /> `,
})
export class CardHeaderComponent {
  @HostBinding('class')
  className = 'card-header';
  @HostBinding('class.has-icon')
  @Input()
  icon?: string;
}
