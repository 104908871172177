import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryCode } from '@dm-workspace/types';

export type ObMarinaAgreementsForm = FormGroup<{
  agreementOne?: FormControl<boolean>;
  agreementTwo: FormControl<boolean>;
  agreementIt?: FormControl<boolean>;
}>;
export type ObMarinaAgreementsFormValue = ReturnType<ObMarinaAgreementsForm['getRawValue']>;

export type ObAgreementsFormType = 'RENEWALS' | 'OFFER_RENEWALS' | 'BOOKING';

@Injectable({
  providedIn: 'root',
})
export class ObMarinaAgreementsFormService {
  create(
    initValue?: Partial<ObMarinaAgreementsFormValue>,
    options?: { countryCode?: CountryCode; agreementsFormType: ObAgreementsFormType }
  ): ObMarinaAgreementsForm {
    const form = new FormGroup({
      agreementTwo: new FormControl(false, [Validators.requiredTrue]),
    });
    this.#changeFormByCountryCode(form, options?.countryCode);
    this.#changeFormByAgreementsFormType(form, options?.agreementsFormType);

    return form;
  }

  #changeFormByAgreementsFormType(form: ObMarinaAgreementsForm, agreementsFormType?: ObAgreementsFormType) {
    if (agreementsFormType === 'OFFER_RENEWALS') {
      form.removeControl('agreementOne');
    } else {
      const control = new FormControl(false, [Validators.requiredTrue]);
      form.addControl('agreementOne', control);
    }
  }

  #changeFormByCountryCode(form: ObMarinaAgreementsForm, countryCode?: CountryCode) {
    if (countryCode === CountryCode.Italy) {
      const control = new FormControl(false, [Validators.requiredTrue]);
      form.addControl('agreementIt', control);
    } else {
      form.removeControl('agreementIt');
    }
  }
}
