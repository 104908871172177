import { ProfileDocumentsForm, ProfileDocumentsFormValue } from './profile-documents-form.service';
import { CountryCode } from '@dm-workspace/types';
import { FormControl, Validators } from '@angular/forms';
import { taxSpainValidator } from '@dm-workspace/forms';
import { CustomerUtils } from '../../../../utils/src/lib/customer.utils';

export class CustomertaxExemptNumberService {
  private defaultValidators = [Validators.minLength(5)];
  private spainValidators = [Validators.required, Validators.minLength(9), taxSpainValidator()];
  private turkeyValidators = [Validators.required, Validators.minLength(5)];

  public changeByCountryCode(
    form: ProfileDocumentsForm,
    countryCode: CountryCode,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    if (countryCode === CountryCode.Italy) {
      form.removeControl('taxExemptNumber');
      return;
    }
    const taxExemptNumberControl = new FormControl(initValue?.taxExemptNumber, { updateOn: 'blur' });
    form.addControl('taxExemptNumber', taxExemptNumberControl);

    const { taxExemptNumber } = form.controls;
    const validators = this.getValidatorsByCountryCode(countryCode);
    taxExemptNumber.setValidators(validators);
    taxExemptNumber.updateValueAndValidity();
  }

  private getValidatorsByCountryCode(countryCode: CountryCode) {
    switch (countryCode) {
      case CountryCode.Spain: {
        return this.spainValidators;
      }
      case CountryCode.Turkey: {
        return this.turkeyValidators;
      }
      default: {
        return this.defaultValidators;
      }
    }
  }

  public changeByCountryCodeAndMarinaCode(
    form: ProfileDocumentsForm,
    countryCode: CountryCode,
    marinaCode: CountryCode,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    if (CustomerUtils.isTaxNumberIdRequired(countryCode, marinaCode) || form.controls.isIndividual?.value === false) {
      const taxExemptNumberControl = new FormControl(initValue?.taxExemptNumber, {
        updateOn: 'blur',
      });
      form.addControl('taxExemptNumber', taxExemptNumberControl);
      const validators = this.getValidatorsByCountryCodeAndMarinaCode(countryCode, marinaCode);
      const { taxExemptNumber } = form.controls;
      taxExemptNumber.setValidators(validators);
      taxExemptNumber.updateValueAndValidity();
    } else {
      form.removeControl('taxExemptNumber');
    }
  }

  private getValidatorsByCountryCodeAndMarinaCode(countryCode: CountryCode, marinaCode?: CountryCode) {
    const turkishInTurkey = marinaCode === CountryCode.Turkey && countryCode === CountryCode.Turkey;
    if (turkishInTurkey) {
      return this.turkeyValidators;
    }
    const spanishInSpain = marinaCode === CountryCode.Spain && countryCode === CountryCode.Spain;
    if (spanishInSpain) {
      return this.spainValidators;
    }
    return this.defaultValidators;
  }
}
