import { environmentDefault } from './environment.default';
import { IEnvironment } from './interfaces/environment.interface';

export const environment: IEnvironment = {
  ...environmentDefault,
  enableServiceWorker: false,
  // apiUrl: 'https://dmarin-api-stage.futuremind.dev',
  // staticUrl: 'https://dmarin-mms-stage.futuremind.dev/static/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';
