@if (hasAccount()) {
  <span class="me-1" [ngbTooltip]="tipContent"
    ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7081_81048)">
        <path
          d="M8 1.33333C8.64533 1.33333 9.23533 1.63933 9.61933 2.17267L10.1167 2.864L10.9533 2.70333C11.0787 2.67933 11.2067 2.66733 11.334 2.66733C11.868 2.66733 12.37 2.87533 12.748 3.25267C13.2173 3.722 13.4227 4.39267 13.2973 5.04733L13.1367 5.884L13.828 6.38133C14.3607 6.76467 14.6667 7.35467 14.6667 8C14.6667 8.64533 14.3607 9.23533 13.8273 9.61933L13.136 10.1167L13.2967 10.9533C13.422 11.6073 13.2173 12.2787 12.7473 12.748C12.37 13.1253 11.868 13.3333 11.334 13.3333C11.2067 13.3333 11.078 13.3213 10.9533 13.2973L10.1167 13.1367L9.61933 13.828C9.23533 14.3607 8.64533 14.6667 8 14.6667C7.35467 14.6667 6.76467 14.3607 6.38067 13.8273L5.88333 13.136L5.04667 13.2967C4.92133 13.3207 4.79333 13.3327 4.666 13.3327C4.132 13.3327 3.63 13.1247 3.25267 12.7473C2.78333 12.278 2.578 11.6067 2.70333 10.9527L2.864 10.116L2.17267 9.61867C1.63933 9.23533 1.33333 8.64533 1.33333 8C1.33333 7.35467 1.63933 6.76467 2.17267 6.38067L2.864 5.88333L2.70333 5.04667C2.578 4.39267 2.78267 3.722 3.25267 3.252C3.63 2.87467 4.132 2.66667 4.666 2.66667C4.79333 2.66667 4.922 2.67867 5.04667 2.70267L5.88333 2.86333L6.38067 2.172C6.76467 1.63933 7.35467 1.33333 8 1.33333ZM8 0C6.88467 0 5.90333 0.552667 5.29867 1.394C5.09 1.354 4.878 1.334 4.666 1.334C3.81333 1.334 2.96 1.65933 2.30933 2.31C1.49667 3.122 1.192 4.24933 1.39333 5.29867C0.552667 5.90333 0 6.88467 0 8C0 9.11533 0.552667 10.0967 1.394 10.7013C1.19267 11.7507 1.49733 12.878 2.31 13.69C2.96067 14.3407 3.81333 14.666 4.66667 14.666C4.87867 14.666 5.09 14.646 5.29933 14.606C5.90333 15.4473 6.88467 16 8 16C9.11533 16 10.0967 15.4473 10.7013 14.606C10.91 14.646 11.122 14.666 11.334 14.666C12.1867 14.666 13.04 14.3407 13.6907 13.69C14.5033 12.8773 14.808 11.7507 14.6067 10.7013C15.4473 10.0967 16 9.11533 16 8C16 6.88467 15.4473 5.90333 14.606 5.29867C14.8073 4.24933 14.5027 3.122 13.69 2.31C13.0393 1.65933 12.1867 1.334 11.3333 1.334C11.1213 1.334 10.91 1.354 10.7007 1.394C10.0967 0.552667 9.11533 0 8 0Z"
          fill="#126CEA"
        />
        <path
          d="M8 1.33333C8.64533 1.33333 9.23533 1.63933 9.61933 2.17267L10.1167 2.864L10.9533 2.70333C11.0787 2.67933 11.2067 2.66733 11.334 2.66733C11.868 2.66733 12.37 2.87533 12.748 3.25267C13.2173 3.722 13.4227 4.39267 13.2973 5.04733L13.1367 5.884L13.828 6.38133C14.3607 6.76467 14.6667 7.35467 14.6667 8C14.6667 8.64533 14.3607 9.23533 13.8273 9.61933L13.136 10.1167L13.2967 10.9533C13.422 11.6073 13.2173 12.2787 12.7473 12.748C12.37 13.1253 11.868 13.3333 11.334 13.3333C11.2067 13.3333 11.078 13.3213 10.9533 13.2973L10.1167 13.1367L9.61933 13.828C9.23533 14.3607 8.64533 14.6667 8 14.6667C7.35467 14.6667 6.76467 14.3607 6.38067 13.8273L5.88333 13.136L5.04667 13.2967C4.92133 13.3207 4.79333 13.3327 4.666 13.3327C4.132 13.3327 3.63 13.1247 3.25267 12.7473C2.78333 12.278 2.578 11.6067 2.70333 10.9527L2.864 10.116L2.17267 9.61867C1.63933 9.23533 1.33333 8.64533 1.33333 8C1.33333 7.35467 1.63933 6.76467 2.17267 6.38067L2.864 5.88333L2.70333 5.04667C2.578 4.39267 2.78267 3.722 3.25267 3.252C3.63 2.87467 4.132 2.66667 4.666 2.66667C4.79333 2.66667 4.922 2.67867 5.04667 2.70267L5.88333 2.86333L6.38067 2.172C6.76467 1.63933 7.35467 1.33333 8 1.33333Z"
          fill="#126CEA"
        />
        <path
          d="M10.8496 5L7.2 8.69673L5.1496 6.62031L4 7.78531L6.6248 10.4434L7.2 11L7.7752 10.4434L12 6.165L10.8496 5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7081_81048">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
}
<ng-template #tipContent>
  {{ "CUSTOMER_HAS_AN" | translate }}
  <strong class="color-primary">{{ "ONLINE_ACCOUNT" | translate | lowercase }}</strong>
</ng-template>
