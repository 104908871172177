import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeaturesEnabledService } from './features-enabled.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturesGuard {
  constructor(private featuresService: FeaturesEnabledService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const canActivate = route.data.feature ? this.featuresService.hasFeatureEnabled(route.data.feature) : true;
    return canActivate || this.router.parseUrl('/404');
  }
}
