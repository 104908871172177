import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { MarinaProductBillingType, ObBookingProduct } from '@dm-workspace/types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dm-booking-ui-products-list-form-control',
  templateUrl: './products-list-form-control.component.html',
  styleUrls: ['./products-list-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductsListFormControlComponent),
      multi: true,
    },
  ],
})
export class ProductsListFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() public marinaCode?: string;
  @Input() public availableProducts: ObBookingProduct[];
  public value: ObBookingProduct[] = [];
  public type: MarinaProductBillingType;
  protected utilitiesEnabled = false;
  public ngOnInit() {
    this.type = this.availableProducts?.[0]?.productBillingType;
  }

  public isChecked(value: ObBookingProduct): boolean {
    return this.value.findIndex((v) => v.product === value.product) > -1;
  }

  public onProductChange(value: ObBookingProduct) {
    const index = this.value.findIndex((v) => v.product === value.product);
    if (index > -1) {
      this.value.splice(index, 1);
    } else {
      this.value.push(value);
    }
    this.onChange(this.value);
  }

  public writeValue(obj: ObBookingProduct[]): void {
    this.value = obj;
  }

  public onChange: (selected: ObBookingProduct[]) => void = () => undefined;
  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  public onTouched: () => void = () => undefined;
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  utilitiesEnabledChange() {
    this.utilitiesEnabled = !this.utilitiesEnabled;
    if (!this.utilitiesEnabled) {
      this.value = [];
    } else {
      this.value = [...this.availableProducts];
    }
    this.onChange(this.value);
  }
}
