import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './has-permission.directive';
import { DropDirective } from './drop.directive';
import { DestroyedDirective } from './destroyed.directive';
import { NgLetModule } from 'ng-let';
import { IsLongTermDirective } from './is-long-term.directive';

const declaration = [DropDirective, HasPermissionDirective, IsLongTermDirective];

@NgModule({
  imports: [CommonModule, DestroyedDirective, NgLetModule],
  declarations: [...declaration],
  exports: [...declaration, NgLetModule],
})
export class DirectivesModule {}
