import { Component, forwardRef, Inject, Input, LOCALE_ID, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NgbDateAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-input';
import { formatDate } from '@dm-workspace/utils';
import { isValid } from 'date-fns';
import { isDateValidator } from '../../validators/validDate.validator';
import { minMaxDateTimeValidator } from '@dm-workspace/forms';
import { DATE_FORMAT_PLACEHOLDER } from '@dm-workspace/shared';

@Component({
  selector: 'dm-form-date-time-picker',
  templateUrl: './form-date-time-picker.component.html',
  styleUrls: ['./form-date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormDateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FormDateTimePickerComponent,
      multi: true,
    },
  ],
})
export class FormDateTimePickerComponent implements Validator, ControlValueAccessor {
  @Input() placeholder = DATE_FORMAT_PLACEHOLDER;
  @Input() defaultHour = 12;
  @Input() maxDate: string;
  @Input() minDate: string;

  @ViewChild('input') input: NgModel;

  public disabled: boolean;
  public time: NgbTimeStruct;
  public date: string;

  get maxDateFormatted(): string {
    return this.maxDate ? formatDate(new Date(this.maxDate)) : null;
  }

  get minDateFormatted(): string {
    return this.minDate ? formatDate(new Date(this.minDate)) : null;
  }

  constructor(
    public adapter: NgbDateAdapter<string>,
    @Inject(LOCALE_ID) private locale?: string
  ) {}

  protected toggleDatepicker($event: MouseEvent, datePicker: NgbInputDatepicker) {
    $event.stopPropagation();
    $event.preventDefault();
    datePicker.open();
  }

  writeValue(value: string | undefined) {
    if (!value) {
      this.date = null;
      this.time = {
        hour: this.defaultHour,
        minute: 0,
        second: 0,
      };
      return;
    }

    this.date = value;
    const date = new Date(value);
    this.time = {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: 0,
    };
  }

  protected dateChange($event: string) {
    this.date = $event;
    this.timeChange(this.time);
    this.#modelChange();
  }

  protected timeChange($event: NgbTimeStruct) {
    const newTime = {
      hour: $event?.hour ?? this.time?.hour ?? 0,
      minute: $event?.minute ?? this.time?.minute ?? 0,
      second: 0,
    };
    this.time = newTime;
    this.#modelChange();
  }

  #modelChange() {
    const date = new Date(this.date);

    if (!this.date || !isValid(date)) {
      this.onChange(null);
      return;
    }

    const { hour = this.defaultHour, minute = 0 } = this.time || {};
    date.setHours(hour, minute, 0);
    const dateString = date.toISOString();
    this.onChange(dateString);
  }

  validate(control: FormControl): ValidationErrors {
    if (!this.date) {
      return null;
    }

    return {
      ...isDateValidator()(control),
      ...minMaxDateTimeValidator(this.maxDate, 'max', this.locale)(control),
      ...minMaxDateTimeValidator(this.minDate, 'min', this.locale)(control),
      ...this.input?.control.errors,
    };
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onChange: (value: string) => void = () => ({});
  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {}
}
