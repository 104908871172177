import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { PaymentCountdownComponent } from './components/payment-countdown/payment-countdown.component';
import { OverflowSpinnerModule, UiModule } from '@dm-workspace/ui';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LibsSharedModule } from '@dm-workspace/shared';
import { PaymentSelectProviderComponent } from './components/payment-select-provider/payment-select-provider.component';
import { EntityPaymentStatusIconPipe } from './pipes/payment-status-icon.pipe';
import { PaymentHistoryEntityPipe } from './pipes/payment-history-entity.pipe';
import { PaymentWireTransferInitPipe } from './pipes/payment-wire-transfer-init.pipe';
import { LibsFormsModule } from '@dm-workspace/forms';
import { PaymentErrorTypePipe } from './pipes/payment-error-type.pipe';
import { PaymentMethodAdyenComponent } from './components/payment-methods/payment-method-adyen/payment-method-adyen.component';
import { PaymentMethodIyzipayComponent } from './components/payment-methods/payment-method-iyzipay/payment-method-iyzipay.component';
import { PaymentMethodWireTransferComponent } from './components/payment-methods/payment-method-wire-transfer/payment-method-wire-transfer.component';
import { PaymentOrderEntityComponent } from './components/payment/payment-order-entity.component';
import { PaymentTypeIconPipe } from './pipes/payment-type-icon.pipe';

const declarations = [
  PaymentOrderEntityComponent,
  PaymentStatusComponent,
  PaymentSelectProviderComponent,
  PaymentMethodAdyenComponent,
  PaymentMethodIyzipayComponent,
  PaymentMethodWireTransferComponent,
  PaymentTypeIconPipe,
  PaymentCountdownComponent,
  EntityPaymentStatusIconPipe,
];

@NgModule({
  declarations: [...declarations, PaymentHistoryEntityPipe, PaymentErrorTypePipe, PaymentWireTransferInitPipe],
  imports: [
    CommonModule,
    OverflowSpinnerModule,
    TranslateModule,
    RouterModule,
    LibsSharedModule,
    UiModule,
    LibsFormsModule,
  ],
  exports: [...declarations],
})
export class PaymentModule {}
