import { Pipe, PipeTransform } from '@angular/core';
import { diffDatesInDays } from '@dm-workspace/utils';

@Pipe({
  name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
  transform(from: string | Date, to: string | Date = new Date()): number {
    if (!to || !from) {
      return;
    }

    return diffDatesInDays(from, to);
  }
}
