/**
 * @deprecated
 * use LOCALIZATION_LANGUAGES
 */
export enum LanguagesSupportedByD365 {
  'en-us' = 'en-us',
  'en-gb' = 'en-gb',
  'de' = 'de',
  'el' = 'el',
  'tr' = 'tr',
  'es' = 'es',
  'fr' = 'fr',
  'hr' = 'hr',
  'it' = 'it',
}
