import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: `[dmFormBlockEventPaste]`,
})
export class FormInputBlockEventPasteDirective {
  @HostListener('paste', ['$event'])
  public onPaste(event: Event) {
    event.preventDefault();
  }
}
