import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExternalScriptService {
  private loadedScripts$: { [url: string]: ReplaySubject<null> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public loadScript(url: string): Observable<boolean> {
    if (this.loadedScripts$[url]) {
      return this.loadedScripts$[url].asObservable();
    }

    this.loadedScripts$[url] = new ReplaySubject(null);

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this.loadedScripts$[url].next(null);
      this.loadedScripts$[url].complete();
    };
    script.onerror = () => {
      this.loadedScripts$[url].error('Loading external script failed. Script URL: ' + url);
    };

    this.document.body.appendChild(script);

    return this.loadedScripts$[url].asObservable();
  }
}
