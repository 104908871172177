import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomAdapter, CustomDateParserFormatter, JwtService } from './services';
import { JWT_OPTIONS, JwtConfig, JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_TOKEN, ENV, SELECTED_MARINA } from '@dm-workspace/types';
import { environment, IEnvironment } from '@dm-workspace/environments';
import { NotificationModule } from '@dm-workspace/notification';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpUnauthorizedInterceptor } from './interceptors/http-unauthorized.interceptor';
import { BehaviorSubject } from 'rxjs';
import { TranslateCoreModule } from './translate-core/translate-core.module';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UserTypeInterceptor } from './interceptors/user-type.interceptor';
import { isAnimationsDisabled } from '@dm-workspace/utils';

export function jwtOptionsFactory(tokenService: JwtService, appConfig: IEnvironment): JwtConfig {
  return {
    allowedDomains: [appConfig.apiUrl.replace(/(^\w+:|^)\/\//, '')],
    tokenGetter: () => {
      return tokenService.token;
    },
  };
}

@NgModule({
  declarations: [],
  providers: [
    {
      provide: ENV,
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpUnauthorizedInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: UserTypeInterceptor,
    },
    {
      provide: SELECTED_MARINA,
      useValue: new BehaviorSubject(null),
    },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
  ],
  exports: [TranslateCoreModule],
  imports: [
    BrowserAnimationsModule.withConfig({ disableAnimations: isAnimationsDisabled }),
    CommonModule,
    NotificationModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LayoutModule,
    TranslateCoreModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [JwtService, ENV, APP_TOKEN],
      },
    }),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
