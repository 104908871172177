import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal.component';
import { TPylonAction, TPylonState } from '@dm-workspace/types';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'dm-ui-pylon-state-confirm-modal',
  templateUrl: './pylon-state-confirm-modal.component.html',
  styleUrls: ['./pylon-state-confirm-modal.component.scss'],
})
export class PylonStateConfirmModalComponent extends ConfirmModalComponent {
  @Input() referenceId?: string;
  @Input() expectedResult?: TPylonState;
  @Input() actionType?: TPylonAction;
  @Input() pylonName: string;
  @Input() pylonOutput: string;

  @ViewChild('refId') refId: NgModel;

  constructor(protected activeModal: NgbActiveModal) {
    super(activeModal);
  }

  public get shouldUseBookingId(): boolean {
    return this.actionType === 'socketState' && this.expectedResult === 'ON';
  }

  public onSubmit(): void {
    if (this.shouldUseBookingId) {
      const { control } = this.refId || {};
      if (control) {
        control.markAsDirty();
        control.updateValueAndValidity();
      }
      if (!this.referenceId) {
        return;
      }
    }
    this.activeModal.close(this.referenceId);
  }
}
