import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
  transform(value: boolean, ...args: unknown[]): string {
    return value ? 'ACTIVE' : 'INACTIVE';
  }
}
