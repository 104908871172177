export enum CrmProcessingStatus {
  'Active' = 'Active',
  'Confirmed' = 'Confirmed',
  'Picked' = 'Picked',
  'Partially Delivered' = 'Partially Delivered',
  'Delivered' = 'Delivered',
  'Invoiced' = 'Invoiced',
  'Partially Invoiced' = 'Partially Invoiced',
  'Canceled' = 'Canceled',
}
