<form [formGroup]="form" *ngIf="form">
  <div [class]="sectionClassName">
    <h3 class="section-title mb-l">{{ "GENERAL_INFORMATION" | translate | uppercase }}</h3>
    <div class="row gy-3 {{rowClass}}">
      <dm-form-group>
        <label>{{ "BOAT_NAME" | translate }}</label>
        <input dmForm formControlName="name" [placeholder]="'BOAT_NAME' | translate" />
        <dm-form-error-message [control]="form.controls.name"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "BOAT_TYPE" | translate }}</label>
        <ng-select
          dmForm
          [placeholder]="'BOAT_TYPE' | translate"
          [searchable]="false"
          [items]="boatTypes"
          formControlName="type"
        >
          <ng-template ng-label-tmp ng-option-tmp let-boatType="item">
            <span>{{ boatType | transformToTranslateKey | translate }}</span>
          </ng-template>
        </ng-select>
        <dm-form-error-message [control]="form.controls.type"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "REGISTRATION_NUMBER" | translate }}</label>
        <ng-container *ngIf="selectableBoat">
          <input
            dmForm
            formControlName="registrationNumber"
            type="text"
            [placeholder]="'REGISTRATION_NUMBER' | translate"
            (selectItem)="onSelectBoat($event)"
            [resultFormatter]="boatResultFormatter"
            [inputFormatter]="boatInputFormatter"
            [ngbTypeahead]="searchBoat"
            [focusFirst]="false"
            [showHint]="true"
            (blur)="onRegistrationNumberBlur($event)"
          />
          <i (click)="clearBoat()" class="clear-btn i-close" *ngIf="form.controls.registrationNumber?.disabled"></i>
        </ng-container>

        <ng-container *ngIf="!selectableBoat">
          <input
            dmForm
            formControlName="registrationNumber"
            type="text"
            [placeholder]="'REGISTRATION_NUMBER' | translate"
          />
        </ng-container>
        <dm-form-error-message controlName="registrationNumber"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "FLAG" | translate }}</label>
        <ng-select
          dmForm
          [clearable]="false"
          formControlName="flag"
          [placeholder]="'FLAG' | translate"
          dmFormSelectCountry
        >
          <ng-template ng-label-tmp ng-option-tmp let-country="item">
            <dm-form-select-option-country [country]="country"/>
          </ng-template>
        </ng-select>
        <dm-form-error-message [control]="form.controls.flag"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "PORT_OF_REGISTRATION" | translate }}</label>
        <dm-form-autocomplete
          dmForm
          [type]="autocompleteTypes.ports"
          [placeholder]="'PORT_OF_REGISTRATION' | translate"
          formControlName="portOfRegistration"
        />
        <dm-form-error-message controlName="portOfRegistration"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "BOAT_USAGE" | translate }}</label>
        <ng-select
          [placeholder]="'BOAT_USAGE' | translate"
          dmForm
          [searchable]="false"
          [items]="boatUsage"
          (change)="onBoatUsageChange($event)"
          formControlName="usage"
        />
        <dm-form-error-message [control]="form.controls.usage"/>
      </dm-form-group>

      <dm-form-group *ngIf="!!this.form.controls.isCharter.value">
        <label>{{ "CHARTER_LICENSE_NO" | translate }}</label>
        <input dmForm formControlName="charterLicenceNumber" id="charterLicenceNumber" />
        <dm-form-error-message controlName="charterLicenceNumber"/>
      </dm-form-group>
    </div>
  </div>

  <div class="mt-4" [ngClass]="sectionClassName">
    <h3 class="section-title mb-l">{{ "DIMENSIONS" | translate | uppercase }}</h3>
    <div class="row gy-3 {{rowClass}}">
      <dm-form-group>
        <label>{{ "LENGTH_M" | translate }}</label>
        <input dmForm formControlName="length" type="number" [placeholder]="'LENGTH_M' | translate" />
        <dm-form-error-message [control]="form.controls.length"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "WIDTH_M" | translate }}</label>
        <input dmForm formControlName="maxBeam" type="number" [placeholder]="'WIDTH_M' | translate" />
        <dm-form-error-message [control]="form.controls.maxBeam"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "DRAFT_M" | translate }}</label>
        <input dmForm formControlName="draft" type="number" [placeholder]="'DRAFT_M' | translate" />
        <dm-form-error-message [control]="form.controls.draft"/>
      </dm-form-group>
    </div>
  </div>

  <div class="mt-4" [ngClass]="sectionClassName">
    <h3 class="section-title mb-l">{{ "ADDITIONAL_INFORMATION" | translate | uppercase }}</h3>
    <div class="row gy-3 {{rowClass}}">
      <dm-form-group>
        <label>{{ "BRAND" | translate }}</label>
        <dm-form-select-autocomplete
          dmForm
          [clearable]="true"
          [autocomplete]="autocompleteTypes.boatBrand"
          [initItem]="boat?.brand"
          bindLabel="name"
          formControlName="brandId"
          bindValue="id"
        />
        <dm-form-error-message [control]="form.controls.brandId"/>
      </dm-form-group>

      <dm-form-group class="col-4">
        <label>{{ "VSS_CODE" | translate }}</label>
        <input dmForm formControlName="vssNumber" type="text" />
        <dm-form-error-message [control]="form.controls.vssNumber"/>
      </dm-form-group>
    </div>
  </div>
</form>
