import { IEnvironment } from '../interfaces/environment.interface';

export const environmentMock: IEnvironment = {
  production: false,
  stage: false,
  apiUrl: 'example_url/',
  cmsApiUrl: 'example',
  staticUrl: 'example_url/static/',
  sentry: 'example_sentry',
  tokenName: 'example_token',
  crmUrl: 'crmUrl',
  mapId: 'example_mapID',
  sense4boatUrl: 'sensorUrl',
  embeddedMap: 'example',
  mapApiKey: 'example_map_key',
  openMobileAppUrl: 'example_mobile_app_url',
  recaptchaKey: 'recaptcha_key',
  cmsKey: ' test',
  googleTagManager: {
    booking: 'exampl_key',
    ncp: 'exampl_key',
  },
  googlePay: {
    scriptUrL: 'example_url/',
  },
  appStores: {
    Google: {
      url: '//play.google.com/store/apps/details?id=',
      id: 'com.dmarin',
    },
    Apple: {
      url: '//apps.apple.com/us/app/d-marin-premium-marinas/id',
      id: '6446983110',
    },
  },
  adyen: {
    environment: 'test',
  },
  resetPasswordUrl: 'example_url/password-lost',
  enableServiceWorker: false,
};
