import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IEnvironment } from '@dm-workspace/environments';
import { LOCALIZATION_LANGUAGES, SUPPORTED_LANGUAGES } from '../../../types/src';

export function createTranslateLoader(http: HttpClient, appConfig: IEnvironment): TranslateHttpLoader {
  if (appConfig.production || appConfig.stage || !window.location.href.includes('localhost')) {
    return new TranslateHttpLoader(http, `${appConfig.apiUrl}/localizations/`, '');
  }
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function hasLngSupport(lng: string): boolean {
  return SUPPORTED_LANGUAGES.includes(lng as LOCALIZATION_LANGUAGES);
}

export function getBrowseLocale(): LOCALIZATION_LANGUAGES {
  const lng = navigator.language.split('-')[0];
  return hasLngSupport(lng) ? (lng as LOCALIZATION_LANGUAGES) : LOCALIZATION_LANGUAGES.ENGLISH;
}
