import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IResourceBookingBoat, IResourceBookingBoatDimensions } from '@dm-workspace/types';

/**
 * @deprecated use dm-mms-table-cell-boat instead
 */
@Component({
  selector: 'dm-ui-boat-cell',
  templateUrl: './boat-cell.component.html',
  styleUrls: ['./boat-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatCellComponent {
  @Input() boat?: IResourceBookingBoat;
  @Input() hideDetails: boolean;
  @Input() boatDimensions: IResourceBookingBoatDimensions;
  @Input() additionalRow: keyof IResourceBookingBoat;
}
