import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { NotificationContainerComponent } from '../components/notification-container/notification-container.component';
import { NotificationConfig } from './notification-config';
import { NotificationRef } from './notification-ref';
import { NotificationInjector } from './notification-injector';
import { take } from 'rxjs/operators';

@Injectable()
export class NotificationService {
  private notificationComponentRef: ComponentRef<NotificationContainerComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  public openSuccess(content?: string): NotificationRef {
    this.close();
    return this.appendNotificationComponentToBody({
      type: 'success',
      content,
    });
  }

  public openError(content?: string): NotificationRef {
    this.close();
    return this.appendNotificationComponentToBody({
      type: 'error',
      content,
    });
  }

  public add(config: NotificationConfig): NotificationRef {
    this.close();
    const ref = this.appendNotificationComponentToBody(config);
    return ref;
  }

  public close() {
    if (!this.notificationComponentRef) {
      return;
    }

    this.removeNotificationComponentFromBody(this.notificationComponentRef);
    this.notificationComponentRef = undefined;
  }

  private appendNotificationComponentToBody(config?: NotificationConfig): NotificationRef {
    const notificationRef = new NotificationRef();
    const injector = this.getInjector(config, notificationRef);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationContainerComponent);
    const componentRef = componentFactory.create(injector);

    this.appRef.attachView(componentRef.hostView);
    this.handleNotificationRefEvents(notificationRef);

    const domElem = (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.notificationComponentRef = componentRef;

    return notificationRef;
  }

  public removeNotificationComponentFromBody(
    notificationComponentRef: ComponentRef<NotificationContainerComponent>
  ): void {
    this.appRef.detachView(notificationComponentRef.hostView);
    notificationComponentRef.destroy();
    notificationComponentRef = null;
  }

  private getInjector(config: NotificationConfig, notificationRef: NotificationRef): NotificationInjector {
    const map = new WeakMap();
    map.set(NotificationConfig, new NotificationConfig(config));
    map.set(NotificationRef, notificationRef);

    return new NotificationInjector(this.injector, map);
  }

  private handleNotificationRefEvents(notificationRef: NotificationRef): void {
    notificationRef.afterClosing.pipe(take(1)).subscribe(() => {
      this.removeNotificationComponentFromBody(this.notificationComponentRef);
    });
  }
}
