export enum EmailTemplateNames {
  BOOKING_BEING_PROCESSED = 'booking-being-processed',
  BOOKING_CONFIRMED = 'booking-confirmed',
  BOOKING_CANCELLED = 'booking-cancelled',
  BOOKING_CANCELLED_FROM_DRAFT = 'booking-cancelled-from-draft',
  BOOKING_CONFIRMED_REGISTER = 'booking-confirmed-register',
  BOOKING_OFFER = 'booking-offer',
  BOOKING_PAID = 'booking-paid',
  CHECK_IN = 'check-in',
  CHECK_IN_CONFIRMED = 'check-in-confirmed',
  CHECK_IN_REJECTED = 'check-in-rejected',
  CHECK_OUT_SOON = 'check-out-soon',
  CONFIRM_BOOKING_AVAILABILITY = 'confirm-booking-availability',
  JOURNEY_GETTING_CLOSER = 'journey-getting-closer',
  NEW_ACCOUNT = 'new-account',
  PAYMENT_FAILURE = 'payment-failure',
  RESET_PASSWORD = 'reset-password',
  SET_PASSWORD = 'set-password',
}
