import { HttpErrorResponse } from '@angular/common/http';
import { DmarinError, DmarinValidationError } from '../backend';

export interface ApiErrorResponse extends HttpErrorResponse {
  error: DmarinError;
}

export interface ApiErrorValidationResponse extends HttpErrorResponse {
  error: DmarinValidationError;
}
