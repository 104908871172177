import { IGetPriceList, IGetPriceListResponse } from '@dm-workspace/types';
import { formatDate, parseDate } from './utils';
import { isAfter, isBefore, subDays } from 'date-fns';

export const mapPriceListsWithValidToDates = (priceLists: IGetPriceListResponse[]): IGetPriceList[] =>
  priceLists.sort(sortPriceLists).map(fillValidTo);

export const sortPriceLists = (a: IGetPriceListResponse, b: IGetPriceListResponse): number => {
  const compareValidFrom = parseDate(b.validFrom).getTime() - parseDate(a.validFrom).getTime();
  return compareValidFrom !== 0 ? compareValidFrom : Date.parse(b.createdAt) - Date.parse(a.createdAt);
};

export const fillValidTo = (
  priceList: IGetPriceListResponse,
  index: number,
  priceLists: IGetPriceListResponse[]
): IGetPriceList => {
  const previousValidFrom = priceLists[index - 1]?.validFrom;
  let validTo;

  if (previousValidFrom) {
    const previousValidFromDate = parseDate(previousValidFrom);
    const subPreviousValidFrom = subDays(previousValidFromDate, 1);

    validTo = isBefore(subPreviousValidFrom, parseDate(priceList.validFrom))
      ? priceList.validFrom
      : formatDate(subPreviousValidFrom);
  }

  return {
    ...priceList,
    validTo,
  };
};

export function findEndDateFor(startDate: string, inDates: string[]): string {
  const start = parseDate(startDate);
  const startDates = [...inDates].sort((a, b) => {
    return parseDate(a).getTime() - parseDate(b).getTime();
  });
  const next = startDates.find((date) => {
    return isAfter(parseDate(date), start);
  });
  return next && formatDate(subDays(parseDate(next), 1));
}
