import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import { IBerthBase, Marina, MarinaMapColorStatus } from '@dm-workspace/types';
import {
  berthsColors,
  BerthsMapApiService,
  defaultBerthOptions,
  GoogleMapComponent,
  piersOptions,
} from '@dm-workspace/map-utils';

@Component({
  selector: 'dm-ob-maps-marina-selected-berth',
  templateUrl: './ob-maps-marina-selected-berth.component.html',
  styleUrls: ['./ob-maps-marina-selected-berth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObMapsMarinaSelectedBerthComponent extends GoogleMapComponent {
  private readonly berthsMapApiService = inject(BerthsMapApiService);
  protected readonly mapOptions = this.googleMapService.createMapOptions({ zoom: 17 });
  protected readonly defaultBerthPolygonOptions: google.maps.PolygonOptions = {
    ...defaultBerthOptions,
    clickable: false,
  };
  protected readonly selectedBerthPolygonOptions: google.maps.PolygonOptions = {
    ...this.defaultBerthPolygonOptions,
    ...berthsColors[MarinaMapColorStatus.green],
  };
  protected readonly piersPolygonOptions = piersOptions;

  @Input({ required: true })
  public set marina(marina: Marina) {
    this.marina$.next(marina);
  }
  private readonly marina$ = new BehaviorSubject<Marina>(null);

  @Input({ required: true })
  public set selectedBerthIds(berthsIds: string[]) {
    this.selectedBerthIds$.next(berthsIds);
  }
  private readonly selectedBerthIds$ = new BehaviorSubject<string[]>(null);

  protected readonly piers$ = this.marina$.pipe(
    filter(Boolean),
    map((marina) => marina.layout?.coordinates)
  );

  protected readonly berths$ = this.marina$.pipe(
    filter(Boolean),
    switchMap((marina) => this.berthsMapApiService.fetchBookingBerths(marina.code)),
    tap((berths) => this.onMarinaBerthsChange(berths)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected readonly selectedBerths$ = combineLatest([this.selectedBerthIds$, this.berths$]).pipe(
    map(([selectedBerthsIds, marinaBerths]) => marinaBerths.filter((berth) => selectedBerthsIds.includes(berth.id))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected readonly infoWindowPosition$ = this.selectedBerths$.pipe(
    map((selectedBerths) => {
      const latLngList = selectedBerths
        .filter((value) => !!value.polygon)
        .reduce((acc, v) => {
          acc.push(...v.polygon);
          return acc;
        }, [])
        .map((value) => {
          const [lng, lat] = value;
          return new google.maps.LatLng(lat, lng);
        });
      const latLngBounds = new google.maps.LatLngBounds();
      latLngList.forEach((latLng) => {
        latLngBounds.extend(latLng);
      });
      return latLngBounds.getCenter();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private onMarinaBerthsChange(berths: IBerthBase[]) {
    const centerBerthsLatLng = berths
      .filter((value) => !!value.polygon)
      .reduce((acc, v) => {
        acc.push(...v.polygon);
        return acc;
      }, []);
    this.setMapBoundsAndCenter(centerBerthsLatLng);
  }
}
