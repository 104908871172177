import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { TabItemDirective } from './tab-item.directive';

@Component({
  selector: 'dm-ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterContentInit {
  @Input() public activeId: string;
  @Output() activeIdChange = new EventEmitter<string>();

  @ContentChildren(TabItemDirective) public items: QueryList<TabItemDirective>;

  constructor(private cd: ChangeDetectorRef) {}

  public ngAfterContentInit() {
    if (this.items && this.items.length) {
      this.activeId = this.items.get(0).id;
      this.activeIdChange.emit(this.activeId);
    }
  }

  public changeTab(value: string) {
    if (this.activeId === value) {
      return;
    }

    this.activeId = value;
    this.activeIdChange.emit(this.activeId);
    this.cd.detectChanges();
  }
}
