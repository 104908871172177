import { ValidatorFn, AbstractControl } from '@angular/forms';

export function inputRepeatValidator(matchTo: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.parent) {
      return null;
    }
    const matchToControl = control.parent.get(matchTo);
    if (!matchToControl) {
      return null;
    }

    return control.value === matchToControl.value
      ? null
      : {
          repeat: {
            valid: false,
          },
        };
  };
}
