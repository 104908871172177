import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dm-ui-state-switch-badge',
  template: '<ng-content/>',
  styles: [
    `
      @use 'variables' as *;
      :host:empty {
        display: none;
      }
      :host {
        margin-left: 16px;
        margin-right: -8px;
        padding: 4px 12px;
        font-size: 12px;
        background-color: rgba(color('grey light'), 0.75);
        border-radius: 100px;
      }

      :host-context(.active) {
        :host {
          background: rgba(color('primary'), 0.1);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateSwitchBadgeComponent {}
