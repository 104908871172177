import { Observable } from 'rxjs';
import { ApiClientService } from '../api/api-client.service';
import { IRequestParamsWithPagination, PaginatedNormal } from '@dm-workspace/types';

export abstract class ApiCRUDResource<CollectionItem, ByIdItem = CollectionItem, CreateItem = CollectionItem> {
  protected abstract resourceUrl: string;

  constructor(protected apiClientService: ApiClientService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetch(params?: any): Observable<CollectionItem[]> {
    return this.apiClientService.get<CollectionItem[]>(this.resourceUrl, {
      params,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchPaginated(params?: IRequestParamsWithPagination<any>): Observable<PaginatedNormal<CollectionItem>> {
    return this.apiClientService.get<PaginatedNormal<CollectionItem>>(this.resourceUrl, {
      params,
    });
  }

  public fetchById(id: string): Observable<ByIdItem> {
    return this.apiClientService.get<ByIdItem>(`${this.resourceUrl}/${id}`);
  }

  public delete(id: string): Observable<boolean> {
    return this.apiClientService.delete(`/${this.resourceUrl}/${id}`);
  }

  public create(data: CreateItem): Observable<CollectionItem> {
    return this.apiClientService.post<CollectionItem>(this.resourceUrl, data);
  }

  public update(id: string, data: Partial<CreateItem>): Observable<CollectionItem> {
    return this.apiClientService.patch<CollectionItem>(`${this.resourceUrl}/${id}`, data);
  }

  public put(id: string, data: Partial<CreateItem>): Observable<CollectionItem> {
    return this.apiClientService.put<CollectionItem>(`${this.resourceUrl}/${id}`, data);
  }
}
