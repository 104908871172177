import { Pipe, PipeTransform } from '@angular/core';

import { BoatTypeCustomer } from '@dm-workspace/types';

@Pipe({
  name: 'boatTypeTranslateKey',
})
export class NcpBoatTypeTranslateKeyPipe implements PipeTransform {
  transform(value: BoatTypeCustomer): string {
    const keys = Object.keys(BoatTypeCustomer);
    return (
      keys.find((key) => BoatTypeCustomer[key as keyof typeof BoatTypeCustomer] === value) ||
      value.replace(' ', '_').toUpperCase()
    );
  }
}
