import { Pipe, PipeTransform } from '@angular/core';
import { QuotationStatus } from '@dm-workspace/types';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'quotationStatusTranslate',
})
export class QuotationStatusTranslatePipe extends TranslatePipe implements PipeTransform {
  public transform(quotationStatus: QuotationStatus): string {
    return super.transform('STATUSES.BOOKINGS.' + quotationStatus);
  }
}
