export interface ServiceBusResendToCrmDto {
  entities: ServiceBusResendToCrmEntityDto[];
}

export interface ServiceBusResendToCrmEntityDto {
  entity: ServiceBusResendToCrmEntity;
  id: string;
}

export enum ServiceBusResendToCrmEntity {
  OnlineBookingReservation = 'OnlineBookingReservation',
  Boat = 'Boat',
  BoatBrand = 'BoatBrand',
  BoatModel = 'BoatModel',
  BoatMovement = 'BoatMovement',
  Contact = 'Contact',
  ContactBoatRelation = 'ContactBoatRelation',
  Contract = 'Contract',
  Customer = 'Customer',
  CustomerGroup = 'CustomerGroup',
  CustomerInLegalEntity = 'CustomerInLegalEntity',
  Invoice = 'Invoice',
  MarinaProduct = 'MarinaProduct',
  Order = 'Order',
  OrderProduct = 'OrderProduct',
  PaymentMethod = 'PaymentMethod',
  PaymentTerm = 'PaymentTerm',
  SalesTaxAuthority = 'SalesTaxAuthority',
  SalesTaxGroup = 'SalesTaxGroup',
}
