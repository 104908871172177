import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OptionLabelAndValue } from '@dm-workspace/types';
import { FeaturesEnabledService, PermissionService } from '@dm-workspace/core';

/**
 * @deprecated use StateSwitchComponent
 */
@Component({
  selector: 'dm-ui-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent implements OnInit {
  @Input() set items(items: OptionLabelAndValue[]) {
    this.tmpItems = items.filter((item) => {
      const { feature, permissions } = item;
      const hasPermission = !permissions || this.permissionsService.hasPermission(permissions);
      const hasFeatureEnabled = !feature || this.features.hasFeatureEnabled(feature);
      return hasPermission && hasFeatureEnabled;
    });
    this.width = 100 / this.tmpItems.length;
  }
  @Input() selectItemIndex? = 0;

  @Output() toggleChange = new EventEmitter<OptionLabelAndValue>();

  public tmpItems: OptionLabelAndValue[] = [];
  public width = 0;

  public get left(): number {
    return this.width * this.selectItemIndex;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private features: FeaturesEnabledService,
    private permissionsService: PermissionService
  ) {}

  ngOnInit(): void {
    this.selectDefaultItem();
  }

  selectDefaultItem(): void {
    if (!(this.selectItemIndex >= 0)) {
      this.tmpItems.map((item, index) => {
        const { isActive } = item;
        if (isActive) {
          this.selectItemIndex = index;
        }
      });
      this.cd.markForCheck();
    }
  }

  itemClasses(index: number) {
    return {
      item: true,
      active: this.selectItemIndex === index,
      'has-warning': this.tmpItems[index].hasWarning,
    };
  }

  public emitToggleChange(item: OptionLabelAndValue, itemIndex: number): void {
    if (this.selectItemIndex !== itemIndex) {
      this.selectItemIndex = itemIndex;
      this.toggleChange.emit(item);
    }
  }
}
