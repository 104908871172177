import { Directive, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { GoogleMapService } from './services/google-map.service';

@Directive()
export abstract class GoogleMapComponent {
  protected readonly googleMapService = inject(GoogleMapService);
  protected readonly el = inject(ElementRef);

  @Input() protected height: 'auto' | number = 'auto';
  @ViewChild(GoogleMap) protected map: GoogleMap;

  protected readonly isLoadedMapScript$ = this.googleMapService.isLoaded$;
  protected center: google.maps.LatLngLiteral;

  protected get mapHeight(): string {
    if (this.height === 'auto' || !this.el) {
      return '100%';
    }
    const { y } = this.el.nativeElement.getBoundingClientRect();
    const height = window.innerHeight - y;
    return `${height}px`;
  }

  protected setMapBoundsAndCenter(latLngArray: [number, number][]) {
    if (!this.map?.googleMap || !latLngArray) {
      return;
    }
    const latLngList = latLngArray.map((value) => {
      const [lng, lat] = value;
      return new google.maps.LatLng(lat, lng);
    });
    const latLngBounds = new google.maps.LatLngBounds();
    latLngList.forEach((latLng) => {
      latLngBounds.extend(latLng);
    });

    this.center = latLngBounds.getCenter().toJSON();
  }
}
