// SHARED_WITH_FRONTEND

export type Position = [number, number];

export type Polygon = Position[];

export type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;

export interface PaginationMetadataNormal {
  type: 'normal';
  totalCount: number;
  limit: number;
  offset: number;
}

export interface PaginationMetadataNextPage {
  type: 'next-page';
  hasNextPage: boolean;
  limit: number;
  offset: number;
}

export type PaginationMetadata = PaginationMetadataNormal | PaginationMetadataNextPage;

export interface PaginatedNormal<Value> {
  values: Value[];
  metadata: PaginationMetadataNormal;
}

export interface PaginatedNextPage<Value> {
  values: Value[];
  metadata: PaginationMetadataNextPage;
}

export type Paginated<Value> = PaginatedNormal<Value> | PaginatedNextPage<Value>;

export type PaginationQuery = {
  limit?: number;
  offset?: number;
};

export type DependentListElement = { id: unknown };

export type DependentListUpdate<T extends DependentListElement, ImmutableFields extends keyof T> = {
  add: Omit<T, 'id'>[];
  modify: Omit<T, ImmutableFields>[];
  delete: Pick<T, 'id'>[];
};

export interface SwaggerExample {
  summary: string;
  value: string | number;
}

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
