import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AutocompleteGetStatesParams,
  AutocompleteTypes,
  IAutocompleteCountyResponse,
  IAutocompleteResourceDetails,
  IAutocompleteResourceParams,
  IAutocompleteResourceResponse,
  IAutocompleteResponse,
  IAutocompleteStateParams,
  IAutocompleteStateResponse,
  IBerthAutocompleteDetailsItem,
  IBerthAutocompleteItem,
  IBerthAutocompleteParams,
  IBerthAutocompleteRequestParams,
  IBoatBrandAndModelAutocompleteItem,
  IResourceAutocompleteParams,
  MARINA_URL_TOKEN,
} from '@dm-workspace/types';
import { ApiClientService } from '../api/api-client.service';
import { removeEmptyProperties } from '@dm-workspace/utils';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  constructor(private apiClientService: ApiClientService) {}

  public search(
    search: string,
    type: AutocompleteTypes,
    searchParams?: Partial<IResourceAutocompleteParams | IAutocompleteStateParams | IAutocompleteResourceParams>,
    options?: { appendMarinaCode?: true; minCharacterCount?: number }
  ): Observable<IAutocompleteResponse[]> {
    const { appendMarinaCode, minCharacterCount = 2 } = options || {};

    if (minCharacterCount > 0 && search.length < minCharacterCount) {
      return of([]);
    }

    return this.apiClientService
      .get<IAutocompleteResponse[]>(`/autocomplete/${type}`, {
        params: {
          ...removeEmptyProperties({ ...searchParams, search, marinaCode: appendMarinaCode && MARINA_URL_TOKEN }),
        },
      })
      .pipe(
        map((res) => {
          if (type === AutocompleteTypes.county || type === AutocompleteTypes.state) {
            return res;
          }
          if (type === AutocompleteTypes.resources) {
            return res.map(({ details }) => details);
          }
          if (type === AutocompleteTypes.boatBrand || type === AutocompleteTypes.boatModel) {
            return (res as IBoatBrandAndModelAutocompleteItem[]).map(({ value, details }) => ({
              id: details.id,
              name: value,
            }));
          }
          return res.map(({ value }) => value);
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  public searchResource(
    search: string,
    params?: Partial<IAutocompleteResourceParams>
  ): Observable<IAutocompleteResourceDetails[]> {
    if (search === '' && search.length < 2) {
      return of([]);
    }

    const url = `/autocomplete/${AutocompleteTypes.resources}`;
    const { marinaCode = MARINA_URL_TOKEN, ...restParams } = params || {};

    return this.apiClientService
      .get<IAutocompleteResourceResponse[]>(url, {
        params: {
          ...restParams,
          marinaCode,
          search,
        },
      })
      .pipe(
        map((items) => items.map((item) => item.details)),
        catchError(() => {
          return of([]);
        })
      );
  }

  searchBerth(searchParams: IBerthAutocompleteParams): Observable<IBerthAutocompleteDetailsItem[]> {
    const url = `/autocomplete/${AutocompleteTypes.berths}`;
    const { marinaCode = MARINA_URL_TOKEN } = searchParams || {};
    const params: IBerthAutocompleteRequestParams = removeEmptyProperties({
      ...searchParams,
      marinaCode,
    });

    return this.apiClientService.get<IBerthAutocompleteItem[]>(url, { params }).pipe(
      map((items) => items.map(({ details }) => details)),
      catchError((error) => {
        console.error('Berth details search error: ', error.message);
        return of([]);
      })
    );
  }

  public getStates(params: AutocompleteGetStatesParams): Observable<IAutocompleteStateResponse[]> {
    return this.apiClientService.get(`/autocomplete/state`, {
      params: {
        ...params,
        limit: 100,
      },
    });
  }

  getStateById(stateId: string): Observable<IAutocompleteCountyResponse[]> {
    if (!stateId) {
      return of([]);
    }
    return this.apiClientService.get(`/autocomplete/state`, {
      params: { stateId },
    });
  }

  getCountyById(countyId: string): Observable<IAutocompleteCountyResponse[]> {
    if (!countyId) {
      return of([]);
    }
    return this.apiClientService.get(`/autocomplete/county`, {
      params: { countyId },
    });
  }

  public getCountiesByStateId(stateId: string): Observable<IAutocompleteCountyResponse[]> {
    if (!stateId) {
      return of([]);
    }

    return this.apiClientService.get(`/autocomplete/county`, {
      params: { stateId, limit: 100 },
    });
  }
}
