import { ChangeDetectionStrategy, Component, forwardRef, HostListener } from '@angular/core';
import { CheckboxButtonClass } from '../checkbox-group/checkbox-button-base-class.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dm-form-checkbox-card',
  templateUrl: './checkbox-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CheckboxButtonClass, useExisting: forwardRef(() => CheckboxCardComponent) },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxCardComponent), multi: true },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'isDisabled()',
    '[class.selected]': 'checked()',
  },
})
export class CheckboxCardComponent extends CheckboxButtonClass {
  @HostListener('click')
  override onModelChange() {
    super.onModelChange();
  }
}
