import { inject, Injectable } from '@angular/core';
import { Contact, EditContactDto } from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { ApiHttpClient, ApiService } from '../api/api-http-client';

@Injectable({
  providedIn: 'root',
})
export class MmsContactApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/mms/contact`);

  public create(data: EditContactDto): Observable<Contact> {
    return this.apiHttpClient.post<Contact>(`${this.resourceUrl}`, data);
  }

  public update(contactId: string, data: EditContactDto): Observable<Contact> {
    return this.apiHttpClient.put<Contact>(`${this.resourceUrl}/${contactId}`, data);
  }

  public createAccount(contactId: string): Observable<void> {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/${contactId}/actions/create-account`, null);
  }
}
