import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { StateSwitchItem } from './state-switch-item/state-switch-item.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dm-ui-state-switch',
  templateUrl: './state-switch.component.html',
  styleUrls: ['./state-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StateSwitchComponent implements AfterContentInit {
  #el = inject(ElementRef<HTMLElement>);
  #destroyRef = inject(DestroyRef);
  @ContentChildren(StateSwitchItem)
  items: QueryList<StateSwitchItem>;
  @Output() stateChange = new EventEmitter<StateSwitchItem>();

  @Input() columnsSize: 'same' | 'flex' = 'same';

  protected readonly tickWidth = signal<number>(null);
  protected readonly tickPositionLeft = signal<number>(null);
  #activeItem: StateSwitchItem;

  public ngAfterContentInit() {
    new ResizeObserver(() => this.#recalculateTickCSS()).observe(this.#el.nativeElement);

    this.items.forEach((item) => {
      if (item.active) {
        this.setActiveItem(item);
      }
      item.stateChange.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
        this.setActiveItem(item);
      });
    });
  }
  setActiveItem(item: StateSwitchItem) {
    this.#activeItem = item;
    this.#recalculateTickCSS();
    this.stateChange.emit(item);
  }
  #recalculateTickCSS() {
    setTimeout(() => {
      this.tickWidth.set(this.#activeItem?.getWidth());
      this.tickPositionLeft.set(this.#activeItem?.getLeftPosition());
    });
  }
}
