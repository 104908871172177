export type DmarinClientPagination<V> = {
  count: number;
  next?: unknown;
  previous?: unknown;
  results: V[];
};

export type DmarinClientOffer = {
  id: number;
  title: string;
  sef: string;
  image: DmarinClientOfferImage;
  start: string;
  end: string;
  discount: string;
  marinas: Array<{
    id: number;
    title: string;
    country: {
      id: number;
      title: string;
      order: number;
      map_svg: string;
    };
  }>;
  highlighted: boolean;
  all_marinas: boolean;
};

export type DmarinClientOfferImage = {
  original: string;
  versions: {
    admin: string;
    vue_image_browser: string;
    v200_200: string;
    v250_250: string;
    v290_560: string;
    v400_400: string;
    v384_281: string;
    v410_470: string;
    v507_507: string;
    v644_363: string;
    v649_117: string;
    v804_664: string;
    v1038: string;
    v1200_560: string;
    v1225_500: string;
    v1440_485: string;
    v1920: string;
  };
};
