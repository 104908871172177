import { Injectable } from '@angular/core';
import { QuotationStatus } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class MmsResourceBookingService {
  public canEdit(quotationStatus: QuotationStatus) {
    return [
      QuotationStatus.DRAFT,
      QuotationStatus.INCOMPLETE,
      QuotationStatus.SENT,
      QuotationStatus.UNANNOUNCED_ARRIVAL,
    ].includes(quotationStatus);
  }
}
