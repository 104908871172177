// SHARED_WITH_FRONTEND

import { DmarinErrorCodes } from './error-codes.enum';

/**
 * This type is exported only because frontend depends on it
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiErrorObject<T extends object> = Record<string, any | T>;

export interface DmarinError {
  errorId: string;
  message: string;
  statusCode: number;
  errorCode: DmarinErrorCodes;
  detailedErrorCode: string;
  details: unknown;
  [k: string]: unknown;
}

export interface DmarinFailedValidationSpecificError {
  code: 'EntityNotFound' | null;
  message: string;
  constraint: string;
}

export interface DmarinFailedValidationInformation {
  modelName: string | null;
  propertyName: string | null;
  value?: string | number | boolean | undefined | null;
  errors: DmarinFailedValidationSpecificError[];
}

export interface DmarinValidationError extends DmarinError {
  errorCode: DmarinErrorCodes;
  details: DmarinFailedValidationInformation[];
}

export interface DmarinOverlappingEntity {
  overlappingEntity: {
    id: string;
  };
}

export interface DmarinOverlappingPeriodsError extends DmarinError {
  statusCode: 409;
  errorCode: DmarinErrorCodes.OVERLAPPING_PERIODS;
  details: DmarinOverlappingEntity;
}
