<div class="modal-header" *ngIf="title">
  <h2 class="title">{{ title | translate }}</h2>
</div>

<div class="modal-body">
  <p [innerHTML]="(body | translate) + (name ? ' <b>' + name + '</b>' : '')"></p>
  <div class="visual-line"></div>

  <dm-ui-info-text [label]="'HANDOVER_CUSTOMER_EMAIL' | translate" [text]="customerEmail" />

  <div class="visual-line"></div>

  <dm-ui-info-box
    icon="exclamation-mark-circle"
    text="HAND_OVER_CONFIRMATION_FOOTNOTES"
    color="warning"
  />
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-warning" (click)="activeModal.close(false)">{{ cancelBtn | translate }}</button>
  <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">{{ confirmBtn | translate }}</button>
</div>
