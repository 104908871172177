import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { isWithinInterval } from 'date-fns';

import { ResourceBookingApiService } from '@dm-workspace/data-access';
import {
  BoatFitmentWithReasons,
  IAvailabilityCheck,
  IAvailabilityCheckPayload,
  IResourceBookingBerthAvailabilityResponse,
} from '@dm-workspace/types';
import { NotificationService } from '@dm-workspace/notification';
import { parseDate } from '@dm-workspace/utils';

@Component({
  selector: 'dm-ui-availability-check',
  templateUrl: './availability-check.component.html',
  styleUrls: ['./availability-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityCheckComponent implements OnChanges {
  @Input() checkPayload: IAvailabilityCheckPayload;
  @Input() boatFitsInExternalCheck: BoatFitmentWithReasons = null;
  @Input() searchInGaps: boolean | null;

  @Output() availabilityCheck = new EventEmitter<IAvailabilityCheck>();

  public berthAvailable: boolean | null = null;
  public boatFit: boolean | null = null;
  public loadingAvailability = false;
  public reasons: string[] | null;

  constructor(
    private resourceService: ResourceBookingApiService,
    private cd: ChangeDetectorRef,
    private notification: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const { checkPayload, searchInGaps } = changes;
    if (searchInGaps) {
      this.checkBerthAvailability();
    }
    if (checkPayload) {
      const { currentValue, previousValue } = checkPayload;
      if (!currentValue) {
        this.berthAvailable = null;
        this.boatFit = null;
        return;
      }
      if (currentValue && currentValue !== previousValue) {
        if (!previousValue) {
          this.checkBerthAvailability();
          return;
        }
        const {
          resourceId: currentId,
          fromDate: currentFromDate,
          toDate: currentToDate,
          boatId: currentBoatId,
        } = currentValue;
        const {
          resourceId: previousId,
          fromDate: previousFromDate,
          toDate: previousToDate,
          boatId: previousBoatId,
        } = previousValue;
        if (
          currentId !== previousId ||
          currentFromDate !== previousFromDate ||
          currentToDate !== previousToDate ||
          currentBoatId !== previousBoatId
        ) {
          this.checkBerthAvailability();
        }
      }
    }
  }

  sendAvailabilityEvent() {
    this.availabilityCheck.emit({
      pending: this.loadingAvailability,
      berthAvailable: this.berthAvailable,
      boatFit: this.boatFit,
    });
  }

  public checkBerthAvailability(): void {
    const { fromDate, toDate, resourceId, boatId, forBooking, marinaCode } = this.checkPayload || {};
    if (!resourceId || !fromDate || !toDate || this.loadingAvailability) {
      return;
    }

    this.loadingAvailability = true;
    this.sendAvailabilityEvent();

    this.resourceService
      .checkAvailability(
        resourceId,
        {
          fromDate,
          toDate,
          forBooking,
        },
        boatId,
        marinaCode
      )
      .pipe(
        finalize(() => {
          this.loadingAvailability = false;
          this.sendAvailabilityEvent();
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: (response) => this.setBerthAvailability(resourceId, forBooking, response),
        error: () => {
          this.setBerthAvailability();
          this.notification.add({
            text: 'ERRORS.AVAILABILITY_NOT_CHECKED',
            type: 'error',
          });
        },
      });
  }

  setBerthAvailability(
    resourceId?: string,
    forBooking?: string,
    availability?: IResourceBookingBerthAvailabilityResponse
  ): void {
    if (!resourceId || !availability) {
      this.boatFit = null;
      this.berthAvailable = null;
      this.cd.detectChanges();
      return;
    }

    const { boatFitsIn, overlapsWith } = availability || {};

    this.boatFit = boatFitsIn?.result ?? this.boatFitsInExternalCheck?.result;
    this.reasons = boatFitsIn?.reasons ?? this.boatFitsInExternalCheck?.reasons;

    const hasNoOverlaps =
      (overlapsWith && overlapsWith.length === 0) || (overlapsWith.length === 1 && overlapsWith[0].id === forBooking);
    const hasGaps = () => {
      const { cruises } = availability;
      const fromDate = parseDate(this.checkPayload.fromDate);
      const toDate = parseDate(this.checkPayload.toDate);

      return (
        overlapsWith.length === cruises.length &&
        !cruises.some((cruise) => {
          const start = new Date(cruise.exitDate);
          const end = new Date(cruise.returnDate || cruise.expectedReturnDate);
          const interval = { start, end };

          return (
            cruise.allowsBerthReuse && (!isWithinInterval(fromDate, interval) || !isWithinInterval(toDate, interval))
          );
        })
      );
    };

    this.berthAvailable = hasNoOverlaps || (this.searchInGaps === true && hasGaps());
    this.cd.detectChanges();
  }
}
