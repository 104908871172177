import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NotificationConfig, TNotificationType } from '../../services/notification-config';
import { NotificationRef } from '../../services/notification-ref';
import { TranslateService } from '@ngx-translate/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'dm-notification',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
  animations: [
    trigger('fromBottomAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: '0' }),
        animate('.2s', style({ transform: 'translateY(0)', opacity: '1' })),
      ]),
      transition(':leave', [animate('.2s', style({ transform: 'translateY(100%)', opacity: '0' }))]),
    ]),
  ],
})
export class NotificationContainerComponent implements OnInit, OnDestroy {
  public wasClosed = false;
  private closeSubscription: Subscription;
  public readonly contentTmp: TemplateRef<unknown> = this.config.contentTpl;
  private readonly defaultTitle: Record<TNotificationType, string> = {
    error: 'ERROR',
    success: 'SUCCESS',
    ncp_customer_exist: 'NCP_CUSTOMER_EXIST',
    ncp_error: 'NCP_ERROR',
    ncp_success: 'NCP_SUCCESS',
  };

  public get title(): string {
    const { text, rawText, hideDefaultTitle } = this.config;
    return hideDefaultTitle ? null : rawText || this.translate.instant(text || this.defaultTitle[this.config.type]);
  }

  public get content(): string {
    const { content, rawContent } = this.config;
    return rawContent || (content && this.translate.instant(content));
  }

  public iconClass = (() => {
    switch (this.config.type) {
      case 'error': {
        return 'i-close-circle';
      }
      case 'ncp_error': {
        return 'i-close-circle';
      }
      case 'success': {
        return 'i-check-circle';
      }
      case 'ncp_success': {
        return 'i-check-circle';
      }
      case 'ncp_customer_exist': {
        return 'i-profile';
      }
    }
  })();

  public customClass = (() => {
    switch (this.config.type) {
      case 'ncp_customer_exist': {
        return 'ncp_customer_exist';
      }
      case 'ncp_error': {
        return 'ncp_error';
      }
      case 'ncp_success': {
        return 'ncp_success';
      }
    }
  })();

  constructor(
    private translate: TranslateService,
    public config: NotificationConfig,
    public ref: NotificationRef
  ) {}

  public ngOnInit() {
    this.getCloseSubscription();
  }

  private getCloseSubscription(): void {
    const close$ = new Observable((observer) => {
      if (!this.config.persistent) {
        setTimeout(() => observer.next(), this.config.duration);
        setTimeout(() => observer.next(), this.config.duration + 200);
      }
    });
    this.closeSubscription = close$.subscribe(() => {
      if (!this.wasClosed) {
        this.wasClosed = true;
      } else {
        this.close();
      }
    });
  }

  private close(): void {
    this.ref.close();
  }

  public closeClick(): void {
    const { onCloseCallback, closeOnClick } = this.config;

    if (onCloseCallback) {
      onCloseCallback();
    }
    if (!closeOnClick) {
      return;
    }
    this.wasClosed = true;
  }

  public closeByX($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.wasClosed = true;
  }

  public ngOnDestroy(): void {
    this.closeSubscription?.unsubscribe();
  }
}
