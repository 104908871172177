import { Pipe, PipeTransform } from '@angular/core';
import { ContractType } from '@dm-workspace/types';
import { TranslatePipe } from '@ngx-translate/core';
import { EnumUtils } from '@dm-workspace/utils';

@Pipe({
  name: 'contractTypeTranslate',
})
export class ContractTypeTranslatePipe extends TranslatePipe implements PipeTransform {
  public transform(contactType: ContractType): string {
    const key = EnumUtils.getKeyByValue(contactType, ContractType);
    return super.transform('CONTRACT_TYPES.' + key);
  }
}
