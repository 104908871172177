import { Component, Input } from '@angular/core';
import { QuoteDetailsCms } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-quote-view-addition',
  templateUrl: './ob-quote-view-addition.component.html',
  styleUrls: ['./ob-quote-view-addition.component.scss'],
})
export class ObQuoteViewAdditionComponent {
  @Input({ required: true }) public quoteCms: QuoteDetailsCms;
}
