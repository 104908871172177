<div class="full-page__header" @enterLeaveFromTop>
  <ng-content select="[header]" />
  <i class="i-close" (click)="onCloseClick()" *ngIf="!hideClose"></i>
</div>
<div @enterLeaveOpacity class="content-wrapper">
  <ng-content />
</div>
<div *ngIf="!hideFooter" class="full-page__footer" @enterLeaveFromBottom>
  <ng-content select="[footer]" />
</div>
