import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

export interface IStep {
  label: string;
  route?: string;
}

@Component({
  selector: 'dm-ui-route-steps',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements OnChanges {
  @Input() public steps: IStep[] = [];
  @Input() public currentStepIndex = 0;
  @Output() public currentStepIndexChange = new EventEmitter<number>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentStepIndex?.currentValue && !changes.currentStepIndex.firstChange) {
      this.currentStepIndexChange.emit(this.currentStepIndex);
    }
  }

  public isPrev(stepIndex: number): boolean {
    return stepIndex < this.currentStepIndex;
  }
}
