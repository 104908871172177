import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateRange } from '@dm-workspace/types';
import { diffDatesInDays } from '@dm-workspace/utils';

export function maxDateRangeValidator(max: number): ValidatorFn {
  return (control: AbstractControl<DateRange>) => {
    const { fromDate, toDate } = control.value ?? {};
    const diffDates = diffDatesInDays(fromDate, toDate);
    if (diffDates && diffDates <= max) {
      return null;
    }

    return {
      maxDateRange: {
        valid: false,
        diffDates,
        max,
      },
    };
  };
}
