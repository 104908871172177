import { Component, input } from '@angular/core';

@Component({
  selector: 'dm-ncp-auth-route-bubble-tooltip',
  templateUrl: './ncp-auth-bubble-tooltip.component.html',
  styleUrl: './ncp-auth-bubble-tooltip.component.scss',
})
export class NcpAuthBubbleTooltipComponent {
  userEmail = input<string>(null);
}
