import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { formats, Formats } from '@dm-workspace/shared';
import { LOCALIZATION_LANGUAGES } from '@dm-workspace/types';

@Pipe({
  name: 'dateFormat',
})
export class CustomDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  public transform(
    value: string | number | Date | undefined,
    format: Formats | string = 'date',
    timezone?: string,
    locale?: string
  ): string {
    if (!value) {
      return '';
    }

    return formatDate(value, formats[format as Formats] || format, locale || this.locale, timezone);
  }
}

export function formatDateByPreset(
  date: string | number | Date,
  format: Formats | string = 'date',
  timezone?: string,
  locale = LOCALIZATION_LANGUAGES.ENGLISH
): string {
  return formatDate(date, formats[format as Formats] || format, locale, timezone);
}
