import { AfterViewInit, Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @deprecated
 * create directive to on textarea to handle auto resize
 */
@Component({
  selector: 'dm-form-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements AfterViewInit, ControlValueAccessor {
  public value: string;
  public disabled = false;

  @ViewChild('textarea', { static: true }) public textareaRef: ElementRef<HTMLTextAreaElement>;

  public writeValue(value: string): void {
    this.value = value;
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.onInput();
    });
  }

  public onChange: (value: string) => void = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public onTouched: () => void = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public onModelChange($event: string): void {
    this.onTouched();
    this.onChange($event);
    this.onInput();
  }

  public onInput(): void {
    this.textareaRef.nativeElement.style.height = '0';
    const height = this.textareaRef.nativeElement.scrollHeight + 2;

    this.textareaRef.nativeElement.style.height = height + 'px';
  }
}
