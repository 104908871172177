import { Directive, HostBinding } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'dm-ui-card-body',
})
export class CardBodyDirective {
  @HostBinding('class')
  className = 'card-body';
}
