import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-form-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
  ],
})
export class InputDateComponent implements ControlValueAccessor {
  /**
   * @deprecated
   * Use input element with dmInputDatepicker directive
   */
  public disabled = false;
  public model: string;
  public datepickerModel: string;

  @Input() public maxDate: NgbDateStruct;
  @Input() public minDate: NgbDateStruct;
  @Input() public clearable = false;

  constructor(
    private adapter: NgbDateAdapter<string>,
    private cd: ChangeDetectorRef
  ) {}

  private onChange: (selected: string | null) => void = () => undefined;
  public registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  private onTouched: () => void = () => undefined;
  public registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  public onModelChange($event: NgbDate): void {
    const date = this.adapter.toModel($event);

    this.writeValue(date);
  }

  public writeValue(value: string): void {
    this.model = value;
    this.datepickerModel = value;

    this.onChange(this.model);
    this.onTouched();

    this.cd.detectChanges();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public clearValue(): void {
    this.writeValue(null);
  }
}
