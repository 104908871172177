import { ChangeDetectionStrategy, Component, HostBinding, Input, input } from '@angular/core';
import { BoatType, BoatTypeCustomer } from '@dm-workspace/types';

export enum BoatDimensionsEnum {
  draft = 'draft',
  width = 'width',
  length = 'length',
}
@Component({
  selector: 'dm-ui-boat-dimension',
  templateUrl: './boat-dimensions.component.html',
  styleUrls: ['./boat-dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatDimensionsComponent {
  boatType = input.required<BoatTypeCustomer | BoatType>();
  @HostBinding('class')
  get boatClassName() {
    return `${this.boatType().toLowerCase().replace(' ', '-')}`;
  }
  activeDimension = input<BoatDimensionsEnum>();
  @Input() dimensions: Partial<Record<BoatDimensionsEnum, string | number>> = {};
  protected readonly dimensionsValues = Object.values(BoatDimensionsEnum);
}
