import { animate, style, transition, trigger } from '@angular/animations';
import { CUBIC } from '../constants/animation-timings';

export const enterFromBottomAnimation = trigger('enterFromBottom', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(100%)',
    }),
    animate(`.6s ${CUBIC}`),
  ]),
]);
