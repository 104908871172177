import { trigger, transition, style, animate } from '@angular/animations';
import { CUBIC } from '../constants';

export const fromRightAnimation = trigger('fromRightAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(20%)', opacity: '0' }),
    animate(`.25s ${CUBIC}`, style({ transform: 'translateY(0)', opacity: '1' })),
  ]),
  transition(':leave', [animate(`.25s ${CUBIC}`, style({ transform: 'translateX(20%)', opacity: '0' }))]),
]);
