import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@dm-workspace/environments';
import { UiModule } from '@dm-workspace/ui';
import { ServiceWorkerUpdateModalComponent } from './modals/service-worker-update-modal/service-worker-update-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceWorkerUpdateService } from './services/service-worker-update.service';

@NgModule({
  declarations: [ServiceWorkerUpdateModalComponent],
  imports: [
    TranslateModule,
    UiModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    ServiceWorkerUpdateService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => ({}),
      deps: [ServiceWorkerUpdateService],
      multi: true,
    },
  ],
})
export class ServiceWorkerCoreModule {}
