export interface INearbyRestaurants {
  linkToNearbyRestaurants: string;
  linkToPlace: string;
  results: INearbyRestaurantsItem[];
}

export interface INearbyRestaurantsItem {
  image?: string;
  name: string;
  place_id: string;
  rating?: number;
  user_ratings_total?: number;
}
