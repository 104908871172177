import { Pipe, PipeTransform } from '@angular/core';
import { BookingResource, IBerthBase, MarinaMapColorStatus } from '@dm-workspace/types';
import { mergePolygonOptionsWithColor } from '../const';

@Pipe({
  name: 'berthPolygonStyleByBookingPipe',
})
export class BerthPolygonStyleByBookingPipePipe implements PipeTransform {
  transform(
    berth: IBerthBase,
    availability?: BookingResource,
    selectedResource?: BookingResource
  ): google.maps.PolygonOptions {
    if (selectedResource && availability && selectedResource.resource.berthIds.includes(berth.id)) {
      return mergePolygonOptionsWithColor(MarinaMapColorStatus.lightGreen);
    }
    if (availability) {
      return mergePolygonOptionsWithColor(MarinaMapColorStatus.darkGreen);
    }
    return mergePolygonOptionsWithColor(MarinaMapColorStatus.gray500);
  }
}
