import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BerthAvailabilityDto,
  BerthAvailabilityType,
  Currency,
  GetBerthSearchCalendarDto,
  IBerthDto,
  IBerthOccupancy,
  IFetchBerthsSearchParams,
  IParsedBerthProposition,
  IPier,
  IRequestParamsWithPagination,
  ISearchBerthForBoatBody,
  ISearchBerthForBoatResponse,
  IShortBerthResourceOccupancy,
  MARINA_URL_TOKEN,
  ServicePrice,
} from '@dm-workspace/types';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiClientService } from '../api/api-client.service';

@Injectable({
  providedIn: 'root',
})
export class BerthsApiService {
  private resourceUrl = `/mms/marinas/${MARINA_URL_TOKEN}`;

  constructor(private apiClientService: ApiClientService) {}

  public fetch(pierId: string): Observable<IBerthDto[]> {
    return this.apiClientService.get<IBerthDto[]>(`${this.resourceUrl}/berths?pierId=${pierId}`);
  }

  public fetchPiers(): Observable<IPier[]> {
    return this.apiClientService.get<IPier[]>(`${this.resourceUrl}/piers`);
  }

  public fetchAll(allBerths = false): Observable<IBerthDto[]> {
    return this.apiClientService.get<IBerthDto[]>(`${this.resourceUrl}/berths`, {
      params: allBerths ? { allBerths: true } : {},
    });
  }

  public fetchAllByPiers(): Observable<IBerthDto[]> {
    return this.fetchPiers().pipe(
      map((piers) => piers.map((pier) => this.fetch(pier.id))),
      switchMap((piers) => forkJoin(piers)),
      map((value) => [].concat(...value))
    );
  }

  update(id: string, body: Partial<IBerthDto>): Observable<IBerthDto> {
    return this.apiClientService.patch<IBerthDto>(`${this.resourceUrl}/berths/${id}`, body);
  }

  adjacent(ids: string[]): Observable<null> {
    return this.apiClientService.patch(`${this.resourceUrl}/berths/adjacent`, { ids });
  }

  public search(
    params: IRequestParamsWithPagination<IFetchBerthsSearchParams>
  ): Observable<IShortBerthResourceOccupancy> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newParams: any = params;
    if (newParams.isOccupiedByBoatTypes) {
      newParams['isOccupiedByBoatTypes[]'] = newParams.isOccupiedByBoatTypes;
      delete newParams.isOccupiedByBoatTypes;
    }
    return this.apiClientService.get<IShortBerthResourceOccupancy>(`${this.resourceUrl}/berth/search`, {
      params: newParams,
    });
  }

  public searchBerthsForBoat(boatId: string, body: ISearchBerthForBoatBody): Observable<IParsedBerthProposition[]> {
    const params = new HttpParams().set('fromDate', body.fromDate).set('toDate', body.toDate);

    return this.apiClientService
      .get<ISearchBerthForBoatResponse>(`${this.resourceUrl}/berth/search/for-boat/${boatId}`, { params })
      .pipe(
        map((response) => {
          return response.propositions.map((proposition) => {
            const berths = proposition.berthIds.map((berthId) => {
              return response.berths.find((berthStatus) => berthStatus.berth.id === berthId);
            });

            return {
              berthStatuses: berths,
              length: proposition.length,
              width: proposition.totalWidth,
            };
          });
        })
      );
  }

  public getPriceForBerth(
    marinaCode: string,
    boatLength: number,
    boatWidth: number,
    fromDate: string,
    toDate: string
  ): Observable<ServicePrice> {
    const params = new HttpParams()
      .set('marinaCode', marinaCode)
      .set('currency', Currency.EUR)
      .set('boatLength', boatLength)
      .set('boatWidth', boatWidth)
      .set('fromDate', fromDate)
      .set('toDate', toDate);

    return this.apiClientService.get<ServicePrice>(`/pricing/calculate`, { params });
  }

  public statuses(marinaCode: string, hide: BerthAvailabilityType[] = []): Observable<BerthAvailabilityDto[]> {
    return this.apiClientService.get<BerthAvailabilityDto[]>(`${this.resourceUrl}/berths/statuses`, {
      params: {
        'hide[]': hide,
      },
    });
  }

  public searchCalendar(dto: GetBerthSearchCalendarDto): Observable<IBerthOccupancy[]> {
    const { marinaCode = MARINA_URL_TOKEN, ...params } = dto;
    return this.apiClientService.get<IBerthOccupancy[]>(`/mms/marinas/${marinaCode}/berth/search/calendar`, {
      params,
    });
  }
}
