import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmUiStepComponent } from './step/step.component';
import { DmUiStepperComponent } from './stepper/stepper.component';
import { DmUiStepLabelComponent } from './step-label/step-label.component';
import { StepStatePipePipe } from './steplabel.pipe';
import { DmUiStepContentDirective } from './step/step-content.directive';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, CdkPortalOutlet, TranslateModule.forChild()],
  declarations: [
    DmUiStepperComponent,
    DmUiStepContentDirective,
    DmUiStepComponent,
    DmUiStepLabelComponent,
    StepStatePipePipe,
  ],
  exports: [DmUiStepperComponent, DmUiStepComponent, DmUiStepContentDirective],
})
export class StepperModule {}
