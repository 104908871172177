<div class="row">
  <div class="col-6">
    <input
      class="input-date"
      [placeholder]="placeholder"
      ngbDatepicker
      type="text"
      #d1="ngbDatepicker"
      #input="ngModel"
      (click)="toggleDatepicker($event, d1)"
      [ngModel]="date"
      (ngModelChange)="dateChange($event)"
      [maxDate]="adapter.fromModel(maxDateFormatted)"
      [minDate]="adapter.fromModel(minDateFormatted)"
      [disabled]="disabled"
    />
  </div>
  <div class="col-6">
    <ngb-timepicker
      [ngModel]="time"
      (ngModelChange)="timeChange($event)"
      [spinners]="false"
      [disabled]="disabled"
    />
  </div>
</div>
