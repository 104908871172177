import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService } from '../api/api-client.service';
import { IRoleShort } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class RolesApiService {
  constructor(private apiClientService: ApiClientService) {}

  get getRoles$(): Observable<IRoleShort[]> {
    return this.apiClientService.get('/mms/roles');
  }
}
