import { Component, EventEmitter, Injector, Input, OnInit, Output, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs';

@Component({
  selector: 'dm-ui-tab-nav',
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss'],
})
export class TabNavComponent implements OnInit {
  @Input() components!: [string, Type<any>][];
  @Input() injector?: Injector;

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  public activeContent: string;

  constructor(public aRoute: ActivatedRoute) {}

  ngOnInit() {
    this.aRoute.fragment
      .pipe(
        take(1),
        tap((fragment) => {
          const startingComponent: [string, Type<any>] = this.components.find((component) => fragment === component[0]);
          this.activeContent = (startingComponent && startingComponent[0]) || this.components[0][0];
        })
      )
      .subscribe();
  }

  activeIdChange($event: string): void {
    this.viewChange.emit($event);
  }
}
