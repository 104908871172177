import { inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NcpAuthLoginComponent } from '../../../../ncp-auth/src/lib/components/ncp-auth-login/ncp-auth-login.component';
import { BoatDimensions } from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { NcpBookingBoatSelectionConfirmComponent } from '../../../../ncp-booking/src/lib/components/ncp-booking-boat-selection-confirm/ncp-booking-boat-selection-confirm.component';

@Injectable({
  providedIn: 'root',
})
export class NcpBookingModalServices {
  #modal = inject(NgbModal);
  openLoginModal() {
    const modal = this.#modal.open(NcpAuthLoginComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-l',
    });
    return modal.closed;
  }

  openBoatConfirmationModal(data: BoatDimensions): Observable<any> {
    const modal = this.#modal.open(NcpBookingBoatSelectionConfirmComponent, {
      backdrop: 'static',
      centered: true,
    });
    modal.componentInstance.data = data;
    return modal.closed;
  }
}
