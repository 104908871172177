import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@dm-workspace/types';

@Pipe({
  name: 'currency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: number | undefined, isoCode: Currency | undefined): string {
    if (isNaN(value) || !isoCode) {
      return '';
    }

    return new Intl.NumberFormat(this.locale, { style: 'currency', currency: isoCode }).format(value);
  }
}
