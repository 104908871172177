import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentMethodType, PaymentProvider } from '@dm-workspace/types';

@Component({
  selector: 'dm-payment-select-provider',
  templateUrl: './payment-select-provider.component.html',
  styleUrls: ['./payment-select-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentSelectProviderComponent {
  @Input({ required: true }) public providers: PaymentMethodType[];
  @Output() public selectProvider = new EventEmitter<PaymentMethodType>();
  protected readonly PaymentProvider = PaymentProvider;
}
