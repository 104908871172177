<div
  class="d-flex justify-content-start align-items-center available"
  [ngClass]="{ available: available }"
  *ngIf="available !== null && available !== undefined"
>
  <div class="status">
    <i [ngClass]="available ? 'i-check-circle' : 'i-close'"></i>

    {{ (available ? availableHeader : unavailableHeader) | translate }}
  </div>
  <div>
    <div class="description">
      {{ (available ? availableDescription : unavailableDescription) | translate
      }}<ng-container *ngIf="reasons && reasons.length > 0"
        >. {{ "BOAT_NOT_FIT_REASON" | translate }}:
        <ng-container *ngFor="let reason of reasons; let isLast = last">
          {{ "BOAT_NOT_FIT_REASONS." + reason | translate | lowercase }}{{ isLast ? "" : ", " }}
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
