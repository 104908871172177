import { Routes } from '@angular/router';
import { ObQuoteViewComponent } from './views/ob-quote-view/ob-quote-view.component';
import { ObQuoteAcceptedViewComponent } from './views/ob-quote-accepted-view/ob-quote-accepted-view.component';
import { ObQuoteRouterWrapperComponent } from './views/ob-quote-router-wrapper/ob-quote-router-wrapper.component';

export const obQuotesRoutes: Routes = [
  {
    path: '',
    component: ObQuoteRouterWrapperComponent,
    children: [
      {
        path: 'accepted',
        component: ObQuoteAcceptedViewComponent,
      },
      {
        path: '',
        component: ObQuoteViewComponent,
      },
    ],
  },
];
