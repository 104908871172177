<div class="pt-3">
  <h3 class="text-header-md-m fw-500 mb-3">{{ "BOAT_DIMENSIONS_POPUP.TITLE" | translate | uppercase }}</h3>
  <span class="text-secondary-strong text-body-l fw-400 body-line-height">{{ "BOAT_DIMENSIONS_POPUP.CONTENT" | translate  }}</span>

      <div class="d-flex flex-column my-4">
        <div class="d-flex flex-column">
          <strong [style.font-size]="'0.8rem'">{{ "BOAT_DIMENSIONS_POPUP.CONTENT_2" | translate | uppercase }}</strong>
          <span class="text-secondary-strong fw-400"
          >{{ "DIMENSIONS" | translate | titlecase }}:
            <dm-ncp-dimensions class="fw-500" [draft]="data.draft" [width]="data.width" [length]="data.length" />
          </span>
        </div>
      </div>

    <dm-ui-horizontal-line />
    <div class="d-flex justify-content-end mt-4 p-0">
      <button type="submit" class="btn btn-primary-outline w-auto me-3" (click)="onClick(false)">
        {{ "BOAT_DIMENSIONS_POPUP.CHANGE_DETAILS" | translate }}
      </button>
      <button type="submit" class="btn btn-primary w-auto" (click)="onClick(true)">
        {{ "CONFIRM" | translate }}
      </button>
    </div>
</div>
