import { QuotationStatus } from '@dm-workspace/types';

export const ConfirmedBookingsStatuses = [
  QuotationStatus.CONFIRMED,
  QuotationStatus.CHECK_IN,
  QuotationStatus.CHECKED_IN,
  QuotationStatus.CHECKED_OFFLINE,
  QuotationStatus.CHECK_IN_REJECTED,
  QuotationStatus.IN_PROGRESS,
  QuotationStatus.COMPLETED,
  QuotationStatus.CANCELLED,
];

export const PlannedBookingsStatuses = [
  QuotationStatus.INCOMPLETE,
  QuotationStatus.DRAFT,
  QuotationStatus.SENT,
  QuotationStatus.UNANNOUNCED_ARRIVAL,
  QuotationStatus.CANCELLED,
];
