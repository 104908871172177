<ng-container *ngIf="items$ | async as items">
  <ng-select
    dmForm
    [loading]="loading()"
    [typeahead]="input$"
    [items]="items"
    [placeholder]="placeholder() | translate"
    [clearable]="clearable()"
    [disabled]="disabled()"
    (focus)="onSearchFocus()"
    [ngModel]="value"
    [virtualScroll]="items?.length > 10"
    groupBy="isHighlighted"
    [minTermLength]="2"
    [typeToSearchText]="'FORM_VALIDATOR_ERRORS.MIN_LENGTH' | translate: { requiredLength: 2 }"
    (ngModelChange)="onSelectChange($event)"
  >
    <ng-template ng-label-tmp let-customer="item">
      {{ customer.name }}
      <ng-container *ngIf="customer.vssNumber"> • {{ customer.vssNumber }}</ng-container>
    </ng-template>

    <ng-template ng-optgroup-tmp let-options="item">
      @if (options.isHighlighted) {
        <p class="fw-700 mb-0">{{ "BOATS_CONNECTED_TO_CUSTOMER" | translate }}</p>
      }
      <div class="group-divider" [class.full]="!options.isHighlighted"></div>
    </ng-template>

    <ng-template ng-option-tmp let-boat="item" let-search="searchTerm">
      <div class="d-flex justify-content-between align-items-start">
        <div class="pe-2 w-50">
          <p class="display-name" [innerHTML]="boat.name | highlight: search"></p>
          <div class="d-flex vss-code align-items-center">
            <p [innerHTML]="boat.vssNumber | highlight: search | empty"></p>
            <a
              [href]="'/boats/' + boat.id"
              target="_blank"
              (click)="$event.stopPropagation()"
              class="btn btn-primary-negative btn-primary-negative--transparent btn-tiny btn--icon-only ml-auto"
            >
              <span class="i-outside_link"></span>
            </a>
          </div>
        </div>
        <div class="text-right ps-2 w-50">
          <p>
            <img class="flag" [src]="boat.flag | flag" />
            <span [innerHTML]="boat.registrationNumber | highlight: search"></span>
          </p>
          <p>
            L: {{ boat.length }}m <span class="mx-1">W: {{ boat.maxBeam }}m</span> D: {{ boat.draft }}m
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template ng-notfound-tmp let-search="searchTerm">
      <dm-form-select-not-found [search]="search" />
    </ng-template>
  </ng-select>
</ng-container>
