export type MmsDashboardStatName =
  | 'boatMovements'
  | 'checkins'
  | 'quotes'
  | 'newQuotes'
  | 'sentQuotes'
  | 'acceptedQuotes'
  | 'cruisesForVerification'
  | 'expectedArrivals'
  | 'expectedDepartures'
  | 'inquiries'
  | 'otherBookingIssues'
  | 'unfinishedBookings'
  | 'customerDidNotShow'
  | 'newInquiries'
  | 'expiredInquiries'
  | 'notStartedCheckins'
  | 'checkinsWithDocuments'
  | 'checkinsPerformedOffline';

export type MmsDashboardQuotesStatName = 'newQuotes' | 'sentQuotes' | 'acceptedQuotes';

export type MmsDashboardStats = Record<MmsDashboardStatName, MmsDashboardStat>;

export interface MmsDashboardStat {
  todoCount: number;
}
