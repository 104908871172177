import { UserStatusSelect } from '../interfaces';

export const UserStatus: UserStatusSelect[] = [
  {
    value: true,
    label: 'ACTIVE',
  },
  {
    value: false,
    label: 'INACTIVE',
  },
];
