import { InjectionToken } from '@angular/core';
import { IEnvironment } from '@dm-workspace/environments';
import { AppToken } from '../interfaces';
import { BehaviorSubject } from 'rxjs';

export const ENV = new InjectionToken<IEnvironment>('envConfig');
export const SELECTED_MARINA = new InjectionToken<BehaviorSubject<string>>('selectedMarina');
export const APP_TOKEN = new InjectionToken<AppToken>('appToken');
export const API_PREFIX = new InjectionToken('apiPrefix');
export const HTTP_REQUESTS_URL = new InjectionToken('httpRequestsUrl');
