import { endOfDay, format, isValid, parse } from 'date-fns';
import {
  BoatMovementOperationForStatus,
  BoatType,
  CalendarPeriods,
  COUNTRIES_NEW,
  OrderProduct,
  QuoteCrmStatus,
  QuoteProduct,
} from '@dm-workspace/types';
import { DAY_FORMAT } from '@dm-workspace/shared';
import { AbstractControl, FormGroup } from '@angular/forms';

export const HOURS = 1000 * 60 * 60;
export const DAYS = 1000 * 60 * 60 * 24;

export function parseDate(date: string): Date {
  return parse(date, DAY_FORMAT, new Date());
}

export function formatDate(date: Date): string {
  return format(date, DAY_FORMAT);
}

export function diffDatesInDays(from: string | Date | number, to: string | Date | number): number {
  const a = convertToDate(from);
  if (!a) {
    return;
  }
  const b = convertToDate(to);
  if (!b) {
    return;
  }
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return (utc2 - utc1) / DAYS;
}

export function diffDatesInHours(from: string | number | Date, to: string | Date | number): number {
  return timeToHours(diffTime(convertToDate(from), convertToDate(to)));
}

/**
 * @deprecated use DateUtils.convertToDate
 */
export function convertToDate(_date: string | number | Date): Date {
  if (!_date) {
    return;
  }
  if (_date instanceof Date) return _date;

  const date = new Date(_date);
  if (!isValid(date)) console.error('Invalid date');
  if (typeof _date === 'string' && _date.includes('T')) return date;
  date.setHours(0, 0, 0, 0);
  return date;
}

export function diffTime(start: string | Date, end: string | Date): number {
  return convertToDate(end).getTime() - convertToDate(start).getTime();
}

export function timeToHours(time: number): number {
  return time / HOURS;
}

export function timeToDays(time: number): number {
  return time / DAYS;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeDeep(target: any, source: any) {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

/**
 * @deprecated use productsSum pipe instead
 */
export function calculateOrderBalance(products: Array<OrderProduct | QuoteProduct>): number {
  return products.reduce((previous, product) => {
    return previous + product.totalPriceGross;
  }, 0);
}

export function isStatusForTransfer(boatStatusInMarina: BoatMovementOperationForStatus): boolean {
  return [undefined, null, BoatMovementOperationForStatus.DEPARTURE, BoatMovementOperationForStatus.CRUISING].includes(
    boatStatusInMarina
  );
}

export function hasStatusForMarkAsComplete(boatStatus: BoatMovementOperationForStatus): boolean {
  return [null, undefined, BoatMovementOperationForStatus.DEPARTURE].includes(boatStatus);
}

export function isMultiHullBoat(boatType: BoatType): boolean {
  return [BoatType.MULTI_HULL_MOTOR, BoatType.MULTI_HULL_SAIL].includes(boatType);
}

export const selectSearchCountryFn = (term: string, item: [string, string]): boolean => {
  const termUp = term.toUpperCase();
  return item[0].toUpperCase().includes(termUp) || item[1].includes(termUp);
};

/**
 * @deprecated use [dmFormSelectCountry] directive in ng-select instead
 */
export const selectSearchCountriesNewFn = (_term: string, { name, code }: (typeof COUNTRIES_NEW)[number]): boolean => {
  const term = _term.toUpperCase();
  return name.toUpperCase().includes(term) || code.toUpperCase().includes(term);
};

export function getDaysBasedOnPeriod(period: CalendarPeriods): number {
  const map = {
    [CalendarPeriods.week]: 7,
    [CalendarPeriods.month]: 31,
  };
  return map[period];
}

export function isNowBeforeEndOfDayDate(date: string): boolean {
  const now = new Date().getTime();
  const compareDate = endOfDay(new Date(date)).getTime();
  return now <= compareDate;
}

/**
 * @deprecated use FormUtils.concatForms
 */
export function concatForms<O extends FormGroup, A extends Partial<FormGroup> = Partial<FormGroup>>(...args: A[]): O {
  const output = new FormGroup({});
  for (const formName in args) {
    const form = args[formName];
    for (const controlName in form.controls) {
      output.addControl(controlName, form.controls[controlName]);
    }
  }

  return output as unknown as O;
}

export function changeFormUpdateStrategy(formControl: AbstractControl, strategy: 'blur' | 'change') {
  Object.defineProperty(formControl, 'updateOn', {
    get: () => strategy,
    configurable: true,
  });
}

export const isEmpty = (value: string | undefined | null | unknown[]): boolean => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return [null, undefined, ''].includes(value);
};

export const dateToUTC = (value: string | Date | number): number => {
  const date = new Date(value);
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

/**
 * @deprecated use getQuoteStatusColor pipe instead
 */
export function getQuoteStatusColor(quoteStatus: QuoteCrmStatus): string {
  switch (quoteStatus) {
    case QuoteCrmStatus.WON:
      return '#25B10E';
    case QuoteCrmStatus.DENIED:
    case QuoteCrmStatus.LOST:
      return '#D90000';
  }
}

export const arrayRange = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);
