import { CountryCode, IAutocompleteStateDetails } from '@dm-workspace/types';
import { AutocompleteService } from '@dm-workspace/data-access';
import { defer, distinctUntilChanged, map, merge, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { Directive, Input } from '@angular/core';
import { ProfileDetailsForm } from './profile-details-form.service';

@Directive({
  selector: '[dmProfileDetailsFormDirective]',
  standalone: true,
})
export class ProfileDetailsFormDirective {
  @Input() form: Partial<ProfileDetailsForm>;

  public readonly zipInputType$ = defer(() => {
    const { countryCode, zip } = this.form.controls;
    return countryCode.valueChanges.pipe(
      startWith(countryCode.value),
      distinctUntilChanged(),
      map((countryCode) => {
        switch (countryCode) {
          case CountryCode.Italy:
            return 'number';
          default:
            return 'text';
        }
      }),
      distinctUntilChanged((p, c) => {
        if (p !== c) {
          zip.reset(null);
        }
        return p === c;
      })
    );
  }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  public readonly states$ = defer(() => {
    const { countryCode } = this.form.controls;
    return countryCode.valueChanges.pipe(
      startWith(countryCode.value),
      switchMap((countryCode) => {
        return this.autocompleteService.getStates({ countryCode });
      }),
      map((value) => value.map((v) => v.details))
    );
  }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  public readonly fetchingCounties$ = defer(() => {
    return merge(
      this.form.controls.stateOrProvince.valueChanges.pipe(map(Boolean), distinctUntilChanged()),
      this.counties$.pipe(map(() => false))
    );
  });

  constructor(private autocompleteService: AutocompleteService) {}

  public readonly counties$ = defer(() => {
    const { stateOrProvince } = this.form.controls;
    return stateOrProvince.valueChanges.pipe(
      startWith(stateOrProvince.value),
      distinctUntilChanged(),
      switchMap((selectedState) => {
        return this.states$.pipe(map((states) => states.find((v) => v?.name === selectedState?.name)));
      }),
      switchMap((state) => this.getCountriesByStateId(state))
    );
  }).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  private getCountriesByStateId(
    state: IAutocompleteStateDetails | undefined
  ): Observable<Array<{ id: string; name: string }>> {
    if (!state) {
      return of(null);
    }
    return this.autocompleteService.getCountiesByStateId(state.id).pipe(
      // Need projection property name->code to match API autocomplete return and bind proper label to select
      map((counties) => {
        return counties.map((v) => {
          return { id: v.details.id, name: v.value };
        });
      })
    );
  }

  public onStateChange() {
    this.form.controls.county.reset(null);
  }
}
