import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRIES_NEW, CountryCode } from '@dm-workspace/types';

@Pipe({
  name: 'countryCodeTranslateKey',
})
export class CountryCodeTranslateKeyPipe implements PipeTransform {
  public transform(value: CountryCode | undefined) {
    if (!value) {
      return;
    }
    return COUNTRIES_NEW.find((country) => country.code === value)?.name;
  }
}
