<input
  #datepicker="ngbDatepicker"
  class="form-hidden-input"
  [(ngModel)]="datepickerModel"
  [maxDate]="maxDate"
  [minDate]="minDate"
  outsideDays="hidden"
  [dayTemplate]="t"
  (dateSelect)="onModelChange($event)"
  ngbDatepicker
  datepickerClass="datepicker"
  [autoClose]="true"
/>

<div
  (click)="datepicker.toggle()"
>
  <input
    class="input"
    [(ngModel)]="datepickerModel"
  />
</div>

<button class="clear-button" type="button" *ngIf="clearable && datepickerModel">
  <i class="i-close" (click)="clearValue()"></i>
</button>

<ng-template #t let-date let-focused="focused">
  <span class="day" [class.is-focused]="focused">
    {{ date.day }}
  </span>
</ng-template>
