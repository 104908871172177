import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IMarinaProduct,
  IMarinaProductsApiParams,
  IMarinaProductsUtilitiesApiParams,
  MARINA_URL_TOKEN,
  MarinaAvailableUtilitiesResponseDto,
} from '@dm-workspace/types';
import { ApiClientService } from '../api/api-client.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsApiService {
  resourceUrl = `/mms/marinas/${MARINA_URL_TOKEN}/products`;

  constructor(private apiClientService: ApiClientService) {}

  public fetch(params?: IMarinaProductsApiParams): Observable<IMarinaProduct[]> {
    return this.apiClientService.get<IMarinaProduct[]>(this.resourceUrl, {
      params,
    });
  }

  public fetchById(id: string): Observable<IMarinaProduct> {
    return this.apiClientService.get<IMarinaProduct>(`${this.resourceUrl}/${id}`);
  }
  public utilities(params: IMarinaProductsUtilitiesApiParams) {
    return this.apiClientService.get<MarinaAvailableUtilitiesResponseDto[]>(`${this.resourceUrl}/utilities`, {
      params,
    });
  }
}
