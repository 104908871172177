<section>
  <ul>
    @for (step of steps; track step; let i = $index) {
      <li
        [class.active]="selectedIndex === i"
        [class.completed]="step.completed"
        [class.disabled]="!step.editable"
        [class.error]="step.hasError"
      >
        @if (orientation === "horizontal") {
          <button type="button" (click)="selectStepByIndex(i)">{{ i + 1 }}. {{ step.label }}</button>
        } @else {
          <span class="step-dots">
            <i class="i-check"></i>
          </span>
          <button type="button" (click)="selectStepByIndex(i)">
            <dm-ui-step-label [index]="i" [label]="step.label" [state]="step | stepStatePipe: selectedIndex === i" />
          </button>
        }
      </li>
    }
  </ul>
</section>
<div class="steps-content" [ngTemplateOutlet]="selected ? selected.content : null"></div>
