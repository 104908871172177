import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_PREFIX, ApiPrefix, Note, INoteRequest, MARINA_URL_TOKEN, SELECTED_MARINA } from '@dm-workspace/types';
import { ApiClientService } from '../api/api-client.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BoatNotesApiService {
  protected get resourceUrl() {
    return `/${this.apiPrefix}/boats`;
  }

  constructor(
    @Inject(API_PREFIX) private apiPrefix: ApiPrefix,
    private apiClientService: ApiClientService,
    @Inject(SELECTED_MARINA) private selectedMarinaCodeSubject: BehaviorSubject<string>
  ) {}

  fetch(boatId: string, withMarina?: boolean): Observable<Note[]> {
    const params = withMarina ? { marinaCode: MARINA_URL_TOKEN } : {};
    return this.apiClientService.get(`${this.resourceUrl}/${boatId}/notes`, {
      params,
    });
  }

  post(boatId: string, params: INoteRequest): Observable<Note> {
    return this.selectedMarinaCodeSubject.pipe(
      take(1),
      switchMap((marinaCode) => {
        const paramsWithMarina = {
          ...params,
          marinaCode,
        };
        return this.apiClientService.post<Note>(`${this.resourceUrl}/${boatId}/notes`, paramsWithMarina);
      })
    );
  }
}
