export enum ResourceBookingSortEnum {
  START_DATE_DESC = 'START_DATE_DESC',
  START_DATE_ASC = 'START_DATE_ASC',
  END_DATE_DESC = 'END_DATE_DESC',
  END_DATE_ASC = 'END_DATE_ASC',
  HUMAN_READABLE_ID = 'HUMAN_READABLE_ID',
  DURATION_ASC = 'DURATION_ASC',
  DURATION_DESC = 'DURATION_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}
