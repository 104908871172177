import { inject, Injectable } from '@angular/core';
import {
  TPaymentApplePayValidateMerchantSessionResponse,
  PaymentAdyenInitPayload,
  PaymentAdyenInitResponse,
  PaymentIyzipayInitPayload,
  PaymentIyzipayInitResponse,
  PaymentWireTransferInitResponse,
  PaymentWireTransferInitPayload,
  PaymentWireTransferConfirmPayload,
} from '@dm-workspace/types';
import { ApiHttpClient, ApiService } from '../api/api-http-client';

@Injectable({
  providedIn: 'root',
})
export class PaymentsApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/payment`);

  applePayValidateMerchantSession(validationUrl: string) {
    return this.apiHttpClient.post<TPaymentApplePayValidateMerchantSessionResponse>(
      `${this.resourceUrl}/apple-pay/validate-merchant-session`,
      { validationUrl }
    );
  }

  initAdyenPayment(payload: PaymentAdyenInitPayload) {
    return this.apiHttpClient.post<PaymentAdyenInitResponse>(`${this.resourceUrl}/adyen/v5/init`, payload);
  }

  initIyzipayPayment(payload: PaymentIyzipayInitPayload) {
    return this.apiHttpClient.post<PaymentIyzipayInitResponse>(`${this.resourceUrl}/iyzipay/init`, payload);
  }

  wireTransferInit(payload: PaymentWireTransferInitPayload) {
    return this.apiHttpClient.post<PaymentWireTransferInitResponse>(`${this.resourceUrl}/wire-transfer/init`, payload);
  }

  wireTransferConfirm(payload: PaymentWireTransferConfirmPayload) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/wire-transfer/confirm-waiting`, payload);
  }
}
