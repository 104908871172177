import { inject, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import {
  APP_TOKEN,
  AppToken,
  AuthLostPasswordDto,
  IAuthLoginUserRequest,
  IAuthLoginUserResponse,
  IAuthResetPassword,
  IAuthSetUpPassword,
  JwtToken,
  UserType,
} from '@dm-workspace/types';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  Observable,
  Subject,
  Subscription,
  switchMap,
  throwError,
  timer,
} from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { JwtService } from '../services/jwt.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DmJwtHelperService } from '../services/jwt-helper.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #dmJwtHelperService = inject(DmJwtHelperService);
  #jwtHelperService = inject(JwtHelperService);

  public redirectUrl: string | null = null;
  loginSubject = new BehaviorSubject<boolean>(null);
  logoutTrigger$ = new Subject<void>();
  forceUserRefresh = false;
  refreshTokenSub: Subscription;

  get loggedIn$(): Observable<boolean> {
    return this.loginSubject.asObservable().pipe(
      filter((value) => value !== null),
      distinctUntilChanged((prev, curr) => {
        if (this.forceUserRefresh) {
          return false;
        }

        return prev === curr;
      }),
      tap(() => (this.forceUserRefresh = false))
    );
  }

  get token(): string | null {
    return this.jwtService.token;
  }

  set token(token) {
    this.jwtService.token = token;
  }

  get tokenInfo(): JwtToken {
    if (this.token) {
      return this.#jwtHelperService.decodeToken(this.token);
    }
    return null;
  }

  constructor(
    @Inject(APP_TOKEN) private appName: AppToken,
    private router: Router,
    private authApiService: AuthApiService,
    private jwtService: JwtService,
    private notificationService: NotificationService,
    private modalService: NgbModal
  ) {
    this.#setLoginState();
  }

  #setLoginState() {
    const validToken = this.#dmJwtHelperService.isTokenValid(this.jwtService.token);
    if (validToken) {
      this.loginSubject.next(validToken);
      this.scheduleRefresh(this.token);
      return;
    }

    const validRefreshToken = this.#dmJwtHelperService.isTokenValid(this.jwtService.refreshToken);
    if (validRefreshToken) {
      this.refreshToken().subscribe();
      return;
    }

    this.loginSubject = new BehaviorSubject(false);
  }

  scheduleRefresh(token: string): void {
    this.refreshTokenSub?.unsubscribe();

    const jwtExp: number = this.#jwtHelperService.decodeToken(token).exp;
    const TRIGGER_REFRESH_EARLIER_MS = 10000;
    const tokenRefreshDate = new Date(jwtExp * 1000 - TRIGGER_REFRESH_EARLIER_MS);

    this.refreshTokenSub = timer(tokenRefreshDate)
      .pipe(switchMap(() => this.refreshToken()))
      .subscribe();
  }

  public logout(withNavigate = true) {
    this.jwtService.removeTokens();
    this.loginSubject.next(false);
    this.notificationService.close();
    this.modalService.dismissAll();
    this.refreshTokenSub?.unsubscribe();
    this.logoutTrigger$.next();

    if (withNavigate) {
      this.router.navigateByUrl('login');
    }
  }

  public refreshToken(): Observable<IAuthLoginUserResponse> {
    const refreshToken = this.jwtService.refreshToken;
    if (refreshToken && this.#dmJwtHelperService.isTokenValid(refreshToken)) {
      return this.authApiService.refreshToken(refreshToken).pipe(
        tap((res) => {
          this.loginProcess(res, false);
        }),
        catchError((err) => {
          this.logout();
          return throwError(() => err);
        })
      );
    }
    throw Error();
  }

  public login(loginData: IAuthLoginUserRequest, withRedirect = true): Observable<IAuthLoginUserResponse> {
    return this.authApiService.login(loginData).pipe(tap((v) => this.loginProcess(v, withRedirect)));
  }

  loginProcess(res: IAuthLoginUserResponse, withRedirect = true) {
    const { access_token, refresh_token } = res;

    const tokenInfo = this.#jwtHelperService.decodeToken<JwtToken>(access_token);

    if (tokenInfo && !this.hasAccessToPanel(tokenInfo.type)) {
      this.logout();
      return;
    }

    this.token = access_token;
    this.jwtService.refreshToken = refresh_token;
    this.loginSubject.next(true);
    this.scheduleRefresh(access_token);
    if (withRedirect) {
      const redirectUrl = this.redirectUrl || '/';
      this.redirectUrl = null;

      this.router.navigate([redirectUrl]);
    }

    return res;
  }

  hasAccessToPanel(userType: UserType): boolean {
    return (
      (userType === UserType.CUSTOMER && this.appName === AppToken.customerPanel) ||
      (userType === UserType.CUSTOMER && this.appName === AppToken.newCustomerPanel) ||
      (userType === UserType.EMPLOYEE && this.appName === AppToken.mmsPanel)
    );
  }

  public forgotPassword(email: string): Observable<null> {
    return this.authApiService.lostPassword({ email } as AuthLostPasswordDto);
  }

  public resetPassword(value: IAuthResetPassword): Observable<void> {
    return this.authApiService.resetPassword(value);
  }

  public setUpPassword(value: IAuthSetUpPassword): Observable<void> {
    return this.authApiService.setUpPassword(value);
  }

  public decryptEmail(encryptedMail: string): Observable<string> {
    return this.authApiService.decryptEmail(new HttpParams().append('email', encryptedMail));
  }
}
