import { ChangeDetectionStrategy, Component, forwardRef, HostListener } from '@angular/core';
import { RadioButtonClass } from '../radio-group/radio-button-base-class.directive';

@Component({
  selector: 'dm-form-radio-card',
  templateUrl: './radio-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: RadioButtonClass, useExisting: forwardRef(() => RadioCardComponent) }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'isDisabled()',
    '[class.selected]': 'checked()',
  },
})
export class RadioCardComponent<T> extends RadioButtonClass<T> {
  @HostListener('click')
  override onModelChange() {
    super.onModelChange();
  }
}
