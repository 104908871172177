import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { Customer } from '@dm-workspace/types';
import { CustomerUtils } from '../../../../../../../utils/src/lib/customer.utils';

@Component({
  selector: 'dm-form-search-has-account-badge',
  templateUrl: './has-account-badge.component.html',
  styleUrl: './has-account-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HasAccountBadgeComponent {
  customer = input.required<Customer>();
  hasAccount = computed(() => CustomerUtils.hasOnlineAccount(this.customer()));
}
