import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dm-ob-credits-top-up-form',
  templateUrl: './ob-credits-top-up-payment-form.component.html',
  styleUrls: ['./ob-credits-top-up-payment-form.component.scss'],
})
export class ObCreditsTopUpPaymentFormComponent {
  @Input() topUpControl: FormControl;
}
