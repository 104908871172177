import { IUserSingle } from './users.interface';

export interface ApiFile {
  id: string;
  downloadUrl: string;
  author: {
    id: string;
    name: string;
  };
  fileName: string;
  originalFileName: string;
  fileSize: number;
  mimeType: string;
  createdAt: string;
}

export enum DocumentType {
  BOAT_REGISTRATION = 'Boat Registration',
  BOAT_INSURANCE = 'Boat Insurance',
  TRANSIT_LOG = 'Transit Log',
  AUTHORIZATION = 'Authorization',
  OTHER = 'Other',
}

export interface IDocumentShort {
  id: string; //00000000-0000-0000-0000-000000000000
  file: ApiFile;
  linkedBy: IUserSingle;
  confirmedBy: IUserSingle;
  documentType: DocumentType;
  confirmedAt: string; //2022-04-05T16:14:43.307Z
}

export interface IDocument extends IDocumentShort {
  isConfirmed?: boolean;
}

export interface IApproveDocumentRq {
  documentType: DocumentType;
  id: string;
  isConfirmed: boolean;
}

export interface ICreateDocumentRq {
  documentType?: DocumentType;
  fileId: string;
  isConfirmed?: boolean;
}
