import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'dm-form-files-local-item',
  templateUrl: './files-local-item.component.html',
  styleUrls: ['../files-item/files-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesLocalItemComponent {
  @Input() public file: File;
  @Output() public deleted: EventEmitter<void> = new EventEmitter();

  public deleteFile(): void {
    this.deleted.emit();
  }
}
