<div class="header-wrapper">
  <div class="card px-xxl py-xl" #card>
    <ng-content />
    <dm-ui-hidden-content (animationStart)="animationStart($event)" [isOpen]="showHiddenContent">
      <ng-container filters-additions>
        <ng-content select="[filters-additions]" />
      </ng-container>
      <ng-content select="[hidden-content]" />
    </dm-ui-hidden-content>
  </div>
</div>
