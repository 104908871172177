import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'dm-ui-overflow-spinner',
  templateUrl: './overflow-spinner.component.html',
  styleUrls: ['./overflow-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverflowSpinnerComponent implements AfterViewInit {
  @HostBinding('class') get spinnerClass(): string {
    return `position-${this.position} overflow-spinner ` + this.type;
  }
  @HostBinding('class.overflow-spinner--show')
  @Input()
  showSpinner: boolean | undefined = false;
  @HostBinding('class.without-bg')
  @Input()
  disableBg = false;
  @Input()
  public position: 'top' | 'center' | 'bottom' = 'center';

  @Input() public offsetTop: number;

  @Input() type: 'absolute' | 'fixed' = 'fixed';

  @Input() size: 'sm' | 'lg' = 'lg';

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.type === 'absolute') {
      this.elRef.nativeElement.parentElement.classList.add('overflow-spinner__wrapper');
    }
  }
}
