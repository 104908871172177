import { Pipe, PipeTransform } from '@angular/core';
import { BoatType } from '@dm-workspace/types';

@Pipe({
  name: 'transformToTranslateKey',
})
export class TransformToTranslateKeyPipe implements PipeTransform {
  transform(value: string | BoatType | undefined): string {
    return value?.toUpperCase().split(' ').join('_') || '';
  }
}
