<h3 class="mb-3 mb-md-5 text-header-xs text-header-md-m">
  {{ "BANK_TRANSFER_DETAILS" | translate }}
</h3>
<dm-form-group class="mt-4">
  <label>{{ "BANK_NAME" | translate }}</label>
  <dm-ui-copy-input [valueToCopy]="data()?.bankName" />
</dm-form-group>

<dm-form-group class="mt-4">
  <label>{{ "RECIPIENT_NAME" | translate }}</label>
  <dm-ui-copy-input [valueToCopy]="data()?.recipientName" />
</dm-form-group>

<dm-form-group class="mt-4">
  <label>{{ "RECIPIENT_ADDRESS" | translate }}</label>
  <dm-ui-copy-input [valueToCopy]="data()?.recipientAddress" />
</dm-form-group>

<dm-form-group class="mt-4">
  <label>{{ "TRANSFER_TITLE" | translate }}</label>
  <dm-ui-copy-input [valueToCopy]="data()?.transferTitle" />
</dm-form-group>

<dm-form-group class="mt-4">
  <label>{{ "ACCOUNT_NUMBER" | translate }}</label>
  <dm-ui-copy-input [valueToCopy]="data()?.iban" />
</dm-form-group>

<dm-form-group class="mt-4">
  <label>{{ "AMOUNT" | translate }}</label>
  <dm-ui-copy-input
    [valueToCopy]="data()?.amount?.value"
    [placeholder]="data()?.amount?.value | currency: data()?.amount?.currency"
  />
</dm-form-group>

<div class="text-center mt-5">
  <button type="button" class="btn btn-primary w-100" [disabled]="apiCallConfirming()" (click)="confirmPay()">
    <dm-ui-spinner class="me-2" [showSpinner]="apiCallConfirming()" />
    {{ "CONFIRM" | translate }}
  </button>
</div>

<dm-ui-overflow-spinner type="absolute" [showSpinner]="pending()" />
