import { signal } from '@angular/core';
import { PaginationMetadataNormal } from '@dm-workspace/types';
import { Subject } from 'rxjs';

export class Pagination {
  readonly #limit = signal<number>(20);
  readonly limit = this.#limit.asReadonly();

  readonly #offset = signal<number>(0);
  readonly offset = this.#offset.asReadonly();

  readonly #totalCount = signal<number>(0);
  readonly totalCount = this.#totalCount.asReadonly();

  changed$ = new Subject<PaginationMetadataNormal>();

  constructor(pagination?: PaginationMetadataNormal) {
    if (pagination) {
      this.setPagination(pagination);
    }
  }

  reset() {
    this.#offset.set(0);
    this.#totalCount.set(0);
  }

  setPagination(pagination: PaginationMetadataNormal) {
    const { limit, offset, totalCount } = pagination;
    this.#limit.set(limit);
    this.#offset.set(offset);
    this.#totalCount.set(totalCount);
  }

  getPagination(): PaginationMetadataNormal {
    return {
      limit: this.#limit(),
      offset: this.#offset(),
      totalCount: this.#totalCount(),
      type: 'normal',
    };
  }

  getParams() {
    return {
      offset: this.#offset(),
      limit: this.#limit(),
    };
  }

  changePage(page: number) {
    const newOffset = this.#limit() * (page - 1);
    this.#offset.set(newOffset);
    this.changed$.next(this.getPagination());
  }

  changeLimit(limit: number) {
    this.#limit.set(limit);
    this.#offset.set(0);
    this.changed$.next(this.getPagination());
  }
}
