import { AbstractControl, ValidatorFn } from '@angular/forms';
import { formatDateByPreset } from '../../../../shared/src/lib/pipes/custom-date.pipe';
import { Formats } from '@dm-workspace/shared';

export function minMaxDateTimeValidator(
  conditionDate_: string,
  conditionDateType: 'min' | 'max',
  locale: string,
  format: Formats = 'date_time_minutes'
): ValidatorFn {
  return (control: AbstractControl) => {
    if (conditionDate_) {
      const { value } = control;
      const selectedDate = new Date(value);
      const conditionDate = new Date(conditionDate_);

      [selectedDate, conditionDate].forEach((d) => d.setMilliseconds(0));

      const dateDiff = selectedDate.getTime() - conditionDate.getTime();

      if (conditionDateType === 'min' ? dateDiff < 0 : dateDiff > 0) {
        return {
          [conditionDateType + 'Date']: {
            [conditionDateType + 'DateValue']: formatDateByPreset(conditionDate, format, locale),
          },
        };
      }
    }
    return null;
  };
}
