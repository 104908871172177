<dm-form-input-value class="cell">
  <ng-container header>{{"THE_OFFER_EXPIRES_IN" | translate}}</ng-container>
  <div body class="d-flex gap-2 align-items-center">
    <span>
      <span class="font-header">{{ daysLeft }}</span>
      {{ "DAYS" | translate }}
    </span>

    <div class="progress">
      <div class="progress-track">
        <div [style.width.%]="fillWidthPercent" class="progress-track-fill"></div>
      </div>
      <div [style.left.%]="pointerLeftPercent" class="progress-indicator"></div>
    </div>
  </div>
</dm-form-input-value>
