import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeGb from '@angular/common/locales/en-GB';
import localeEl from '@angular/common/locales/el';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeHr from '@angular/common/locales/hr';
import localeIt from '@angular/common/locales/it';
import localeTr from '@angular/common/locales/tr';
import { LOCALE_ID, NgModule } from '@angular/core';

import { ENV } from '@dm-workspace/types';
import { createTranslateLoader, getBrowseLocale } from '@dm-workspace/utils';
import {
  FakeMissingTranslationHandler,
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateCoreService } from './translate-core.service';
import { IEnvironment } from '@dm-workspace/environments';
import { TranslateCoreLogMissingHandler } from './translate-core-log-missing-handler';

[localeGb, localeFr, localeDe, localeEl, localeTr, localeEs, localeHr, localeIt].forEach((locale) =>
  registerLocaleData(locale)
);

@NgModule({
  exports: [TranslateModule],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: getBrowseLocale(),
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: (env: IEnvironment) => {
          if (env.production || env.stage) {
            return new TranslateCoreLogMissingHandler();
          }
          return new FakeMissingTranslationHandler();
        },
        deps: [ENV],
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, ENV],
      },
    }),
  ],
  providers: [
    TranslateCoreService,
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateCoreService) => {
        return translate.locale;
      },
      deps: [TranslateCoreService],
    },
  ],
})
export class TranslateCoreModule {}
