import { Pipe, PipeTransform } from '@angular/core';
import { MarinasService } from '@dm-workspace/core';
import { Observable } from 'rxjs';
import { Marina } from '@dm-workspace/types';

@Pipe({
  name: 'getMarinaByCode',
})
export class GetMarinaByCodePipe implements PipeTransform {
  constructor(private marinaService: MarinasService) {}

  public transform(marinaCode: string): Observable<Marina | null> {
    return this.marinaService.getMarinaByCode(marinaCode);
  }
}
