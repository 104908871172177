<div class="wrap">
  <div class="container d-flex justify-content-center align-items-center my-5">
    <div class="mx-4">
      <a href="https://www.d-marin.com/"><span class="logo i-logo"></span></a>
    </div>
    <div class="mx-4">
      <span class="text-secondary footer-text">© 2024 D-MARIN</span>
    </div>
  </div>
</div>
