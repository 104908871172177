import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@dm-workspace/environments';
import { AppToken, APP_TOKEN, ENV } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  readonly tokenName = this.appName + this.env.tokenName;
  readonly refreshTokenName = this.appName + this.env.tokenName + '_refresh';

  get token(): string {
    return localStorage.getItem(this.tokenName);
  }

  set token(token) {
    if (!token) {
      return;
    }
    localStorage.setItem(this.tokenName, token);
  }

  get refreshToken(): string {
    return localStorage.getItem(this.refreshTokenName);
  }

  set refreshToken(token) {
    if (!token) {
      return;
    }
    localStorage.setItem(this.refreshTokenName, token);
  }

  constructor(
    @Inject(ENV) private env: IEnvironment,
    @Inject(APP_TOKEN) private appName: AppToken
  ) {}

  public removeToken(): void {
    localStorage.removeItem(this.tokenName);
  }

  public removeTokens(): void {
    this.removeToken();
    this.removeRefreshToken();
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(this.refreshTokenName);
  }
}
