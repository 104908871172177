import { Contact } from './contact.interface';
import { Ability, ContactMethod, UserType } from '../backend';
import { IContract } from './contract.interface';
import { IAutocompleteCountyResponse } from './autocomplete.interface';
import { CountryCode, LOCALIZATION_LANGUAGES } from '../constants';
import { IBoatAlertResponseDtoTypeEnum } from './boats.interface';

export interface IUserAddress {
  country?: string;
  phone?: string;
  address?: string;
  city: string;
  state?: string;
  zip?: string;
}

export interface IUser extends IUserAddress {
  email: string;
  type: UserType;
  sub: string; //UUID
  contact: Contact;
  firstName: string;
  localFirstName?: string;
  lastName?: string;
  localLastName?: string;
  birthday?: string;
  drivingLicense?: string;
  taxExemptNumber?: string;
  countryCode?: string;
  country?: string;
  language?: LOCALIZATION_LANGUAGES;
  nationality?: string;
  contactMethod?: ContactMethod;
  passport?: string;
  contracts?: IContract[];
  countyId?: string;
}

export type TUpdateUserPersonalInfo = {
  countryCode?: string;
  phone?: string;
  firstName: string;
  localFirstName?: string;
  lastName: string;
  localLastName?: string | null;
  birthday?: string;
  nationality?: string;
  drivingLicense?: string;
  countyId?: string | null;
  taxExemptNumber?: string | null;
  invoiceAddress?: TUpdateUserPersonalInfoInvoiceAddress;
  language?: LOCALIZATION_LANGUAGES | null;
  contactMethod?: ContactMethod;
  passport?: string | null;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export type TUpdateUserPersonalInfoInvoiceAddress = {
  firstName?: string;
  lastName?: string;
  countryCode?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  countyId?: string;
  zip?: string;
};

export interface IUserSearchParams {
  search?: string;
  roleId?: string;
  type?: UserType;
  marinaCode?: string;
}

export interface IMarinaPermissions {
  marinas: string[];
}

export interface ICustomerUser {
  id: string;
  name: string;
  email: string;
  isActive: boolean;
  type: UserType;
}

export interface IUserSingle extends ICustomerUser {
  contact?: Contact;
  permissions: IMarinaPermissions;
  role: IRole;
  preferredLanguage: LOCALIZATION_LANGUAGES;
}

export interface IUserShort extends ICustomerUser {
  contactId: string;
  facebookUserId: string;
  permissions: IMarinaPermissions;
  roleId: string;
}

export interface IRole {
  createdAt: Date;
  updatedAtDate: Date;
  id: string;
  name: UserRoleName;
  abilities: Ability[];
}

export type UserRoleName = 'Marina Manager';

export interface IRoleShort {
  id: string;
  name: string;
  abilities: Ability[];
}

export interface JwtToken extends IUser {
  scope: Ability[];
}

export interface IUserCreateRequest {
  name: string;
  email: string;
  password: string;
  roleId: string;
  isActive: boolean;
  marinas: string[];
  preferredLanguage: LOCALIZATION_LANGUAGES;
}

export interface IUserChangeRequest {
  name?: string;
  email?: string;
  password?: string;
  roleId?: string;
  isActive?: boolean;
  marinas?: string[];
  preferredLanguage?: LOCALIZATION_LANGUAGES;
}

export interface UserStatusSelect {
  value: boolean;
  label: string;
}

export interface ICreateUserResponse {
  confirmCode: string;
  contactId: string;
  createdAt: string;
  email: string;
  facebookUserId: string;
  googleUserSub: string;
  id: string;
  isActive: boolean;
  name: string;
  password: string;
  permissions: IMarinaPermissions;
  roleId: string;
  type: UserType;
  updatedAtDate: string;
}

//TODO: Update interface
export interface IUpdateUserResponse extends ICreateUserResponse {
  any: string;
}

export interface IUpdateUserOnboardingPersonalInfoBody {
  countryCode: string;
  phone?: string | null;
  firstName: string;
  localFirstName?: string | null;
  lastName: string;
  localLastName?: string | null;
  birthday?: string | null;
  drivingLicense?: string | null;
  countyId?: string | null;
  taxExemptNumber: string;
  invoiceAddress?: {
    countryCode: string;
    phone?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
  } | null;
  language: LOCALIZATION_LANGUAGES | null;
  nationality?: string | null;
  contactMethod: ContactMethod | null;
  passport: string | null;
  address: string | null;
  city: string | null;
  state?: string | null;
  zip: string | null;
  county?: IAutocompleteCountyResponse;
}

export interface AddressBase {
  countryCode?: string;
  phone?: string | null;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  // TODO: add to api
  countyId?: string;
}

export type ProfileDetailsDto = {
  countryCode: CountryCode;
  phone?: string | null;
  firstName?: string;
  email?: string;
  companyName?: string;
  lastName?: string;
  isIndividual: boolean;
  countyId?: string | null;
  fiscalCode?: string | null;
  language: LOCALIZATION_LANGUAGES;
  passport?: string | null;
  address: string;
  city: string;
  state?: string;
  zip: string;
};

export type ProfileDocumentsDto = {
  taxExemptNumber?: string;
  fiscalCode?: string | null;
};

export type UpdatePersonalInfoDto = {
  countryCode?: string;
  phone?: string | null;
  firstName?: string;
  localFirstName?: string;
  lastName?: string;
  localLastName?: string | null;
  birthday?: string | null;
  drivingLicense?: string | null;
  countyId?: string | null;
  fiscalCode?: string | null;
  taxExemptNumber?: string;
  invoiceAddress?: AddressBase | null;
  language?: LOCALIZATION_LANGUAGES | null;
  contactMethod?: ContactMethod | null;
  passport?: string | null;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export type UserMeCheckOnboardingResponse = {
  isOnboardCompleted: boolean;
};

export interface IShortUser {
  id: string;
  name: string;
  type: UserType;
}

export interface UserMeSse {
  title: string;
  message: string | null;
  data: UserMeSseData;
}

export interface UserMeSseData {
  marinaCode: string;
  type: UserMeSseType;
  context: UserMeSseContext;
}

export interface UserMeSseContext {
  boatAlertId: string;
  alertType: IBoatAlertResponseDtoTypeEnum;
  boatId: string;
}

export enum UserMeSseType {
  BOAT_ALERT = 'BOAT_ALERT',
}
