import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BoatsService } from '@dm-workspace/core';
import { BoatType } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-boat-icon',
  templateUrl: './boat-icon.component.html',
  styleUrls: ['./boat-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatIconComponent {
  @Input() boatType: BoatType = BoatType.MOTOR_BOAT;

  get boatIcon(): string {
    return this.boatsService.getBoatIcon(this.boatType);
  }

  constructor(private boatsService: BoatsService) {}
}
