<dm-ob-quote-introduction>
  <div class="wrap">
    <div class="hero">
      <h2 class="title">
        {{ "CUSTOMER_QUOTE_VIEW_TITLE" | translate: { customerName: quote()?.customer?.name || "..." } }}
      </h2>
      @switch (quote()?.type) {
        @case (QuoteType.ANNUAL_SALES) {
          <p
            class="subtitle"
            [innerHTML]="'CUSTOMER_QUOTE_VIEW_SUBTITLE_ANNUAL' | translate: { boatName: quote()?.boat?.name || '...' }"
          ></p>
        }
        @case (QuoteType.RENEWAL) {
          <p
            class="subtitle"
            [innerHTML]="'CUSTOMER_QUOTE_VIEW_SUBTITLE' | translate: { boatName: quote()?.boat?.name || '...' }"
          ></p>
        }
      }
    </div>
  </div>
</dm-ob-quote-introduction>

<div *ngIf="quote()" class="position-relative">
  <div class="wrap-md">
    <img class="line-decoration-right" src="/assets/line-decoration-right.svg" />

    <div class="position-relative float-track-wrapper">
      <ng-content *ngIf="isMinTablet(); then floatTrackSummary; else staticSummary" />

      <ng-template #floatTrackSummary>
        <div class="float-track">
          <div class="float-track-summary">
            <ng-template [ngTemplateOutlet]="summary" />
          </div>
        </div>
      </ng-template>
      <ng-template #staticSummary>
        <ng-template [ngTemplateOutlet]="summary" />
      </ng-template>

      <ng-template #summary>
        <dm-ob-quote-summary (priceDetailsClick)="scrollToPriceDetails()" [quote]="quote()">
          <ng-container footer>
            @if (marinaAgreementsForm$ | async; as formTerm) {
              <dm-ob-quote-marina-agreements-form
                [terms]="marinaTerms$ | async"
                [form]="formTerm"
                dmFormValidationChange
                (formValidationChange)="onMarinaAgreementsFormValidationChange($event)"
              />
            }

            <div class="px-2">
              <button
                (click)="clickAcceptOffer()"
                [disabled]="apiCalling() || !marinaAgreementsAgree()"
                class="btn btn-block btn-primary"
                type="button"
              >
                <dm-ui-spinner [showSpinner]="apiCalling()" class="me-2" />
                {{ "ACCEPT_OFFER" | translate }}
              </button>
            </div>
          </ng-container>
        </dm-ob-quote-summary>
      </ng-template>

      <div class="mt-md-5 mt-3">
        @for (cmsPortalInfoData of quote()?.cmsPortalInfo?.data; track cmsPortalInfoData.id) {
          <dm-ob-quote-section class="wysiwyg">
            @for (data of cmsPortalInfoData.attributes.benefits; track data.id; let first = $first; let last = $last) {
              <h2 class="h2">{{ data.title }}</h2>
              @for (imageData of data.image.data; track imageData.id) {
                <dm-ob-cms-image class="mt-3 mb-3 d-block" [data]="imageData" />
              }
              <dm-ob-cms-child
                [class.mt-md-5]="!first"
                [class.mt-3]="!first"
                [class.mb-5]="!last"
                [class.mb-3]="!last"
                [data]="data.description"
              />
            }

            <dm-ob-cms-child [data]="cmsPortalInfoData.attributes.marinaOffer" />
          </dm-ob-quote-section>
        }
      </div>
    </div>
  </div>
</div>

<div class="wrap">
  <section #sectionProductsDetails class="section section--card mt-md-5 mt-3">
    <dm-ob-quote-products-table [products]="quote()?.data" [currency]="quote()?.currency">
      <caption class="font-header-xl mb-3">
        {{
          "PRODUCT_DETAILS" | translate
        }}
      </caption>
      <tfoot>
        <tr>
          <td class="text-right font-body-m" colspan="2">
            <strong>{{ "TOTAL_AMOUNT" | translate | uppercase }}</strong>
            <strong [style.min-width.px]="130" class="d-inline-block">{{
              quote()?.totalPriceGross | currency: quote()?.currency
            }}</strong>
          </td>
        </tr>
      </tfoot>
    </dm-ob-quote-products-table>
  </section>

  <section *ngLet="quote()?.proposedResource as berth" class="section section--card mt-md-5 mt-3">
    <div class="row row-cols-1 row-cols-md-2 gy-3">
      <div class="location-column">
        <dm-ob-maps-marina-selected-berth
          [marina]="quoteMarina$ | async"
          [selectedBerthIds]="quote()?.proposedResource?.berthIds"
          class="map"
        />
      </div>
      <div>
        <h2 class="font-header-xl mb-4">{{ berth?.name }}</h2>
        @for (cmsPortalInfoData of quote()?.cmsPortalInfo?.data; track cmsPortalInfoData.id) {
          <dm-ob-cms-child class="wysiwyg" [data]="cmsPortalInfoData.attributes.marinaSurroundings" />
        }
      </div>
    </div>
  </section>
</div>

<footer *ngIf="quoteMarina$ | async as quoteMarina" class="footer">
  <div class="wrap">
    <div class="content">
      <div class="logo">
        <img [src]="quoteMarina?.logo?.base64" />
      </div>
      <address>
        <p class="title">{{ "FRONT_OFFICE_TEAM" | translate }}</p>
        <ng-container *ngIf="quoteMarina?.additionalProperties?.contactInfo as contactInfo">
          <p>{{ contactInfo.address }}</p>
          <p>
            T: <a href="tel:{{ contactInfo.phone }}">{{ contactInfo.phone }}</a>
          </p>
          <p>
            F: <a href="fax:{{ contactInfo.fax }}">{{ contactInfo.fax }}</a>
          </p>
          <p>
            E: <a href="mailto:{{ contactInfo.email }}">{{ contactInfo.email }}</a>
          </p>
        </ng-container>
      </address>
    </div>
  </div>
</footer>
