import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findItem',
})
export class FindItemPipe<T> implements PipeTransform {
  transform(data: T[], arrayIndex: keyof T, value: string | number): T {
    if (!data || !arrayIndex || !value) {
      return;
    }

    const item = data.find((v) => {
      return (v[arrayIndex] as unknown as string) === value;
    });

    if (!item) {
      return;
    }

    return item;
  }
}
