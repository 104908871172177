import { Pipe, PipeTransform } from '@angular/core';
import { BoatMovementOperationForStatus } from '@dm-workspace/types';

@Pipe({
  name: 'statusInMarina',
})
export class StatusInMarina implements PipeTransform {
  transform(value?: BoatMovementOperationForStatus | null): string {
    switch (value) {
      case BoatMovementOperationForStatus.ARRIVAL:
      case BoatMovementOperationForStatus.RETURN:
      case BoatMovementOperationForStatus.DRY_DOCK_LAUNCH:
        return 'ON_MARINA';
      case BoatMovementOperationForStatus.CRUISING:
        return 'ON_CRUISE';
      case BoatMovementOperationForStatus.DEPARTURE:
        return 'DEPARTED';
      case BoatMovementOperationForStatus.DRY_DOCK_LIFT:
        return 'IN_DRY_DOCK';
      default:
        return value;
    }
  }
}
