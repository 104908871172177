import { Inject, Injectable } from '@angular/core';
import { ApiPrefix, API_PREFIX, ICreateOrderBody, IOrder, MARINA_URL_TOKEN } from '@dm-workspace/types';
import { ApiCRUDResource } from '../classes/api-resource.class';
import { ApiClientService } from '../api/api-client.service';

/**
 * @deprecated use MmsOrdersApiService instead
 */
@Injectable({
  providedIn: 'root',
})
export class OrdersApiService extends ApiCRUDResource<IOrder, IOrder, ICreateOrderBody> {
  resourceUrl =
    this.apiPrefix === ApiPrefix.mmsPanel
      ? `/${ApiPrefix.mmsPanel}/marinas/${MARINA_URL_TOKEN}/order`
      : `/${ApiPrefix.customerPanel}/quote`;

  constructor(apiClientService: ApiClientService, @Inject(API_PREFIX) private apiPrefix: ApiPrefix) {
    super(apiClientService);
  }
}
