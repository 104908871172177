import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'flag',
})
export class FlagPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(countryCode: string, size = 80): unknown {
    if (!countryCode) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustUrl(`https://flagcdn.com/w${size}/${countryCode.toLowerCase()}.png`);
  }
}
