import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRIES_NEW } from '@dm-workspace/types';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  transform(countryCode?: string): string {
    if (!countryCode) {
      return;
    }
    return COUNTRIES_NEW.find(({ code }) => code === countryCode)?.name || countryCode;
  }
}
