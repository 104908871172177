import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StateSwitchItem } from './state-switch-item.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[dmUiStateSwitchButton]',
  template: '<ng-content/>',
  providers: [{ provide: StateSwitchItem, useExisting: StateSwitchItemButtonComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateSwitchItemButtonComponent extends StateSwitchItem<string> implements AfterContentInit {
  ngAfterContentInit(): void {
    if (this.active) {
      this.stateChange.emit();
    }
  }
  @Input()
  public active = false;
  @Output() stateChange = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active && changes.active.currentValue) {
      this.stateChange.emit();
    }
  }
}
