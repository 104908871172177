<div class="container">
  <p class="title">{{ file.name }}</p>

  <div class="progress">
    <div class="progress__inner" [style.width.%]="progress"></div>
  </div>

  <button type="button" class="btn-remove" (click)="cancelUpload()">
    <i class="icon i-close"></i>
  </button>
</div>
