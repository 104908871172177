import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ObMapsMarinaSelectedBerthComponent } from './components/map-marina-selected-berth/ob-maps-marina-selected-berth.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LibsSharedModule } from '@dm-workspace/shared';
import { ObMapsResourcesComponent } from './components/map-resources/ob-maps-resources.component';

import { ObMapsPricePopupComponent } from './components/marina-map-price-popup/ob-maps-price-popup.component';
import { UiModule } from '@dm-workspace/ui';
import { BerthsMapApiService, MapTransformationsService, MapUtilsModule } from '@dm-workspace/map-utils';

const declarations = [ObMapsMarinaSelectedBerthComponent, ObMapsResourcesComponent];

@NgModule({
  declarations: [...declarations, ObMapsPricePopupComponent],
  exports: [...declarations],
  providers: [MapTransformationsService, BerthsMapApiService],
  imports: [CommonModule, TranslateModule, MapUtilsModule, GoogleMapsModule, LibsSharedModule, UiModule],
})
export class ObMapsModule {}
