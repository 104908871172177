import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api-client.service';
import { Observable } from 'rxjs';
import { IPier, MARINA_URL_TOKEN } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class PiersApiService {
  resourceUrl = '/mms/marinas';

  constructor(private apiClientService: ApiClientService) {}

  fetch(marinaCode = MARINA_URL_TOKEN): Observable<IPier[]> {
    return this.apiClientService.get<IPier[]>(`${this.resourceUrl}/${marinaCode}/piers`);
  }

  fetchById(marinaCode: string, pierName: string): Observable<IPier> {
    return this.apiClientService.get<IPier>(`${this.resourceUrl}/${marinaCode}/piers/${pierName}`);
  }

  update(pierId: string, pier: Partial<IPier>): Observable<void> {
    return this.apiClientService.patch<void>(`${this.resourceUrl}/${MARINA_URL_TOKEN}/piers/${pierId}`, pier);
  }
}
