import { InjectionToken } from '@angular/core';
import { PaymentMethodOptions } from '@adyen/adyen-web/dist/types/types';

export const PAYMENT_METHOD_ADYEN_OPTIONS = new InjectionToken<PaymentMethodOptions<'dropin'>>(
  'PAYMENT_METHOD_ADYEN_OPTIONS'
);

export const PAYMENT_METHOD_ADYEN_LIGHT_THEME_OPTIONS: PaymentMethodOptions<'dropin'> = {
  paymentMethodsConfiguration: {
    card: { styles: { base: { color: '#ffffff' }, error: { color: '#ffffff' } } },
  },
};
export const PAYMENT_METHOD_ADYEN_LIGHT_THEME_OPTIONS_NEW_CUSTOMER: PaymentMethodOptions<'dropin'> = {
  paymentMethodsConfiguration: {
    card: { styles: { base: { color: '#031630' }, error: { color: '#031630' } } },
  },
};
