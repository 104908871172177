export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  NON_BINARY = 'Non binary',
}
export enum SwitchViewType {
  map = 'map',
  list = 'list',
  calendar = 'calendar',
}

export enum AppToken {
  mmsPanel = 'DM_MMS',
  customerPanel = 'DM_CUSTOMER',
  newCustomerPanel = 'DM_NEW_CUSTOMER',
  onlineBooking = 'DM_BOOKING',
}
export enum AppUserType {
  mmsPanel = 'DM_MMS',
  customerPanel = 'DM_CUSTOMER',
  onlineBooking = 'DM_BOOKING',
}
export enum ApiPrefix {
  mmsPanel = 'mms',
  customerPanel = 'customer',
  newCustomerPanel = 'new-customer',
  onlineBooking = 'online-booking',
}
export type GenericObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export const USER_TYPE_HEADER_NAME = 'X-User-Type';
export const USER_TYPE_HEADERS: Record<AppToken, string> = {
  [AppToken.customerPanel]: 'cp',
  [AppToken.mmsPanel]: 'mms',
  [AppToken.onlineBooking]: 'ob',
  [AppToken.newCustomerPanel]: 'ncp',
};
