<table class="table table-desktop">
  <ng-content select="caption" />

  <colgroup>
    <col />
    <col [style.max-width.px]="200" />
  </colgroup>

  <thead>
    <th>{{ "SERVICES" | translate }}</th>
    <th class="text-right">{{ "TOTAL_AMOUNT" | translate }}</th>
  </thead>

  <tbody>
    <ng-container *ngFor="let product of products; trackBy: 'id' | trackByProperty">
      <tr *ngIf="product.totalPriceGross">
        <td>
          {{ product.product | marinaProductNameTranslate | async }}<br />{{
            ("VAT" | translate | uppercase) + " " + product.vatRate + "%"
          }}
        </td>
        <td class="text-right">
          {{ product.totalPrice | currency: currency }}<br />{{ product.vatAmount | currency: currency }}
        </td>
      </tr>
    </ng-container>
  </tbody>

  <ng-content select="tfoot" />
</table>
