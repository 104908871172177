import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private isMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @deprecated use BreakpointsService
   */
  get isMobile$(): Observable<boolean> {
    return this.isMobile.asObservable().pipe(distinctUntilChanged());
  }

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([`(max-width: 767px)`])
      .pipe(
        map((state: BreakpointState) => {
          return state.matches;
        })
      )
      .subscribe((isMobile: boolean) => {
        this.setMobile(isMobile);
      });
  }

  public setMobile(value: boolean) {
    this.isMobile.next(value);
  }
}
