import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { inputNotRepeatValidator, minSpecialCharactersValidator } from '@dm-workspace/forms';

export type ProfilePasswordForm = FormGroup<{
  oldPassword: FormControl<string>;
  newPassword: FormControl<string>;
}>;
export type ProfilePasswordFormValue = ReturnType<ProfilePasswordForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class ProfilePasswordFormService {
  constructor(private fb: FormBuilder) {}

  public createForm(): ProfilePasswordForm {
    return this.fb.group({
      oldPassword: this.fb.control(null, { validators: [Validators.required], updateOn: 'blur' }),
      newPassword: this.fb.control(null, {
        validators: [
          Validators.required,
          Validators.minLength(8),
          minSpecialCharactersValidator(1),
          inputNotRepeatValidator('oldPassword'),
        ],
        updateOn: 'blur',
      }),
    });
  }
}
