import { Injectable } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MarinaProductDto, ObBookingProduct } from '@dm-workspace/types';

export type MarinaProductForm = FormControl<ObBookingProduct[]>;
export type MarinaProductFormValue = ReturnType<MarinaProductForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class MarinaProductFormService {
  constructor(private fb: FormBuilder) {}

  public createForm(initValue?: Partial<MarinaProductFormValue>): MarinaProductForm {
    return this.fb.control(initValue ?? []);
  }

  public transformFormValueToPayload(formValue: MarinaProductFormValue): MarinaProductDto[] {
    return formValue
      .filter((v) => !v.isMandatory)
      .map((v) => {
        return {
          type: v.product,
          quantity: 1,
        };
      });
  }
}
