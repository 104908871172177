import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dm-ui-meta-information',
  templateUrl: './meta-information.component.html',
  styleUrls: ['./meta-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaInformationComponent {
  @Input() public icon: string;
  @Input() public uppercaseLabel: boolean;
  @Input() public largerText: boolean;
  @Input() public horizontalOnMobile: boolean;
}
