import { Pipe, PipeTransform } from '@angular/core';
import { diffDatesInDays } from '@dm-workspace/utils';
import { MarinasService } from '@dm-workspace/core';
import { map, Observable } from 'rxjs';
import { ResourceBookingResponse } from '@dm-workspace/types';

@Pipe({
  name: 'isBookingPast',
})
export class IsBookingPastPipe implements PipeTransform {
  constructor(private marinasService: MarinasService) {}

  public transform(booking: ResourceBookingResponse): Observable<boolean> {
    return this.marinasService
      .getMarinaDateByCode(booking.marina)
      .pipe(map((marinaTime) => diffDatesInDays(marinaTime, booking.toDate) <= 0));
  }
}
