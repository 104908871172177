<div class="checkbox-control">
  <div class="checkbox-control__container" (click)="onClick()">
    <div class="checkbox-control__checkbox">
      <div class="checkbox-control__checkbox__button" [ngClass]="{ checked: checked }"></div>
    </div>
    <label class="checkbox-control__checkbox__label"><ng-content /> </label>
  </div>
  <div *ngIf="hasError" class="invalid-feedback d-block checkbox-control__message">
    {{ errorMessage }}
  </div>
</div>
