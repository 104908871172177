<p class="header" *ngIf="marinaCode">
  {{ marinaCode | marinaName | async }}
</p>

<div class="body" *ngIf="hasProgress">
  <span class="progress">
    <span class="progress__thumb" [style.width.%]="(diffToday / diffDates) * 100"></span>
  </span>

  <span class="value">
    <span class="value__current">{{ diffToday }}</span
    >/{{ from | dateDiff : to }} d
  </span>
</div>
