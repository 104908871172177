import { Pipe, PipeTransform } from '@angular/core';
import { IResourceBooking } from '@dm-workspace/types';

@Pipe({
  name: 'bookingTotalPrice',
})
export class BookingTotalPricePipe implements PipeTransform {
  transform(booking: IResourceBooking): number {
    return booking.bookedProducts.reduce((acc, product) => {
      return acc + product.totalPriceGross;
    }, 0);
  }
}
