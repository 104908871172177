import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CountryCode,
  Contact,
  ICountySimple,
  LOCALIZATION_LANGUAGES,
  ProfileDetailsDto,
  IStateSimple,
} from '@dm-workspace/types';
import { getBrowseLocale, removeEmptyString } from '@dm-workspace/utils';
import { emailValidator, integerOnlyValidator } from '@dm-workspace/forms';
import { ProfileFormValue } from './profile-form.service';

export type ProfileDetailsForm = Partial<
  FormGroup<{
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email?: FormControl<string>;
    city: FormControl<string>;
    address: FormControl<string>;
    language: FormControl<LOCALIZATION_LANGUAGES>;
    phone: FormControl<string>;
    stateOrProvince?: FormControl<IStateSimple>;
    county?: FormControl<ICountySimple>;
    isIndividual?: FormControl<boolean>;
    passport?: FormControl<string>;
    countryCode: FormControl<CountryCode>;
    zip: FormControl<string>;
  }>
>;
export type ProfileDetailsFormValue = ReturnType<ProfileDetailsForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class ProfileDetailsFormService {
  constructor(private fb: FormBuilder) {}

  public createForm(
    initValue?: Partial<ProfileDetailsFormValue>,
    options?: {
      countryCode?: CountryCode;
      marinaCode?: CountryCode;
    }
  ): ProfileDetailsForm {
    const form = this.fb.group({
      firstName: this.fb.control(initValue?.firstName, { validators: [Validators.required], updateOn: 'blur' }),
      lastName: this.fb.control(initValue?.lastName, { validators: [Validators.required], updateOn: 'blur' }),
      countryCode: this.fb.control(initValue?.countryCode, { validators: [Validators.required], updateOn: 'blur' }),
      city: this.fb.control(initValue?.city, { validators: [Validators.required], updateOn: 'blur' }),
      address: this.fb.control(initValue?.address, { validators: [Validators.required], updateOn: 'blur' }),
      zip: this.fb.control(initValue?.zip, { validators: [Validators.required], updateOn: 'blur' }),
      language: this.createLanguageControl(initValue?.language),
      phone: this.fb.control(initValue?.phone, { updateOn: 'blur', validators: [Validators.required] }),
    });

    if (initValue?.countryCode) {
      this.changeFormByCountryCode(form, initValue.countryCode, initValue);
    }

    return form;
  }

  public createEmailControl(initValue: string) {
    return this.fb.control(initValue, {
      validators: [Validators.required, emailValidator()],
      updateOn: 'blur',
    });
  }

  public createTypeControl(initValue: string) {
    return this.fb.control(initValue, {
      validators: [Validators.required],
      updateOn: 'blur',
    });
  }

  public createLanguageControl(initValue?: LOCALIZATION_LANGUAGES) {
    return this.fb.control(initValue || getBrowseLocale(), {
      validators: [Validators.required],
      updateOn: 'blur',
      nonNullable: true,
    });
  }

  public changeFormByCountryCode(
    form: ProfileDetailsForm,
    countryCode: CountryCode,
    initValue?: Partial<ProfileDetailsFormValue>
  ) {
    this.changeStateCountyByCountryCode(form, countryCode, initValue);
    this.changeZipByCountryCode(form, countryCode, initValue);
  }

  private changeStateCountyByCountryCode(
    form: ProfileDetailsForm,
    countryCode: CountryCode,
    initValue?: Partial<ProfileDetailsFormValue>
  ) {
    if ([CountryCode.Turkey, CountryCode.Spain].includes(countryCode)) {
      const { stateOrProvince, county } = form.controls;
      if (stateOrProvince) {
        stateOrProvince.reset(initValue?.stateOrProvince);
      } else {
        form.addControl('stateOrProvince', new FormControl(initValue?.stateOrProvince, [Validators.required]));
      }

      const countyValue = initValue?.county as ICountySimple;
      if (county) {
        county.reset(countyValue);
      } else {
        form.addControl('county', new FormControl(countyValue, [Validators.required]));
      }
    } else {
      form.removeControl('stateOrProvince');
      form.removeControl('county');
    }
  }

  private changeZipByCountryCode(
    form: ProfileDetailsForm,
    countryCode: CountryCode,
    initValue?: Partial<ProfileDetailsFormValue>
  ) {
    const { zip } = form.controls;
    if (countryCode === CountryCode.Italy) {
      zip.setValidators([
        Validators.required,
        integerOnlyValidator(),
        Validators.minLength(5),
        Validators.maxLength(5),
      ]);
    } else {
      zip.setValidators([Validators.required]);
    }
    zip.updateValueAndValidity();
  }

  public transformFormValueToPayload(formValue: ProfileDetailsFormValue): ProfileDetailsDto {
    const { county, stateOrProvince, isIndividual, ...restFormValue } = formValue;
    return removeEmptyString({
      ...restFormValue,
      countyId: county?.id,
      isIndividual: isIndividual || true,
      stateId: stateOrProvince?.id,
    });
  }

  public transformCustomerContactToFormValue(contact: Contact): Partial<ProfileFormValue> {
    return {
      ...contact,
      county: contact.customer?.county,
    };
  }
}
