import { Pipe, PipeTransform } from '@angular/core';
import { Currency, IResourceBooking } from '@dm-workspace/types';

@Pipe({
  name: 'bookingCurrency',
})
export class BookingCurrencyPipe implements PipeTransform {
  transform(booking: IResourceBooking): Currency | undefined {
    return booking.bookedProducts.find((p) => p.currency)?.currency;
  }
}
