import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '@dm-workspace/core';
import { Permissions } from '@dm-workspace/types';

@Directive({
  selector: '[dmHasPermission]',
})
export class HasPermissionDirective implements OnChanges {
  private elseTemplateRef?: TemplateRef<unknown>;

  @Input() dmHasPermission!: Permissions | undefined;

  @Input() set dmHasPermissionElse(templateRef: TemplateRef<unknown>) {
    this.elseTemplateRef = templateRef;
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateView();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (this.elseTemplateRef) {
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      return;
    }
  }

  private checkPermission(): boolean {
    return this.dmHasPermission === undefined || this.permissionService.hasPermission(this.dmHasPermission);
  }
}
