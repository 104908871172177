import { Component, Input } from '@angular/core';
import { Currency, QuoteProduct } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-quote-products-table',
  templateUrl: './ob-quote-products-table.component.html',
  styleUrls: ['./ob-quote-products-table.component.scss'],
})
export class ObQuoteProductsTableComponent {
  @Input({ required: true }) public currency: Currency;
  @Input({ required: true }) public products: QuoteProduct[];
}
