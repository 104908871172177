import { Pipe, PipeTransform } from '@angular/core';
import { IBerthsSearchResponseItem } from '@dm-workspace/types';

@Pipe({
  name: 'berthStatus',
})
export class BerthStatusPipe implements PipeTransform {
  transform(value: IBerthsSearchResponseItem): null {
    return null;
  }
}
