import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ObQuoteViewComponent } from './views/ob-quote-view/ob-quote-view.component';
import { obQuotesRoutes } from './ob-quotes.routes';
import { NgLetModule } from 'ng-let';
import { LibsSharedModule } from '@dm-workspace/shared';
import { ObQuoteHeroComponent } from './components/ob-quote-hero/ob-quote-hero.component';
import { ObQuoteSectionComponent } from './components/ob-quote-section/ob-quote-section.component';
import { ObQuoteViewAdditionComponent } from './components/ob-quote-view-addition/ob-quote-view-addition.component';
import { ObQuoteProductsTableComponent } from './components/ob-quote-products-table/ob-quote-products-table.component';
import { ObQuoteAcceptedViewComponent } from './views/ob-quote-accepted-view/ob-quote-accepted-view.component';
import { ObQuoteRouterWrapperComponent } from './views/ob-quote-router-wrapper/ob-quote-router-wrapper.component';
import { ObQuoteSummaryComponent } from './components/ob-quote-summary/ob-quote-summary.component';
import { UiModule } from '@dm-workspace/ui';
import { ObQuoteIntroductionComponent } from './components/ob-quote-introduction/ob-quote-introduction.component';
import { LibsFormsModule } from '@dm-workspace/forms';
import { ObSumQuoteProductsDiscountsPipe } from './pipes/ob-sum-quote-products-discounts.pipe';
import { ObQuoteExpiredTimerComponent } from './components/ob-quote-expired-timer/ob-quote-expired-timer.component';
import { ObQuoteSectionHeroComponent } from './components/ob-quote-section-hero/ob-quote-section-hero.component';
import { ObSharedModule } from '@dm-workspace/online-booking/ob-shared';
import { ObQuoteMarinaAgreementsFormComponent } from './components/ob-quote-marina-agreements-form/ob-quote-marina-agreements-form.component';
import { ObMapsModule } from '@dm-workspace/online-booking/ob-maps';

@NgModule({
  declarations: [
    ObQuoteViewComponent,
    ObQuoteHeroComponent,
    ObQuoteSectionComponent,
    ObQuoteViewAdditionComponent,
    ObQuoteProductsTableComponent,
    ObQuoteAcceptedViewComponent,
    ObQuoteRouterWrapperComponent,
    ObQuoteSummaryComponent,
    ObQuoteIntroductionComponent,
    ObSumQuoteProductsDiscountsPipe,
    ObQuoteExpiredTimerComponent,
    ObQuoteSectionHeroComponent,
    ObQuoteMarinaAgreementsFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(obQuotesRoutes),
    RouterModule,
    ObMapsModule,
    NgLetModule,
    LibsSharedModule,
    UiModule,
    LibsFormsModule,
    ObSharedModule,
  ],
})
export class ObQuotesModule {}
