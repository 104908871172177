import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValid } from 'date-fns';
import { DateRange } from '@dm-workspace/types';
import { checkDateRangeIsValidDate } from '@dm-workspace/utils';

export function isDateValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const { value } = control;
    if (!isValid(new Date(value))) {
      return {
        invalidDate: true,
      };
    }
    return null;
  };
}

export function isDateRangeValidator(): ValidatorFn {
  return (control: AbstractControl<DateRange>) => {
    const { value } = control;
    if (value && !checkDateRangeIsValidDate(value)) {
      return {
        invalidDate: true,
      };
    }
    return null;
  };
}
