import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiClientService } from '../api/api-client.service';
import {
  Contact,
  IAuthChangePassword,
  IBoat,
  IBoatCreatePayload,
  ICreateUserResponse,
  IPreferences,
  IRequestParamsWithPagination,
  IUpdateUserResponse,
  IUserChangeRequest,
  IUserCreateRequest,
  IUserSearchParams,
  IUserShort,
  IUserSingle,
  PaginatedNormal,
  UpdatePersonalInfoDto,
  UserMeCheckOnboardingResponse,
} from '@dm-workspace/types';
import { DeepPartial } from '@dm-workspace/utils';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private api: ApiClientService) {}

  public me(): Observable<IUserSingle> {
    return this.api.get('/users/me');
  }

  public updatePersonalInfo(dto: UpdatePersonalInfoDto): Observable<IUserSingle> {
    return this.api.patch('/users/me/personal-info', dto);
  }

  public updatePreferences(dto: DeepPartial<IPreferences>): Observable<IUserSingle> {
    return this.api.patch('/users/me/preferences', dto);
  }

  public changePassword(dto: IAuthChangePassword): Observable<void> {
    return this.api.post('/users/me/change-password', dto);
  }

  public getOnboardingContactProposition(): Observable<Contact> {
    return this.api.get('/customer/users/me/onboarding/contact-proposition');
  }

  public checkOnboardingStatus(): Observable<UserMeCheckOnboardingResponse> {
    return this.api.get('/customer/users/me/onboarding/check-onboarding');
  }

  public uploadOnboardingPersonalInfo(dto: UpdatePersonalInfoDto): Observable<IUserSingle> {
    return this.api.post('/customer/users/me/onboarding/personal-info', dto);
  }

  public uploadOnboardingBoat(boat: IBoatCreatePayload): Observable<IBoat> {
    return this.api.post('/customer/users/me/onboarding/boat', boat);
  }

  public createUser(user: IUserCreateRequest): Observable<ICreateUserResponse> {
    return this.api.post<ICreateUserResponse>('/users', user).pipe(
      catchError((error) => {
        console.error('Error: ', error);
        return throwError(error);
      })
    );
  }

  public updateUser(user: IUserChangeRequest, id: string): Observable<IUpdateUserResponse> {
    return this.api.patch<IUpdateUserResponse>(`/users/${id}`, user).pipe(
      catchError((error) => {
        console.error('Error: ', error);
        return throwError(error);
      })
    );
  }

  public fetchById(id: string): Observable<IUserSingle> {
    return this.api.get(`/users/${id}`);
  }

  public fetchUsersSearch(
    params?: IRequestParamsWithPagination<IUserSearchParams>
  ): Observable<PaginatedNormal<IUserShort>> {
    return this.api.get<PaginatedNormal<IUserShort>>(`/users`, {
      params: { ...params },
    });
  }
}
