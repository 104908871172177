<div class="navigation" [ngStyle]="{ 'maxWidth.px': navigation.length > 2 ? '' : '800' }">
  <a
    class="navigation__item"
    *ngFor="let item of navigation; let i = index"
    [routerLink]="item.link"
    routerLinkActive="is-active"
    (isActiveChange)="onActiveChange($event, i)"
  >
    {{ item.label }}
  </a>
  <div
    class="active"
    [ngClass]="{ show: showActive }"
    [style.width.%]="100 / navigation.length"
    [style.left.%]="getLeftOffset"
  ></div>
</div>
