@for (item of data; track item) {
  <ng-template [ngTemplateOutlet]="loopTemplateRef" [ngTemplateOutletContext]="{ data: item }" />
}

<ng-template #loopTemplateRef let-data="data">
  @switch (data.type) {
    @case ("heading") {
      <h3>
        @for (child of data.children; track child) {
          <ng-template [ngTemplateOutlet]="loopTemplateRef" [ngTemplateOutletContext]="{ data: child }" />
        }
      </h3>
    }
    @case ("paragraph") {
      <p>
        @for (child of data.children; track child) {
          <ng-template [ngTemplateOutlet]="loopTemplateRef" [ngTemplateOutletContext]="{ data: child }" />
        }
      </p>
    }
    @case ("link") {
      <a [href]="data.url" class="link" target="_blank">
        @for (child of data.children; track child) {
          <ng-template [ngTemplateOutlet]="loopTemplateRef" [ngTemplateOutletContext]="{ data: child }" />
        }
      </a>
    }
    @case ("list") {
      @switch (data.format) {
        @case ("unordered") {
          <ul>
            @for (child of data.children; track child) {
              <ng-template [ngTemplateOutlet]="loopTemplateRef" [ngTemplateOutletContext]="{ data: child }" />
            }
          </ul>
        }
        @case ("ordered") {
          <ol>
            @for (child of data.children; track child) {
              <ng-template [ngTemplateOutlet]="loopTemplateRef" [ngTemplateOutletContext]="{ data: child }" />
            }
          </ol>
        }
      }
    }
    @case ("list-item") {
      @for (child of data.children; track child) {
        <li>
          <ng-template [ngTemplateOutlet]="loopTemplateRef" [ngTemplateOutletContext]="{ data: child }" />
        </li>
      }
    }
    @case ("text") {
      <span [class.fw-bold]="data.bold" [class.font-italic]="data.italic">
        {{ data.text }}
      </span>
    }
  }
</ng-template>
