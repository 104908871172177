<section>
  <div class="wrap">
    <img class="icon mb-4" src="/assets/green-checkmark.svg" />
    <h1 [innerHTML]="'QUOTE_ACCEPTED_TITLE' | translate" class="font-header-xl mb-3">
      {{"YOU_ACCEPTED_THE_OFFER"}}<br>
      {{"THANK_YOU"}}!
    </h1>
    <div [innerHTML]="'QUOTE_ACCEPTED_SUBTITLE' | translate" class="font-body-l subtitle"></div>
  </div>
</section>
