import { BehaviorSubject, distinctUntilChanged, Observable, take } from 'rxjs';
import { Marina, SELECTED_MARINA } from '@dm-workspace/types';
import { filter, map, skip, tap } from 'rxjs/operators';
import { DateUtils } from '@dm-workspace/utils';
import { SortMarinasByCountryPipe } from '../../../../shared/src/lib/pipes/sort-marinas-by-country.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { MarinasApiServiceToken } from '@dm-workspace/data-access';

@Injectable({
  providedIn: 'root',
})
export class MarinasService {
  #marinasApiService = inject(MarinasApiServiceToken);
  #selectedMarinaCodeSubject = inject(SELECTED_MARINA);
  #selectedMarinaSubject: BehaviorSubject<Marina | null> = new BehaviorSubject<Marina | null>(null);
  #marinasSubject = new BehaviorSubject<Marina[] | null>(null);
  #destroyRef = inject(DestroyRef);
  #dmarinWebsiteUrl = 'https://www.d-marin.com';
  #SELECTED_MARINA_LOCAL_STORAGE_KEY = 'MARINA_SELECTED';

  #selectedMarina = signal<Marina>(null);
  selectedMarina = this.#selectedMarina.asReadonly();

  constructor() {
    this.selectedMarina$.subscribe((value) => this.#selectedMarinaCodeSubject.next(value.code || null));
    this.#initService()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((marinas) => this.#marinasSubject.next(marinas));
  }

  #initService(): Observable<Marina[]> {
    return this.#marinasApiService.get(true).pipe(
      map((marinas) => SortMarinasByCountryPipe.transform(marinas)),
      tap((marinas) => {
        if (this.#selectedMarinaSubject.value === null && marinas.length) {
          const savedMarinaId = localStorage.getItem(this.#SELECTED_MARINA_LOCAL_STORAGE_KEY);
          const marina = marinas.find((marina) => marina.code === savedMarinaId) || marinas[0];
          this.#selectedMarinaSubject.next(marina);
          this.#selectedMarina.set(marina);
        }
      })
    );
  }

  get selectedMarina$(): Observable<Marina> {
    return this.#selectedMarinaSubject.asObservable().pipe(filter(Boolean)) as Observable<Marina>;
  }

  get selectedMarinaChange$(): Observable<Marina> {
    return this.#selectedMarinaSubject
      .asObservable()
      .pipe(filter(Boolean), skip(1), distinctUntilChanged()) as Observable<Marina>;
  }

  get marinas$(): Observable<Marina[]> {
    return this.#marinasSubject.asObservable().pipe(filter(Boolean)) as Observable<Marina[]>;
  }

  get selectedMarinaCode$(): Observable<string> {
    return this.selectedMarina$.pipe(map((marina) => marina.code));
  }

  public selectMarina(marina: Marina): void {
    localStorage.setItem(this.#SELECTED_MARINA_LOCAL_STORAGE_KEY, marina.code);
    this.#selectedMarina.set(marina);
    this.#selectedMarinaSubject.next(marina);
  }

  public getMarinaByCode(marinaCode: string): Observable<Marina | undefined> {
    return this.marinas$.pipe(
      map((marinas) => {
        return marinas.find((marina) => marina.code === marinaCode);
      })
    );
  }

  public getMarinaDateByCode(marinaCode: string): Observable<Date> {
    return this.getMarinaByCode(marinaCode).pipe(
      map((marina) => {
        return this.getMarinaTime(marina);
      })
    );
  }

  public getMarinaTime(marina: Marina): Date {
    const now = new Date();
    const { timezone } = marina?.additionalProperties || {};
    return DateUtils.changeTimezone(now, timezone);
  }

  public getMarinaWebsiteLink({ slug, countyCode = 'en' }: { slug: string; countyCode?: string }): string {
    if (slug) {
      return `${this.#dmarinWebsiteUrl}/${countyCode}/marinas/${slug}/`;
    } else {
      return `${this.#dmarinWebsiteUrl}/${countyCode}/`;
    }
  }

  public validatedMarinaCode(marinaCode: string): Observable<boolean> {
    return this.marinas$.pipe(
      take(1),
      map((marinas) => marinas.find((marina) => marina.code === marinaCode)),
      map((marina) => {
        if (marina) {
          this.selectMarina(marina);
        }
        return !!marina?.code;
      })
    );
  }
}
