export enum BoatMaterial {
  WOOD = 'Wood',
  Alluminium = 'Alluminium',
  Concrete = 'Concrete',
  Kevlar = 'Kevlar',
  Laminated = 'Laminated',
  Other = 'Other',
  Plastic = 'Plastic',
  Steel = 'Steel',
  Polyester = 'Polyester',
}
