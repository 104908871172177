export interface Note {
  id: string;
  marinaCode: string;
  note: string;
  title: string;
  author: {
    id: string;
    name: string;
  };
  timestamp: string;
}

export interface INoteRequestParams {
  marinaCode: string;
  note: string;
  title: string;
}

export interface INoteRequest extends INoteRequestParams {
  boatId: string;
}
