import { PaginationQuery, ResourceType } from '../backend';
import { Marina } from './marina.interface';

export interface IBoatMovementResource extends Omit<IResource, 'berthIds'> {
  berthIdArray: string[];
  marina?: Marina;
  marinaCode: string;
}

export interface IResource {
  berthIds: string[];
  id: string;
  type: ResourceType;
  name: string;
  disabled: boolean;
}

export type IResourceForBoatQueryParams = PaginationQuery & {
  fromDate: string;
  toDate: string;
  pier?: string;
};

export interface IResourceDetails extends IResource {
  marina: string;
  pier: string;
  pierId: string;
  berthIdArray: string[];
  length: string;
  width: string;
  depth: string;
  minAllowedBoatLength: string;
  minAllowedBoatWidth: string;
  maxAllowedBoatLength: string;
  maxAllowedBoatWidth: string;
  area: string;
  berthStatus: string;
}

export interface IResourceForBoat {
  resource: IResourceDetails;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  occupations: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reservedPercent?: any;
}

export interface IResourceProposition {
  resourceId: string;
  depth: string;
  length: string;
  width: string;
}

export interface IResourcesForBoatRs {
  resources: IResourceForBoat[];
  propositions: IResourceProposition[];
}

export interface IResourceAutocompleteParams {
  pier?: string; //Pier ID or name
  limit?: number;
  marinaCode?: string;
  excludeInaccessible?: boolean;
  types?: ResourceType | ResourceType[];
}
