import { Currency } from '../backend';

export interface AdditionalService {
  name: AdditionalServiceTypes;
  description: string | null;
  price: number;
}

export enum AdditionalServiceTypes {
  BERTHING = 'Berthing',
  ASHORE_SERVICES = 'Ashore services',
  LIFT_AND_LAUNCH = 'Lift and launch',
  CRANE_PASS = 'Crane pass',
  PRESSURE_WASHING = 'Pressure washing',
  DIVER = 'Diver',
  HANGAR_RENTAL = 'Hangar rental',
}

export interface ServicePrice {
  currency: Currency;
  duration: number;
  durationUnit: 'DAYS' | 'YEAR';
  sumPrice: number;
}
