import { Currency } from '../backend';

export interface GetWalletBalancesDto extends WalletChangeThresholdsRequestDto {
  amount: number;
  minBalanceAlertThreshold: number;
  maxBalanceAlertThreshold: number;
  minTopUpAmount: number;
  topUpCurrency: Currency;
}
export interface CustomerWalletTopUpDto {
  amount: number | null;
  topUpType: MmsWalletTopUpType;
}
export interface WalletChangeThresholdsRequestDto {
  balanceAlertThreshold: number;
  balanceAlertEnabled: boolean;
}
export interface WalletTopUpHistoryDto {
  amount: number;
  createdAt: string;
}
export type WalletTopUpHistoryResponseDto = WalletTopUpHistoryDto[];

export interface MmsWalletStepsModalLabels {
  button: string;
  header: string;
}
export interface MmsWalletStepsModalViewModel {
  labels: MmsWalletStepsModalLabels;
  type: MmsWalletStepsModalViewTypes;
  steps: MmsWalletModalSteps[];
  successMessage: string;
}
export type MmsWalletStepsModalViewModelsByType = Record<MmsWalletStepsModalViewTypes, MmsWalletStepsModalViewModel>;

export enum MmsWalletTopUpType {
  CASH = 'CASH',
  INITIAL_BALANCE = 'INITIAL_BALANCE',
  ONLINE_REQUEST = 'ONLINE_REQUEST',
  ONLINE_REQUEST_WITH_AMOUNT = 'ONLINE_REQUEST_WITH_AMOUNT',
}
export enum MmsWalletModalSteps {
  Description,
  SelectProcessMethod,
  TopUpAmount,
  SelectPaymentMethod,
  SocketNumbers,
  ContactEmail,
}
export enum MmsWalletStepsModalViewTypes {
  Start,
  Change,
  TopUp,
}
