import { Pipe, PipeTransform } from '@angular/core';
import { isNowBeforeEndOfDayDate } from '@dm-workspace/utils';

@Pipe({
  name: 'isNowBeforeDatesEndOfDay',
})
export class IsNowBeforeDatesEndOfDay implements PipeTransform {
  transform(dateString: string): boolean {
    return isNowBeforeEndOfDayDate(dateString);
  }
}
