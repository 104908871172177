import { inject, Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AutocompleteService } from '@dm-workspace/data-access';
import { IAutocompleteCountyResponse } from '@dm-workspace/types';

@Pipe({
  name: 'getStateById',
})
export class GetStateByIdPipe implements PipeTransform {
  #autocompleteService = inject(AutocompleteService);

  transform(stateId: string | undefined | null): Observable<IAutocompleteCountyResponse> {
    if (!stateId) {
      return;
    }

    return this.#autocompleteService.getStateById(stateId).pipe(map((v) => v[0]));
  }
}
