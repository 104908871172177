import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGES_SUPPORTED_BY_D365, LOCALIZATION_LANGUAGES } from '@dm-workspace/types';

@Pipe({
  name: 'languageTranslateKey',
})
export class LanguageTranslateKeyPipe implements PipeTransform {
  public transform(value: LOCALIZATION_LANGUAGES | undefined) {
    if (!value) {
      return;
    }
    return LANGUAGES_SUPPORTED_BY_D365.find((lang) => lang.code === value)?.name;
  }
}
