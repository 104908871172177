import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { Breakpoint } from '@dm-workspace/core';
import { BookingResource } from '@dm-workspace/types';

@Component({
  selector: 'dm-ncp-berth-map-item',
  templateUrl: './ncp-berth-map-item.component.html',
  styleUrl: './ncp-berth-map-item.component.scss',
})
export class NcpBerthMapItemComponent {
  @Input() resource: BookingResource;
  public openList = input<boolean>(false);
  protected readonly Breakpoint = Breakpoint;
  @Output() chooseBerth = new EventEmitter<BookingResource>();
}
