import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RolesService } from '@dm-workspace/core';

@Pipe({
  name: 'roleName',
})
export class RoleNamePipe implements PipeTransform {
  constructor(private rolesService: RolesService) {}
  transform(roleId: string): Observable<string> {
    return this.rolesService.roles$.pipe(
      map((roles) => {
        const matchingRole = roles.find((role) => role.id === roleId);
        return matchingRole?.name || roleId;
      })
    );
  }
}
