import { inject, Injectable } from '@angular/core';
import { ApiHttpClient, ApiService } from '../api/api-http-client';
import {
  CustomerOrder,
  MARINA_URL_TOKEN,
  EntityPaymentMethods,
  TPaymentChannel,
  IResourceBookingOrderPaymentPayload,
  TResourceBookingPaymentResponse,
  PaymentHistory,
} from '@dm-workspace/types';

export type ObGetOrderByIdParams = {
  checkId: string;
};

export type ObGetOrderPaymentMethods = {
  marinaCode?: string;
  checkId: string;
  orderId: string;
  shopperLocale: string;
  channel: TPaymentChannel;
};
@Injectable({
  providedIn: 'root',
})
export class ObOrdersApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/online-booking/${MARINA_URL_TOKEN}/order`);

  getById(orderId: string, params: ObGetOrderByIdParams) {
    return this.apiHttpClient.get<CustomerOrder>(`${this.resourceUrl}/${orderId}`, { params });
  }

  getPaymentHistory(orderId: string, params: ObGetOrderByIdParams) {
    return this.apiHttpClient.get<PaymentHistory>(`${this.resourceUrl}/${orderId}/payments`, { params });
  }

  getPaymentMethods({ marinaCode, ...params }: ObGetOrderPaymentMethods) {
    const modifiedResourceUrl = this.modifyResourceUrl({ marinaCode });
    return this.apiHttpClient.post<EntityPaymentMethods>(`${modifiedResourceUrl}/enabled-payment-methods`, params);
  }

  paymentOrder(payload: IResourceBookingOrderPaymentPayload) {
    return this.apiHttpClient.post<TResourceBookingPaymentResponse>(`${this.resourceUrl}/payment`, payload);
  }
}
