<div class="modal-header" *ngIf="title">
  <h2 class="title">{{ title | translate }}</h2>
</div>

<div class="modal-body">
  <p [innerHTML]="(body | translate) + (name ? ' <b>' + name + '</b>' : '')"></p>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-secondary-negative" (click)="activeModal.close(false)">
    {{ cancelBtn | translate }}
  </button>
  <button type="button" class="btn" [ngClass]="actionButtonClass" (click)="activeModal.close(true)">
    {{ confirmBtn | translate }}
  </button>
</div>
