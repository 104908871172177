import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EntityPaymentStatus } from '@dm-workspace/types';
import { PaymentErrorTypePipe } from '../../pipes/payment-error-type.pipe';

export type PaymentStatusComponentType = 'success' | 'warning' | 'error';

@Component({
  selector: 'dm-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentStatusComponent {
  @Input() entityPaymentStatus: EntityPaymentStatus;
  @Input() contactEmail: string | undefined;
  @Input() entityId: string;
  @Input() ncpOrderPaymentView: boolean;

  @HostBinding('class')
  get hostClass(): string {
    return PaymentErrorTypePipe.getPaymentErrorTypeFromPaymentStatus(this.entityPaymentStatus);
  }
}
