import { Component, forwardRef, InjectionToken, signal } from '@angular/core';

export const NcpLayoutMainView = new InjectionToken<NcpLayoutMainViewComponent>('NcpLayoutMainView');

@Component({
  selector: 'dm-ncp-layout-main-view',
  templateUrl: './ncp-layout-main-view.component.html',
  styleUrls: ['./ncp-layout-main-view.component.scss'],
  providers: [{ provide: NcpLayoutMainView, useExisting: forwardRef(() => NcpLayoutMainViewComponent) }],
})
export class NcpLayoutMainViewComponent {
  protected readonly showingFooter = signal<boolean>(true);
  protected readonly showingLoginButton = signal<boolean>(true);
  protected readonly showingNavbar = signal<boolean>(true);

  changeFooterVisibility(visibility: boolean) {
    this.showingFooter.set(visibility);
  }

  changeHeaderLoginButtonVisibility(visibility: boolean) {
    this.showingLoginButton.set(visibility);
  }

  changeNavbarVisibility(visibility: boolean) {
    this.showingNavbar.set(visibility);
  }
}
