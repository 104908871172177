export enum BoatType {
  MOTOR_BOAT = 'Motor Boat',
  SAIL_BOAT = 'Sail Boat',
  MULTI_HULL_SAIL = 'Multi Hull Sail',
  MULTI_HULL_MOTOR = 'Multi Hull Motor',
  DINGHY = 'Dinghy',
  JET_SKI = 'JetSki',
  GULET = 'Gulet',
  OTHER = 'Other',
}

export enum BoatTypeCustomer {
  MOTOR_BOAT = BoatType.MOTOR_BOAT,
  SAIL_BOAT = BoatType.SAIL_BOAT,
  MULTI_HULL_SAIL = BoatType.MULTI_HULL_SAIL,
  MULTI_HULL_MOTOR = BoatType.MULTI_HULL_MOTOR,
}

export enum ObBoatTypes {
  MOTOR_BOAT = 'Motor Boat',
  SAIL_BOAT = 'Sail Boat',
  MULTIHULL_SAIL = 'Multihull Sail',
  MULTIHULL_MOTOR = 'Multihull Motor',
}

export const onlineBookingBoatTypeMap: Record<ObBoatTypes, BoatType> = {
  [ObBoatTypes.SAIL_BOAT]: BoatType.SAIL_BOAT,
  [ObBoatTypes.MOTOR_BOAT]: BoatType.MOTOR_BOAT,
  [ObBoatTypes.MULTIHULL_MOTOR]: BoatType.MULTI_HULL_MOTOR,
  [ObBoatTypes.MULTIHULL_SAIL]: BoatType.MULTI_HULL_SAIL,
};
export const boatTypeMap: Record<BoatTypeCustomer, ObBoatTypes> = {
  [BoatType.SAIL_BOAT]: ObBoatTypes.SAIL_BOAT,
  [BoatType.MOTOR_BOAT]: ObBoatTypes.MOTOR_BOAT,
  [BoatType.MULTI_HULL_MOTOR]: ObBoatTypes.MULTIHULL_MOTOR,
  [BoatType.MULTI_HULL_SAIL]: ObBoatTypes.MULTIHULL_SAIL,
};
export const boatTypeToPortalTypes = {
  [BoatType.SAIL_BOAT]: BoatType.SAIL_BOAT,
  [BoatType.MOTOR_BOAT]: BoatType.MOTOR_BOAT,
  [BoatType.MULTI_HULL_MOTOR]: BoatType.MULTI_HULL_MOTOR,
  [BoatType.MULTI_HULL_SAIL]: BoatType.MULTI_HULL_SAIL,
};
export const cmsMapperBoatType: Record<string, BoatTypeCustomer> = {
  ['sailboat']: BoatTypeCustomer.SAIL_BOAT,
  ['motorboat']: BoatTypeCustomer.MOTOR_BOAT,
  ['multihull-motorboat']: BoatTypeCustomer.MULTI_HULL_MOTOR,
  ['multihull-sailboat']: BoatTypeCustomer.MULTI_HULL_SAIL,
};
