import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@dm-workspace/environments';
import { ENV, ApiFile } from '@dm-workspace/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileApiService {
  private resourceUrl: string;

  constructor(@Inject(ENV) private env: IEnvironment, public httpClient: HttpClient) {
    this.resourceUrl = `${this.env.apiUrl}/file`;
  }

  public fetchAll(): Observable<ApiFile[]> {
    return this.httpClient.get<ApiFile[]>(this.resourceUrl);
  }

  public fetchMine(): Observable<ApiFile[]> {
    return this.httpClient.get<ApiFile[]>(this.resourceUrl);
  }

  public upload(file: FormData): Observable<HttpEvent<ApiFile>> {
    return this.httpClient.post<ApiFile>(this.resourceUrl, file, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.resourceUrl}/${id}`);
  }
}
