import { ChangeDetectionStrategy, Component, ContentChild, signal } from '@angular/core';
import { FormControlDirective } from '../form-group/form-group.component';

@Component({
  selector: 'dm-form-password-toggle',
  templateUrl: './password-toggle.component.html',
  styleUrls: ['./password-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordToggleComponent {
  @ContentChild(FormControlDirective, { static: false }) inputChild?: FormControlDirective;
  public readonly passwordVisible = signal<boolean>(false);

  public toggleType() {
    if (!this.inputChild) {
      return;
    }
    this.passwordVisible.update((v) => !v);
    this.inputChild.setType = this.passwordVisible() ? 'text' : 'password';
  }
}
