import { ActivatedRouteSnapshot, Data, Route, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionService } from './permission.service';
import { Permissions } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(private permissions: PermissionService, private router: Router) {}

  canActiveByPermission(data: Data): boolean | UrlTree {
    const permissions = data.permissions as Permissions;
    const hasPermission = permissions && this.permissions.hasPermission(permissions);
    if (!this.permissions.isLoggedIn) {
      return true;
    } else if (!hasPermission) {
      return this.router.parseUrl('/403');
    }

    return hasPermission;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.canActiveByPermission(route.data);
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return route.data.permissions ? this.canActiveByPermission(route.data) : true;
  }

  canLoad(route: Route): boolean | UrlTree {
    return this.canActiveByPermission(route.data);
  }
}
