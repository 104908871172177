import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  public transform(value: string | null | undefined): string {
    if (!value) {
      return;
    }
    return value
      .split(' ', 2)
      .map((v) => v[0])
      .join('')
      .toUpperCase();
  }
}
