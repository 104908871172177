import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FilesItemComponent } from './components/files-item/files-item.component';
import { FilesItemUploadComponent } from './components/files-item-upload/files-item-upload.component';
import { FilesLocalItemComponent } from './components/files-local-item/files-local-item.component';
import { LibsSharedModule } from '@dm-workspace/shared';
import { FilesDropComponent } from './components/files-drop/files-drop.component';
import { FilesSelectComponent } from './components/files-select/files-select.component';
import { FilesUploaderComponent } from './components/files-uploader/files-uploader.component';

const exports = [FilesSelectComponent, FilesUploaderComponent];

@NgModule({
  declarations: [...exports, FilesDropComponent, FilesItemComponent, FilesItemUploadComponent, FilesLocalItemComponent],
  imports: [TranslateModule, LibsSharedModule],
  exports: [...exports],
})
export class FormsFilesModule {}
