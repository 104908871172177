import {
  ApiErrorResponse,
  ApiErrorValidationResponse,
  DmarinError,
  DmarinErrorCodes,
  DmarinFailedValidationSpecificError,
} from '@dm-workspace/types';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

export class ApiValidator {
  static getApiNotificationError(res?: HttpErrorResponse): string {
    if (ApiValidator.isApiErrorResponse(res)) {
      const { errorCode, detailedErrorCode } = res.error;
      return 'ERRORS.' + (detailedErrorCode || errorCode);
    }
    return res?.message ?? 'ERRORS.UNKNOWN_ERROR';
  }

  static isApiValidationErrorResponse(res: HttpErrorResponse): res is ApiErrorValidationResponse {
    return ApiValidator.isApiErrorResponse(res) && res.error.errorCode === DmarinErrorCodes.VALIDATION_FAILED;
  }

  static isApiErrorResponse(res?: HttpErrorResponse): res is ApiErrorResponse {
    return !!(res?.error as DmarinError | undefined)?.errorCode;
  }

  static setApiValidationErrors(form: FormGroup, res: HttpErrorResponse) {
    if (!ApiValidator.isApiValidationErrorResponse(res)) {
      return;
    }

    for (const controlErrorValidation of res.error.details) {
      const { propertyName } = controlErrorValidation;
      const control = form.get(propertyName);
      const errorMessage = ApiValidator.getErrorValidationMessage(controlErrorValidation.errors);
      if (!control || !errorMessage) {
        return;
      }

      control.setErrors({
        server: errorMessage,
      });
      control.markAsDirty();
    }
  }

  static getErrorValidationMessage(value: DmarinFailedValidationSpecificError[]): string {
    return value.reduce((acc, v) => acc + v.message + '\n', '');
  }
}
