import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { ENV } from '@dm-workspace/types';
import { IEnvironment } from '@dm-workspace/environments';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private mapIsInit = false;
  private defaultOptions: google.maps.MapOptions = {
    zoom: 4,
    mapId: this.env.mapId,
  };

  constructor(
    @Inject(ENV) private env: IEnvironment,
    private httpClient: HttpClient
  ) {}

  init(options?: google.maps.MapOptions): Observable<google.maps.MapOptions> {
    const returnOptions = Object.assign({}, this.defaultOptions, options || {});
    return this.mapIsInit
      ? of(returnOptions)
      : this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.env.mapApiKey}`, 'callback').pipe(
          tap((value) => (this.mapIsInit = true)),
          map(() => returnOptions)
        );
  }

  setMapBoundsAndCenter(map: google.maps.Map, latLngArray: [number, number][], padding?: number | google.maps.Padding) {
    const latLngList = latLngArray.map((value) => {
      const [lng, lat] = value;
      return new google.maps.LatLng(lat, lng);
    });
    const latlngbounds = new google.maps.LatLngBounds();
    latLngList.forEach((latLng) => {
      latlngbounds.extend(latLng);
    });
    // this.center = latlngbounds.getCenter().toJSON();

    if (map) {
      map.fitBounds(latlngbounds, padding);
    }
  }
}
