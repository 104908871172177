import { Component, Input } from '@angular/core';

@Component({
  selector: 'dm-form-input-icon',
  templateUrl: './form-input-icon.component.html',
  styleUrls: ['./form-input-icon.component.scss'],
})
export class FormInputIconComponent {
  @Input() public icon!: string;
}
