<section>
  <div class="d-flex justify-content-start align-items-start description">
    <dm-form-group>
      <input
        dmForm
        type="radio"
        [name]="name"
        [disabled]="isDisabled()"
        [value]="value"
        [checked]="checked()"
        [id]="id"
      />
      <label></label>
    </dm-form-group>
    <div>
      <h4 class="text-body-l">{{ name | translate }}</h4>
      <p class="text-body-s text-body-md-m fw-400">{{ "BOAT_TYPE_DESCRIPTION." + name | translate }}</p>
    </div>
  </div>
  <div class="img-wrapper">
    <dm-ui-boat-image [boatType]="value" />
  </div>
</section>
