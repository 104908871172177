import { Component, HostBinding, Input } from '@angular/core';
import { spinnerShowAnimation } from '../../animations/spinner-show.animation';

@Component({
  selector: 'dm-ui-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [spinnerShowAnimation],
})
export class SpinnerComponent {
  @Input() public showSpinner: boolean;
  @Input() public size: 'xs' | 'sm' | 'lg' = 'sm';
  @Input() public color: 'white' | 'primary' = 'white';
  @HostBinding('class')
  public get bindHostClassNames(): string {
    return this.showSpinner ? this.hostClassNames : '';
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') public hostClassNames: string;

  protected get sizeClass(): string {
    return `spinner--${this.size}`;
  }
}
