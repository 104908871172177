import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'dm-ncp-dimensions',
  template: `{{ 'LENGTH' | translate }}: {{ length() }}m, {{ 'WIDTH' | translate }}: {{ width() }}m,
    {{ 'DRAFT' | translate }}: {{ draft() }}m`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpDimensionsComponent {
  width = input.required();
  length = input.required();
  draft = input.required();
}
