import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter } from '@dm-workspace/core';

export class NgbDateUtils {
  static convertValueToDateStruct(value: string | Date | NgbDateStruct): NgbDateStruct {
    if (!value) {
      return;
    }
    if (value instanceof Date) {
      return {
        year: value.getFullYear(),
        month: value.getMonth() + 1,
        day: value.getDate(),
      };
    }
    if (typeof value === 'string') {
      const adapter = new CustomAdapter();
      return adapter.fromModel(value);
    }
    return value;
  }
}
