import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { LibsFormsModule } from '@dm-workspace/forms';
import { NcpUiModule } from '@dm-workspace/new-customer-portal/ncp-ui';
import { UiModule } from '@dm-workspace/ui';
import { NcpSharedModule } from '@dm-workspace/new-customer-portal/ncp-shared';
import { TranslateModule } from '@ngx-translate/core';
import { LibsSharedModule } from '@dm-workspace/shared';
import { NcpAuthLoginComponent } from './components/ncp-auth-login/ncp-auth-login.component';
import { NcpAuthSetResetPasswordComponent } from './components/ncp-auth-set-reset-password-view/ncp-auth-set-reset-password.component';
import { NcpAuthForgotPasswordComponent } from './components/ncp-auth-forgot-password-view/ncp-auth-forgot-password.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NcpAuthLoginComponent, NcpAuthSetResetPasswordComponent, NcpAuthForgotPasswordComponent],
  exports: [NcpAuthLoginComponent, NcpAuthSetResetPasswordComponent, NcpAuthForgotPasswordComponent],
  providers: [],
  imports: [
    LibsFormsModule,
    CommonModule,
    UiModule,
    RouterModule,
    CommonModule,
    TranslateModule,
    NcpSharedModule,
    NcpUiModule,
    ReactiveFormsModule,
    LibsSharedModule,
  ],
})
export class NcpAuthModule {}
