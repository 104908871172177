import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Features, FeaturesEnabledService } from '@dm-workspace/core';

@Directive({
  selector: '[dmHasFeature]',
})
export class HasPermissionDirective implements OnChanges {
  private elseTemplateRef?: TemplateRef<unknown>;

  @Input() dmHasFeature!: Features | undefined;

  @Input() set dmHasPermissionElse(templateRef: TemplateRef<unknown>) {
    this.elseTemplateRef = templateRef;
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private featuresService: FeaturesEnabledService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateView();
  }

  private updateView(): void {
    if (this.checkFeature()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (this.elseTemplateRef) {
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      return;
    }
  }

  private checkFeature(): boolean {
    return this.dmHasFeature === undefined || this.featuresService.hasFeatureEnabled(this.dmHasFeature);
  }
}
