export interface IPreferences {
  privacyPolicy: boolean;
  marketingContent: boolean;
  specialNeeds: {
    wheelchair: boolean;
    disabilityGolfCart: boolean;
  };
  mooringPreferences: {
    mooringAssistant: boolean;
    bertCloseTo: {
      sanitary: boolean;
      parking: boolean;
      office: boolean;
      restaurants: boolean;
      gasStation: boolean;
      exitOfMarina: boolean;
      other: boolean;
      otherMsg: string;
    };
  };
}
