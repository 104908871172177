import { Directive } from '@angular/core';
import { IRequestParamsWithPagination, PaginationQuery } from '@dm-workspace/types';

@Directive()
export abstract class CollectionListClassDirective<F> {
  filters: Partial<F>;
  protected defaultFilters: Partial<F>;
  public showSpinner = false;
  public pagination: PaginationQuery = {
    limit: 20,
    offset: 0,
  };

  constructor() {
    this.defaultFilters = {
      ...this.filters,
    };
  }

  abstract fetchCollection(): void;

  onFiltersChange($event: F): void {
    this.filters = $event;
    this.fetchCollection();
  }

  onPageChange(page: number): void {
    this.pagination.offset = this.pagination.limit * (page - 1);
    this.fetchCollection();
  }

  onLimitChange(limit: number): void {
    this.pagination.limit = limit;
    this.pagination.offset = 0;
    this.fetchCollection();
  }

  resetPageAndFilters(fetchAfter = true, defaultFilters: F = undefined): void {
    this.filters = defaultFilters;
    this.pagination.offset = 0;
    if (fetchAfter) {
      this.fetchCollection();
    }
  }

  getParams(): IRequestParamsWithPagination<F> {
    return {
      ...this.pagination,
      ...this.filters,
    };
  }
}
