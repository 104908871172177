import { DestroyRef, inject, Injectable } from '@angular/core';
import {
  BookingResource,
  CountryCode,
  DateRange,
  ISimpleBrandModel,
  BoatTypeCustomer,
  ObReservationChannel,
  MarinaTermsTypes,
  AllTerms,
  TermsForm,
  ConsentDto,
} from '@dm-workspace/types';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isDateRangeValidator } from '@dm-workspace/forms';
import {
  ProfileDetailsForm,
  ProfileDetailsFormService,
  ProfileDocumentsForm,
  ProfileDocumentsFormService,
  ProfileFormService,
} from '@dm-workspace/shared';
import { NcpMarinasService } from '@dm-workspace/new-customer-portal/ncp-layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type NcpCustomerForm = FormGroup<
  ProfileDetailsForm['controls'] &
    ProfileDocumentsForm['controls'] &
    FormGroup<{
      email: FormControl<string>;
      isIndividual: FormControl<boolean>;
      companyName: FormControl<string>;
    }>['controls']
>;
export type NcpProfileFormValue = ReturnType<NcpCustomerForm['getRawValue']>;

const BOOKING_TERMS = [MarinaTermsTypes.services, MarinaTermsTypes.vexationClauses, MarinaTermsTypes.privacyPolicy];
const ANNUAL_TERMS = [
  MarinaTermsTypes.servicesRenewals,
  MarinaTermsTypes.vexationClausesRenewals,
  MarinaTermsTypes.withdrawalTermRenewals,
];

@Injectable()
export class NcpBookingFormService {
  #fb = inject(FormBuilder);
  #dRef = inject(DestroyRef);
  #selectedMarinaService = inject(NcpMarinasService);
  profileForm = inject(ProfileFormService);
  profileDetailsFormService = inject(ProfileDetailsFormService);
  profileDocumentsFormService = inject(ProfileDocumentsFormService);
  bookingForm() {
    // const resource = JSON.parse(localStorage.getItem('resource'));
    return this.#fb.group({
      dates: new FormControl<DateRange>(null, [isDateRangeValidator, Validators.required]),
      resource: new FormControl<BookingResource>(null, Validators.required),
      boat: this.boatDimensionForm(),
    });
  }
  summaryForm(marinaCode?: string) {
    return this.#fb.group({
      boat: this.boatDetailsForm(),
      channel: this.createChannelController(),
      customer: this.createProfileForm({}, marinaCode),
    });
  }

  public createProfileForm(initValue: Partial<NcpProfileFormValue>, marinaCode: string): NcpCustomerForm {
    const form = this.profileForm.createForm(initValue, {
      marinaCode: marinaCode as CountryCode,
    }) as unknown as NcpCustomerForm;
    form.addControl('email', this.profileDetailsFormService.createEmailControl(initValue?.email));
    form.addControl('isIndividual', new FormControl<boolean>(true, { nonNullable: true }));
    form.addControl(
      'companyName',
      new FormControl<string>(null, {
        updateOn: 'blur',
      })
    );
    this.addValidationsToPersonalForm(form);
    return form;
  }
  boatDimensionForm() {
    return this.#fb.group({
      type: new FormControl<BoatTypeCustomer>(null, Validators.required),
      length: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
      width: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
      draft: new FormControl<number>(null, [Validators.required, Validators.min(1)]),
      existingBoatID: new FormControl<string>(null),
    });
  }
  boatDetailsForm() {
    return this.#fb.group({
      name: new FormControl<string>(null, Validators.required),
      brand: new FormControl<ISimpleBrandModel>(null, Validators.required),
      flag: new FormControl<string>(null, Validators.required),
      registrationNumber: new FormControl<string>(null, Validators.required),
      portOfRegistration: new FormControl<string>(null, Validators.required),
    });
  }

  public createChannelController() {
    return new FormControl<ObReservationChannel>(null, { validators: [Validators.required], updateOn: 'blur' });
  }
  public createTermsAndConditionsForm(terms: AllTerms, isLongTerm: boolean): TermsForm {
    const termsKeys = isLongTerm ? ANNUAL_TERMS : BOOKING_TERMS;
    return this.#fb.record<
      FormGroup<{
        check: FormControl<boolean>;
        terms: FormControl<ConsentDto>;
      }>
    >(
      termsKeys
        .filter((value) => !!terms[value])
        .reduce(
          (p, c) => ({
            ...p,
            [c]: this.#fb.group({
              check: new FormControl<boolean>(false, Validators.requiredTrue),
              terms: [
                {
                  term: c,
                  document: terms[c],
                } as ConsentDto,
              ],
            }),
          }),
          {}
        )
    );
  }

  private addValidationsToPersonalForm(form: NcpCustomerForm) {
    form.controls.isIndividual.valueChanges.pipe(takeUntilDestroyed(this.#dRef)).subscribe((isIndividual) => {
      const lastName = form.controls.lastName;
      const firstName = form.controls.firstName;
      const name = form.controls.companyName;
      if (isIndividual) {
        lastName.setValidators(Validators.required);
        firstName.setValidators(Validators.required);
        name.removeValidators(Validators.required);
        name.reset();
      } else {
        lastName.removeValidators(Validators.required);
        firstName.removeValidators(Validators.required);
        lastName.reset();
        firstName.reset();
        name.setValidators(Validators.required);
      }
      this.updateCustomerFormWhenMarinaChange(
        form,
        this.#selectedMarinaService.selectedMarina()?.marinaOB.countryCode,
        true,
        form.getRawValue()
      );
    });
    form.controls.countryCode.valueChanges.pipe(takeUntilDestroyed(this.#dRef)).subscribe(() => {
      this.updateCustomerFormWhenMarinaChange(
        form,
        this.#selectedMarinaService.selectedMarina()?.marinaOB.countryCode,
        false,
        form.getRawValue()
      );
    });
  }

  updateCustomerFormWhenMarinaChange(
    customer: NcpCustomerForm,
    marinaCode: CountryCode,
    onlyDocuments = false,
    initValue?: NcpProfileFormValue
  ) {
    const customerData = customer.getRawValue();
    if (!onlyDocuments) {
      this.profileDetailsFormService.changeFormByCountryCode(customer, customerData?.countryCode, initValue);
    }
    this.profileDocumentsFormService.changeForm(
      customer,
      {
        marinaCode,
        countryCode: customerData?.countryCode || initValue?.countryCode,
      },
      initValue
    );
  }
}
