import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  forwardRef,
  inject,
  Inject,
  ViewContainerRef,
} from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';
import { map, startWith, switchMap } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { DmUiStepContentDirective } from './step-content.directive';
import { DmUiStepperComponent } from '../stepper/stepper.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dm-ui-step',
  template: `<ng-template>
    <ng-content />
    <ng-template [cdkPortalOutlet]="portal" />
  </ng-template>`,
  providers: [{ provide: CdkStep, useExisting: DmUiStepComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DmUiStepComponent extends CdkStep implements AfterContentInit {
  portal!: TemplatePortal;
  #destroyRef = inject(DestroyRef);
  @ContentChild(DmUiStepContentDirective, { static: false }) _lazyContent!: DmUiStepContentDirective;

  constructor(
    @Inject(forwardRef(() => DmUiStepperComponent)) stepper: DmUiStepperComponent,
    private _viewContainerRef: ViewContainerRef
  ) {
    super(stepper);
  }

  ngAfterContentInit(): void {
    this._stepper.steps.changes
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        switchMap(() => {
          return this._stepper.selectionChange.pipe(
            map((event) => event.selectedStep === this),
            startWith(this._stepper.selected === this)
          );
        })
      )
      .subscribe((isSelected) => {
        if (isSelected && this._lazyContent && !this.portal) {
          this.portal = new TemplatePortal(this._lazyContent._template, this._viewContainerRef!);
        }
      });
  }
}
