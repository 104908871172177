import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { GenericObject, GroupConstructor } from '@dm-workspace/types';
import { mapObjectPropertyValue } from '@dm-workspace/utils';
import { FiltersHelperService } from '@dm-workspace/core';

/**
 * @deprecated use FiltersCollectionDirective instead
 */
@Directive()
export class FiltersCollectionClassDirective<T = unknown, D = T> implements OnInit, OnDestroy {
  @Input() defaultFilters: Partial<D>;
  @Input() emitOnInit = false;
  @Input() storageKey?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() filtersChange = new EventEmitter<any>();
  public filtersCount = signal(0);
  form: UntypedFormGroup = this.buildForm();
  mapKeys: { [key: string]: (value: unknown) => unknown } = {};
  mapInitKeys: { [key: string]: (value: unknown) => unknown } = {};
  protected subscription: Subscription = new Subscription();

  constructor(
    protected fb: UntypedFormBuilder,
    protected filterHelper?: FiltersHelperService
  ) {}

  ngOnInit(): void {
    this.formSubscribe();
    if (this.filterHelper) {
      const queryParams = { ...this.filterHelper.urlParams() };
      Object.keys(queryParams).forEach((key) => {
        if (this.mapInitKeys[key] && queryParams[key] !== null && queryParams[key] !== '') {
          queryParams[key] = this.mapInitKeys[key](queryParams[key]);
        }
      });
      this.form.patchValue({ ...this.formPathValue, ...queryParams }, { emitEvent: this.emitOnInit });
    } else {
      this.form.patchValue({ ...this.formPathValue }, { emitEvent: this.emitOnInit });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected buildForm(): UntypedFormGroup {
    return this.fb.group(this.formBuilderInitialValue());
  }

  protected formBuilderInitialValue?(): GroupConstructor {
    return {};
  }

  private get formPathValue(): GenericObject {
    if (this.storageKey) {
      const storage = this.filterHelper.loadFromStorage(this.storageKey) as T;

      if (storage) {
        return storage;
      }
    }

    return this.formInitialPatchValue;
  }

  private get formInitialPatchValue(): GenericObject {
    const formInitialPatchValue = mapObjectPropertyValue(this.formBuilderInitialValue(), (value) => value[0]);

    return {
      ...formInitialPatchValue,
      ...this.defaultFilters,
    };
  }

  protected getFiltersWithoutNull() {
    const filtersBeforeSend = { ...this.defaultFilters, ...this.form.value };
    const filters: { [key: string]: string } = {};
    Object.keys(filtersBeforeSend).forEach((key) => {
      if (this.mapKeys[key]) {
        filtersBeforeSend[key] = this.mapKeys[key](filtersBeforeSend[key]) as string;
      }
      if (filtersBeforeSend[key] !== null && filtersBeforeSend[key] !== '' && filtersBeforeSend[key] !== undefined) {
        filters[key] = filtersBeforeSend[key] as string;
      }
    });
    return filters;
  }

  private emitFilters(): void {
    if (this.form && !this.form.valid) {
      return;
    }
    const filters = this.getFiltersWithoutNull();

    if (this.filterHelper) {
      this.filterHelper.addToSearchParams(filters);
    }
    this.filtersCount.set((Object.keys(filters) as [keyof D]).filter((value) => !this.defaultFilters?.[value]).length);
    this.filtersChange.emit(filters);
  }

  private formSubscribe(): void {
    this.subscription.add(
      this.form.valueChanges.pipe(debounceTime(500), distinctUntilChanged(isEqual)).subscribe((value) => {
        this.saveFilters(value);
        this.emitFilters();
      })
    );
  }

  private saveFilters(value: T) {
    if (!this.storageKey) {
      return;
    }

    this.filterHelper.saveToStorage(this.storageKey, value);
  }

  public resetForm($event: Event): void {
    $event.preventDefault();
    this.form.reset({ ...this.formInitialPatchValue });
  }
}
