import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITicketMessage } from '@dm-workspace/types';

@Component({
  selector: 'dm-ui-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss'],
})
export class TicketsListComponent {
  @Input() comments: ITicketMessage[];
  @Input() disableNewComment: boolean;

  @Output() addNewClick: EventEmitter<null> = new EventEmitter();
}
