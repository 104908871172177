import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dm-form-input-value',
  templateUrl: './input-value.component.html',
  styleUrls: ['./input-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputValueComponent {
  /**
   * @deprecated use [body] instead
   */
  @Input() public value?: string | number | undefined;
  /**
   * @deprecated use [header] instead
   */
  @Input() public label?: string;
}
