import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { PaymentMethodMount } from '@dm-workspace/types';
import { HttpErrorResponse } from '@angular/common/http';

@Directive()
export abstract class PaymentMethodComponent {
  @Input({ required: true }) paymentReferenceId: string;
  @Output() paymentSuccess = new EventEmitter<void>();
  @Output() paymentError = new EventEmitter<void>();
  @Output() paymentPending = new EventEmitter<void>();
  @Output() paymentMounted = new EventEmitter<PaymentMethodMount>();
  @Output() paymentMountError = new EventEmitter<HttpErrorResponse>();
}
