import { Component, computed, ElementRef, inject, signal, ViewChild } from '@angular/core';
import { ObQuoteViewService } from '../../services/ob-quote-view.service';
import { Breakpoint, BreakpointsService } from '@dm-workspace/core';
import { ObQuotesApiService } from '@dm-workspace/data-access';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize, switchMap } from 'rxjs/operators';
import { NotificationService } from '@dm-workspace/notification';
import { FormUtils } from '@dm-workspace/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteStatus, QuoteType } from '@dm-workspace/types';
import { TermsApiService } from '../../../../../../data-access/src/libs/terms-api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';

@Component({
  selector: 'dm-ob-quote-view',
  templateUrl: './ob-quote-view.component.html',
  styleUrls: ['./ob-quote-view.component.scss'],
})
export class ObQuoteViewComponent {
  #quotesApiService = inject(ObQuotesApiService);
  #quoteViewService = inject(ObQuoteViewService);
  #breakpointService = inject(BreakpointsService);
  #notificationService = inject(NotificationService);
  #termsApiService = inject(TermsApiService);
  #activatedRoute = inject(ActivatedRoute);
  #router = inject(Router);

  @ViewChild('sectionProductsDetails') protected readonly sectionProductsDetailsEl: ElementRef<HTMLElement>;
  protected readonly quote = this.#quoteViewService.quote;
  protected readonly marinaAgreementsAgree = signal<boolean>(false);
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly quoteMarina$ = this.#quoteViewService.quoteMarina$;
  protected readonly isMinTablet = computed(() => this.#breakpointService.breakpoint() >= Breakpoint.TABLET);
  protected readonly marinaTerms$ = this.#quoteViewService.quoteMarina$.pipe(
    switchMap((marina) => this.#termsApiService.fetchMarinaTerms(marina.code))
  );
  protected readonly marinaAgreementsForm$ = this.marinaTerms$.pipe(
    map((terms) => {
      const form = new FormGroup({});
      if (terms.withdrawalTermRenewals) {
        form.addControl('withdrawalTermRenewals', new FormControl(false, [Validators.requiredTrue]));
      }
      if (terms.vexationClausesRenewals) {
        form.addControl('vexationClausesRenewals', new FormControl(false, [Validators.requiredTrue]));
      }
      if (terms.servicesRenewals) {
        form.addControl('servicesRenewals', new FormControl(false, [Validators.requiredTrue]));
      }
      return form;
    })
  );

  onMarinaAgreementsFormValidationChange(isValid: boolean) {
    this.marinaAgreementsAgree.set(isValid);
  }

  protected clickAcceptOffer() {
    const { quoteId } = this.quote();
    if (this.apiCalling() || !quoteId || !this.marinaAgreementsAgree()) {
      return;
    }
    this.apiCalling.set(true);

    this.#quotesApiService
      .acceptQuote(quoteId, this.#quoteViewService.routeSnapshot().queryParams)
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: () => {
          this.#notificationService.openSuccess();
          this.#router.navigate(['./accepted'], {
            relativeTo: this.#activatedRoute,
          });
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = FormUtils.getApiNotificationError(res);
          this.#notificationService.openError(notificationContent);
        },
      });
  }

  protected scrollToPriceDetails() {
    this.sectionProductsDetailsEl?.nativeElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  protected readonly QuoteStatus = QuoteStatus;
  protected readonly QuoteType = QuoteType;
}
