import { Injectable, OnDestroy } from '@angular/core';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { BoatType, IBoat, IBoatCreatePayload, IBoatDto } from '@dm-workspace/types';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from '../auth/auth.service';

export type RefreshViewType = 'sensors' | 'alerts' | null;

@Injectable({
  providedIn: 'root',
})
export class BoatsService implements OnDestroy {
  protected itemsSubject = new BehaviorSubject<IBoat[]>(null);
  private refreshViewSubject$ = new BehaviorSubject<RefreshViewType>(null);

  private readonly boatIcons: { [key: string]: string } = {
    [BoatType.MOTOR_BOAT]: 'motorboat',
    [BoatType.SAIL_BOAT]: 'boat',
    [BoatType.MULTI_HULL_MOTOR]: 'catamaran',
    [BoatType.MULTI_HULL_SAIL]: 'catamaran',
  };

  public ngOnDestroy() {
    this.refreshViewSubject$.complete();
  }

  public get refreshView$(): Observable<RefreshViewType> {
    return this.refreshViewSubject$.asObservable();
  }

  public triggerViewRefresh(tab?: RefreshViewType): void {
    this.refreshViewSubject$.next(tab ?? null);
  }

  public get items$(): Observable<IBoat[]> {
    return this.itemsSubject.asObservable().pipe(distinctUntilChanged());
  }

  protected activeItemSubject = new BehaviorSubject<IBoat>(null);
  public get active$(): Observable<IBoat> {
    return this.activeItemSubject.asObservable().pipe(distinctUntilChanged());
  }

  constructor(
    private boatsApiService: MmsBoatsApiService,
    private authService: AuthService
  ) {
    this.logoutStateReset();
  }

  private logoutStateReset() {
    this.authService.loggedIn$.pipe(filter((v) => !v)).subscribe(() => {
      this.itemsSubject.next(null);
      this.activeItemSubject.next(null);
    });
  }

  public setActive(value: IBoat) {
    this.activeItemSubject.next(value);
  }

  public fetch(): Observable<IBoat[]> {
    return this.boatsApiService.fetch().pipe(map(this.sortBoats));
  }

  public fetchIfEmpty(): Observable<IBoat[]> {
    if (this.itemsSubject.value?.length) {
      return this.items$.pipe(take(1));
    }

    return this.fetch().pipe(
      tap((item) => {
        this.itemsSubject.next(item);
      })
    );
  }

  public fetchById(id: string): Observable<IBoat> {
    if (this.itemsSubject.value !== null) {
      return this.items$.pipe(
        take(1),
        map((items) => items.filter((item) => item.id === id)[0])
      );
    }

    return this.boatsApiService.fetchById(id);
  }

  public delete(id: string): Observable<boolean> {
    return this.boatsApiService.delete(id).pipe(
      tap(() => {
        this.itemsSubject.next((this.itemsSubject.value || []).filter((item) => item.id !== id));
      })
    );
  }

  public create(dto: IBoatCreatePayload): Observable<IBoatDto> {
    return this.boatsApiService.create(dto).pipe(
      tap((response) => {
        const items = this.itemsSubject.value;
        if (!items) {
          return;
        }

        const boatAlreadyAdded = items.some((boat) => boat.id === response.id);
        if (boatAlreadyAdded) {
          throw new Error('BOAT_ALREADY_ADDED');
        }

        this.itemsSubject.next(this.sortBoats([...items, response]));
      }),
      catchError(() => throwError(() => ({ error: { message: 'BOAT_NOT_ADDED' } })))
    );
  }

  public update(id: string, dto: Partial<IBoatCreatePayload>): Observable<IBoat> {
    return this.boatsApiService.update(id, dto).pipe(
      tap((response) => {
        const _items = this.itemsSubject.value;

        if (!_items) {
          return;
        }

        const items = [..._items];
        const index = items.findIndex((item) => item.id === id);

        if (index === -1) {
          return;
        }

        items.splice(index, 1, response);

        this.itemsSubject.next(this.sortBoats(items));
      })
    );
  }

  public getBoatIcon(boatType: BoatType): string {
    return this.boatIcons[boatType];
  }

  private sortBoats(value: IBoat[]) {
    return value.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
}
