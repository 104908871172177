import { Pipe, PipeTransform } from '@angular/core';
import { CorporateType, CustomerType } from '@dm-workspace/types';

@Pipe({
  name: 'getCustomerTypeTranslateKey',
})
export class GetCustomerTypeTranslateKeyPipe implements PipeTransform {
  public transform(corporateOrCustomerType?: CorporateType | CustomerType | null): string {
    if (!corporateOrCustomerType) {
      return '';
    }
    const corporateTypeTranslateKey = this.getCorporateTypeTranslateKey(corporateOrCustomerType as CorporateType);
    return corporateTypeTranslateKey || this.getTypeTranslateKey(corporateOrCustomerType as CustomerType);
  }

  private getCorporateTypeTranslateKey(corporateType: CorporateType) {
    const foundCorporateType = Object.entries(CorporateType).find(([key, value]) => value === corporateType);
    return foundCorporateType?.[0];
  }

  private getTypeTranslateKey(customerType: CustomerType) {
    const foundCustomerType = Object.entries(CustomerType).find(([key, value]) => value === customerType);
    return foundCustomerType?.[0];
  }
}
