import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlAttributeUrlValue',
})
export class HtmlAttributeUrlValue implements PipeTransform {
  public transform(value?: string): string {
    if (!value) return '';
    return `url(${value})`;
  }
}
