import { Component, inject, ViewEncapsulation } from '@angular/core';
import { ObQuoteViewService } from '../../services/ob-quote-view.service';
import { combineLatest, filter, map } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { QuoteType } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-quote-router-wrapper',
  templateUrl: './ob-quote-router-wrapper.component.html',
  styleUrls: ['./ob-quote-router-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ObQuoteRouterWrapperComponent {
  #quoteViewService = inject(ObQuoteViewService);
  protected readonly quoteMarina$ = this.#quoteViewService.quoteMarina$;
  protected readonly fetchingOffer = this.#quoteViewService.fetching;
  protected readonly quote = this.#quoteViewService.quote;
  protected readonly quoteWithMarina$ = combineLatest([
    this.quoteMarina$.pipe(filter(Boolean)),
    toObservable(this.#quoteViewService.quote),
  ]).pipe(
    filter(Boolean),
    map(([marina, quote]) => {
      return { marina, ...quote };
    })
  );
  protected readonly QuoteType = QuoteType;
}
