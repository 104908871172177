import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isValid as isSpainTaxValid } from 'better-dni';

export function taxSpainValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const valid = isSpainTaxValid(control.value);
    if (valid) {
      return;
    }
    return {
      taxExemptNumber: {
        valid: false,
      },
    };
  };
}
