import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  MARINA_URL_TOKEN,
  MmsJournalTaskStatName,
  MmsJournalTaskStats,
  MmsJournalTaskBooking,
  PaginatedNormal,
  PaginationQuery,
} from '@dm-workspace/types';
import { ApiHttpClient, ApiService } from '../api/api-http-client';

@Injectable({
  providedIn: 'root',
})
export class MmsJournalTasksApiService extends ApiService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/mms/journal-tasks/${MARINA_URL_TOKEN}`);

  public getStats(): Observable<MmsJournalTaskStats> {
    return this.apiHttpClient.get<MmsJournalTaskStats>(`${this.resourceUrl}/stats`);
  }

  public getBookingsByStats(
    statName: MmsJournalTaskStatName,
    params?: PaginationQuery
  ): Observable<PaginatedNormal<MmsJournalTaskBooking>> {
    return this.apiHttpClient.get<PaginatedNormal<MmsJournalTaskBooking>>(`${this.resourceUrl}/bookings/${statName}`, {
      params,
    });
  }
}
