<input
  [id]="id"
  type="text"
  [placeholder]="placeholder || ''"
  [ngModel]="model"
  [editable]="editable"
  [inputFormatter]="inputFormatter"
  [resultFormatter]="resultFormatter"
  [ngbTypeahead]="search"
  [disabled]="disabled"
  (ngModelChange)="onModelChange($event)"
  (blur)="onBlur($event)"
/>

<button class="clear-button" type="button" *ngIf="clearable && model !== null">
  <i class="i-close" (click)="clearValue()"></i>
</button>
