import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { CUBIC } from '../constants';

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    // each time the binding value changes
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(.7)', height: '0px' }),
        stagger(100, [animate(`.35s ${CUBIC}`, style({ opacity: 1, transform: 'scale(1)', height: '*' }))]),
      ],
      { optional: true }
    ),
  ]),
]);

export const blockAnimation = trigger('blockAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(.6) translateY(40%)' }),
        stagger(150, [animate(`.5s ${CUBIC}`, style({ opacity: 1, transform: 'scale(1) translateX(0%)', offset: 1 }))]),
      ],
      { optional: true }
    ),
  ]),
]);

export const selfBlockAnimation = trigger('selfBlockAnimation', [
  transition('* => *', [
    query(
      ':self',
      [
        style({ opacity: 0, transform: 'scale(.6) translateY(40%)' }),
        stagger(150, [animate(`.5s ${CUBIC}`, style({ opacity: 1, transform: 'scale(1) translateX(0%)', offset: 1 }))]),
      ],
      { optional: true }
    ),
  ]),
]);
