import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'marinaProductNameTranslate',
})
export class MarinaProductNameTranslatePipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);
  private readonly translatePrefixKey = 'MARINA_PRODUCT_';

  public transform(productDetails: { type: string; name: string }): Observable<string> {
    if (!productDetails) {
      return null;
    }
    const { type, name } = productDetails;

    if (type) return this.getTranslation(type);
    if (name) return of(name);
    return this.getTranslation('UNKNOWN');
  }

  private getTranslation(value: string) {
    return this.translateService.get(`${this.translatePrefixKey}.${value}`);
  }
}
