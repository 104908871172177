<div class="steps">
  <ng-container *ngFor="let step of steps; let i = index; let isLast = last">
    <div
      class="step {{ step }}"
      [ngClass]="{
        current: i === currentStepIndex,
        prev: isPrev(i)
      }"
    >
      <div class="icon-wrapper">
        {{ i + 1 }}
      </div>

      <span class="label">{{ step.label | uppercase | translate }}</span>
    </div>

    <div class="divider" *ngIf="!isLast" [ngClass]="{ prev: isPrev(i) }"></div>
  </ng-container>
</div>
