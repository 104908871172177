import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api-client.service';
import { ServiceBusResendToCrmDto } from '@dm-workspace/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ServiceBusApiService {
  private readonly resourceUrl = `/mms/admin/service-bus`;

  constructor(private api: ApiClientService) {}

  public resendEntities(dto: ServiceBusResendToCrmDto): Observable<void> {
    return this.api.post(`${this.resourceUrl}/resend`, dto);
  }
}
