import { ChangeDetectionStrategy, Component, forwardRef, HostListener, Input } from '@angular/core';
import { RadioButtonClass } from '../radio-group/radio-button-base-class.directive';

@Component({
  selector: 'dm-form-radio-icon',
  templateUrl: './radio-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: RadioButtonClass, useExisting: forwardRef(() => RadioIconComponent) }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'isDisabled()',
    '[class.selected]': 'checked()',
  },
})
export class RadioIconComponent extends RadioButtonClass {
  @Input({ required: true }) icon: string;
  @HostListener('click')
  override onModelChange() {
    super.onModelChange();
  }
}
