<div class="container" [ngClass]="{ horizontal: horizontalOnMobile }">
  <i *ngIf="icon" class="icon i-{{ icon }}"></i>
  <div *ngIf="!icon" class="custom-icon">
    <ng-content select="[icon]" />
  </div>

  <p class="label" [ngClass]="{ uppercase: uppercaseLabel }">
    <ng-content select="[label]" />
  </p>
  <p class="text" [ngClass]="{ large: largerText }">
    <ng-content select="[content]" />
  </p>
</div>
