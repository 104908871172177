import { ChangeDetectionStrategy, Component, signal } from '@angular/core';

@Component({
  selector: 'dm-service-worker-update-modal',
  templateUrl: './service-worker-update-modal.component.html',
  styleUrls: ['./service-worker-update-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceWorkerUpdateModalComponent {
  protected pending = signal<boolean>(false);

  updateVersion() {
    if (this.pending()) {
      return;
    }

    this.pending.set(true);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
