<div class="position-relative wrap container">
  @if (headerBackLink()) {
    <a class="back-link me-2" [routerLink]="headerBackLink()">
      <img class="line-decoration" src="/assets/icons/chevron-left.svg">
    </a>
  }

  <dm-ui-logo class="logo" color="white" [class.ms-5]="headerBackLink()" />

  <h1 class="title">
    {{ title$ | async | translate }}
  </h1>

  <a [href]="backToMarinaLink$ | async" class="close-button">
    <i class="i-close"></i>
  </a>
</div>
