import { PriceListType, UOM, Weekdays } from '../backend';

export interface IPriceAdditionalParams {
  l: number;
  fc: number;
  fd: number;
  dd: number;
  dc: number;
  k: number;
}

export interface IGetPriceList {
  id: string;
  productNumber: string;
  validFrom: string;
  validTo: string;
  name: string;
  type: PriceListType;
  createdAt: string;
  modifiedBy: IPriceListUser;
  params: IPriceListParams;
}

export type IGetPriceListResponse = Omit<IGetPriceList, 'validTo'>;

export interface IPriceListGetOne extends IPriceList {
  tests: IPricingTestScenarioResult;
}

export type IPriceListCreated = Omit<IGetPriceListResponse, 'modifiedBy' | 'createdAt'>;

export type ICreatePriceListParams = {
  dateFrom?: string;
  tests?: IPriceListsTest;
  dryRun?: boolean;
};

export interface IPriceList {
  modifiedBy: IPriceListUser;
  createdAt: string;
  id: string;
  name: string | null;
  type: PriceListType;
  productNumber: string;
  validFrom: string;
  validTo: string;
  uom: UOM;
  params: IPriceListParams;
}

export interface IPriceListParams {
  defaultPricePerSqm: number;
  multiHullSizeRatio: number;
  multiHullPriceRatio: number;
  minDays: number;
  halfDayRatio: number;
  seasonDiscounts: ISeasonDiscount[];
  weekendDiscounts: IWeekendDiscounts[];
  items: IPriceListItem[];
  additionalParams: IPriceAdditionalParams;
}

export type IPriceListGeneralForm = Omit<
  IPriceListParams,
  'seasonDiscounts' | 'weekendDiscounts' | 'items' | 'additionalParams'
> & {
  name?: string | null;
  validFrom?: string;
};

export interface IPriceListUser {
  id: string;
  email: string;
  name: string;
}

export interface IPricingTestScenarioResult {
  length: number;
  width: number;
  from: string;
  to: string;
  total: number;
  duration: number;
}

export interface ICreatePriceListPayload extends IPriceListParams {
  name?: string | null;
  tests?: IPricingTestScenarioResult[];
}

export interface ICreatePriceListResponse {
  isTestError: boolean;
  pricelist: IPriceListCreated;
  testScenarioResults: IPricingTestScenarioResult[];
}

export interface ISeasonDiscount {
  from: string;
  to: string;
  discount: number;
}

export interface ISeasonDiscountForm {
  from: string;
  to: string;
  discount: number;
  weekday?: Weekdays;
}

export interface IWeekendDiscounts extends ISeasonDiscount {
  weekday: Weekdays;
}

export interface IPriceListItem {
  maxLength: number;
  maxWidth: number;
  annualPrice: number;
  oneDayOfAnnualRatio: number;
  durationDiscounts: IDurationDiscount[];
}

export interface IDurationDiscount {
  days: number;
  discount: number;
}

export enum IPriceListsTest {
  CHECK = 'CHECK',
  SKIP = 'SKIP',
  FILL_EMPTY = 'FILL_EMPTY',
  FILL_ALL = 'FILL_ALL',
}
