import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';

/**
 * @deprecated use input[type="radio"] instead
 */
@Component({
  selector: 'dm-booking-ui-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input() public option: string | boolean | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() selectEvent: EventEmitter<any> = new EventEmitter();

  public value: string | null = null;
  public checked = false;

  onChange: (selected: boolean) => void = (selected) => undefined;

  onTouched: () => void = () => undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any) {
    this.value = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onClick() {
    this.checked = !this.checked;
    this.onChange(this.checked);
  }
}
