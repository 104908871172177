import { DestroyRef, inject, Injectable } from '@angular/core';
import { first } from 'rxjs';
import { IChooseResource, IFindBertForm, IOnlineBookingSocketForm, ObBookingProduct } from '@dm-workspace/types';
import { ObBookingStepTwoFormValue } from './ob-form.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MarinasService } from '@dm-workspace/core';

export enum FORMS_KEYS {
  chooseBerthForm = 'chooseBerthForm',
  findBerthForm = 'findBerthForm',
  productsForm = 'productsForm',
  productsMandatory = 'productsMandatory',
  stepTwoForm = 'stepTwoForm',
}

export type ObBookingStepTwoFormStoreValue = Partial<Omit<ObBookingStepTwoFormValue, 'agreements'>>;

type FormTypes =
  | IFindBertForm
  | IChooseResource
  | ObBookingStepTwoFormStoreValue
  | ObBookingProduct[]
  | IOnlineBookingSocketForm;

@Injectable({
  providedIn: 'root',
})
export class ObFormStoreService {
  #marinasService = inject(MarinasService);
  #destroyRef = inject(DestroyRef);

  #formStore: {
    [FORMS_KEYS.findBerthForm]?: IFindBertForm;
    [FORMS_KEYS.chooseBerthForm]?: IChooseResource;
    [FORMS_KEYS.stepTwoForm]?: ObBookingStepTwoFormStoreValue;
    [FORMS_KEYS.productsForm]?: ObBookingProduct[];
    [FORMS_KEYS.productsMandatory]?: ObBookingProduct[];
  } = {};

  #marinaCode: string;

  constructor() {
    this.#marinasService.selectedMarina$.pipe(first(), takeUntilDestroyed(this.#destroyRef)).subscribe((marina) => {
      this.#marinaCode = marina.code;
    });
  }

  public saveToStorage(formType: FORMS_KEYS, values: FormTypes, withoutStorage = false) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.#formStore[formType] = values as any;
    if (!withoutStorage) {
      localStorage.setItem(this.#marinaCode + formType, JSON.stringify(values));
    }
  }

  public clearStore(formType?: FORMS_KEYS) {
    this.#formStore = {};
    if (formType) {
      localStorage.removeItem(this.#marinaCode + formType);
    } else {
      Object.keys(FORMS_KEYS).forEach((key) => {
        localStorage.removeItem(this.#marinaCode + key);
      });
    }
  }

  public loadFromStorage<T = FormTypes>(formType: FORMS_KEYS): T {
    const form = localStorage.getItem(this.#marinaCode + formType);
    return form ? JSON.parse(form) : {};
  }

  public getFormValues<T = FormTypes>(key: FORMS_KEYS): T | undefined {
    return (this.#formStore[key] as T) || undefined;
  }
}
