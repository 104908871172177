import { IEnvironment } from '@dm-workspace/environments';
import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ENV } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class EnvProdGuard {
  constructor(@Inject(ENV) private env: IEnvironment, private router: Router) {}

  public canLoad(): boolean | UrlTree {
    if (!this.env.production) {
      return true;
    }

    return this.router.parseUrl('/403');
  }
}
