import { DestroyRef, Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[dmFormValidationChange]',
})
export class FormValidationChangeDirective implements OnInit {
  #destroyRef = inject(DestroyRef);
  @Input() form: FormGroup;
  @Output() formValidationChange = new EventEmitter<boolean>();

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(() => this.form.enabled),
        map(() => this.form.valid),
        distinctUntilChanged(),
        tap((isValid) => this.formValidationChange.emit(isValid))
      )
      .subscribe();
  }
}
