import { PriceListProductNumbers as PN } from './pricelist-product-numbers.enum';
import { UOM } from './pricelist-uom.enum';

export interface ProductSettings {
  type: PriceListType;
  uom: UOM;
}

export enum PriceListType {
  DRY_DOCK = 'dry_dock',
}

export function getProductSettings(productNumber: PN): ProductSettings {
  return (<{ [p in PN]: ProductSettings }>{
    [PN.P9_000009]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.PRESSURE_WASHING_10]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.LAUNCH_15]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.MOORING_21]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.PROPPING_CHARGE_26]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.P33_000033]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.P35_000035]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.CRADLE_RENTALS_47]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.LIFT_52]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.HOLDING_ON_THE_SLINGS_128]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.DRY_DOCK_ANNUAL_MOORING_153]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.DRY_DOCK_MIDTERM_MOORING_154]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.DRY_DOCK_NO_MOORING_155]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.DRY_DOCK_EXTENSION_156]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.DRY_DOCK_EXTENSION_W_ANNUAL_CONTRACT_157]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.HAULING_WITH_ANNUAL_CONTRACT_166]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.HAULING_NOT_ANNUAL_CONTRACT_167]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.LAUNCHING_WITH_ANNUAL_CONTRACT_168]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.LAUNCHING_NOT_ANNUAL_CONTRACT_169]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.MOORING_INSTALLMENTS_170]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.HIGH_PRESSURE_CLEANING_WITH_ANNUAL_171]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.JET_SKI_RENT_396]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.CHARTER_MOORING_FEES_PREPAYMENT_416]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.CHARTER_MOORING_FEES_INSTALLMENTS_417]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.CRANE_PASS_418]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.GARAGE_WITH_ANNUAL_CONTRACT_414]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.JET_SKI_TENDER_WITH_ANNUAL_MOORING_419]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.MOORING_WITH_ANNUAL_LAND_CONTRACT_420]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.TRAVEL_LIFT_2_OPERATIONS_421]: { type: PriceListType.DRY_DOCK, uom: UOM.NR },
    [PN.SEASONAL_PACKAGE_422]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.EXTENDING_SEASONAL_PACKAGE_423]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.TRAVEL_LIFT_RELOCATION_424]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.TENANTS_IN_RENTAL_GARAGE_WITHOUT_ANNUAL_CONTRACT_425]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.MOORING_FEE_BERTH_WITH_FINGER_426]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
    [PN.MOORING_WITH_ADDITIONAL_FEES]: { type: PriceListType.DRY_DOCK, uom: UOM.DAYS },
  })[productNumber];
}
