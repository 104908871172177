import { Observable, Subject } from 'rxjs';

export class NotificationRef {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly _afterClosing = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public afterClosing: Observable<any> = this._afterClosing.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public close(result?: any) {
    this._afterClosing.next(result);
  }
}
