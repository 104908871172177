import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuoteDetails } from '@dm-workspace/types';
import { diffDatesInDays } from '@dm-workspace/utils';

@Component({
  selector: 'dm-ob-quote-expired-timer',
  templateUrl: './ob-quote-expired-timer.component.html',
  styleUrls: ['./ob-quote-expired-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObQuoteExpiredTimerComponent implements OnChanges {
  @Input({ required: true }) public quote: QuoteDetails;
  protected fillWidthPercent: number;
  protected pointerLeftPercent: number;
  protected sendStartDaysDiff: number;
  protected daysLeft: number;

  @HostBinding('class') get hostClass() {
    return this.#getHostProgressColorClass();
  }

  public ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['quote'].currentValue) {
      this.calculateProgressValues();
    }
  }

  public calculateProgressValues() {
    this.sendStartDaysDiff = diffDatesInDays(this.quote.activitySummary?.firstSentAt, this.quote.expirationDate) || 0;
    this.daysLeft = diffDatesInDays(new Date(), this.quote.expirationDate) || 0;
    if (this.daysLeft < 0) this.daysLeft = 0;

    this.fillWidthPercent = 100 * (this.daysLeft / this.sendStartDaysDiff);
    if (this.fillWidthPercent >= 100) {
      this.fillWidthPercent = 100;
    }
    this.pointerLeftPercent = this.fillWidthPercent;
  }

  #getHostProgressColorClass() {
    if (this.daysLeft >= 31) {
      return 'green';
    }
    if (this.daysLeft >= 11) {
      return 'orange';
    }
    return 'red';
  }
}
