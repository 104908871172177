import { Currency, PaymentStatus, PaymentStatusEnum, PaymentMethodType, PaymentProvider } from '../backend';
import { IResourceBookingPaymentAmount } from './resource-booking.interface';
import { CrmPaymentStatus } from './quotes.interface';

export type TPaymentApplePayValidateMerchantSessionResponse = {
  merchantSession: TPaymentApplePayValidateMerchantSession;
};

export type PaymentHistory = {
  paymentStatus: PaymentStatus;
  crmPaymentStatus: CrmPaymentStatus;
  payments: EntityPayment[];
};

export type EntityPayment = {
  id: string;
  status: EntityPaymentStatus;
  failReason: string;
  createdAt: string;
};

export type EntityPaymentStatus = 'Pending' | 'Success' | 'Failed' | 'Expired' | 'No_Payment' | 'Session_Expired';

export enum EntityPaymentStatusEnum {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  EXPIRED = 'Expired',
  NO_PAYMENT = 'No_Payment',
  SESSION_EXPIRED = 'Session_Expired',
}

export type TPaymentApplePayValidateMerchantSession = {
  epochTimestamp: number;
  expiresAt: number;
  merchantSessionIdentifier: string;
  nonce: string;
  merchantIdentifier: string;
  domainName: string;
  displayName: string;
  signature: string;
  operationalAnalyticsIdentifier: string;
  retries: number;
  pspIdentifier: string;
};

export type TPaymentMethodsPayload = {
  reservationNumber: string;
  shopperLocale: string;
  channel: TPaymentChannel;
};

export interface IOrderPaymentMethodsPayload extends Omit<TPaymentMethodsPayload, 'reservationNumber'> {
  orderId: string;
}

export type TPaymentMethodsResponse = {
  paymentTypes: PaymentProvider[];
};

export type EntityPaymentMethods = {
  paymentTypes: PaymentMethodAvailability[];
};

export type PaymentMethodAvailability = {
  type: PaymentMethodType;
  isEnabled: boolean;
};
export interface QuoteAvailablePaymentMethodsDto {
  channel: TPaymentChannel;
  amount: IResourceBookingPaymentAmount;
  duration: number;
}

export const PaymentChannel = {
  iOS: 'iOS',
  Android: 'Android',
  Web: 'Web',
} as const;

export type TPaymentChannel = (typeof PaymentChannel)[keyof typeof PaymentChannel];

export type PaymentAdyenInitPayload = {
  referenceId: string;
};

export type PaymentIyzipayInitPayload = {
  referenceId: string;
};

export type PaymentWireTransferInitPayload = {
  referenceId: string;
};

export type PaymentWireTransferConfirmPayload = {
  referenceId: string;
};

export type PaymentAdyenInitResponse = {
  session: {
    sessionData: string;
    id: string;
    expiresAt: string;
  };
  clientKey: string;
};

export type PaymentMethodMount = {
  sessionExpiresAt: Date;
  paymentMethodType: PaymentMethodType;
};

export type TPaymentAdyenV5PayResponse = {
  resultCode: PaymentAdyenV5ResultCode;
};

export type TPaymentAdyenOptions = {
  paymentMethodsConfiguration: {
    card: { styles: { base: { color: string }; error: { color: string } } };
  };
};

export enum PaymentAdyenV5ResultCode {
  AUTHORISED = 'Authorised',
  PENDING = 'Pending',
  PRESENT_TO_SHOPPER = 'PresentToShopper',
}

export type PaymentIyzipayInitResponse = {
  locale: 'tr' | 'en';
  checkoutFormContent: string;
  tokenExpireTime: number;
  paymentPageUrl: string;
  iframePaymentPageUrl: string;
  paymentId: string;
};

export type PaymentWireTransferInitResponse = {
  bankName: string;
  recipientName: string;
  recipientAddress: string;
  transferTitle: string;
  iban: string;
  amount: {
    value: number;
    currency: Currency;
  };
};

export type TPaymentDetailsResponse = {
  id: string;
  type: string;
  purchaseEntityType: string;
  paidAt: string;
  failedAt: string;
  failReason: string;
  expiredAt: string;
  createdAt: string;
  amount: IResourceBookingPaymentAmount;
  marinaCode: string;
};

export type TPayments = {
  status:
    | 'Arrived'
    | 'Blocked'
    | 'Canceled'
    | 'Committed'
    | 'Completed'
    | 'In Progress'
    | 'No Show'
    | 'On cruise'
    | 'Reserved'
    | 'Temporary assignment'
    | null;
  paymentStatus: PaymentStatusEnum;
  payments: TPaymentsPayment[];
};

export type TPaymentsPayment = {
  id: string;
  status: PaymentsPaymentStatus;
  failReason: string | null;
  createdAt: string;
};

export enum PaymentsPaymentStatus {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  EXPIRED = 'Expired',
}
