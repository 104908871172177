import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';
import { NcpBookingStepsRoute } from '../../../../../ncp-booking/src/lib/const/stpes-routes';

@Component({
  selector: 'dm-ncp-ui-route-stepper-item',
  templateUrl: './ncp-ui-route-stepper-item.component.html',
  styleUrls: ['./ncp-ui-route-stepper-item.component.scss'],
  providers: [{ provide: CdkStep, useExisting: NcpUiRouteStepperItemComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpUiRouteStepperItemComponent extends CdkStep {
  @Input() routerLink: NcpBookingStepsRoute;

  @Input({ required: true }) icon: string;
}
