import { Component, HostBinding, Inject, Input } from '@angular/core';
import { BadgeColor, BadgeSize, ENV } from '@dm-workspace/types';
import { IEnvironment } from '@dm-workspace/environments';

export type NativeAppStore = 'Apple' | 'Google';

@Component({
  selector: 'dm-ui-native-app-badge-link',
  templateUrl: './native-app-badge-link.component.html',
  styleUrls: ['./native-app-badge-link.component.scss'],
})
export class NativeAppBadgeLinkComponent {
  @Input() public store: NativeAppStore = 'Apple';
  @Input() public color: BadgeColor = 'black';
  @HostBinding('class')
  @Input()
  public size: BadgeSize = 'md';

  constructor(@Inject(ENV) private env: IEnvironment) {}

  public get language(): string {
    return 'en';
  }

  public get badgeSrc(): string {
    switch (this.store) {
      case 'Apple':
        return `/app-store-${this.color}-${this.language}.svg`;
      case 'Google':
        return `/google-play-${this.color}-${this.language}.svg`;
    }
  }

  public get altText(): string {
    switch (this.store) {
      case 'Apple':
        return 'DOWNLOAD_ON_THE_APP_STORE';
      case 'Google':
        return 'GET_IT_ON_GOOGLE_PLAY';
    }
  }

  public get storeLink(): string {
    return this.env.appStores[this.store].url + this.env.appStores[this.store].id;
  }
}
