// TODO: add proper typing and DTOs for events

export enum InternalEvents {
  REQUEST_COMPLETED = 'app.http.request.completed',
  ORDER_ELIGIBLE_FOR_INVOICE_GENERATION = 'order.eligible-for-invoice',

  BOOKING_BEING_PROCESSED = 'booking.being-processed',
  BOOKING_CONFIRMED_WITHOUT_PAYMENT = 'booking.confirmed.without-payment',
  BOOKING_OFFER = 'booking.offer',
  BOOKING_CONFIRMED_AVAILABILITY = 'booking.confirmed.availability',
  BOOKING_CANCELLED = 'booking.cancelled',
  BOOKING_CANCELLED_FROM_DRAFT = 'booking.cancelled.draft',

  PAYMENT_SUCCESS = 'payment.success',
  PAYMENT_FAILED = 'payment.failed',
  PAYMENT_CONFIRMED = 'payment.confirmed',

  CHECK_IN = 'check.in',
  CHECK_IN_CONFIRMED = 'check.in.confirmed',
  CHECK_IN_REJECTED = 'check.in.rejected',
  CHECK_OUT_SOON = 'check.out.soon',

  JOURNEY_GETTING_CLOSER = 'journey.getting.closer',

  NEW_CUSTOMER_ACCOUNT = 'new.customer.account',
  RESET_PASSWORD = 'reset.password',
  SET_PASSWORD = 'set.password',
}
