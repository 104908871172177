@switch (daysAgo()) {
  @case (0) {
    {{ "TODAY" | translate }}
  }
  @case (1) {
    {{ "YESTERDAY" | translate }}
  }
  @default {
    @if (daysAgo() <= daysAgoLimit) {
      {{ daysAgo() }} {{ "DAYS_AGO" | translate | lowercase }}
    } @else {
      {{ date() | dateFormat : dateFormat}}
    }
  }
}
