import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';

export class TranslateCoreLogMissingHandler extends MissingTranslationHandler {
  #missingKeys: string[] = [];

  handle({ key, translateService }: MissingTranslationHandlerParams) {
    const lang = translateService.currentLang ?? translateService.defaultLang;
    if (this.#missingKeys.includes(key) || !translateService.translations[lang]) {
      return;
    }
    this.#missingKeys.push(key);

    Sentry.captureMessage(`Missing translation for key "${key}"`, {
      level: 'warning',
      tags: {
        lang,
        'missing-translation': key,
      },
      user: null,
    });
  }
}
