import { inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class DmJwtHelperService {
  #jwtHelperService = inject(JwtHelperService);

  isTokenValid(token: string): boolean {
    try {
      return !this.#jwtHelperService.isTokenExpired(token);
    } catch (e) {
      return false;
    }
  }
}
