import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  forwardRef,
  HostBinding,
  inject,
  InjectionToken,
  Input,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioButtonClass } from './radio-button-base-class.directive';
import { delay } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const RADIO_GROUP = new InjectionToken<FormRadioGroupComponent<unknown>>('FormRadioGroupComponent');

@Component({
  selector: 'dm-form-radio-group',
  template: `<ng-content />`,
  providers: [
    { provide: RADIO_GROUP, useExisting: forwardRef(() => FormRadioGroupComponent) },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioGroupComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRadioGroupComponent<T> implements AfterViewInit, ControlValueAccessor {
  #destroyRef = inject(DestroyRef);
  @HostBinding('class.inline')
  @Input()
  inline: boolean;
  @ContentChildren(RadioButtonClass, {
    descendants: true,
  })
  radios!: QueryList<RadioButtonClass>;
  isInit = false;
  onChange: (selected: T) => void;

  onTouched: () => void = () => undefined;
  value: T;

  isDisabled = false;

  registerOnChange(fn: (_: T) => void): void {
    this.onChange = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.radios) {
      this.radios.forEach((item) => item.isDisabled.set(this.isDisabled));
    }
  }
  writeValue(obj: T): void {
    this.value = obj;
    if (this.isInit) {
      this.updateSelectedValue();
    }
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  updateSelectedValue() {
    if (this.radios) {
      this.radios.forEach((item) => item.checked.set(item.value === this.value));
    }
  }
  setValue(value: T) {
    if (this.isDisabled) {
      return;
    }
    this.value = value;
    this.updateSelectedValue();
    this.onChange(this.value);
    this.onTouched();
  }
  ngAfterViewInit(): void {
    this.isInit = true;
    this.setDisabledState(this.isDisabled);
    this.updateSelectedValue();

    this.radios.changes.pipe(takeUntilDestroyed(this.#destroyRef), delay(0)).subscribe(() => {
      this.updateSelectedValue();
    });
  }
}
