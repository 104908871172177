import { Component, Input } from '@angular/core';
import { Marina } from 'libs/types/src';

@Component({
  selector: 'dm-ob-quote-hero',
  templateUrl: './ob-quote-hero.component.html',
  styleUrls: ['./ob-quote-hero.component.scss'],
})
export class ObQuoteHeroComponent {
  @Input({ required: true }) public marina: Marina;
}
