import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { GenericObject } from '@dm-workspace/types';

interface TrackByPropertyCache {
  [propertyName: string]: (index: number, item: GenericObject) => number | string;
}

@Pipe({
  name: 'trackByProperty',
})
export class TrackByPropertyPipe implements PipeTransform {
  private static cache: TrackByPropertyCache = Object.create(null);

  public transform<T>(propertyName: string): (index: number, item: T) => number | string {
    if (!TrackByPropertyPipe.cache[propertyName]) {
      TrackByPropertyPipe.cache[propertyName] = function trackByProperty(
        index: number,
        item: GenericObject
      ): number | string {
        return item[propertyName];
      };
    }
    return TrackByPropertyPipe.cache[propertyName];
  }
}
