<dm-form-files
  (add)="addFilesToUpload($event)"
  [hasTip]="hasTip"
  [sizeLimitBytes]="sizeLimitBytes"
  [multiple]="multiple"
  [allowedFormats]="allowedFormats"
/>

<dm-form-files-item
  *ngFor="let file of model; let index = index"
  [file]="file"
  [forceDelete]="deleteFromListIfError"
  (deleted)="deleteUploadedFile(index)"
/>

<dm-form-files-item-upload
  *ngFor="let file of files; let index = index"
  [file]="file"
  (cancel)="cancelUploadingFile(index)"
  (uploaded)="addUploadedFile($event, index)"
/>
