@if (this.viewMode === AuthViewType.resetPassword) {
  <h3 class="title text-center mb-5">{{ "NCP_AUTH_MODULE.RESET_PASSWORD.HEADER" | translate | uppercase }}</h3>
  <form *ngIf="form" class="text-left" [formGroup]="form" (ngSubmit)="onSubmit()">
    <dm-form-group class="mb-4">
      <label>{{ "PASSWORD" | translate }}</label>
      <dm-form-password-toggle>
        <input dmForm formControlName="password" type="password" [placeholder]="'ENTER_PASSWORD' | translate" />
      </dm-form-password-toggle>
      <dm-form-error-message controlName="password" />
    </dm-form-group>

    <button class="btn btn-fluid btn-primary btn-full" type="submit" [disabled]="pending">
      <dm-ui-spinner class="me-2" [showSpinner]="pending" />
      <span class="text-body-s text-body-md-m fw-700"> {{ "NCP_AUTH_MODULE.RESET_PASSWORD.HEADER" | translate }}</span>
    </button>
  </form>
} @else {
  <h3 class="title text-center mb-3 mb-md-5">{{ "NCP_AUTH_MODULE.SET_PASSWORD.HEADER" | translate | uppercase }}</h3>
  <form *ngIf="form" class="text-left" [formGroup]="form" (ngSubmit)="onSubmit()">
    <dm-form-group class="mb-4">
      <label>{{ "PASSWORD" | translate }}</label>
      <dm-form-password-toggle>
        <input dmForm formControlName="password" type="password" [placeholder]="'ENTER_PASSWORD' | translate" />
      </dm-form-password-toggle>
      <dm-form-error-message controlName="password" />
    </dm-form-group>

    <dm-form-group class="mb-4">
      <label>{{ "REPEAT_PASSWORD" | translate }}</label>
      <dm-form-password-toggle>
        <input dmForm formControlName="repeatPassword" type="password" [placeholder]="'REPEAT_PASSWORD' | translate" />
      </dm-form-password-toggle>
      <dm-form-error-message controlName="repeatPassword" />
    </dm-form-group>

    <button class="btn btn-fluid btn-primary btn-full" type="submit" [disabled]="pending">
      <dm-ui-spinner class="me-2" [showSpinner]="pending" />
      <span class="text-body-s text-body-md-m fw-700">{{ "NCP_AUTH_MODULE.SET_PASSWORD.HEADER" | translate }}</span>
    </button>
  </form>
}
