import { ValidatorFn, AbstractControl } from '@angular/forms';

export function isAutocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value as string;

    return typeof value === 'object'
      ? null
      : {
          isAutocompleteObject: {
            valid: false,
          },
        };
  };
}
