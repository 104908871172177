import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  #sanitizer = inject(DomSanitizer);
  transform(value: string, search: string): SafeHtml {
    if (!search || !value) {
      return value;
    }

    const re = new RegExp(search, 'gi');
    const match = value.match(re);

    if (!match) {
      return value;
    }

    const replacedValue = value.replace(re, '<mark>' + match[0] + '</mark>');
    return this.#sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}
