import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, concat, debounceTime, map, merge, Observable, of, Subject, switchMap, tap } from 'rxjs';

import {
  AutocompleteService,
  MmsBoatsApiService,
  MmsCustomerApiService,
  ResourcesApiService,
} from '@dm-workspace/data-access';
import {
  IAutocompleteResourceDetails,
  IAutocompleteResourceParams,
  IBerthAutocompleteDetailsItem,
  IBerthAutocompleteParams,
  IBoat,
  Customer,
  ResourceType,
} from '@dm-workspace/types';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dm-form-select-typeahead',
  templateUrl: './select-typeahead.component.html',
  styleUrls: ['./select-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTypeaheadComponent),
      multi: true,
    },
  ],
})
export class SelectTypeaheadComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public typeahead: 'boats' | 'customers' | 'berths' | 'resources';
  @Input() public pier: string;
  @Input() public clearable = true;
  @Input() public addTag = false;
  @Input() public minLengthTerm = 2;
  @Input() public markFirst = true;
  @Input() public placeholder: string;
  @Input() public selectOnTab = false;
  @Input() public notFoundText: string;
  @Input() public bindLabel = 'label';
  @Input() public bindValue: string;
  @Input() public multiple: boolean;
  @Input() public defaultSearchValue?: string;
  @Input() public resourceType: ResourceType | ResourceType[];
  @Input() public addonRequestParams?: {
    excludeInaccessible?: boolean;
  };
  @Input() marinaCode: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() itemChanged = new EventEmitter<any>();

  public isLoading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public items$: Observable<any[]>;
  public input$ = new Subject<string>();
  public inputWriteValue$ = new Subject<[string] | string[]>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public value: any;
  public disabled: boolean;

  constructor(
    private boatsApiService: MmsBoatsApiService,
    private customersApiService: MmsCustomerApiService,
    private autocompleteService: AutocompleteService,
    private resourceApi: ResourcesApiService
  ) {}

  public ngOnInit(): void {
    this.items$ = concat(
      of([]),
      merge(this.inputWriteValue$, this.input$).pipe(
        tap(() => (this.isLoading = true)),
        debounceTime(300),
        switchMap((term) => this.fetchItemsByType(term))
      )
    );

    if (this.defaultSearchValue) {
      this.setDefaultValue(this.defaultSearchValue);
    }
  }

  public ngOnDestroy() {
    this.inputWriteValue$.complete();
  }

  private onChange: (selected: string | null) => void = () => undefined;

  public registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  private onTouched: () => void = () => undefined;

  public registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }

  public writeValue(value: string | string[]): void {
    this.value = value;
    if (!value) {
      this.input$.next(null);
      return;
    }
    setTimeout(() => {
      if (this.bindValue) {
        if (typeof value === 'string') {
          return this.inputWriteValue$.next([value]);
        } else {
          return this.inputWriteValue$.next(value);
        }
      }
      this.input$.next(value as string);
    }, 10);
  }

  private setDefaultValue(defaultValue: string): void {
    this.isLoading = true;

    this.fetchItemsByType(defaultValue).subscribe((result) => {
      this.input$.next(defaultValue);

      if (result.length > 0) {
        const value = this.bindValue ? result[0][this.bindValue] : result[0];
        this.value = value;
        this.onChange(value);
        this.onTouched();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public compareWith = (optionItem: any, selected: any): boolean => {
    if (this.bindValue) {
      return optionItem[this.bindValue] === selected;
    } else {
      return optionItem === selected;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private fetchItemsByType(term: string | [string] | string[]): Observable<any[]> {
    let search = of([]);

    if (term) {
      let termText = term as string;
      let useAltProperty: boolean | undefined;
      if (typeof term === 'object') {
        termText = term[0];
        useAltProperty = true;
      }

      switch (this.typeahead) {
        case 'boats':
          search = this.searchBoats(termText);
          break;
        case 'customers':
          search = this.searchCustomers(termText, useAltProperty);
          break;
        case 'berths':
          search = this.searchBerths(term, useAltProperty);
          break;
        case 'resources': {
          search = this.searchResources(termText);
          break;
        }
      }
    }
    return search.pipe(
      catchError(() => of([])),
      finalize(() => (this.isLoading = false))
    );
  }

  private searchBoats(search: string): Observable<IBoat[]> {
    return this.boatsApiService.searchBoats({ search });
  }

  private searchCustomers(search: string, useAltProperty: boolean): Observable<Customer[]> {
    const params = useAltProperty ? { ids: [search] } : { search };
    return this.customersApiService.searchCustomers(params);
  }

  private searchResources(searchTerm: string): Observable<IAutocompleteResourceDetails[]> {
    const params: Partial<IAutocompleteResourceParams> = {};
    const uidregex = new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/);
    if (uidregex.test(searchTerm)) {
      return this.resourceApi.fetchById(searchTerm).pipe(
        catchError(() => []),
        map((value1) => [
          {
            pierName: '',
            pierId: '',
            name: value1.name,
            id: value1.id,
          },
        ])
      );
    }
    if (this.addonRequestParams) {
      Object.assign(params, this.addonRequestParams);
    }
    if (this.pier) {
      params.pier = this.pier;
    }
    if (this.marinaCode) {
      params.marinaCode = this.marinaCode;
    }
    if (this.resourceType) {
      params.types = this.resourceType;
    }

    return this.autocompleteService.searchResource(searchTerm, params);
  }

  private searchBerths(
    searchTerm: string | string[],
    useAltProperty: boolean
  ): Observable<IBerthAutocompleteDetailsItem[]> {
    const params: IBerthAutocompleteParams = {
      pier: this.pier,
      marinaCode: this.marinaCode,
    };

    if (typeof searchTerm === 'string') {
      params.search = !useAltProperty && searchTerm;
      params.berthId = useAltProperty && searchTerm;
    }
    if (typeof searchTerm === 'object') {
      if (searchTerm?.length === 1) {
        params.berthId = searchTerm[0];
      } else {
        params.multipleBerthsIds = searchTerm;
      }
    }

    return this.autocompleteService.searchBerth(params);
  }

  public onSelectChange(): void {
    this.onTouched();
    this.onChange(this.value);
    this.itemChanged.emit(this.value);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
