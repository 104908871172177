import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-ncp-booking-boat-selection-confirm',
  templateUrl: './ncp-booking-boat-selection-confirm.component.html',
  styleUrl: './ncp-booking-boat-selection-confirm.component.scss',
})
export class NcpBookingBoatSelectionConfirmComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() data: any;
  #aModal = inject(NgbActiveModal);

  onClick(value: boolean): void {
    this.#aModal.close(value);
  }
}
