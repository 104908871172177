import { Component, Input } from '@angular/core';
import { BookingResource } from '@dm-workspace/types';

@Component({
  selector: 'dm-ob-maps-price-popup',
  templateUrl: './ob-maps-price-popup.component.html',
  styleUrls: ['./ob-maps-price-popup.component.scss'],
})
export class ObMapsPricePopupComponent {
  @Input() resource: BookingResource;
  @Input() marinaCode: string;
}
