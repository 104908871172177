import { Pipe, PipeTransform } from '@angular/core';
import { Marina } from '@dm-workspace/types';

@Pipe({
  name: 'sortMarinasByCountry',
})
export class SortMarinasByCountryPipe implements PipeTransform {
  public static transform(marinas: Marina[]): Marina[] {
    if (!marinas?.length) {
      return;
    }
    return marinas.sort((prev, next) => prev.country.localeCompare(next.country));
  }

  public transform(marinas: Marina[]): Marina[] {
    return SortMarinasByCountryPipe.transform(marinas);
  }
}
