import { ChangeDetectionStrategy, Component, forwardRef, HostListener } from '@angular/core';
import { RadioButtonClass } from '@dm-workspace/forms';
import { BoatTypeCustomer } from '@dm-workspace/types';

@Component({
  selector: 'dm-ncp-radio-boat-type',
  templateUrl: './radio-boat-type.component.html',
  styleUrl: './radio-boat-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: RadioButtonClass, useExisting: forwardRef(() => RadioBoatTypeComponent) }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'isDisabled()',
    '[class.selected]': 'checked()',
  },
})
export class RadioBoatTypeComponent extends RadioButtonClass<BoatTypeCustomer> {
  @HostListener('click')
  override onModelChange() {
    super.onModelChange();
  }
}
