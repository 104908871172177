import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @deprecated
 * use <input type="checkbox"
 */
@Component({
  selector: 'dm-booking-ui-checkbox-old-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxButtonComponent),
      multi: true,
    },
  ],
})
export class CheckboxButtonComponent implements ControlValueAccessor {
  @Input() public hasError: boolean | undefined = false;
  @Input() public errorMessage: string | null = null;

  public checked = false;

  onChange: (selected: boolean) => void = () => undefined;

  onTouched: () => void = () => undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(value: any) {
    if (value !== undefined && value !== null) {
      this.checked = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onClick() {
    this.checked = !this.checked;
    this.onChange(this.checked);
  }
}
