import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { circleSpinnerShowTrigger } from '../../animations/circle-spinner-show.animation';

@Component({
  selector: 'dm-ui-circle-spinner',
  templateUrl: './circle-spinner.component.html',
  styleUrls: ['./circle-spinner.component.scss'],
  animations: [circleSpinnerShowTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleSpinnerComponent {
  @Input() showSpinner: boolean;
  @Input() size: 'lg' | 'sm';

  get sizeClass(): string {
    if (this.size) {
      return 'sk-circle__' + this.size;
    }
    return '';
  }
}
