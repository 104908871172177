import { Directive, Host, Optional, SimpleChange } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { COUNTRIES_NEW } from '@dm-workspace/types';

@Directive({
  selector: '[dmFormSelectCountry]',
})
export class FormSelectCountryDirective {
  readonly #items = COUNTRIES_NEW;
  readonly #searchFn = (_term: string, { name, code }: (typeof COUNTRIES_NEW)[number]): boolean => {
    const term = _term.toUpperCase();
    return name.toUpperCase().includes(term) || code.toUpperCase().includes(term);
  };
  readonly #virtualScroll = true;
  readonly #bindValue = 'code';

  constructor(@Host() @Optional() private ngSelectComponent: NgSelectComponent) {
    if (!this.ngSelectComponent) {
      return;
    }

    this.#init();
  }

  #init() {
    this.ngSelectComponent.ngOnChanges(this.#getChanges());

    this.ngSelectComponent.bindValue ??= this.#bindValue;
    this.ngSelectComponent.items = this.#items;
    this.ngSelectComponent.searchFn = this.#searchFn;
    this.ngSelectComponent.virtualScroll = this.#virtualScroll;
  }

  #getChanges() {
    const changes = {
      items: new SimpleChange(this.ngSelectComponent.items, this.#items, false),
      searchFn: new SimpleChange(this.ngSelectComponent.searchFn, this.#searchFn, false),
      virtualScroll: new SimpleChange(this.ngSelectComponent.virtualScroll, this.#virtualScroll, false),
    } as {
      items: SimpleChange;
      searchFn: SimpleChange;
      virtualScroll: SimpleChange;
      bindValue?: SimpleChange;
    };

    if (!this.ngSelectComponent.bindValue) {
      changes.bindValue = new SimpleChange(this.ngSelectComponent.bindValue, this.#bindValue, false);
    }

    return changes;
  }
}
