import { inject, Pipe, PipeTransform } from '@angular/core';
import { Breakpoint, BreakpointsService } from '@dm-workspace/core';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'minBreakpoint',
})
export class MinBreakpointPipe implements PipeTransform {
  #breakpointsService = inject(BreakpointsService);

  transform(breakpoint: Breakpoint): Observable<boolean> {
    return this.#breakpointsService.breakpoint$.pipe(map((v) => v >= breakpoint));
  }
}

@Pipe({
  name: 'maxBreakpoint',
})
export class MaxBreakpointPipe implements PipeTransform {
  #breakpointsService = inject(BreakpointsService);

  transform(breakpoint: Breakpoint): Observable<boolean> {
    return this.#breakpointsService.breakpoint$.pipe(map((v) => v <= breakpoint));
  }
}
