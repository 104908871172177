import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService } from '../api/api-client.service';
import { ITranslationItem, LOCALIZATION_LANGUAGES } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class TranslationsApiService {
  constructor(private apiClientService: ApiClientService) {}

  public getTranslationsTable(): Observable<ITranslationItem[]> {
    return this.apiClientService.get(`/localizations/table`);
  }

  public updateTranslationsTable(body: ITranslationItem[]): Observable<null> {
    return this.apiClientService.post('/localizations', body);
  }
  public getTranslation(lng: LOCALIZATION_LANGUAGES): Observable<null> {
    return this.apiClientService.get(`/localizations/${lng}`);
  }
}
