import { LOCALIZATION_LANGUAGES } from '../constants';

export interface AuthAccountConfirmParams {
  confirmCode: string;
  id: string;
}

export type IAuthConfirm = AuthAccountConfirmParams;

export interface IAuthBackendResetPasswordParam {
  passwordResetCode: string;
  confirmCode?: string;
  id: string;
}

export interface IAuthSocialMediaUrl {
  url: string;
}

export interface IAuthSocialMedia {
  auth: AuthSocialMedia;
}

export interface AuthSocialMediaGoogleDto {
  code: string;
  state: string;
  scope: string;
}

export interface AuthSocialMediaFacebookDto {
  code: string;
  state: string;
}

export interface IAuthSocialMediaResponse {
  url: string;
}

export type CpAuthRegisterCustomerDto = {
  email: string;
  password: string;
  language: LOCALIZATION_LANGUAGES;
};

export interface IAuthResetPassword extends IAuthBackendResetPasswordParam {
  password: string;
}
export interface IAuthSetUpPassword extends IAuthResetPassword {
  confirmCode: string;
  cryptedEmail: string;
}
export interface AuthLostPasswordDto {
  email: string;
}

export interface IAuthChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface IAuthLoginUserRequest {
  email: string;
  password: string;
}

export interface IAuthLoginUserResponse {
  access_token: string;
  refresh_token: string;
}

export enum AuthSetPasswordViewType {
  resetPassword = 'resetPassword',
  setUpPassword = 'setUpPassword',
}

export enum AuthSocialMedia {
  facebook = 'facebook',
  google = 'google',
}
export type AuthSocialMediaQueryParams = AuthSocialMediaFacebookDto | AuthSocialMediaGoogleDto;
