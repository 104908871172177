export const VALIDATORS_ERROR_MESSAGES: Record<string, string> = {
  required: 'REQUIRED',
  email: 'EMAIL',
  min: 'MIN_NUMBER',
  max: 'MAX_NUMBER',
  digits: 'DIGITS',
  mismatch: 'MISMATCH',
  maxlength: 'MAX_LENGTH',
  itemsmaxlength: 'ITEMS_MAX_LENGTH',
  minlength: 'MIN_LENGTH',
  minArrayLength: 'ITEMS_MIN_LENGTH',
  integerOnly: 'INTEGER_ONLY',
  greaterThan: 'GREATER_THAN',
  lessThan: 'LESS_THAN',
  minSpacialCharacters: 'MIN_SPECIAL_CHARACTERS',
  repeat: 'REPEAT',
  notRepeat: 'NOT_REPEAT',
  isAutocompleteObject: 'AUTOCOMPLETE_OBJECT',
  ngbDate: 'NGB_DATE',
  invalidDate: 'INVALID_DATE',
  maxDate: 'MAX_DATE',
  minDate: 'MIN_DATE',
  fiscalCode: 'FISCAL_CODE',
  taxExemptNumber: 'TAX_REFERENCE_ID',
  maxDateRange: 'MAX_DATE_RANGE',
};

export const VALIDATORS_ERROR_KEY = 'FORM_VALIDATOR_ERRORS.';

export function getValidatorsErrorKey(key: string): string {
  return VALIDATORS_ERROR_KEY + VALIDATORS_ERROR_MESSAGES[key];
}
