export const DAY_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT_PLACEHOLDER = 'DD.MM.YYYY';
export const DATE_SHORT_YEAR_FORMAT = 'dd.MM.YY';

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_FORMAT_WITHOUT_YEAR = 'dd.MM';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_MINUTES = 'HH:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`;
export const DATE_TIME_FORMAT_MINUTES = `${DATE_FORMAT}, ${TIME_FORMAT_MINUTES}`;

export type Formats = 'date' | 'time' | 'date_time' | 'date_time_minutes' | 'date_short';

export const formats: Record<Formats, string> = {
  date: DATE_FORMAT,
  time: TIME_FORMAT,
  date_time: DATE_TIME_FORMAT,
  date_time_minutes: DATE_TIME_FORMAT_MINUTES,
  date_short: DATE_SHORT_YEAR_FORMAT,
};
