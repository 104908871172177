import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENV, MARINA_URL_TOKEN } from '@dm-workspace/types';
import { Observable } from 'rxjs';

export abstract class ApiService {
  protected abstract readonly apiHttpClient: ApiHttpClient;
  protected abstract readonly resourceUrl: string;

  protected modifyResourceUrl({ marinaCode }: ResourceUrlOptions = {}): string {
    if (!marinaCode) return this.resourceUrl;
    return this.resourceUrl.replace(MARINA_URL_TOKEN, marinaCode);
  }
}

export type ResourceUrlOptions = {
  marinaCode?: string;
};

export interface ApiCrudService {
  getById?: (id: string, ...args: never[]) => Observable<unknown>;
  get?: (...args: never[]) => Observable<unknown>;
  delete?: (id: string, ...args: never[]) => Observable<unknown>;
  create?: (...args: never[]) => Observable<unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class ApiHttpClient extends HttpClient {
  protected readonly env = inject(ENV);

  public createResourceUrl(url?: string): string {
    return `${this.env.apiUrl}${url}`;
  }
}
