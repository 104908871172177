import { Directive, HostBinding } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'dm-ui-card-footer',
})
export class CardFooterDirective {
  @HostBinding('class')
  className = 'card-footer';
}
