import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BoatType, ISimpleBrandModel } from '@dm-workspace/types';
import { greaterThan } from '@dm-workspace/forms';

export type BoatForm = FormGroup<{
  name: FormControl<string>;
  type: FormControl<BoatType>;
  registrationNumber: FormControl<string>;
  brand: FormControl<ISimpleBrandModel>;
  model?: FormControl<ISimpleBrandModel>;
  portOfRegistration: FormControl<string>;
  flag: FormControl<string>;
  length: FormControl<number>;
  width?: FormControl<number>;
  maxBeam: FormControl<number>;
  draft: FormControl<number>;
  isCharter?: FormControl<boolean>;
  charterLicenceNumber?: FormControl<string>;
}>;
export type BoatFormValue = ReturnType<BoatForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class BoatFormService {
  constructor(private fb: FormBuilder) {}

  public createForm(initValue?: Partial<BoatFormValue>): BoatForm {
    return this.fb.group({
      name: new FormControl(initValue?.name, { validators: [Validators.required], updateOn: 'blur' }),
      type: new FormControl(initValue?.type, { validators: [Validators.required], updateOn: 'blur' }),
      registrationNumber: new FormControl(initValue?.registrationNumber, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      brand: new FormControl(initValue?.brand, { validators: [Validators.required], updateOn: 'change' }),
      portOfRegistration: new FormControl(initValue?.portOfRegistration, [Validators.required]),
      flag: new FormControl(initValue?.flag, { validators: [Validators.required], updateOn: 'change' }),
      length: this.fb.control(initValue?.length, {
        validators: [Validators.required, greaterThan(0)],
        updateOn: 'blur',
      }),
      // TODO: https://futuremind.atlassian.net/browse/DMAR2-1689
      maxBeam: this.fb.control(initValue?.maxBeam || initValue?.width, {
        validators: [Validators.required, greaterThan(0)],
        updateOn: 'blur',
      }),
      draft: this.fb.control(initValue?.draft, { validators: [Validators.required, greaterThan(0)], updateOn: 'blur' }),
    });
  }

  public createMaxBeamControl(initValue?: number) {
    return new FormControl(initValue, { validators: [Validators.required], updateOn: 'blur' });
  }

  public createCharterLicenceNumberControl(initValue?: string) {
    return new FormControl(initValue, { validators: [Validators.required], updateOn: 'blur' });
  }

  public createIsCharterControl(initValue?: boolean) {
    return new FormControl(initValue, { updateOn: 'blur' });
  }

  public transformFormValueToPayload(formValue: BoatFormValue) {
    const { brand, model, ...restBoatValue } = formValue;
    return {
      ...restBoatValue,
      brandId: brand?.id,
      modelId: model?.id || null,
    };
  }
}
