import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[dmDrop]',
})
export class DropDirective {
  @Output() fileDropped: EventEmitter<File[]> = new EventEmitter();
  @Output() changeDragStatus: EventEmitter<boolean> = new EventEmitter();

  @HostListener('dragover', ['$event']) public dragOver(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.changeDragStatus.emit(true);
  }

  @HostListener('dragleave', ['$event']) public dragLeave(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.changeDragStatus.emit(false);
  }

  @HostListener('drop', ['$event']) public drop(e: CustomEvent & { dataTransfer?: DataTransfer }) {
    e.preventDefault();
    e.stopPropagation();

    this.changeDragStatus.emit(false);

    const { files } = e.dataTransfer;

    if (!files.length) {
      return;
    }

    this.fileDropped.emit(Array.from(files));
  }
}
