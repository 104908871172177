import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Directive, EventEmitter, inject, OnDestroy, Output } from '@angular/core';

@Directive()
export class ConfirmModal implements OnDestroy {
  // Cold observable
  @Output() public readonly confirmed = new EventEmitter<boolean>();
  protected readonly aModal = inject(NgbActiveModal);

  protected onConfirm() {
    this.aModal.close(true);
    this.confirmed.emit(true);
    this.confirmed.complete();
  }

  protected onCancel() {
    this.aModal.close(false);
    this.confirmed.emit(false);
    this.confirmed.complete();
  }

  public ngOnDestroy() {
    this.confirmed.emit(false);
    this.confirmed.complete();
  }
}
