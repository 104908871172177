import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modifyDateDays',
})
export class ModifyDateDaysPipe implements PipeTransform {
  transform(_date: string, modification: number): Date {
    if (!_date) {
      return;
    }
    const date = new Date(_date);
    date.setDate(date.getDate() + modification);
    return date;
  }
}
