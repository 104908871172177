import { Pipe, PipeTransform } from '@angular/core';
import { CorporateType, Customer, CustomerType } from '@dm-workspace/types';

@Pipe({
  name: 'customerIsCorporate',
})
export class MmsCustomerIsCorporatePipe implements PipeTransform {
  public transform(customer?: Customer | undefined | null): boolean {
    if (!customer) {
      return;
    }
    const { corporateType, type } = customer;
    if (type === CustomerType.INDIVIDUAL || corporateType === CorporateType.INDIVIDUAL) {
      return false;
    }
    return true;
  }
}
