import { inject, Pipe, PipeTransform } from '@angular/core';
import { PaymentWireTransferInitResponse } from '@dm-workspace/types';
import { PaymentsApiService } from '@dm-workspace/data-access';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'paymentWireTransferInit',
})
export class PaymentWireTransferInitPipe implements PipeTransform {
  #paymentApiService = inject(PaymentsApiService);

  transform(entityId: string | undefined): Observable<PaymentWireTransferInitResponse> {
    if (!entityId) {
      return of(null);
    }
    return this.#paymentApiService.wireTransferInit({ referenceId: entityId });
  }
}
