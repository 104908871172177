import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'dm-ncp-ui-route-stepper-counter',
  templateUrl: './ncp-ui-route-stepper-counter.component.html',
  styleUrls: ['./ncp-ui-route-stepper-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpUiRouteStepperCounterComponent {
  steps = input.required<number>();
  currentStep = input.required<number>();
}
