import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObHeaderComponent } from './components/ob-header/ob-header.component';
import { CalendarRangeComponent } from './components/callendar-range/calendar-range.component';
import { ObBerthsListComponent } from './components/ob-berths-list/ob-berths-list.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { CheckboxButtonComponent } from './components/checkbox-button/checkbox-button.component';
import { ProductsListFormControlComponent } from './components/products-list-form-control/products-list-form-control.component';
import { ObSectionLoadingComponent } from './components/ob-section-loading/ob-section-loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LibsFormsModule } from '@dm-workspace/forms';
import { UiModule } from '@dm-workspace/ui';
import { LibsSharedModule } from '@dm-workspace/shared';
import { RouterLink } from '@angular/router';
import { ObCreditsTopUpPaymentFormComponent } from './components/ob-credits-top-up-payment-form/ob-credits-top-up-payment-form.component';
import { ObFormRadioCardComponent } from './components/ob-form-radio-card/ob-form-radio-card.component';
import { ObCmsChildComponent } from './components/ob-cms-child/ob-cms-child.component';
import { ObCmsImageComponent } from './components/ob-cms-image/ob-cms-image.component';

const declarations = [
  ObHeaderComponent,
  ObBerthsListComponent,
  CalendarRangeComponent,
  RadioButtonComponent,
  ObFormRadioCardComponent,
  ObCreditsTopUpPaymentFormComponent,
  CheckboxButtonComponent,
  ProductsListFormControlComponent,
  ObSectionLoadingComponent,
  ObCmsChildComponent,
  ObCmsImageComponent,
];

@NgModule({
  declarations: [...declarations],
  exports: [...declarations],
  imports: [
    CommonModule,
    TranslateModule,
    NgbDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    LibsFormsModule,
    UiModule,
    NgbTooltipModule,
    LibsSharedModule,
    RouterLink,
  ],
})
export class ObSharedModule {}
