import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(value: { firstName?: string; lastName?: string }, ...args: unknown[]): string {
    return value ? `${value?.firstName} ${value?.lastName}` : '';
  }
}
