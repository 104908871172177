import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=number]',
})
export class DisableScrollDirective {
  @HostListener('wheel', ['$event']) onScroll($event: Event) {
    $event.preventDefault();
  }
}
