import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dm-ui-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent {
  @Input() hideClose = false;
  @Output() closed = new EventEmitter<void>();

  //TODO: Add animation
  // @HostBinding('@enterLeaveFromRight') animate: boolean;

  close(): void {
    this.closed.emit();
  }
}
