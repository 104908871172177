export enum CrmOrderStatus {
  'Draft' = 'Draft',
  'In Progress' = 'In Progress',
  'No Money' = 'No Money',
  'New' = 'New',
  'Pending' = 'Pending',
  'Complete' = 'Complete',
  'Partial' = 'Partial',
  'Invoiced' = 'Invoiced',
}
