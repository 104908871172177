import { NgModule } from '@angular/core';
import { MultihullPolygonStyleByStatusPipe } from './pipes/multihull-styles.pipe';
import { GeoToPolyPipe } from './pipes/geo-to-poly.pipe';
import { CenterArrayToMarkerPipe } from './pipes/center-array-to-marker.pipe';
import { BerthPolygonStyleByBookingPipePipe } from './pipes/berth-polygon-style-by-booking-pipe.pipe';
const declarations = [
  BerthPolygonStyleByBookingPipePipe,
  MultihullPolygonStyleByStatusPipe,
  CenterArrayToMarkerPipe,
  GeoToPolyPipe,
];

@NgModule({
  imports: [],
  providers: [],
  declarations: [...declarations],
  exports: [...declarations],
})
export class MapUtilsModule {}
