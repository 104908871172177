import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { DmarinClientOffer, DmarinClientPagination } from '@dm-workspace/types';
import { Injectable } from '@angular/core';

export type DMARIN_CLIENT_HOMEPAGE_SUPPORTED_LANGUAGES = 'tr' | 'hr' | 'de' | 'el' | 'en';

@Injectable({
  providedIn: 'root',
})
export class DmarinClientApiService {
  protected resourceUrl = `https://www.d-marin.com/api`;

  constructor(public httpClient: HttpClient) {}

  public getOffers(
    lang: DMARIN_CLIENT_HOMEPAGE_SUPPORTED_LANGUAGES
  ): Observable<DmarinClientPagination<DmarinClientOffer>> {
    return this.httpClient.get<DmarinClientPagination<DmarinClientOffer>>(`${this.resourceUrl}/offers`, {
      headers: {
        'Accept-Language': lang,
      },
    });
  }

  public getEvents(
    lang: DMARIN_CLIENT_HOMEPAGE_SUPPORTED_LANGUAGES
  ): Observable<DmarinClientPagination<DmarinClientOffer>> {
    return this.httpClient.get<DmarinClientPagination<DmarinClientOffer>>(`${this.resourceUrl}/events`, {
      headers: {
        'Accept-Language': lang,
      },
    });
  }

  public getOffersUrl(lang: DMARIN_CLIENT_HOMEPAGE_SUPPORTED_LANGUAGES) {
    const translatedStringOfferByLang = (() => {
      switch (lang) {
        case 'en':
          return 'offers';
        case 'de':
          return 'angeboten';
        case 'tr':
          return 'kampanyalar';
        case 'hr':
          return 'ponude';
        case 'el':
          return 'prosfores';
      }
    })();

    return `https://d-marin.com/${lang}/${translatedStringOfferByLang}`;
  }

  public getEventsUrl(lang: DMARIN_CLIENT_HOMEPAGE_SUPPORTED_LANGUAGES) {
    const translatedStringEventsByLang = (() => {
      switch (lang) {
        case 'en':
          return 'events';
        case 'de':
          return 'angebote';
        case 'tr':
          return 'etkinlikler';
        case 'hr':
          return 'dogadjaji';
        case 'el':
          return 'ekdhlwseis';
      }
    })();

    return `https://d-marin.com/${lang}/${translatedStringEventsByLang}`;
  }
}
