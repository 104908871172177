<h2 class="h1 mb-2 text-center">{{ "UTILITIES" | translate }}</h2>
<p class="text-center mb-4 text-center">{{ "GET_ADDITIONAL_SERVICES_FROM_SOCKETS" | translate }}</p>
<p class="warning mb-4" *ngIf="type === 'POSTPAID'">
  <i class="i-exclamation-mark-circle"></i>
  {{ "GET_ADDITIONAL_SERVICES_FROM_SOCKETS_WARNING" | translate }}
</p>
<div class="card d-flex justify-content-between align-items-start">
  <div>
    <h4>{{ "UTILITIES_INFRASTRUCTURE_USAGE" | translate }}</h4>
    @if (utilitiesEnabled) {
      <p [innerHTML]="'UTILITIES_INFRASTRUCTURE_USAGE_ENABLED' | translate"></p>
    } @else {
      <p [innerHTML]="'UTILITIES_INFRASTRUCTURE_USAGE_DISABLED' | translate"></p>
    }
  </div>
  <dm-form-slide-toggle [ngModel]="utilitiesEnabled" (ngModelChange)="utilitiesEnabledChange()" />
</div>
@if (utilitiesEnabled) {
  <dm-ui-horizontal-line />
  <table class="table table-desktop">
    <tbody>
      <ng-container *ngFor="let availableProduct of availableProducts">
        <tr
          *ngLet="isChecked(availableProduct) as isChecked"
          [class.checked]="isChecked"
          (click)="onProductChange(availableProduct)"
        >
          <td>
            {{ "MARINA_PRODUCT_." + availableProduct.productBillingType + "_" + availableProduct.product | translate }}
          </td>
          <td>
            {{ availableProduct.pricing.sumPriceGross | currency: availableProduct.pricing.currency }}
          </td>
          <td class="text-center">
            <div class="checkbox"></div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
}
