import { animate, group, state, style, transition, trigger } from '@angular/animations';

export const toggleHeightAnimation = trigger('toggleHeight', [
  state(
    'false',
    style({
      height: '0px',
      opacity: '0',
      overflow: 'hidden',
    })
  ),
  state(
    'true',
    style({
      height: '*',
      opacity: '1',
      overflow: 'visible',
    })
  ),
  transition(
    'false=> true',
    group([
      animate('300ms ease-in-out', style({ height: '*', opacity: 1 })),
      animate('300ms steps(1,end)', style({ overflow: 'visible' })),
    ])
  ),
  transition('true => false', [style({ overflow: 'hidden' }), animate('300ms ease-in-out')]),
]);
