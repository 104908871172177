@if (dates()?.toDate && dates()?.fromDate) {
  <p class="text-body-s text-body-lg-m fw-500">
    {{ dates()?.fromDate | dateFormat }} - {{ dates()?.toDate | dateFormat }}<br />
    <small class="text-secondary fw-400 text-body-xs text-body-lg-s"
      >({{ diff() }} {{ (diff() === 1 ? "NIGHT" : "NIGHTS") | translate }})</small
    >
  </p>
} @else {
  <p class="fw-400 text-body-s text-body-lg-m">{{ "START_DATE" | translate }} - {{ "END_DATE" | translate }}</p>
}
