import { ValidatorFn, AbstractControl } from '@angular/forms';

export function integerOnlyValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value as string;

    const regexp = new RegExp(/^[0-9]*$/i);

    return regexp.test(value)
      ? null
      : {
          integerOnly: {
            valid: false,
          },
        };
  };
}
