import { Pipe, PipeTransform } from '@angular/core';

import { MarinaTermsTypes } from '@dm-workspace/types';

@Pipe({
  name: 'ncpAgreementsLink',
})
export class NcpAgreementsLinkTextPipe implements PipeTransform {
  transform(type: MarinaTermsTypes | string): string {
    switch (type) {
      case MarinaTermsTypes.vexationClauses:
      case MarinaTermsTypes.vexationClausesRenewals:
        return 'VEXATION_CLAUSES';
      case MarinaTermsTypes.servicesRenewals:
        return 'TERMS_CONDITIONS_OF_SERVICES';
      case MarinaTermsTypes.privacyPolicy:
        return 'PRIVACY_POLICY';
      default:
        return 'TERMS_CONDITIONS_OF_' + type.toUpperCase();
    }
  }
}
