import { Pipe, PipeTransform } from '@angular/core';
import { QuotePromotionalOffers } from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';

const PROMOTIONAL_OFFER_TAG_COLOR_MAP: Record<QuotePromotionalOffers, TagColor> = {
  [QuotePromotionalOffers.HAPPY_BERTH_DAYS]: 'yellow-light',
};

@Pipe({
  name: 'promotionalOfferTagColor',
})
export class PromotionalOfferTagColorPipe implements PipeTransform {
  transform(offer: QuotePromotionalOffers): TagColor {
    return PROMOTIONAL_OFFER_TAG_COLOR_MAP[offer];
  }
}
