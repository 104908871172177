<div class="container">
  <h2 class="title">{{ "VERSION_UPDATE.TITLE" | translate }}</h2>
  <p>{{ "VERSION_UPDATE.BODY" | translate }}</p>

  <div class="footer">
    <button class="btn btn-primary btn-default" type="button" [disabled]="pending()" (click)="updateVersion()">
      <dm-ui-spinner class="me-2" [showSpinner]="pending()"/>
      {{ "VERSION_UPDATE.RELOAD_BUTTON" | translate }}
    </button>
  </div>
</div>
