import { Directive, ElementRef, Host, HostListener, Self } from '@angular/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[dmInputDatepicker][ngbDatepicker]',
})
// TODO: extend base InputDirective
export class InputDatepickerDirective {
  constructor(
    @Host() @Self() private ngbDatepicker: NgbInputDatepicker,
    @Host() @Self() private elementRef: ElementRef<HTMLElement>,
    @Host() @Self() private ngControl: NgControl
  ) {}

  @HostListener('click')
  onClick() {
    this.ngbDatepicker.toggle();
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    });
  }

  @HostListener('keydown.Backspace')
  @HostListener('keydown.Delete')
  onDelete() {
    this.ngControl.reset(null);
  }
}
