import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/**
 * @deprecated use InputValueComponent instead
 */
@Component({
  selector: 'dm-ui-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTextComponent {
  @Input() label!: string;
  @Input() text?: unknown;
  @Input() link?: string[];

  @HostBinding('class.inline')
  @Input()
  inline?: boolean;
  @HostBinding('class.dark')
  @Input()
  textBackground?: boolean;
}
