import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { LibsFormsModule } from '@dm-workspace/forms';
import { RadioBoatTypeComponent } from './components/radio-boat-type/radio-boat-type.component';
import { UiModule } from '@dm-workspace/ui';
import { RemainingHeightDirective } from './directives/reamianingHeight';
import { CalendarRangeComponent } from './components/callendar-range/calendar-range.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NcpDimensionsComponent } from './components/ncp-dimensions/ncp-dimensions.component';
import { NcpPeriodComponent } from './components/ncp-period/ncp-period.component';
import { AspectRatioHeightDirective } from './directives/aspectRatioHeight';
import { NcpDatesComponent } from './components/ncp-dates/ncp-dates.component';
import { NcpMarinaReviewsComponent } from './components/ncp-reviews/ncp-marina-reviews.component';
import { NcpAgreementsFormComponent } from './components/ncp-agreements-form/ncp-agreements-form.component';
import { NcpAgreementsLinkRequiredPipe } from './components/ncp-agreements-form/ncp-agreements-link-required.pipe';
import { NcpAgreementsLinkTextPipe } from './components/ncp-agreements-form/ncp-agreements-link-text.pipe';
import { NcpStepErrorComponent } from '../../../ncp-ui/src/lib/components/ncp-step-error/ncp-step-error.component';
import { NcpBerthMapItemComponent } from './components/ncp-berth-map-item/ncp-berth-map-item.component';
import { NcpBoatTypeTranslateKeyPipe } from './pipe/boat-type-translate-key.pipe';
import { DisableScrollDirective } from './directives/disable-scroll.directive';

const DECLARATIONS = [
  RemainingHeightDirective,
  NcpDimensionsComponent,
  NcpPeriodComponent,
  RadioBoatTypeComponent,
  DisableScrollDirective,
  NcpBoatTypeTranslateKeyPipe,
  NcpDatesComponent,
  AspectRatioHeightDirective,
  NcpAgreementsLinkRequiredPipe,
  NcpAgreementsLinkTextPipe,
  CalendarRangeComponent,
  NcpAgreementsFormComponent,
  NcpMarinaReviewsComponent,
  NcpStepErrorComponent,
  NcpBerthMapItemComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  exports: [LibsFormsModule, ...DECLARATIONS],
  imports: [CommonModule, TranslateModule, RouterOutlet, RouterLink, LibsFormsModule, UiModule, NgbDatepickerModule],
})
export class NcpSharedModule {}
