import { Pipe, PipeTransform } from '@angular/core';
import { EntityPaymentStatus } from '@dm-workspace/types';

@Pipe({
  name: 'entityPaymentStatusIcon',
})
export class EntityPaymentStatusIconPipe implements PipeTransform {
  transform(paymentStatus: EntityPaymentStatus | undefined): 'success' | 'error' | 'pending' {
    if (!paymentStatus) {
      return null;
    }
    switch (paymentStatus) {
      case 'Pending':
        return 'pending';
      case 'Success':
        return 'success';
      case 'Failed':
      case 'Expired':
      case 'Session_Expired':
      case 'No_Payment':
        return 'error';
      default:
        return null;
    }
  }
}
