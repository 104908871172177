import { Pipe, PipeTransform } from '@angular/core';
import { BoatSensorType } from '@dm-workspace/types';

@Pipe({
  name: 'sensorTypeTranslateKey',
})
export class SensorTypeTranslateKeyPipe implements PipeTransform {
  public transform(type: BoatSensorType | string): string {
    return `SENSOR_TYPES_.${type}`;
  }
}
