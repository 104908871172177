import { Pipe, PipeTransform } from '@angular/core';
import { MmsJournalTaskBookingStatusType } from '@dm-workspace/types';
import { TranslatePipe } from '@ngx-translate/core';
import { EnumUtils } from '@dm-workspace/utils';

@Pipe({
  name: 'journalTaskBookingStatusTypeTranslate',
})
export class JournalTaskBookingStatusTypeTranslate extends TranslatePipe implements PipeTransform {
  public transform(statusType: MmsJournalTaskBookingStatusType | undefined): string {
    if (!statusType) {
      return;
    }

    const key = EnumUtils.getKeyByValue(statusType, MmsJournalTaskBookingStatusType);
    return super.transform(key);
  }
}
