import { inject, Pipe, PipeTransform } from '@angular/core';
import { DateRange } from '@dm-workspace/types';
import { BookingHelperService } from '@dm-workspace/shared';

@Pipe({
  name: 'isBookingLongTerm',
})
export class IsBookingLongTermPipe implements PipeTransform {
  #bookingHelperService = inject(BookingHelperService);

  public transform(dates: DateRange, marinaCode?: string): boolean {
    return this.#bookingHelperService.isLongTerm(dates, marinaCode);
  }
}
