import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_TOKEN, AppToken, ENV, USER_TYPE_HEADER_NAME, USER_TYPE_HEADERS } from '@dm-workspace/types';
import { IEnvironment } from '@dm-workspace/environments';

@Injectable()
export class UserTypeInterceptor implements HttpInterceptor {
  private userTokenHeader = {
    [USER_TYPE_HEADER_NAME]: USER_TYPE_HEADERS[this.appName],
  };
  constructor(@Inject(APP_TOKEN) private appName: AppToken, @Inject(ENV) private env: IEnvironment) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'JSONP') {
      return next.handle(request);
    }

    if (request.url.includes(this.env.apiUrl) || request.withCredentials) {
      request.clone({
        setHeaders: this.userTokenHeader,
      });
    }

    return next.handle(request);
  }
}
