import { Pipe, PipeTransform } from '@angular/core';
import { EntityPaymentStatus } from '@dm-workspace/types';
import { PaymentStatusComponentType } from '../components/payment-status/payment-status.component';
@Pipe({
  name: 'paymentHistoryEntity',
})
export class PaymentErrorTypePipe implements PipeTransform {
  static getPaymentErrorTypeFromPaymentStatus(paymentStatus: EntityPaymentStatus): PaymentStatusComponentType {
    switch (paymentStatus) {
      case 'Pending':
        return 'warning';
      case 'Success':
        return 'success';
      case 'Expired':
      case 'Failed':
      case 'No_Payment':
      case 'Session_Expired':
        return 'error';
      default:
        return null;
    }
  }

  transform(paymentStatus?: EntityPaymentStatus): PaymentStatusComponentType {
    return PaymentErrorTypePipe.getPaymentErrorTypeFromPaymentStatus(paymentStatus);
  }
}
