import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { DateRange } from '@dm-workspace/types';
import { diffDatesInDays } from '@dm-workspace/utils';

@Component({
  selector: 'dm-ncp-dates',
  templateUrl: 'ncp-dates.component.html',
  styleUrl: 'ncp-dates.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpDatesComponent {
  dates = input<DateRange>();
  diff = computed(() =>
    this.dates().toDate && this.dates().toDate ? diffDatesInDays(this.dates().fromDate, this.dates().toDate) : 0
  );
}
