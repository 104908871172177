import { inject, Injectable, signal } from '@angular/core';
import { NotificationService } from '@dm-workspace/notification';
import { finalize, switchMap } from 'rxjs/operators';
import { ApiValidatorService } from '@dm-workspace/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { QuoteDetails, QuoteProduct } from 'libs/types/src';
import { ObGetQuoteByIdParams, ObQuotesApiService } from '@dm-workspace/data-access';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, map, share } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { MarinasService } from '@dm-workspace/core';

export type ObQuoteRouteSnapshot = ActivatedRouteSnapshot & {
  queryParams: ObGetQuoteByIdParams;
  params: { quoteId: string };
};

@Injectable({
  providedIn: 'root',
})
export class ObQuoteViewService {
  public readonly marinasService = inject(MarinasService);
  public readonly quote = signal<QuoteDetails>(null);
  public readonly routeSnapshot = signal<ObQuoteRouteSnapshot>(null);
  public readonly router = inject(Router);
  public readonly quoteMarina$ = toObservable(this.quote).pipe(
    filter(Boolean),
    switchMap((quote) => {
      return this.marinasService.marinas$.pipe(
        map((marinas) => marinas.find((marina) => marina.code === quote.marinaCode)),
        share()
      );
    })
  );
  public readonly fetching = signal<boolean>(false);
  private readonly quotesApiService = inject(ObQuotesApiService);

  constructor(
    private apiValidatorService: ApiValidatorService,
    private notificationService: NotificationService
  ) {}

  public setQuoteRouteSnapshot(routeSnapshot: ObQuoteRouteSnapshot) {
    this.fetching.set(true);
    this.routeSnapshot.set(routeSnapshot);

    const { params, queryParams } = routeSnapshot;
    this.quotesApiService
      .getById(params.quoteId, queryParams)
      .pipe(finalize(() => this.fetching.set(false)))
      .subscribe({
        next: (quote) => {
          this.quote.set({
            ...quote,
            data: this.filterNonPriceProducts(quote.data),
          });
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidatorService.getApiNotificationError(res);
          this.notificationService.openError(notificationContent);
          this.router.navigateByUrl('/');
        },
      });
  }

  private filterNonPriceProducts(products: QuoteProduct[]) {
    return products.filter((product) => product.totalPriceGross);
  }

  public onDeactivateRoute() {
    this.reset();
  }

  private reset() {
    this.quote.set(null);
    this.routeSnapshot.set(null);
    this.fetching.set(false);
  }
}
