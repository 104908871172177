export enum QuotationStatus {
  INCOMPLETE = 'INCOMPLETE',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_REJECTED = 'CHECK_IN_REJECTED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OFFLINE = 'CHECKED_OFFLINE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  BLOCKED = 'BLOCKED',
  UNANNOUNCED_ARRIVAL = 'UNANNOUNCED_ARRIVAL',
}
