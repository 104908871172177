import { Injectable } from '@angular/core';
import {
  AuthLostPasswordDto,
  IAuthConfirm,
  IAuthLoginUserRequest,
  IAuthLoginUserResponse,
  CpAuthRegisterCustomerDto,
  IAuthResetPassword,
  IAuthSetUpPassword,
} from '@dm-workspace/types';
import { map, Observable } from 'rxjs';
import { ApiClientService } from '../api/api-client.service';
import { IAuthUser } from '@dm-workspace/types';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly resourceUrl = '/auth';

  constructor(private api: ApiClientService) {}

  public login(loginData: IAuthLoginUserRequest): Observable<IAuthLoginUserResponse> {
    return this.api.post<IAuthLoginUserResponse>(`${this.resourceUrl}/login`, loginData);
  }

  public refreshToken(refresh_token: string): Observable<IAuthLoginUserResponse> {
    return this.api.post<IAuthLoginUserResponse>(`${this.resourceUrl}/refresh-token`, { refresh_token });
  }

  public registerCustomer(dto: CpAuthRegisterCustomerDto): Observable<IAuthUser> {
    return this.api.post(`${this.resourceUrl}/register-customer`, dto);
  }

  public confirmCustomer(dto: IAuthConfirm): Observable<IAuthUser> {
    return this.api.post(`${this.resourceUrl}/confirm-customer`, dto);
  }

  public resetPassword(dto: IAuthResetPassword): Observable<void> {
    return this.api.post(`${this.resourceUrl}/password-reset-confirm`, dto);
  }

  public setUpPassword(dto: IAuthSetUpPassword): Observable<void> {
    return this.api.post(`${this.resourceUrl}/set-up-password`, dto);
  }

  public lostPassword(dto: AuthLostPasswordDto): Observable<null> {
    return this.api.post(`${this.resourceUrl}/password-reset-init`, dto);
  }

  public decryptEmail(params: HttpParams): Observable<string> {
    return this.api
      .get<{ email: string }>(`${this.resourceUrl}/decrypt-email?${params}`)
      .pipe(map((item) => item.email));
  }
}
