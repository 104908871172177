import { ChangeDetectionStrategy, Component, forwardRef, HostListener } from '@angular/core';
import { RadioButtonClass } from '@dm-workspace/forms';

@Component({
  selector: 'dm-ob-form-checkbox-card',
  templateUrl: './ob-form-radio-card.component.html',
  styleUrl: './ob-form-radio-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: RadioButtonClass, useExisting: forwardRef(() => ObFormRadioCardComponent) }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.disabled]': 'isDisabled()',
    '[class.selected]': 'checked()',
  },
})
export class ObFormRadioCardComponent extends RadioButtonClass {
  @HostListener('click')
  override onModelChange() {
    super.onModelChange();
  }
}
