import { ValidatorFn, AbstractControl } from '@angular/forms';
import CodiceFiscale from 'codice-fiscale-js';

export function fiscalCodeValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const valid = CodiceFiscale.check(control.value);

    return valid
      ? null
      : {
          fiscalCode: {
            valid: false,
          },
        };
  };
}
