import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(enumType: { [key: string]: string }, translate = true): { key: string; label: string }[] {
    return Object.keys(enumType).map((key) => ({
      key,
      label: translate ? this.translateService.instant(key.toUpperCase()) : enumType[key],
    }));
  }
}
