import { ChangeDetectionStrategy, Component, computed, input, Input } from '@angular/core';
import { diffDatesInDays } from '@dm-workspace/utils';

@Component({
  selector: 'dm-ui-relative-date-formatter',
  templateUrl: './relative-date-formatter.component.html',
  styleUrls: ['./relative-date-formatter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelativeDateFormatterComponent {
  date = input.required<Date | string>();
  @Input() daysAgoLimit = 5;
  @Input() dateFormat = 'dd MMM yy';

  protected daysAgo = computed(() => {
    return diffDatesInDays(this.date(), new Date());
  });
}
