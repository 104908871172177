import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dm-ui-availability-info',
  templateUrl: './availability-info.component.html',
  styleUrls: ['./availability-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityInfoComponent {
  @Input() available?: boolean | null;
  @Input() availableHeader: string;
  @Input() unavailableHeader: string;
  @Input() availableDescription: string;
  @Input() unavailableDescription: string;
  @Input() reasons: string[];
}
