import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dm-ui-double-arrow-line',
  templateUrl: './double-arrow-line.component.html',
  styleUrls: ['./double-arrow-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoubleArrowLineComponent {
  @HostBinding('class.focused')
  @Input()
  focused = false;
}
